import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CostService {
  constructor(private http: HttpClient) { }

  getCostReport(from, to): Observable<any> {
    return this.http.get(environment.appUrl + '/reports/tripcosts?fromDate=' + from + '&toDate=' + to + "&branchCodes=" + JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")));
  }

}
