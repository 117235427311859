import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import {
  MatPaginator, MatTableDataSource,
  MatDialog, MatDialogRef, MAT_DIALOG_DATA
} from '@angular/material';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import { DataSource } from '@angular/cdk/collections';
import { OrderService } from '../../services/order/order.service';
import { AllorderdetailsComponent } from './allorderdetails/allorderdetails.component';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {
  datefilter: string
  selectedVal: string
  mapView: boolean = false;
  @Output() notifyParent: EventEmitter<any> = new EventEmitter();

  constructor(
    public dialog: MatDialog,
    public orderService: OrderService
  ) { }  

  ngOnInit() {
    this.selectedVal ='1week';
  }
  toogleView() {
    if (!this.mapView) {
      this.mapView = true;
      console.log(this.mapView);
    } else {
      this.mapView = false
      console.log(this.mapView);
    }
  }

  onValChange(e) {
     this.notifyParent.emit('e');
  }

  // ngAfterViewInit() {
  //   this.orderDataArray.paginator = this.paginator;
  // }



}

