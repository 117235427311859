import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { TokenStorage } from 'src/app/auth/token.storage';
import { PlanService } from 'src/app/services/plan/plan.service';

@Component({
  selector: 'app-plandeviations',
  templateUrl: './plandeviations.component.html',
  styleUrls: ['./plandeviations.component.scss']
})
export class PlandeviationsComponent implements OnInit {
  planName: any;
  planId: any;
  originalPlan: any;
  updatedPlan: any;
  dataFlag: number = 0;
  //color object for route
  colorArray = ["0700F9", 'C100F9', 'F90000', '13D505', 'BCB71B',
    'F9008D', '191591', '81380D', 'F9A600', 'BE0B7C',
    'F85F18', '73892E', '3F2472', '7A3F93', '3945A1',
    '3F292B', '134E28', 'AA6000', '03045E', '6F4E00', '4743DE'];
  currencySymbol = "₹"
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private planService: PlanService,
    private datePipe: DatePipe,
    private router: Router,
    private token: TokenStorage,
    private dialog: MatDialog,
    private notifierService: NotifierService,
    private spinnerService: NgxSpinnerService
  ) { }

  ngOnInit() {
    console.log(this.data)
    this.planName = this.data.data.planName;
    this.planId = this.data.data.planId;
    this.getComparePlanDetails();
  }

  getComparePlanDetails() {
    this.dataFlag = 0;
    this.planService.getComparePlan(this.planId).subscribe(res => {
      this.dataFlag = 1;
      console.log(res);
      this.originalPlan = res.originalPlans[0];
      this.updatedPlan = res.updatedPlans[0];


      this.originalPlan.numberOfVehiclesUsed = this.originalPlan.tripList.length;
      this.updatedPlan.numberOfVehiclesUsed = this.updatedPlan.tripList.length;

      for (let i = 0; i < this.originalPlan.tripList.length; i++) {
        this.originalPlan.tripList[i].state = 'default';
        this.originalPlan.tripList[i].isChecked = false;
        this.originalPlan.tripList[i].routeColor = this.colorArray[i];
        this.originalPlan.tripList[i].approved = this.originalPlan.tripList[i].tState === 'TRIP_STATE_APPROVED' || this.originalPlan.tripList[i].tState === 'TRIP_STATE_IN_TRANSIT'
          || this.originalPlan.tripList[i].tState === 'TRIP_STATE_SCHEDULED' || this.originalPlan.tripList[i].tState === 'TRIP_STATE_COMPLETED' ? true : false;
      }

      for (let i = 0; i < this.updatedPlan.tripList.length; i++) {
        this.updatedPlan.tripList[i].state = 'default';
        this.updatedPlan.tripList[i].isChecked = false;
        this.updatedPlan.tripList[i].routeColor = this.colorArray[i];
        this.updatedPlan.tripList[i].approved = this.updatedPlan.tripList[i].tState === 'TRIP_STATE_APPROVED' || this.updatedPlan.tripList[i].tState === 'TRIP_STATE_IN_TRANSIT'
          || this.updatedPlan.tripList[i].tState === 'TRIP_STATE_SCHEDULED' || this.updatedPlan.tripList[i].tState === 'TRIP_STATE_COMPLETED' ? true : false;
      }
      console.log(this.originalPlan)
    },
      (err) => {
        this.dataFlag = -1;
      }
    );
  }

  customerList(jobList: any) {
    let customerNameList = [];

    for (let k = 0; k < jobList.length; k++) {
      if (jobList[k].customerInfo) {
        if (!customerNameList.includes(jobList[k].customerInfo.customerName + " - " + jobList[k].customerInfo.address.city)) {
          customerNameList.push(jobList[k].customerInfo.customerName + " - " + jobList[k].customerInfo.address.city)
        }
      }
    }
    return customerNameList;
  }

  editTrip(index: number) {
    console.log(this.originalPlan.tripList[index])

  }
}
