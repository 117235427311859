import { Component, OnInit } from '@angular/core';
import { OrderService } from '../../services/order/order.service';
import { Chart } from 'chart.js';
import 'chart.piecelabel.js';
import { MatTableDataSource } from '@angular/material';
import { TokenStorage } from 'src/app/auth/token.storage';
import { DomSanitizer } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { TripService } from 'src/app/services/trip/trip.service';
import { BillingService } from 'src/app/services/billing/billing.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  columnsToDisplay = ["zone", "ordercount"]
  zoneUnplannedDataArrayForDisplay = new MatTableDataSource;
  zoneUnplannedDataArray = [];
  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  orderLineChart = [];
  fleetBarChart = [];
  donutZone = [];
  totaldeliveredcount: number = 0;
  totalundeliveredcount: number = 0;
  totalpendingcount: number = 0;
  onTimeDeliveries: number;
  avgTripTime: number;
  totalTripsCompleted: number;
  isMetro: boolean = false;
  isKcc: boolean = false;
  powerBiUrl: any;
  fromDate: any;
  toDate: any;
  inPlanningCount: number;
  scheduledCount: number;
  intransitCount: number;
  completedCount: number;
  incomplete: number;
  trpReviewCount: number;
  scReviewCount: number;
  approvedCount: number;
  dashboardFilter:any;
  options:any= {
    theme: 'default',
    labels: ['Start', 'End'],
    menu: null,
    dateFormat: 'YYYY-MM-DD',
    outputFormat: 'DD-MM-YYYY',
    startOfWeek: 1,
    outputType: 'object',
    locale: 'en',
    date: {
      from: {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        day: new Date().getDate()
      },
      to: {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        day: new Date().getDate()
      }
    }
  };

  totalPODCompleted:any=0;
  totalPODPending:any=0;
  totalPODUnclean:any=0;
  podBarChart: Chart=[];
  isDateFiltered:boolean = false;

  //chart Params
  eastComplianceName:any=[];
  westComplianceName:any=[];
  northComplianceName:any=[];
  southComplianceName:any=[];

  eastComplianceData:any=[];
  westComplianceData:any=[];
  northComplianceData:any=[];
  southComplianceData:any=[];

  eastComplianceChart:any=[];
  westComplianceChart:any=[];
  northComplianceChart:any=[];
  southComplianceChart:any=[];

  eastComplianceColor:any=[];
  westComplianceColor:any=[];
  northComplianceColor:any=[];
  southComplianceColor:any=[];



  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    public orderService: OrderService,
    private token: TokenStorage,
    private sanitizer: DomSanitizer,
    public datePipe: DatePipe,
    public tripService: TripService,
    private billingService: BillingService,

  ) {
    if (this.token.getTenant() == 'metro-dist-prod' || this.token.getTenant() == 'metro-dist-test') {
      this.isMetro = true;
      this.powerBiUrl = this.sanitizer.bypassSecurityTrustResourceUrl("https://app.powerbi.com/view?r=eyJrIjoiMTAyNzY0OTMtYzM4MS00ZWQ2LWJkMjgtZGI2YzZhNDY3MWUyIiwidCI6ImI0NTk5ZGVkLTM4N2EtNGI4OS1iMWZmLWVmN2YzMjk4MDZmNCJ9");
    }

    if (this.token.getTenant() == 'kimberly-dist-prod') {
      this.isKcc = true;
    }
  }

  ngOnInit() {
    // let date, last, day, month, year;
    // date = new Date();
    // last = new Date(date.getTime() - (90 * 24 * 60 * 60 * 1000));
    // day = last.getDate();
    // month = last.getMonth();
    // year = last.getFullYear();
    // this.fromDate = this.datePipe.transform(new Date(year, month, day), 'yyyy-MM-dd');
    // this.toDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.fromDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.toDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
   
    this.getDeliveredAndUndeliveredCount();

    if (!this.isMetro) {
      this.orderService.getDashbordData().subscribe(getData => {
        this.onTimeDeliveries = getData[0].ontimedelivery;
        this.avgTripTime = getData[0].avgTripTime;
        this.totalTripsCompleted = getData[0].tripsCompleted;
      });
      this.totalRecords();
      this.getBillCount();
      this.getBranchDashboardData();
      this.lineOrderChart();
      this.podUploadsData();
    }
  }

  sortByDate() {
    console.log(this.dashboardFilter);
    if (this.dashboardFilter) {
      this.fromDate = this.dashboardFilter.from.substring(6, 10) + '-' + this.dashboardFilter.from.substring(3, 5) + '-' + this.dashboardFilter.from.substring(0, 2);
      this.toDate = this.dashboardFilter.to.substring(6, 10) + '-' + this.dashboardFilter.to.substring(3, 5) + '-' + this.dashboardFilter.to.substring(0, 2);
      console.log(this.podBarChart)
      if(this.podBarChart) 
        this.podBarChart.destroy();

        this.isDateFiltered = true;
      this.getDeliveredAndUndeliveredCount();
      this.totalRecords();
      this.getBillCount();
      this.getBranchDashboardData();
      this.podUploadsData();

    }
  }

  clearAllFilter() {
    this.createInitialFilter();
    // this.sortByDate()
    this.isDateFiltered = false;
  }


  podUploadsData()
  {
    this.westComplianceName = [];
    this.westComplianceData = [];
    this.westComplianceColor= [];
    this.eastComplianceName = [];
    this.eastComplianceData = [];
    this.eastComplianceColor = [];
    this.southComplianceName = [];
    this.southComplianceData = [];
    this.southComplianceColor = [];
    this.northComplianceName = [];
    this.northComplianceData = [];
    this.northComplianceColor = [];


    this.getData(this.fromDate, this.toDate).pipe(takeUntil(this.ngUnsubscribe)).subscribe(orders => {
      console.log("Pod Compliance", orders);
      for(let i=0;i<orders.length;i++)
      {
        let item = orders[i];

        if(item.branch_region == 'West')
        {
          this.westComplianceName.push(item.branch_name);
          this.westComplianceData.push(item.pod_compliance_percent);
          if(item.pod_compliance_percent<70)
            this.westComplianceColor.push('#dc3545') // for danger
          else if(item.pod_compliance_percent>=70 && item.pod_compliance_percent<=90)
            this.westComplianceColor.push('#ffc107') // for warning
          else if(item.pod_compliance_percent>90)
            this.westComplianceColor.push('#28a745') // for success
        }
        else if(item.branch_region == 'East')
        {
          this.eastComplianceName.push(item.branch_name);
          this.eastComplianceData.push(item.pod_compliance_percent);
          if(item.pod_compliance_percent<70)
            this.eastComplianceColor.push('#dc3545') // for danger
          else if(item.pod_compliance_percent>=70 && item.pod_compliance_percent<=90)
            this.eastComplianceColor.push('#ffc107') // for warning
          else if(item.pod_compliance_percent>90)
            this.eastComplianceColor.push('#28a745') // for success

          

        }
        else if(item.branch_region == 'South')
        {
          this.southComplianceName.push(item.branch_name);
          this.southComplianceData.push(item.pod_compliance_percent);
          if(item.pod_compliance_percent<70)
            this.southComplianceColor.push('#dc3545') // for danger
          else if(item.pod_compliance_percent>=70 && item.pod_compliance_percent<=90)
            this.southComplianceColor.push('#ffc107') // for warning
          else if(item.pod_compliance_percent>90)
            this.southComplianceColor.push('#28a745') // for success
          
          
        }
        else if(item.branch_region == 'North')
        {
          this.northComplianceName.push(item.branch_name);
          this.northComplianceData.push(item.pod_compliance_percent);
          if(item.pod_compliance_percent<70)
            this.northComplianceColor.push('#dc3545') // for danger
          else if(item.pod_compliance_percent>=70 && item.pod_compliance_percent<=90)
            this.northComplianceColor.push('#ffc107') // for warning
          else if(item.pod_compliance_percent>90)
            this.northComplianceColor.push('#28a745') // for success
        }
      }
      this.setNorthChart();
      this.setSouthChart();
      this.setEastChart();
      this.setWestChart();
    }, Error => {
      // this.isLoading = false;
    })
  }

  setNorthChart()
  { 
    if(this.northComplianceChart instanceof Chart)
    {
      this.northComplianceChart.destroy();
    }

    this.northComplianceChart = new Chart('northCompliance', {
      type: "bar",
      "data": {
        "labels": this.northComplianceName,
        "datasets": [
          {
            "label": "Compliance",
            "data": this.northComplianceData,
            "backgroundColor": this.northComplianceColor,
            "borderWidth": 1,
          }
        ]
      },

      options: {
        tooltips: {
          enabled: true,
          mode : 'index'
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              userCallback: function (label, index, labels) {
                if (Math.floor(label) === label) {
                  return label;
                }
              }
            },
            gridLines: {
              display: false
           },
           scaleLabel: {
            display: true,
            labelString: '% of  POD Compliance',
            fontSize: 12,
            fontColor: "black"
          }
          }],
          xAxes: [{
            //  barThickness: 15,
            ticks: {
              autoSkip: false,
              fontSize: 13
              /*  maxRotation: 40,
               minRotation: 40 */
            },
            gridLines: {
              display: false
           }
          }]
        },

        legend: {
          display: false
        }
      }
    })
  }

  setSouthChart()
  { 
    if(this.southComplianceChart instanceof Chart)
    {
      this.southComplianceChart.destroy();
    }

    this.southComplianceChart = new Chart('southCompliance', {
      type: "bar",
      "data": {
        "labels": this.southComplianceName,
        "datasets": [
          {
            "label": "Compliance",
            "data": this.southComplianceData,
            "backgroundColor": this.southComplianceColor,
            "borderWidth": 1,
          }
        ]
      },

      options: {
        tooltips: {
          enabled: true,
          mode : 'index'
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              userCallback: function (label, index, labels) {
                if (Math.floor(label) === label) {
                  return label;
                }
              }
            },
            gridLines: {
              display: false
           },
           scaleLabel: {
            display: true,
            labelString: '% of  POD Compliance',
            fontSize: 12,
            fontColor: "black"
          }
          }],
          xAxes: [{
            //  barThickness: 15,
            ticks: {
              autoSkip: false,
              fontSize: 13
              /*  maxRotation: 40,
               minRotation: 40 */
            },
            gridLines: {
              display: false
           }
          }]
        },

        legend: {
          display: false
        }
      }
    })
  }

  setEastChart()
  { 
    if(this.eastComplianceChart instanceof Chart)
    {
      this.eastComplianceChart.destroy();
    }

    this.eastComplianceChart = new Chart('eastCompliance', {
      type: "bar",
      "data": {
        "labels": this.eastComplianceName,
        "datasets": [
          {
            "label": "Compliance",
            "data": this.eastComplianceData,
            "backgroundColor": this.eastComplianceColor,
            "borderWidth": 1,
          }
        ]
      },

      options: {
        tooltips: {
          enabled: true,
          mode : 'index'
        },
        legend: {
          display: false
       },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              userCallback: function (label, index, labels) {
                if (Math.floor(label) === label) {
                  return label;
                }
              }
            },
            gridLines: {
              display: false
           },
           scaleLabel: {
            display: true,
            labelString: '% of  POD Compliance',
            fontSize: 12,
            fontColor: "black"
          }
          }],
          xAxes: [{
            //  barThickness: 15,
            ticks: {
              autoSkip: false,
              fontSize: 13
              /*  maxRotation: 40,
               minRotation: 40 */
            },
            gridLines: {
              display: false
           }
          }]
        },
      }
    })
  }

  setWestChart()
  { 
    if(this.westComplianceChart instanceof Chart)
    {
      this.westComplianceChart.destroy();
    }

    this.westComplianceChart = new Chart('westCompliance', {
      type: "bar",
      "data": {
        "labels": this.westComplianceName,
        "datasets": [
          {
            "label": "Compliance",
            "data": this.westComplianceData,
            "backgroundColor": this.westComplianceColor,
            "borderWidth": 1,
          }
        ]
      },

      options: {
        tooltips: {
          enabled: true,
          mode : 'index'
        },
        legend: {
          display: false
       },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              userCallback: function (label, index, labels) {
                if (Math.floor(label) === label) {
                  return label;
                }
              }
            },
            gridLines: {
              display: false
           },
           scaleLabel: {
            display: true,
            labelString: '% of  POD Compliance',
            fontSize: 12,
            fontColor: "black"
          }
          }],
          xAxes: [{
            //  barThickness: 15,
            ticks: {
              autoSkip: false,
              fontSize: 13
              /*  maxRotation: 40,
               minRotation: 40 */
            },
            gridLines: {
              display: false
           }
          }]
        }
      }
    })
  }

  getData(from, to) {
    return this.orderService.getPodComplianceReport(from, to);
  }

  createInitialFilter() {
    this.options = {
      theme: 'default',
      labels: ['Start', 'End'],
      menu: null,
      dateFormat: 'YYYY-MM-DD',
      outputFormat: 'DD-MM-YYYY',
      startOfWeek: 1,
      outputType: 'object',
      locale: 'en',
      date: {
        from: {
          year: new Date().getFullYear(),
          month: new Date().getMonth() + 1,
          day: new Date().getDate()
        },
        to: {
          year: new Date().getFullYear(),
          month: new Date().getMonth() + 1,
          day: new Date().getDate()
        }
      }
    };
    this.fromDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.toDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.dashboardFilter.from = this.datePipe.transform(new Date(), 'dd-MM-yyyy');
    this.dashboardFilter.to = this.datePipe.transform(new Date(), 'dd-MM-yyyy');
    this.sortByDate();
  }

  getDeliveredAndUndeliveredCount() {
    this.orderService.getOrderCountByStatus(this.fromDate, this.toDate).subscribe(res => {
      console.log("order count by status", res);
      if (this.searchArraryForCount(res, 'ORDER_STATE_FAILED') != undefined) {
        this.totalundeliveredcount = this.searchArraryForCount(res, 'ORDER_STATE_FAILED').ordercount;
      }

      if (this.searchArraryForCount(res, 'ORDER_STATE_DELIVERED') != undefined) {
        this.totaldeliveredcount = this.searchArraryForCount(res, 'ORDER_STATE_DELIVERED').ordercount;
      }

      if (this.searchArraryForCount(res, 'ORDER_STATE_SCHEDULED') != undefined || this.searchArraryForCount(res, 'ORDER_STATE_IN_TRANSIT') != undefined) {
        let schedulecount = 0, intransitcount = 0;
        if (this.searchArraryForCount(res, 'ORDER_STATE_SCHEDULED'))
          schedulecount = this.searchArraryForCount(res, 'ORDER_STATE_SCHEDULED').ordercount;
        if (this.searchArraryForCount(res, 'ORDER_STATE_IN_TRANSIT'))
          intransitcount = this.searchArraryForCount(res, 'ORDER_STATE_IN_TRANSIT').ordercount
        this.totalpendingcount = schedulecount + intransitcount;
      }
    });
  }

  
  getBranchDashboardData()
  {
    this.orderService.getBranchDashboardData(this.fromDate, this.toDate).subscribe(res => {
     

      console.log("**************")
      console.log(res)
      console.log("**************")
      this.totalPODCompleted=0;
      this.totalPODPending=0;
      this.totalPODUnclean=0;
      let branchName = [];
      let delivered = [];
      let intransit = [];
      let pod_completed = [];
      let unplanned = [];
      this.podBarChart=null;
      if(this.podBarChart instanceof Chart)
      {
        this.podBarChart.destroy();
      }
      for(let i=0;i<res.length;i++)
      {
        this.totalPODCompleted += res[i].pod_completed;
        this.totalPODPending += res[i].pod_pending;
        this.totalPODUnclean += res[i].pod_unclean;
        branchName.push(res[i].branch_name);
        intransit.push(res[i].intransit);

        if(res[i].pod_completed != null)
          pod_completed.push(res[i].pod_completed);
        else 
          pod_completed.push(0);

        if(res[i].delivered != null)
          delivered.push(res[i].delivered);
        else
          delivered.push(0)

        let total_unpalnned = 0;
        let total_inTransit = 0;
        if(res[i].unplanned != null)
          total_unpalnned = res[i].unplanned;
        else
          total_unpalnned = 0;

        if(res[i].intransit != null)
          total_inTransit = res[i].intransit;
        else
          total_inTransit = 0;

        let total_pending = total_inTransit + total_unpalnned;
        unplanned.push(total_pending);
      }


      this.podBarChart = new Chart('canvasBar', {

        type: "bar",
        "data": {
          "labels": branchName,
          "datasets": [
            {
              "label": "Pending",
              "data": unplanned,
              "backgroundColor": '#17a2b8',
              "borderWidth": 1,
            },
            {
              "label": "Delivered",
              "data": delivered,
              "backgroundColor": '#007bff',
              "borderWidth": 1,
            },
            {
              "label": "POD Completed",
              "data": pod_completed,
              "backgroundColor": '#f27173',
              "borderWidth": 1,
            }
          ]
        },

        options: {
          tooltips: {
            enabled: true,
            mode : 'index'
          },
          // "hover": {
          //   "animationDuration": 0
          // },
          // "animation": {
          //   "duration": 1,
          //   "onComplete": function () {
          //     var chartInstance = this.chart,
          //       ctx = chartInstance.ctx;

          //     ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
          //     ctx.textAlign = 'center';
          //     ctx.textBaseline = 'bottom';

          //     this.data.datasets.forEach(function (dataset, i) {
          //       var meta = chartInstance.controller.getDatasetMeta(i);
          //       meta.data.forEach(function (bar, index) {
          //         var data = dataset.data[index];
          //         ctx.fillText(data, bar._model.x, bar._model.y - 5);
          //       });
          //     });
          //   }
          // },

          responsive: true,
          maintainAspectRatio: false,
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true,
                userCallback: function (label, index, labels) {
                  if (Math.floor(label) === label) {
                    return label;
                  }
                }
              },
              gridLines: {
                display: false
             },
             scaleLabel: {
              display: true,
              labelString: '# of Orders',
              fontSize: 15,
              fontColor: "black"
            }
            }],
            xAxes: [{
              //  barThickness: 15,
              ticks: {
                autoSkip: false,
                fontSize: 13
                /*  maxRotation: 40,
                 minRotation: 40 */
              },
              gridLines: {
                display: false
             }
            }]
          },

          legend: {
            display: true
          }
        }
      })
    });
      
  }


  totalRecords() {
    this.tripService.getTripCountByStatus(this.fromDate, this.toDate).subscribe(res => {
      console.log("trip count by status", res);
      this.inPlanningCount = 0;
      this.scheduledCount = 0;
      this.intransitCount = 0;
      this.completedCount = 0;
      this.incomplete = 0;
      if (this.searchTripArraryForCount(res, 'TRIP_STATE_IN_PLANNING') != undefined) {
        if (this.searchTripArraryForCount(res, 'TRIP_STATE_APPROVED') != undefined)
          this.inPlanningCount = this.searchTripArraryForCount(res, 'TRIP_STATE_IN_PLANNING').tripcount + this.searchTripArraryForCount(res, 'TRIP_STATE_APPROVED').tripcount;
        else
          this.inPlanningCount = this.searchTripArraryForCount(res, 'TRIP_STATE_IN_PLANNING').tripcount;
      }
      else if (this.searchTripArraryForCount(res, 'TRIP_STATE_APPROVED') != undefined) {
        if (this.searchTripArraryForCount(res, 'TRIP_STATE_IN_PLANNING') != undefined)
          this.inPlanningCount = this.searchTripArraryForCount(res, 'TRIP_STATE_APPROVED').tripcount + this.searchTripArraryForCount(res, 'TRIP_STATE_IN_PLANNING').tripcount;
        else
          this.inPlanningCount = this.searchTripArraryForCount(res, 'TRIP_STATE_APPROVED').tripcount;
      }
      if (this.searchTripArraryForCount(res, 'TRIP_STATE_SCHEDULED') != undefined) {
        this.scheduledCount = this.searchTripArraryForCount(res, 'TRIP_STATE_SCHEDULED').tripcount;
      }
      if (this.searchTripArraryForCount(res, 'TRIP_STATE_IN_TRANSIT') != undefined) {
        this.intransitCount = this.searchTripArraryForCount(res, 'TRIP_STATE_IN_TRANSIT').tripcount;
      }
      if (this.searchTripArraryForCount(res, 'TRIP_STATE_COMPLETED') != undefined) {
        this.completedCount = this.searchTripArraryForCount(res, 'TRIP_STATE_COMPLETED').tripcount;
      }
      if (this.searchTripArraryForCount(res, 'TRIP_STATE_INCOMPLETE') != undefined) {
        this.incomplete = this.searchTripArraryForCount(res, 'TRIP_STATE_INCOMPLETE').tripcount;
      }
    });
  }



  searchTripArraryForCount(arr, value) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].tripstatus === value) {
        return arr[i];
      }
    }
  }


  // Get count for billing
  getBillCount() {
    this.billingService.getBillCount(this.fromDate, this.toDate,"").subscribe(res => {
      this.trpReviewCount = 0;
      this.scReviewCount = 0;
      this.approvedCount = 0;

      this.trpReviewCount = this.searchBillingArraryForCount(res, 'BILL_STATE_INITIAL') + this.searchBillingArraryForCount(res, 'BILL_STATE_IN_TRANSPORTER_REVIEW');

      this.scReviewCount = this.searchBillingArraryForCount(res, 'BILL_STATE_IN_SC_REVIEW')
        + this.searchBillingArraryForCount(res, 'BILL_STATE_PENDING_SC_APPROVAL');
      + this.searchBillingArraryForCount(res, 'BILL_STATE_TRANSPORTER_APPROVED')

      this.approvedCount = this.searchBillingArraryForCount(res, 'BILL_STATE_SC_APPROVED')
        + this.searchBillingArraryForCount(res, 'BILL_STATE_RAISED');   
    });
  }

  searchBillingArraryForCount(arr, value) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].billstatus === value) {
        return arr[i].total_number_of_bills;
      }
    }
    return 0;
  }



  searchArraryForCount(arr, value) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].orderstatus === value) {
        return arr[i];
      }
    }
  }


  // barfleetChart() {
  //   this.orderService.getUtilizationTrend().subscribe(res => {
  //     let totalFleets = [], totalFleetUtilization = [], dateArray = [], totalOrders = [];
  //     res.forEach(utilization => {
  //       totalFleets.push(utilization.totalFleets);
  //       totalFleetUtilization.push(utilization.avgFleetUtilization.slice(0, -1));
  //       dateArray.push(utilization.deliverydate);
  //       totalOrders.push(utilization.totalOrders);
  //     });
  //     var ctx = document.getElementById("canvasbar");

  //     this.fleetBarChart = new Chart('canvasbar', {

  //       type: "bar",
  //       "data": {
  //         "labels": dateArray,
  //         "datasets": [
  //           {
  //             "label": "Total Orders",
  //             "data": totalOrders,
  //             "fill": false,
  //             "backgroundColor": this.getRandomColor(),
  //             "borderWidth": 1,
  //           },
  //           {
  //             "label": "# Of Fleets",
  //             "data": totalFleets,
  //             "fill": false,
  //             "backgroundColor": this.getRandomColor(),
  //             "borderWidth": 1,
  //           }, {
  //             "label": "Avg Fleet Utilization",
  //             "data": totalFleetUtilization,
  //             "fill": false,
  //             "backgroundColor": this.getRandomColor(),
  //             "borderWidth": 1,
  //           }
  //         ]
  //       },

  //       options: {
  //         tooltips: {
  //           enabled: false
  //         },
  //         "hover": {
  //           "animationDuration": 0
  //         },
  //         "animation": {
  //           "duration": 1,
  //           "onComplete": function () {
  //             var chartInstance = this.chart,
  //               ctx = chartInstance.ctx;

  //             ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
  //             ctx.textAlign = 'center';
  //             ctx.textBaseline = 'bottom';

  //             this.data.datasets.forEach(function (dataset, i) {
  //               var meta = chartInstance.controller.getDatasetMeta(i);
  //               meta.data.forEach(function (bar, index) {
  //                 var data = dataset.data[index];
  //                 ctx.fillText(data, bar._model.x, bar._model.y - 5);
  //               });
  //             });
  //           }
  //         },

  //         responsive: true,
  //         maintainAspectRatio: false,
  //         scales: {
  //           yAxes: [{
  //             ticks: {
  //               beginAtZero: true,
  //               userCallback: function (label, index, labels) {
  //                 if (Math.floor(label) === label) {
  //                   return label;
  //                 }
  //               }
  //             }
  //           }],
  //           xAxes: [{
  //             //  barThickness: 15,
  //             ticks: {
  //               autoSkip: false,
  //               /*  maxRotation: 40,
  //                minRotation: 40 */
  //             },
  //             gridLines: {
  //               offsetGridLines: true
  //             }
  //           }]
  //         },

  //         legend: {
  //           display: true
  //         }
  //       }
  //     })
  //   })
  // }

  getRandomColor() {
    var color = Math.floor(0x1000000 * Math.random()).toString(16);
    //console.log("color" + color);
    //return '#' + ("000000"+color);
    return '#' + ("000000" + color).slice(-6);
  }


  // doghnutForDelivered() {
  //   this.orderService.getOrderReport(this.fromDate, this.toDate).subscribe(res => {
  //     let k, deliveredByWarehouse = [], delivereddonut = [], unplannedByWarehouse = [];

  //     for (k in res) {
  //       if (res[k] != null) {
  //         if (res[k].orderstatus == "ORDER_STATE_DELIVERED" && res[k].wareHouse == "2130") {
  //           deliveredByWarehouse.push(res[k]);
  //         }

  //         else if (res[k].orderstatus == "ORDER_STATE_UNPLANNED" && res[k].wareHouse == "2130") {
  //           unplannedByWarehouse.push(res[k]);
  //         }
  //       }
  //     }

  //     this.zoneUnplannedDataArray.push({
  //       wareHouse: '2130',
  //       ordercount: unplannedByWarehouse.length
  //     });

  //     this.zoneUnplannedDataArrayForDisplay.data = this.zoneUnplannedDataArray;
  //     this.drawPieChart(deliveredByWarehouse, delivereddonut, 'delivered');
  //   })
  // }

  // doghnutForUnDelivered() {
  //   this.orderService.getOrderReport(this.fromDate, this.toDate).subscribe(res => {
  //     let k, undeliveredSew = [], undeliverBhw = [], undeliveredByWarehouse = [], undelivereddonut = [], unplanned = [];

  //     for (k in res) {
  //       if (res[k] != null) {
  //         if (res[k].orderstatus == "ORDER_STATE_FAILED" && res[k].wareHouse == "2130") {
  //           undeliveredByWarehouse.push(res[k]);
  //         }
  //         else if (res[k].orderstatus == "ORDER_STATE_FAILED" && res[k].wareHouse == "2130") {
  //           undeliveredSew.push(res[k]);
  //         }
  //       }
  //     }

  //     this.drawPieChart(undeliveredByWarehouse, undelivereddonut, 'undelivered');
  //   })
  // }

  // drawPieChart(deliveredByWarehouse, arrayname, canvasname) {
  //   let zoneData = [];
  //   let zoneNames = [];
  //   let colors = [], count = {}, i, j = 0;
  //   count[0] = deliveredByWarehouse.length;
  //   zoneNames.push('Nivea');

  //   for (i in count) {
  //     //console.log(count)
  //     zoneNames.push(i);
  //     zoneData.push(JSON.stringify(count[i]));
  //     colors.push(this.getRandomColor());
  //   }
  //   this.donutZone = arrayname;
  //   this.donutZone = new Chart(canvasname, {

  //     type: "doughnut",
  //     "data": {
  //       "labels": zoneNames,
  //       "datasets": [{
  //         "data": zoneData,
  //         "fill": false,
  //         "backgroundColor": colors,
  //         "borderColor": ["white"],
  //         "borderWidth": 1
  //       }]
  //     },
  //     options: {

  //       pieceLabel: {
  //         render: function (args) {
  //           const value = args.value;
  //           return value;
  //         },
  //         fontColor: "white",
  //         fontSize: 10,
  //       },
  //       legend: {
  //         display: false,
  //         position: 'bottom'
  //       }
  //     }
  //   })
  // }

  lineOrderChart() {
    
    this.orderService.getChartData().subscribe(res => {
      console.log("trendanalysis", res);
      let deliveredcount = res.map(res => res.deliveredcount);
      let undeliveredcount = res.map(res => res.unDeliveredcount);
      let alldates = res.map(res => res.dateforDisplay);
      this.orderLineChart = new Chart('canvasLine', {
        type: 'line',
        data: {
          labels: alldates,
          datasets: [
            {
              label: 'Delivered',
              data: deliveredcount,
              fill: true,
              pointHoverBackgroundColor: "#36A2EB",
              pointHoverBorderColor: "#36a2eb05",
              pointBorderWidth: 1,
              borderWidth:2,
              backgroundColor: '#36a2eb05',
              borderColor: '#36A2EB',
              pointBorderColor: '#36A2EB05',
              pointBackgroundColor: '#36a2eb',
            },
            {
              label: 'Undelivered',
              data: undeliveredcount,
              fill: true,
              pointHoverBackgroundColor: "#f27173",
              pointHoverBorderColor: "#f2717305",
              pointBorderWidth: 1,
              borderWidth:2,
              backgroundColor: '#f2717305',
              borderColor: '#f27173',
              pointBorderColor: '#f2717305',
              pointBackgroundColor: '#f27173',
            }
          ]
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          legend: {
            display: true
          },
          tooltips : {
            mode : 'index'
          },
          scales: {
            xAxes: [{
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Date',
                fontSize: 15,
                fontColor: "black"
              },
              gridLines: {
                display: false
             }
            }],
            yAxes: [{
              display: true,
              scaleLabel: {
                display: true,
                labelString: '# of Orders',
                fontSize: 15,
                fontColor: "black"
              },
              ticks: {
                beginAtZero: true,
                userCallback: function (label, index, labels) {
                  if (Math.floor(label) === label) {
                    return label;
                  }
                },
              },
              gridLines: {
                display: false
             }
            }]
          }
        }
      });
    })
  }
}

