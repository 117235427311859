import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { TokenStorage } from 'src/app/auth/token.storage';
import { ProductService } from 'src/app/services/products/product.service';
import { ValidatorService } from 'src/app/services/validator/validator.service';


@Component({
  selector: 'app-addproduct',
  templateUrl: './addproduct.component.html',
  styleUrls: ['./addproduct.component.scss']
})
export class AddproductComponent implements OnInit {
  productForm: FormGroup;
  isDuplicateSkuNumber: boolean = false;
  isQtyPackZero: boolean = false;
  isSubmitButtonDisabled: boolean = false;
  volUnit:any;
  constructor(
    private formBuilder: FormBuilder,
    private productservice: ProductService,
    private token: TokenStorage,
    private notifierService: NotifierService,
  ) {
    if (this.token.getTenant() == 'godrej-dist-prod' || this.token.getTenant() =='godrej-dist-test') {
      this.volUnit = 'Cubic CM';
    } else if (this.token.getTenant() == 'kimberly-dist-prod' || this.token.getTenant() == 'kimberly-dist-test') {
      this.volUnit = 'Cubic M';
    }
    else {
      this.volUnit = 'CF';
    }

    this.productForm = formBuilder.group({
      'productName': [null, [
        Validators.required,
        ValidatorService.whitespaceValidator
      ]],
      'brand': [null, [
        ValidatorService.whitespaceValidator
      ]],
      'category': [null, [
        ValidatorService.whitespaceValidator
      ]],
      'length': [0, [
        Validators.required,
        Validators.minLength(0),
        Validators.maxLength(15),
        Validators.pattern("^[0-9]+$"),
        ValidatorService.whitespaceValidator
      ]],
      'height': [0, [
        Validators.required,
        Validators.minLength(0),
        Validators.maxLength(15),
        Validators.pattern("^[0-9]+$"),
        ValidatorService.whitespaceValidator
      ]],
      'width': [0, [
        Validators.required,
        Validators.minLength(0),
        Validators.maxLength(15),
        Validators.pattern("^[0-9]+$"),
        ValidatorService.whitespaceValidator
      ]],
      'weight': [0, [
        Validators.required,
        Validators.minLength(0),
        Validators.maxLength(15),
        Validators.pattern("^[0-9]+(\.[0-9]{0,3})?"),
        ValidatorService.whitespaceValidator
      ]],
      'price': [null, [
        Validators.minLength(0),
        Validators.maxLength(15),
        Validators.pattern("^[[0-9]+(\.[0-9]{0,3})?"),
        ValidatorService.whitespaceValidator
      ]],
      'skuNumber': [null, [
        Validators.required,
        ValidatorService.whitespaceValidator
      ]],
      'qtyPack': [null, [
        Validators.required,
        Validators.minLength(0),
        Validators.maxLength(15),
        Validators.pattern("^[0-9]+(\.[0-9]{0,2})?"),
        ValidatorService.whitespaceValidator
      ]],
      'hsnCode': [null, [
        ValidatorService.whitespaceValidator
      ]],
      'modelNumber': [null, [
        ValidatorService.whitespaceValidator
      ]],
      'totalVolume': [0, [
        ValidatorService.whitespaceValidator,
        Validators.minLength(0),
        Validators.maxLength(15),
        Validators.pattern("^[0-9]+(\.[0-9]{0,3})?"),
            ]]
    });

  }

  ngOnInit() {
  }

  makeisqtypacZeroFalse() {
    this.isQtyPackZero = false;
  }

  checkzeroqtypack(event) {
    if (event.target.value == 0 && event.target.value != '') {
      this.isQtyPackZero = true;
      this.isSubmitButtonDisabled = true;
    }
    else {
      this.isQtyPackZero = false;
      this.isSubmitButtonDisabled = false;
    }
  }

  addProduct() {
    this.isSubmitButtonDisabled = true;
    console.log("form value", this.productForm.value);
    this.productservice.addProduct(this.productForm.value).subscribe(productadded => {
      console.log(productadded);
      this.notifierService.notify('success', 'Product saved successfully.');
      this.productForm.reset();
    }, error => {
      console.log(error);
      this.notifierService.notify("error", error.error.Error);
      this.productForm.patchValue({
        skuNumber: null,
        qtyPack: null,
      });
      // alert(Error.error.Error);
    });
    this.isSubmitButtonDisabled = true;
  }

  checkForDuplicateSkuNumber(event) {
    console.log("function called", event.srcElement.value);
    this.productservice.checkDuplicateSkuNumber(event.srcElement.value).subscribe(isAvailable => {
      console.log(isAvailable);
      if (isAvailable) {
        this.isDuplicateSkuNumber = true;
      }
      else
        this.isDuplicateSkuNumber = false;
    });
  }
  typingSkuNumber() {
    this.isDuplicateSkuNumber = false;
  }
}
