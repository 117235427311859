import { Component, OnInit, ViewChild, Inject, Injector } from '@angular/core';
import {
  MatPaginator, MatDialog, MAT_DIALOG_DATA
} from '@angular/material';
import { OrderService } from '../../../services/order/order.service';
import { TripService } from '../../../services/trip/trip.service';
import { TokenStorage } from '../../../auth/token.storage';
import { AngularFireDatabase } from '@angular/fire/database';
import { Subscription } from 'rxjs';
import { DialogService } from 'src/app/services/dialog/dialog.service';

@Component({
  selector: 'app-tripdetails',
  templateUrl: './tripdetails.component.html',
  styleUrls: ['./tripdetails.component.scss']
})
export class TripdetailsComponent implements OnInit {

  googleMaps: boolean = true;
  locationData;

  packageDetailsColumnDisplay = [
    'jobId',
    'receiverDetails',
    'numOfBoxes',
    'numberOfOrders',
    'orderState',
    'orderStateInTransit'
  ];
  jobDetailsArray = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;

  totalBoxesInTrip: number = 0;
  tripId: number;
  tripName: string;
  ordersByTripIdArray = [];
  productArrayInSelectedOrder = [];
  productInputDisplayArray = [];
  markersArray = [];
  tripStatus: string;
  destinationAddress: string;
  branchName: string;
  zone: string;
  totalNumberOfOrders: string;
  tripScheduleDate: string;
  deliveryEstDate: string;
  inScheduled: boolean = false;
  inTransit: boolean = false;
  completed: boolean = false;
  vehicleLicenseNumber: string;
  vehicleType: string;
  tripStartTime: string;
  tripStopTime: string;
  driverName: string;
  mobileNumber: string;
  receiverDetailsArray = [];
  totalweightcapacity: string;
  totalvolumecapacity: string;
  transporter: string;
  ispackageDetails: boolean = false;
  trackingType: string;
  deviceName: string;
  vehicleReportingTime: any;
  volUnit: string;

  isGodrej: boolean = false;
  locationSubscription: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public tripService: TripService,
    public orderService: OrderService,
    public db: AngularFireDatabase,
    private token: TokenStorage,
    public dialog: MatDialog,
    private injector: Injector
  ) {
    if (window.sessionStorage.getItem('tripDetailCount')) {
      let count = Number(window.sessionStorage.getItem('tripDetailCount')) - 1;
      if (dialog.getDialogById('tripDetail' + count.toString())) {
        console.log("got")
        dialog.getDialogById('tripDetail' + count.toString()).close()
      }
    }
    if (this.token.getTenant() == 'kimberly-dist-prod') {
      this.volUnit = 'CM';
    } else if (this.token.getTenant() == 'godrej-dist-prod') {
      this.volUnit = 'CCM';
    } else {
      this.volUnit = 'CF';
    }
  }

  ngOnInit() {
    console.log(this.data)
    if (this.data.component == 'jobreport' || this.data.component == 'tripreport' || this.data.component == 'orderDetails') {
      this.tripService.getTripById(this.data.data).subscribe(tripdata => {
        this.data.data = tripdata;
        console.log(this.data);
        this.tripDetails();
      })
    }
    else if (this.data.component == 'tripDetail') {
      this.tripService.getTripById(this.data.data.tripId).subscribe(tripdata => {
        this.data.data = tripdata;
        console.log(this.data);
        this.tripDetails();
      })
    } else {
      this.tripDetails();
    }

    /* let path;
    if (this.data.data)
      path = '/' + this.token.getTenant() + '/' + this.data.data.tripName;
    this.locationSubscription = this.db.list(path).valueChanges().subscribe(res => {
      this.locationData = res;
    }); */

  }

  tripDetails() {
    this.tripId = this.data.data.tripId;
    this.tripName = this.data.data.tripName;
    this.tripStatus = (this.data.data.tState).slice(11);
    this.destinationAddress = this.data.data.destinationAddress;
    this.branchName = this.data.data.branchName;
    this.zone = this.data.data.tripZone;
    this.totalNumberOfOrders = this.data.data.totalNumberOfOrders;
    if (this.data.data.tripTransaction) {
      this.tripScheduleDate = this.data.data.tripTransaction.scheduledDateTime;
      this.vehicleLicenseNumber = this.data.data.assignedVehicleLicenseNumber;
    }
    this.deliveryEstDate = this.data.data.tripPlannedEndDateTime;
    this.totalvolumecapacity = this.data.data.totalVolume;
    this.totalweightcapacity = this.data.data.totalWeight;
    this.driverName = this.data.data.assignedDriverName;
    this.mobileNumber = this.data.data.assignedDriverPhoneNumber;
    this.transporter = this.data.data.transporterName;
    this.vehicleReportingTime = this.data.data.tripTransaction.vehicleReportingDateTime;
    if (this.data.data.tState === "TRIP_STATE_SCHEDULED") {
      this.inScheduled = true;
    } else if (this.data.data.tState === "TRIP_STATE_IN_TRANSIT") {
      this.inTransit = true;
      this.tripStartTime = this.data.data.tripTransaction.tripActualStartDateTime;
    } else if (this.data.data.tState === "TRIP_STATE_COMPLETED") {
      this.completed = true;
      this.tripStartTime = this.data.data.tripTransaction.tripActualStartDateTime;
      this.tripStopTime = this.data.data.tripTransaction.tripActualEndDateTime;
    }

    //this.cdRef.detectChanges();

    if (this.inScheduled || this.inTransit || this.completed) {
      if (this.data.data.fleet)
        this.vehicleLicenseNumber = this.data.data.fleet.vehicleLicenseNumber;
      this.vehicleType = this.data.data.vehicleType;
      if (this.data.data.driver) {
        this.driverName = this.data.data.driver.firstName + " " + this.data.data.driver.lastName;
        this.mobileNumber = this.data.data.driver.phoneNumber1;
      }
    }


    this.jobDetailsArray = this.data.data.jobs;
    this.data.data.jobs.forEach(job => {
      this.totalBoxesInTrip = this.totalBoxesInTrip + job.totalNumberOfContainers;
    });

    if (this.data.data.trackerDeviceType) {
      this.deviceName = this.data.data.trackerDeviceRef;
      if (this.data.data.trackerDeviceType == "DEVICE_TYPE_GPS_DEVICE") {
        this.trackingType = "GPS Device";
      }
      if (this.data.data.trackerDeviceType == "DEVICE_TYPE_NO_TRACKING") {
        this.trackingType = "No Tracking";
      }
      else if (this.data.data.trackerDeviceType == "DEVICE_TYPE_MOBILE") {
        this.trackingType = "Mobile Device";
      }
    }

  }

  tabChanged(event: any) {
    console.log(event.index);
  }

  orderDetails(data, jobref) {
    data.tripRefId = this.tripId;
    data.tripRefName = this.tripName;
    data.jobRef = jobref;
    this.injector.get(DialogService).orderDetails(data, 'tripDetails');
  }

  ngOnDestroy() {
    if (this.locationSubscription) {
      this.locationSubscription.unsubscribe();
    }
  }

  changeMap() {
    this.googleMaps = !this.googleMaps;
  }
}