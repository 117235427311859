import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { OrderService } from 'src/app/services/order/order.service';
import { DatePipe } from '@angular/common';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-addjob',
  templateUrl: './addjob.component.html',
  styleUrls: ['./addjob.component.scss']
})
export class AddjobComponent implements OnInit {

  customers;
  allCustomers;
  pickedOrders = [];
  addJob = new EventEmitter();
  loading: boolean = true;
  fromDate;
  toDate;
  constructor(
    public dialogR: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private orderService: OrderService,
    private datePipe: DatePipe,
    private notifierService: NotifierService
  ) {
    dialogR.disableClose = true
  }

  ngOnInit() {
    this.customers = [];
    // this.fromDate = this.datePipe.transform(new Date(new Date().setDate(new Date().getDate() - 90)), 'yyyy-MM-dd'); // Digvijay
    this.fromDate = this.datePipe.transform(new Date(new Date().setDate(new Date().getDate() - 2)), 'yyyy-MM-dd');
    this.toDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.orderService.getAllOrdersByTypesDateRange('ORDER_STATE_UNPLANNED', -1, this.fromDate, this.toDate, 'nozone').subscribe(res => {
      console.log(res);
      this.loading = false;
      for (let i = 0; i < res.content.length; i++) {
        let exists = false;
        let j;
        for (j = 0; j < this.customers.length; j++) {
          if (this.customers[j].customerName == res.content[i].customerInfo.customerName) {
            exists = true;
            break;
          }
        }

        if (!exists) {
          let orders = [], orderId = [];
          orderId.push(res.content[i].orderInvoiceNumber)
          let tmp = {
            order: res.content[i],
            selected: false
          };
          orders.push(tmp);
          let customer = {
            customerName: res.content[i].customerInfo.customerName,
            numOfBoxes: res.content[i].num_of_containers,
            numOfOrders: 1,
            ordersList: orders,
            zone: res.content[i].customerInfo.zone.city,
            orderId: orderId,
            branchName: res.content[i].branchName
          }
          this.customers.push(customer);
        } else {
          let tmp = {
            order: res.content[i],
            selected: false
          };
          this.customers[j].ordersList.push(tmp);
          this.customers[j].orderId.push(res.content[i].orderInvoiceNumber);
          this.customers[j].numOfOrders++;
          this.customers[j].numOfBoxes += res.content[i].num_of_containers;
        }
      }
      this.allCustomers = this.customers;
    });
  }

  add() {
    this.customers.forEach(customer => {
      customer.ordersList.forEach(order => {
        if (order.selected) {
          this.pickedOrders.push(order.order.orderUniqueId);
        }
      });
    });

    console.log(this.pickedOrders);

    if (this.pickedOrders.length > 0) {
      this.addJob.emit({ orders: this.pickedOrders });
      this.closeDialog();
    } else {
      this.notifierService.notify("default", "Please select something to add");
    }
  }

  closeDialog() {
    this.dialogR.close();
  }

  pickCustomer(index: number, event: any) {
    event.stopPropagation();
    if (event.target.checked) {
      this.customers[index].ordersList.forEach(order => {
        order.selected = true;
      });
    } else {
      this.customers[index].ordersList.forEach(order => {
        order.selected = false;
      });
    }
  }

  pickOrder(customerIndex: number, orderIndex: number, event: any) {
    if (event.target.checked) {
      this.customers[customerIndex].ordersList[orderIndex].selected = true;
    } else {
      this.customers[customerIndex].ordersList[orderIndex].selected = false;
    }
  }

  applyFilter(filterValue: string) {
    if (filterValue) {
      this.customers = this.allCustomers.filter(customer => {
        return ((customer.customerName.toLowerCase().includes(filterValue.trim().toLowerCase())) || (customer.orderId.find(a => a.includes(filterValue.trim()))) || (customer.branchName.toLowerCase().includes(filterValue.trim().toLowerCase())) || (customer.zone.toLowerCase().includes(filterValue.trim().toLowerCase()))) ? customer : null;
      });
    }
    else {
      this.customers = this.allCustomers;
    }
  }
}
