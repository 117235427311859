import { Component, OnInit, Inject, EventEmitter, TemplateRef, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FleetService } from 'src/app/services/fleet/fleet.service';
import { trigger, style, transition, animate } from '@angular/animations';
import { TokenStorage } from 'src/app/auth/token.storage';
import { CustomerService } from 'src/app/services/customer/customer.service';
import { ZoneService } from 'src/app/services/zone/zone.service';
import { BranchService } from 'src/app/services/branch/branch.service';
import { MaterialcategoryService } from 'src/app/services/materialcategories/materialcategory.service'
import { InvoiceSplitService } from 'src/app/services/invoicesplit/invoicesplit.service'
import { ExcelgeneratorService } from 'src/app/services/excelgenerator/excelgenerator.service';
import { all } from 'q';

@Component({
  selector: 'app-settingscomponent',
  templateUrl: './settingscomponent.component.html',
  styleUrls: ['./settingscomponent.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('200ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ transform: 'translateX(100%)' }))
      ])
    ])
  ]
})
export class SettingsComponent implements OnInit {

  @ViewChild("main") mainSettngsSection: TemplateRef<any>;
  @ViewChild("open") vehicleMixSection: TemplateRef<any>;
  currentSection: TemplateRef<any>;

  numOfCustomersSettingsEnabled: boolean = false;
  isReturnToDepot: boolean = false;
  // numOfCustomersValue: number = 2;
  numOfCustomersValue: number = 50;
  maxDistance: number = 0;
  isServiceTimeWindowChanged: boolean = false;
  fromServiceTime = 0;
  toServiceTime = 0;
  totime: string;
  fromtime: string;
  optimizationType = "distance";
  isFromTimeCorrect: boolean = true;
  isToTimeCorrect: boolean = true;
  saveSettings = new EventEmitter();
  planMode = new EventEmitter();
  settingobj: {};
  planObj: {};
  istotalDistanceTravelToggled: boolean = false;
  istotalTransportationCostToggled: boolean = false;
  mode = "Manual";
  isAutoMode: boolean;
  vehicleSettingObj: any;
  isVehicleMixToggled: boolean = true;
  isKcc: boolean = false;
  isMetro: boolean = false;
  zoneClubbing = new EventEmitter();
  isZoneClubbingToggled: boolean;
  serviceTimePerNodeValue: number = 0;
  vehicleLoadingFactorSettinEnabled: boolean = false;
  vehicleFactorMin: number = 0;
  vehicleFactorMax: number = 0;
  isMaxDistanceEnabled: boolean = true;
  isServiceTimeAtCustomerEnabled: boolean = true;
  isHeretech: boolean = false;
  isSuhana: boolean = false;


  //New Params
  allCustomers: any;
  allZones: any;
  vehicleTypes = [];
  config = {
    search: true,
    displayKey: 'customerName',
    searchOnKey: 'customerName',
    height: '30vh',
    placeholder: 'Select Customer'
  };

  // Digvijay Start

  materialCategoryData: any;
  allBranches: any;
  selectedBranch: any;
  selectedBranches: any;
  InvoiceSplitBranchData: any;
  isInvoiceSplitBranchDataLoading: boolean = false;

  branchConfig = {
    search: true,
    displayKey: 'branchName',
    searchOnKey: 'branchName',
    height: '30vh',
    placeholder: 'Select Branch',
    optionValueField: 'branchId',
    optionTextField: 'branchName',
  };


  allMaterialCategories: any;
  allMaterialCategoryGroups: any;
  selectedMaterialCategoryGroup: any;
  selectedMaterialCategoryConstraints: any;
  isMaterialCategoryDataLoading: boolean = false;

  materialCategoryConfig = {
    search: true,
    displayKey: 'materialCategoryGroup',
    searchOnKey: 'materialCategoryGroup',
    height: '30vh',
    placeholder: 'Select Material Category Group',
    optionValueField: 'materialCategoryId',
    optionTextField: 'materialCategoryGroup',
  };

  materialCategoryConstraintsConfig = {
    search: true,
    displayKey: 'materialCategoryName',
    searchOnKey: 'materialCategoryName',
    height: '20vh',
    placeholder: 'Select Material Category',
  };

  //Digvijay End

  zoneConfig = {
    search: true,
    displayKey: 'zoneName',
    searchOnKey: 'zoneName',
    height: '30vh',
    placeholder: 'Select Zone',
    optionValueField: 'zoneId',
    optionTextField: 'zoneName',
  };

  vehicleConfig = {
    search: true,
    displayKey: 'vehicleType',
    searchOnKey: 'vehicleType',
    height: '25vh',
    placeholder: 'Select Vehicle',

  };

  zoneConstraintConfig = {
    search: true,
    displayKey: 'zoneName',
    searchOnKey: 'zoneName',

    height: '20vh',
    placeholder: 'Select Zone'
  };

  vehicleTypeConfig = {
    search: true,
    displayKey: 'vehicleType',
    searchOnKey: 'vehicleType',
    height: '20vh',
    placeholder: 'Select Vehicle Type'
  };

  selectedZone: any;
  selectedZoneConstraints: any;
  selectedVehicleType: any;
  allVehicleType: any;
  selectedCustomer: any;
  isAssignDedicatedVehicle: any = false;
  selectedForbiddenVehicle: any;
  customerData: any;
  zoneData: any;
  totalTravelTime: boolean = false;
  isZoneDataLoading: boolean = false;
  isCustomerDataLoading: boolean = false;

  constructor(
    public dialogR: MatDialogRef<any>,
    private fleetService: FleetService,
    private token: TokenStorage,
    private customerService: CustomerService,
    private zoneService: ZoneService,
    private branchService: BranchService,
    private materialcategoryService: MaterialcategoryService,
    private invoiceSplitService: InvoiceSplitService,
    public excelService: ExcelgeneratorService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    dialogR.disableClose = true;
    this.isAutoMode = data.isAutoMode;
    this.isZoneClubbingToggled = data.zoneClubbing
    if (data.data) {
      delete data.fleetConstraints;
      delete data.serviceConstraints;
      data.fleetConstraints = data.data.fleetConstraints;
      data.serviceConstraints = data.data.serviceConstraints;
      delete data.data;
    }
    console.log("data", data);
    if (this.isAutoMode) {
      this.mode = "Auto";
    } else {
      this.mode = "Manual";
    }
    if (data.serviceConstraints) {
      this.numOfCustomersValue = data.serviceConstraints.maxNodesToVisit;
      this.serviceTimePerNodeValue = data.serviceConstraints.serviceTimePerNode;
    }
    this.optimizationType = data.optimizationType;
    if (data.optimizationType == "time") {
      this.totalTravelTime = true;
    }
    if (data.fleetConstraints) {
      this.vehicleFactorMax = data.fleetConstraints.maxVehicleLoadPercent;
      this.vehicleFactorMin = data.fleetConstraints.minVehicleLoadPercent;
      this.vehicleLoadingFactorSettinEnabled = true;
      this.isReturnToDepot = data.fleetConstraints.returnToDepot;
      this.maxDistance = data.fleetConstraints.maxDistance
    }
    this.vehicleSettingObj = data.vehicleMixSetting;
    if (data && data.serviceConstraints) {
      /*      if (this.data.data.optimizationType == 'time') {
             this.isServiceTimeWindowChanged = true;
             this.optimizationType = "time";
           } */
      //  this.numOfCustomersSettingsEnabled = data.data.globalConstraints.numOfCustomersEnabled;
      this.serviceTimePerNodeValue = (data.serviceConstraints.serviceTimePerNode) / 60;
      this.toServiceTime = Math.round(this.data.serviceConstraints.globalTimeWindow.timeWindowEnd) / 3600;
      if (this.toServiceTime.toString().length == 1)
        this.totime = "0" + this.toServiceTime.toString() + ":00";
      else if (this.toServiceTime.toString().length == 2)
        this.totime = this.toServiceTime.toString() + ":00";
      else if (this.toServiceTime.toString().length == 3)
        this.totime = "0" + this.toServiceTime.toString().split(".")[0] + ":0" + this.toServiceTime.toString().split(".")[1];
      else if (this.toServiceTime.toString().length == 4) {
        if (this.toServiceTime.toString().split(".")[0].length == 1)
          this.totime = "0" + this.toServiceTime.toString().split(".")[0] + ":" + this.toServiceTime.toString().split(".")[1];
        else if (this.toServiceTime.toString().split(".")[1].length == 1)
          this.totime = this.toServiceTime.toString().split(".")[0] + ":" + this.toServiceTime.toString().split(".")[1] + "0";
      }
      else {
        this.totime = this.toServiceTime.toString().replace('.', ':');
      }

      this.fromServiceTime = Math.round(this.data.serviceConstraints.globalTimeWindow.timeWindowStart) / 3600;
      if (this.fromServiceTime.toString().length == 1)
        this.fromtime = "0" + this.fromServiceTime.toString() + ":00";
      else if (this.fromServiceTime.toString().length == 2)
        this.fromtime = this.fromServiceTime.toString() + ":00";
      else if (this.fromServiceTime.toString().length == 3)
        this.fromtime = "0" + this.fromServiceTime.toString().split(".")[0] + ":0" + this.fromServiceTime.toString().split(".")[1];
      else if (this.fromServiceTime.toString().length == 4) {
        console.log(this.fromServiceTime.toString().split("."))
        if (this.fromServiceTime.toString().split(".")[0].length == 1)
          this.fromtime = "0" + this.fromServiceTime.toString().split(".")[0] + ":" + this.fromServiceTime.toString().split(".")[1];
        else if (this.fromServiceTime.toString().split(".")[1].length == 1)
          this.fromtime = this.fromServiceTime.toString().split(".")[0] + ":" + this.fromServiceTime.toString().split(".")[1] + "0";
      }
      else {
        this.fromtime = this.fromServiceTime.toString().replace('.', ':');
      }
    }
    this.toServiceTime = this.toServiceTime * 3600;
    this.fromServiceTime = this.fromServiceTime * 3600;
  }

  ngOnInit() {
    debugger;
    if (this.token.getTenant() == 'metro-dist-prod' || this.token.getTenant() == 'metro-dist-test') {
      this.isMetro = true;
    }
    if (this.token.getTenant() == 'kimberly-dist-prod' || this.token.getTenant() == 'kimberly-dist-test') {
      this.isKcc = true;
    }
    if (this.token.getTenant() == 'heretech-dist-test' || this.token.getTenant() == 'heretech-dist-prod') {
      this.isHeretech = true;
    }
    if (this.token.getTenant() == 'suhana-dist-test' || this.token.getTenant() == 'suhana-dist-prod') {
      this.isSuhana = true;
      this.numOfCustomersSettingsEnabled = true;
    }
    this.currentSection = this.mainSettngsSection;
    if (this.fromServiceTime == 0) {
      this.fromtime = "09:00";
    }
    if (this.toServiceTime == 0) {
      this.totime = "19:00";
    }
    if (this.fromtime == "24:00") {
      this.fromtime = "12:00";
    }
    if (this.totime == "24:00") {
      this.totime = "12:00";
    }
    console.log(this.totime, this.fromtime);

    this.customerService.getAllCustomers().subscribe(allcusts => {
      this.allCustomers = allcusts;
    });


    this.fleetService.getVehicleTypes().subscribe(vehicletypes => {
      console.log(vehicletypes)
      this.allVehicleType = vehicletypes;
      vehicletypes.forEach(vehicle => {
        this.vehicleTypes.push(vehicle.vehicleType);
      });
      this.vehicleTypes.sort(function (a, b) {
        let vehicle1 = a.toLowerCase(), vehicle2 = b.toLowerCase()
        if (vehicle1 < vehicle2) //sort string ascending
          return -1
        if (vehicle1 > vehicle2)
          return 1
        return 0
      });
      this.allVehicleType.sort(function (a, b) {
        let vehicle1 = a.weightCapacity, vehicle2 = b.weightCapacity
        if (vehicle1 < vehicle2) //sort string ascending
          return -1
        if (vehicle1 > vehicle2)
          return 1
        return 0
      });
    });

    console.log(this.vehicleTypes);
    // Digvijay start
    this.branchService.getAllBranches().subscribe(allbranches => {
      debugger;
      this.allBranches = allbranches;
      this.allBranches.sort(function (a, b) {
        let branchName1 = a.branchName.toLowerCase(), branchName2 = b.branchName.toLowerCase()
        if (branchName1 < branchName2) //sort string ascending
          return -1
        if (branchName1 > branchName2)
          return 1
        return 0
      });
    });
    // Digvijay End
   // Below code commented by Digvijay 
    this.zoneService.getAllZones().subscribe(allzones => {
      this.allZones = allzones;
      this.allZones.sort(function (a, b) {
        let zoneName1 = a.zoneName.toLowerCase(), zoneName2 = b.zoneName.toLowerCase()
        if (zoneName1 < zoneName2) //sort string ascending
          return -1
        if (zoneName1 > zoneName2)
          return 1
        return 0
      });
    });

    this.materialcategoryService.getAllMaterialCategories().subscribe(allmc => {
      this.allMaterialCategories = allmc;
      this.allMaterialCategories.sort(function (a, b) {
        let materialCategoryName1 = a.materialCategoryName.toLowerCase(), materialCategoryName2 = b.materialCategoryName.toLowerCase()
        if (materialCategoryName1 < materialCategoryName2) //sort string ascending
          return -1
        if (materialCategoryName1 > materialCategoryName2)
          return 1
        return 0
      });
    });

    this.getCustomerConstraints();
    this.getZoneConstraints();
    this.getMaterialCategoryConstraints();
    this.getInvoiceSplitConstraints();
  }


  getCustomerConstraints() {
    this.isCustomerDataLoading = true;
    this.customerService.getCustomerConstraints().subscribe(data => {
      if (data) {
        data.sort(function (a, b) {
          var keyA = a.customerName,
            keyB = b.customerName;
          // Compare the 2 dates
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        });
      }
      this.customerData = data;
      this.isCustomerDataLoading = false;
    }, () => {
      this.isCustomerDataLoading = false;
    });
  }

  getZoneConstraints() {
    this.isZoneDataLoading = true;
    this.zoneService.getZoneConstraintsDetails().subscribe(data => {
      console.log(data);
      // if (data) {
      //   data.sort(function (a, b) {
      //     var keyA = a.zoneName,
      //       keyB = b.zoneName;
      //     // Compare the 2 dates
      //     if (keyA < keyB) return -1;
      //     if (keyA > keyB) return 1;
      //     return 0;
      //   });
      // Added By Digvijay and above code commented
      if (data) {
        data.sort(function (a, b) {
          // Compare the 2 zones by first branch name and then by zone name
          if (a.branch.branchName === b.branch.branchName){
            return a.zoneName < b.zoneName ? -1 : 1
          } else {
            return a.branch.branchName < b.branch.branchName ? -1 : 1
          }
          //return 0;
        });
        // Digvijay End
      }
      this.zoneData = data;
      if( this.zoneData){
      this.zoneData.forEach(zone => {
        let allZ = [], allV = [];
        if (zone.forbiddenZones && zone.forbiddenZones.length > 0) {
          zone.forbiddenZones.sort(function (a, b) {
            let zoneName1 = a.zoneName.toLowerCase(), zoneName2 = b.zoneName.toLowerCase()
            if (zoneName1 < zoneName2) //sort string ascending
              return -1
            if (zoneName1 > zoneName2)
              return 1
            return 0
          });
          allZ = this.allZones.filter(o1 => !zone.forbiddenZones.some(o2 => o1.zoneId == o2.zoneId));
        }
        if (zone.forbiddenVehicleTypes && zone.forbiddenVehicleTypes.length > 0) {
          zone.forbiddenVehicleTypes.sort(function (a, b) {
            let vehicle1 = a.weightCapacity, vehicle2 = b.weightCapacity
            if (vehicle1 < vehicle2) //sort string ascending
              return -1
            if (vehicle1 > vehicle2)
              return 1
            return 0
          });
        allV = this.allVehicleType.filter(o1 => !zone.forbiddenVehicleTypes.some(o2 => o1.vehicleTypeCode == o2.vehicleTypeCode));
      }
      zone.allowedZones = allZ;
      zone.allowedVehicles = allV;
      })
    }
      console.log("zones", this.zoneData)
      this.isZoneDataLoading = false;
    }, () => {
      this.isZoneDataLoading = false
    });
  }

  getMaterialCategoryConstraints() {
    this.isMaterialCategoryDataLoading = true;
    this.materialcategoryService.getMaterialCategoryConstraintsDetails().subscribe(data => {
      console.log(data);
      // Added By Digvijay and above code commented
      if (data) {
        data.sort(function (a, b) {
          // Compare the 2 zones by first branch name and then by material category name
          if (a.branch.branchName === b.branch.branchName){
            return a.materialCategoryGroup < b.materialCategoryGroup ? -1 : 1
          } else {
            return a.branch.branchName < b.branch.branchName ? -1 : 1
          }
          //return 0;
        });
        // Digvijay End
      }
      this.materialCategoryData = data;
      if( this.materialCategoryData){
      this.materialCategoryData.forEach(materialCategory => {
        if (materialCategory.allowedMaterialCategories && materialCategory.allowedMaterialCategories.length > 0) {
          materialCategory.allowedMaterialCategories.sort(function (a, b) {
            let materialCategoryName1 = a.materialCategoryName.toLowerCase(), materialCategoryName2 = b.materialCategoryName.toLowerCase()
            if (materialCategoryName1 < materialCategoryName2) //sort string ascending
              return -1
            if (materialCategoryName1 > materialCategoryName2)
              return 1
            return 0
          });
      }
      })
    }
      console.log("materialCategories", this.materialCategoryData)
      this.isMaterialCategoryDataLoading = false;
    }, () => {
      this.isMaterialCategoryDataLoading = false
    });
  }

  
  getInvoiceSplitConstraints() {
    this.isInvoiceSplitBranchDataLoading = true;
    this.invoiceSplitService.getInvoiceSplitConstraintsDetails().subscribe(data => {
      console.log(data);

      if (data) {
        data.sort(function (a, b) {
          return a.branch.branchName < b.branch.branchName ? -1 : 1
        });
      }
      this.InvoiceSplitBranchData = data;
      console.log("Invoice Split Constraints", this.InvoiceSplitBranchData)
      this.isInvoiceSplitBranchDataLoading = false;
    }, () => {
      this.isInvoiceSplitBranchDataLoading = false
    });
  }
  //In customer constraints customer selection 
  filterByCustomer() {
    if (this.selectedCustomer.customerConstraints) {
      this.isAssignDedicatedVehicle = this.selectedCustomer.customerConstraints.needsDedicatedVehicle;
    }
    else {
      this.isAssignDedicatedVehicle = false;
    }
    this.selectedForbiddenVehicle = [];
    if (this.selectedCustomer.customerConstraints) {
      if (this.selectedCustomer.customerConstraints.forbiddenVehicles && this.selectedCustomer.customerConstraints.forbiddenVehicles.length > 0) {
        for (var i = 0; i < this.selectedCustomer.customerConstraints.forbiddenVehicles.length; i++) {
          let obj = this.allVehicleType.find(o => o.vehicleTypeId === this.selectedCustomer.customerConstraints.forbiddenVehicles[i]);
          this.selectedForbiddenVehicle.push(obj)
        }
      }
    }

  }
// Digvijay Start
  filterByBranch() {
    this.allZones = [];
    this.selectedZone = null;
    this.selectedZoneConstraints = [];
    this.selectedVehicleType = [];
    this.branchService.getZonesByBranchId(this.selectedBranch.branchId).subscribe(zones => {
      if (zones) {
        this.allZones = zones;
        this.allZones.sort(function (a, b) {
        let zoneName1 = a.zoneName.toLowerCase(), zoneName2 = b.zoneName.toLowerCase()
        if (zoneName1 < zoneName2) //sort string ascending
          return -1
        if (zoneName1 > zoneName2)
          return 1
        return 0
      });
   
      }
    });
  }

  filterMaterialCategoryByBranch() {
    this.selectedMaterialCategoryGroup = null;
    this.selectedMaterialCategoryConstraints = [];
    this.materialcategoryService.getMaterialCategoryGroupsByBranchId(this.selectedBranch.branchId).subscribe(materialCategoryGroups => {
      if (materialCategoryGroups) {
        this.allMaterialCategoryGroups = materialCategoryGroups;
        this.allMaterialCategoryGroups.sort(function (a, b) {
        let materialCategoryGroup1 = a.toLowerCase(), materialCategoryGroup2 = b.toLowerCase()
        if (materialCategoryGroup1 < materialCategoryGroup2) //sort string ascending
          return -1
        if (materialCategoryGroup1 > materialCategoryGroup2)
          return 1
        return 0
      });
   
      }
    });
  }


  filterByMaterialCategoryConstraints() {
    this.selectedMaterialCategoryConstraints = [];
    console.log(this.selectedMaterialCategoryGroup);
    console.log(this.allMaterialCategories);
    this.materialcategoryService.getMaterialCategoryConstraintsByBranchIdAndMaterialCategoryGroup(this.selectedBranch.branchId,this.selectedMaterialCategoryGroup).subscribe(allMaterialCategory => {
      debugger;
      console.log(allMaterialCategory);
      if (allMaterialCategory) {
        if (allMaterialCategory.allowedMaterialCategories && allMaterialCategory.allowedMaterialCategories.length > 0) {
          for (var i = 0; i < allMaterialCategory.allowedMaterialCategories.length; i++) {
            let obj = this.allMaterialCategories.find(o => o.materialCategoryId === allMaterialCategory.allowedMaterialCategories[i]);
            this.selectedMaterialCategoryConstraints.push(obj)
          }
        }
        this.selectedMaterialCategoryConstraints = [...this.selectedMaterialCategoryConstraints];

      }
      // this.selectedZoneConstraints = allZoneConstraints;
    });
  }

// Digvijay End
/*
  filterByZone() {
    this.selectedZoneConstraints = [];
    this.selectedVehicleType = [];
    this.zoneService.getZoneConstraintsByBranchIdAndZoneId(this.selectedBranch.branchId,this.selectedZone.zoneId).subscribe(allZoneConstraints => {
      if (allZoneConstraints) {
        if (allZoneConstraints.forbiddenZoneIds && allZoneConstraints.forbiddenZoneIds.length > 0) {
          for (var i = 0; i < allZoneConstraints.forbiddenZoneIds.length; i++) {
            let obj = this.allZones.find(o => o.zoneId === allZoneConstraints.forbiddenZoneIds[i]);
            this.selectedZoneConstraints.push(obj)
          }
        }

        if (allZoneConstraints.forbiddenVehicleTypeIds.length > 0) {
          for (var i = 0; i < allZoneConstraints.forbiddenVehicleTypeIds.length; i++) {
            let obj = this.allVehicleType.find(o => o.vehicleTypeId === allZoneConstraints.forbiddenVehicleTypeIds[i]);
            this.selectedVehicleType.push(obj)
          }
        }
        this.selectedZoneConstraints = [...this.selectedZoneConstraints];
        this.selectedVehicleType = [...this.selectedVehicleType];

      }
      // this.selectedZoneConstraints = allZoneConstraints;
    });
  }
*/
  filterByZone() {
    this.selectedZoneConstraints = [];
    this.selectedVehicleType = [];
    this.zoneService.getZoneConstraints(this.selectedZone.zoneId).subscribe(allZoneConstraints => {
      if (allZoneConstraints) {
        if (allZoneConstraints.forbiddenZoneIds && allZoneConstraints.forbiddenZoneIds.length > 0) {
          for (var i = 0; i < allZoneConstraints.forbiddenZoneIds.length; i++) {
            let obj = this.allZones.find(o => o.zoneId === allZoneConstraints.forbiddenZoneIds[i]);
            this.selectedZoneConstraints.push(obj)
          }
        }

        if (allZoneConstraints.forbiddenVehicleTypeIds.length > 0) {
          for (var i = 0; i < allZoneConstraints.forbiddenVehicleTypeIds.length; i++) {
            let obj = this.allVehicleType.find(o => o.vehicleTypeId === allZoneConstraints.forbiddenVehicleTypeIds[i]);
            this.selectedVehicleType.push(obj)
          }
        }
        this.selectedZoneConstraints = [...this.selectedZoneConstraints];
        this.selectedVehicleType = [...this.selectedVehicleType];

      }
      // this.selectedZoneConstraints = allZoneConstraints;
    });
  }


  toggleNumOfCustomersSetting(event: any) {
    if (event.checked) {
      this.numOfCustomersSettingsEnabled = true;
    } else {
      this.numOfCustomersSettingsEnabled = false;
    }
  }

  toggleServiceTimeAtCustomer(event: any) {
    if (event.checked) {
      this.isServiceTimeAtCustomerEnabled = true;
    } else {
      this.isServiceTimeAtCustomerEnabled = false;
    }
  }

  toggleVehicleLoadingFactorSetting(event: any) {
    if (event.checked) {
      this.vehicleLoadingFactorSettinEnabled = true;
    } else {
      this.vehicleLoadingFactorSettinEnabled = false;
    }
  }


  returnToDepotSetting(event: any) {
    if (event.checked) {
      this.isReturnToDepot = true;
    } else {
      this.isReturnToDepot = false;
    }
  }

  serviceTimetoggle(event) {
    if (event.checked) {
      this.fromServiceTime = 9.00 * 3600;
      this.toServiceTime = 19.00 * 3600;
      this.isServiceTimeWindowChanged = true;
      this.istotalDistanceTravelToggled = false;
      this.istotalTransportationCostToggled = false;
    } else {
      this.fromServiceTime = 0;
      this.toServiceTime = 0;
      this.isServiceTimeWindowChanged = false;
    }
  }

  assignDedicatedVehicletoggle(event) {
    this.isAssignDedicatedVehicle = event.checked;
  }


  fromSrviceTime(value) {
    if (value == "12:00")
      value = "24:00";
    if (value == "00:00")
      value = "12:00";
    value = value.replace(':', '.');
    if (!value)
      this.isFromTimeCorrect = false;
    else {
      this.isFromTimeCorrect = true;
      this.fromServiceTime = (+value) * 3600;
    }
  }

  toSrviceTime(value) {
    if (value == "12:00")
      value = "24:00";
    if (value == "00:00")
      value = "12:00";
    value = value.replace(':', '.');
    if (!value)
      this.isFromTimeCorrect = false;
    else {
      this.isFromTimeCorrect = true;
      this.toServiceTime = (+value) * 3600;
    }
  }

  totalDistanceTravelToggle(event) {
    if (event.checked) {
      this.istotalDistanceTravelToggled = true;
      this.isServiceTimeWindowChanged = false;
      this.istotalTransportationCostToggled = false;
      this.fromServiceTime = 0;
      this.toServiceTime = 0;
      this.optimizationType = "distance";
    } else {
      this.istotalDistanceTravelToggled = false;
    }
  }

  totalTransportationCostToggle(event) {
    if (event.checked) {
      this.istotalTransportationCostToggled = true;
      this.istotalDistanceTravelToggled = false;
      this.isServiceTimeWindowChanged = false;
    } else {
      this.istotalTransportationCostToggled = false;
    }
  }

  toggleVehicleMixSetting(event) {
    if (event.checked) {
      this.isVehicleMixToggled = true;
    } else {
      this.isVehicleMixToggled = false;
    }
  }
  togglezoneClubbingSetting(event) {
    if (event.checked) {
      this.isZoneClubbingToggled = true;
    } else {
      this.isZoneClubbingToggled = false;
    }
  }
  toggleMaxDistance(event) {
    if (event.checked) {
      this.isMaxDistanceEnabled = true;
    } else {
      this.isMaxDistanceEnabled = false;
    }
  }

  closeSettings() {
    this.dialogR.close();
  }

  numOfCustomersChanged(value: any) {
    this.numOfCustomersValue = Number(value);
  }

  vehicleFactorMinChanged(value: any) {
    this.vehicleFactorMin = Number(value);
  }

  vehicleFactorMaxChanged(value: any) {
    this.vehicleFactorMax = Number(value);
  }
  serviceTimePerNodeChanged(value: any) {
    this.serviceTimePerNodeValue = Number(value);
  }

  autoToggle(event: any) {
    if (event.checked) {
      this.mode = "Auto";
      this.isAutoMode = true;
    } else {
      this.mode = "Manual";
      this.isAutoMode = false;
    }
  }

  /*   assignDedicatedVehicletoggle(event)
    {
      this.isAssignDedicatedVehicle = event.checked;
    } */


  save() {
    if (this.isServiceTimeWindowChanged) {
      if (this.fromServiceTime >= this.toServiceTime) {
        alert("Fromtime should not be greater than or equal to Totime");
        return;
      }
    }
    this.planObj = {
      bEnableAutoPlan: this.isAutoMode,
      optimizationConfigurations: {
        optimizationType: this.optimizationType,
        serviceConstraints: {
          zoneClubbing: this.isZoneClubbingToggled,
          maxNodesToVisit: this.numOfCustomersValue,
          serviceTimePerNode: this.serviceTimePerNodeValue * 60,
          globalTimeWindow: {
            timeWindowStart: this.fromServiceTime,
            timeWindowEnd: this.toServiceTime
          },
          applyCapacityDimension: true
        },
        fleetConstraints: {
          returnToDepot: this.isReturnToDepot,
          maxDistance: this.maxDistance,
          minVehicleLoadPercent: this.vehicleFactorMin,
          maxVehicleLoadPercent: this.vehicleFactorMax,
          minVehicleVolumePercent: 0,
          minVehicleWeightPercent: 0,
          maxVehicleVolumePercent: 0,
          maxVehicleWeightPercent: 0
        }
      },
      vehicles: this.vehicleSettingObj,
    }
    this.planMode.emit(this.isAutoMode);
    //this.zoneClubbing.emit(this.isZoneClubbingToggled);
    this.saveSettings.emit(this.planObj);
    this.dialogR.close();
    this.fleetService.saveSetting(this.planObj).subscribe(saveSettings => {
      console.log("saved", saveSettings)
    });
    // this.fleetService.saveSettingParameter(this.planObj, this.selectedBranch.branchId).subscribe(saveSettings => {
    //   console.log("saved", saveSettings)
    // });

    if (this.selectedZone && this.selectedZone.zoneId) {
      let zoneIds = [];
      let vehicleIds = [];
      for (let i = 0; i < this.selectedZoneConstraints.length; i++) {
        zoneIds.push(this.selectedZoneConstraints[i].zoneId)
      }

      for (let i = 0; i < this.selectedVehicleType.length; i++) {
        vehicleIds.push(this.selectedVehicleType[i].vehicleTypeId)
      }

      let zoneData = {
        "branchId": this.selectedBranch.branchId,
        "zoneId": this.selectedZone.zoneId,
        "zoneCode": this.selectedZone.zoneCode,
        "allowedZoneIds": null,
        "forbiddenZoneIds": zoneIds,
        "forbiddenVehicleTypeIds": vehicleIds
      }

      this.zoneService.updateZoneConstraints(zoneData).subscribe(saveSettings => {
        console.log("saved", zoneData)
      });
    }


    if (this.selectedCustomer.id && this.selectedCustomer) {

      let forbiddenVehicles = [];
      for (let i = 0; i < this.selectedForbiddenVehicle.length; i++) {
        forbiddenVehicles.push(this.selectedForbiddenVehicle[i].vehicleTypeId)
      }
      let customerData = {
        "customerId": this.selectedCustomer.id,
        "needsDedicatedVehicle": this.isAssignDedicatedVehicle,
        "forbiddenVehicleTypeIds": forbiddenVehicles
      };

      this.customerService.updateCustomerConstraints(customerData).subscribe(saveSettings => {
        console.log("saved", saveSettings)
      });
    }
  }

  secondarySave() {
    this.currentSection = this.mainSettngsSection;
  }

  secondaryClose() {
    this.currentSection = this.mainSettngsSection;
  }

  editVehicleMix() {
    this.currentSection = this.vehicleMixSection;
    this.vehicleSettingObj = this.data.vehicleMixSetting;
    this.vehicleSettingObj.sort(function (a, b) {
      let vehicle1 = a.vehicleTypeMaster.vehicleType.toLowerCase(), vehicle2 = b.vehicleTypeMaster.vehicleType.toLowerCase()
      if (vehicle1 < vehicle2)
        return -1
      if (vehicle1 > vehicle2)
        return 1
      return 0
    });
  }

  totalTravelTimeSet(event) {
    if (event.checked) {
      this.optimizationType = "time";
      this.totalTravelTime = true;
    } else {
      this.optimizationType = "distance";
      this.totalTravelTime = false;
    }
  }


  exportAsXLSX() {
    let excelRecords = []
    this.zoneData.forEach(zone => {
      let forebiddenZones = "", forebiddenVehicles = "", allowedZones = "",allowedVehicles = "",allZ = [], allV = [];
      if (zone.forbiddenZones && zone.forbiddenZones.length > 0) {
        let count = 0, count1= 0;
        zone.forbiddenZones.forEach(z => {
          if (count < zone.forbiddenZones.length - 1)
            forebiddenZones = forebiddenZones + z.zoneName + ','
          else
            forebiddenZones = forebiddenZones + z.zoneName

          count = count + 1
        });
        zone.allowedZones.forEach(z => {
          if (count1 < zone.allowedZones.length - 1)
          allowedZones = allowedZones + z.zoneName + ','
          else
          allowedZones = allowedZones + z.zoneName

          count1 = count1 + 1
        });

      }

      if (zone.forbiddenVehicleTypes && zone.forbiddenVehicleTypes.length > 0) {
        let count = 0, count1=0;
        zone.forbiddenVehicleTypes.forEach(v => {
          if (count < zone.forbiddenVehicleTypes.length - 1)
          forebiddenVehicles = forebiddenVehicles + v.vehicleTypeCode + ','
          else
          forebiddenVehicles = forebiddenVehicles + v.vehicleTypeCode
          count = count + 1
      });
      // allV = this.allVehicleType.filter(o1 => !zone.forbiddenVehicleTypes.some(o2 => o1.vehicleTypeCode == o2.vehicleTypeCode));
      zone.allowedVehicles.forEach(v => {
        if (count1 < zone.allowedVehicles.length - 1)
        allowedVehicles = allowedVehicles + v.vehicleTypeCode + ','
        else
        allowedVehicles = allowedVehicles + v.vehicleTypeCode

        count1 = count1 + 1
      });
    }
      if (zone) {
        excelRecords.push({
          'Branch Name' : zone.branch.branchName, // Added By Digvijay
          'Zone Name': zone.zoneName,
          'Forbidden Zones': forebiddenZones,
          'Allowed Zones' : allowedZones,
          'Forbidden Vehicles': forebiddenVehicles,
          'Allowed Vehicles': allowedVehicles,
        })
      }
    });
    this.excelService.exportAsExcelFile(excelRecords, 'ciplaZoneData');
  }

  exportMaterialCategoryAsXLSX() {
    let excelRecords = []
    this.materialCategoryData.forEach(materialCategory => {
      let allowedMaterialCategories = "";
      if (materialCategory.allowedMaterialCategories && materialCategory.allowedMaterialCategories.length > 0) {
        let count = 0, count1= 0;
        materialCategory.allowedMaterialCategories.forEach(m => {
          if (count < materialCategory.allowedMaterialCategories.length - 1)
          allowedMaterialCategories = allowedMaterialCategories + m.materialCategoryName + ','
          else
          allowedMaterialCategories = allowedMaterialCategories + m.materialCategoryName

          count = count + 1
        });
      }
      if (materialCategory) {
        excelRecords.push({
          'Branch Name' : materialCategory.branch.branchName, // Added By Digvijay
          'Material Category Group': materialCategory.materialCategoryGroup,
          'Combine Material Categories' : allowedMaterialCategories
        })
      }
    });
    this.excelService.exportAsExcelFile(excelRecords, 'ciplaMaterialCategoryData');
  }

  exportInvoiceSplitAsXLSX() {
    let excelRecords = []
    this.InvoiceSplitBranchData.forEach(invoiceSplitBranch => {
      if (invoiceSplitBranch) {
        excelRecords.push({
          'Branch Name' : invoiceSplitBranch.branch.branchName, // Added By Digvijay
          'Split': invoiceSplitBranch.allowedSplit ? 'Allowed' : 'Not Allowed'
        })
      }
    });
    this.excelService.exportAsExcelFile(excelRecords, 'ciplaInvoiceSplitData');
  }
}
