import { Injectable } from '@angular/core';
import { Headers } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TripService {

  base_url: string;
  constructor(
    private http: HttpClient,
  ) {
    this.base_url = environment.appUrl + "/trips";
  }

  getAllTrips(): Observable<any> {

    //change post url next to base_url 
    return this.http.get(this.base_url);
  }

  getAllTripsByTypesDateRange(tripTypes, page: number, from: Date, to: Date, selectedZone: string): Observable<any> {
    if (selectedZone == 'nozonePod' && ((!window.sessionStorage.getItem("selectedBranchesCodes")) || (window.sessionStorage.getItem("selectedBranchesCodes") && window.sessionStorage.getItem("totalUserBranches") == JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")).length))) {
      return this.http.get(this.base_url + '/typedaterange' + '?page=' + page + '&size=500' + '&types=' + tripTypes + '&fromDate=' + from + '&toDate=' + to + '&zone=nozone');
    }
    else if (selectedZone == 'nozonePod') {
      return this.http.get(this.base_url + '/typedaterange' + '?page=' + page + '&size=500' + '&types=' + tripTypes + '&fromDate=' + from + '&toDate=' + to + '&branchCodes=' + JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")) + '&zone=nozone');
    }
    else if ((!window.sessionStorage.getItem("selectedBranchesCodes")) || (window.sessionStorage.getItem("selectedBranchesCodes") && window.sessionStorage.getItem("totalUserBranches") == JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")).length)) {
      return this.http.get(this.base_url + '/typedaterange' + '?page=' + page + '&types=' + tripTypes + '&fromDate=' + from + '&toDate=' + to + '&zone=' + selectedZone);
    }
    else {
      return this.http.get(this.base_url + '/typedaterange' + '?page=' + page + '&types=' + tripTypes + '&fromDate=' + from + '&toDate=' + to + '&branchCodes=' + JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")) + '&zone=' + selectedZone);
    }
  }

  getAllTripsByTripName(tripTypes, page: number, from: Date, to: Date, selectedZone: string, tripName): Observable<any> {
    if (selectedZone == 'nozonePod') {
      return this.http.get(this.base_url + '/typedaterange' + '?page=' + page + '&size=500' + '&types=' + tripTypes + '&fromDate=' + from + '&toDate=' + to + '&branchCodes=' + JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")) + '&zone=nozone' + '&tripname=' + tripName);
    }
    else {
      return this.http.get(this.base_url + '/typedaterange' + '?page=' + page + '&types=' + tripTypes + '&fromDate=' + from + '&toDate=' + to + '&branchCodes=' + JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")) + '&zone=' + selectedZone + '&tripname=' + tripName);
    }
  }

  getTripById(tripId): Observable<any> {
    return this.http.get(this.base_url + "/" + tripId);
  }

  getOrdersTripById(tripId): Observable<any> {
    return this.http.get(this.base_url + "/id/" + tripId + "/orders");
  }

  createTrip(orderArray: Array<any>): Observable<any> {

    console.log(orderArray);

    return this.http.post(this.base_url, orderArray);
  }

  assignFleetToTrip(tripId, lisencenumber): Observable<any> {
    return this.http.put(this.base_url + "/" + tripId + "/assignfleet?vehiclelisencenumber=" + lisencenumber, null);
  }

  assignDriverToTrip(tripId, updatedDriverData): Observable<any> {
    console.log(updatedDriverData);
    return this.http.put(this.base_url + "/" + tripId + "/" + "assigndriver", updatedDriverData);
  }

  assignTransporterToTrip(tripId, transporter): Observable<any> {
    return this.http.put(this.base_url + "/" + tripId + "/" + "assigntransporter", transporter);
  }

  assignVehicleTypeToTrip(tripId, vtype, comments): Observable<any> {
    return this.http.put(this.base_url + "/" + tripId + "/" + "assignvehicleType?comments=" + comments, vtype);
  }

  scheduleTrip(tripId): Observable<any> {
    console.log(tripId);

    return this.http.put(this.base_url + "/" + tripId + "/" + "schedule", null);
  }

  deleteTrip(tripId: number, comments: string): Observable<any> {
    console.log(tripId);
    return this.http.delete(this.base_url + "/id/" + tripId + "?comments=" + comments);
  }

  startTrip(tripId, timeStamp): Observable<any> {
    return this.http.put(this.base_url + "/" + tripId + '/starttrip?odometer=' + 0.0 + '&updatedDateTime=' + timeStamp + '&isWeb=true', null);
  }

  getTripCountByStatus(from, to): Observable<any> {
    if ((!window.sessionStorage.getItem("selectedBranchesCodes")) || (window.sessionStorage.getItem("selectedBranchesCodes") && window.sessionStorage.getItem("totalUserBranches") == JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")).length)) {
      let headers = new Headers();
      headers.append('Content-Type', 'application/json');
      return this.http.get(this.base_url + '/status/count/branch?fromDate=' + from + '&toDate=' + to)
    }
    else {
      return this.http.get(this.base_url + '/status/count/branch?fromDate=' + from + '&toDate=' + to + '&branchCodes=' + JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")));
    }
  }

  togglePostSN(): Observable<any> {
    return this.http.put(this.base_url + "/" + "togglepostsn", null);
  }

  getTripReport(from, to): Observable<any> {
    return this.http.get(environment.appUrl + '/reports/tripSummary?fromDate=' + from + '&toDate=' + to + "&branchCodes=" + JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")));
  }

  approveTrip(tripId): Observable<any> {
    return this.http.put(this.base_url + '/' + tripId + '/approve', null);
  }

  deleteJobFromTrip(tripId: number, jobId: number): Observable<any> {
    return this.http.delete(this.base_url + '/id/' + tripId + '/jobs/' + jobId);
  }

  addJobsInTrip(tripId: number, orders: any) {
    return this.http.post(this.base_url + '/id/' + tripId + '/addJobs', orders);
  }

  requestVehicleTypeChange(tripId, vehilcleType, comments): Observable<any> {
    return this.http.put(this.base_url + '/' + tripId + '/changeVehicleType?comments=' + comments, vehilcleType, { observe: 'response' });
  }

  snapToRoads(path) {
    return this.http.get('https://roads.googleapis.com/v1/snapToRoads?path=' + path + '&interpolate=true' + '&key=AIzaSyA4bHp5Nouogbx5iIMps7Y02euB5NwJUFs');
  }

  deleteSingleOrderFromTrip(orderId, jobId): Observable<any> {
    return this.http.delete(environment.appUrl + '/order/' + orderId + '/job/' + jobId);
  }

  getTripsForPod(from, to): Observable<any> {
    return this.http.get(environment.appUrl + '/reports/tripsdetails?fromDate=' + from + '&toDate=' + to);
  }

  moveJobsInTrip(tripId: number, jobId: any, comments: string) {
    return this.http.post(environment.appUrl + '/trip/id/' + tripId + '/moveJobs?jobId=' + jobId + '&comments=' + comments, null);
  }

  moveOrdersInTrip(tripId: number, orderId: any, comments: string) {
    return this.http.post(environment.appUrl + '/trips/id/' + tripId + '/moveorder?orderId=' + orderId + '&remarks=' + comments , null);
  }

  trackOrder(trackId) {
    return this.http.get(environment.appUrl + '/trackorder/id/' + trackId);
  }

  unApproveTrip(tripId,comments): Observable<any>{
    return this.http.put(this.base_url + "/" + tripId + "/unapprove" + '?comments=' + comments, null);
  }
}
