import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ZoneService {
  base_url: string;
  constructor(private http: HttpClient) {
    this.base_url = environment.appUrl + "/zones"
  }
 
  getAllZones(): Observable<any> {
    return this.http.get(this.base_url);
  }


  getAllbranchesOfZone(branchRefs): Observable<any> {
    return this.http.get(this.base_url + "/branches?branchRefs=" + branchRefs);
  }

  addZone(newZone): Observable<any> {
    JSON.stringify(newZone);
    return this.http.post(this.base_url, newZone);
  }

  updateZone(zone): Observable<any> {
    return this.http.put(environment.appUrl + '/zone', zone);
  }

  updateZoneConstraints(zone): Observable<any> {
    return this.http.put(this.base_url + '/constraints', zone);
  }

  getZoneConstraints(zoneId): Observable<any>{
    return this.http.get(this.base_url + '/constraints/'+zoneId);
  }

  getZoneConstraintsByBranchIdAndZoneId(branchId,zoneId): Observable<any>{
    return this.http.get(this.base_url + '/constraints/'+branchId+'/'+zoneId);
  }

  getZoneConstraintsDetails(): Observable<any>{
    return this.http.get(environment.appUrl + '/constraints/zones');
  }
}
