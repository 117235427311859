import { Component, OnInit, ViewChild, ElementRef, Input, OnChanges } from '@angular/core';

declare var H: any;

@Component({
  selector: 'here-map-polygon',
  templateUrl: './here-map-polygon.component.html',
  styleUrls: ['./here-map-polygon.component.scss']
})
export class HereMapPolygonComponent implements OnChanges {

  // Global parameter declarations
  platform: any;
  map: any;
  router: any;
  ui: any;

  //color object for polygon
  colorArray = ["0700F9", '4743DE', 'C100F9', 'F90000', '13D505', 'BCB71B', 
  'F9008D', '191591', '81380D', 'F9A600', 'BE0B7C',
  'F85F18', '73892E', '3F2472', '7A3F93', '3945A1', 
  '3F292B', '134E28', 'AA6000', '03045E', '6F4E00'];

  // Get inputs from component
  @ViewChild("map")
  public mapElement: ElementRef;

  @Input("appid")
  public appId: string;

  @Input("appcode")
  public appCode: string;

  @Input("zoom")
  public zoom: number;

  @Input("lat")
  public lat: number;

  @Input("lng")
  public lng: number;

  @Input("allTrackDetails")
  public zonesWiseData:any

  //constructor declaration
  constructor() { }

  ngOnChanges() {
      //Here map configuration
      this.platform = new H.service.Platform({
          // "app_id": this.appId,
          apikey: this.appCode,
          useCIT: true,
          useHTTPS: true
      });
      this.router = this.platform.getRoutingService();
      console.log("----zonesWiseData")
      console.log(this.zonesWiseData)
      setTimeout(() => {
        this.route("47.6,-122","47,-122.2");
      }, 150);
  }

  public ngAfterViewInit() {
      setTimeout(() => {
      this.map = new H.Map(
          this.mapElement.nativeElement,
          this.platform.createDefaultLayers().vector.normal.map,
          {
              zoom: this.zoom,
              center: { lat: this.lat, lng: this.lng },
              pixelRatio: window.devicePixelRatio || 1,
          }
      );
      window.addEventListener('resize', () => this.map.getViewPort().resize());
      let behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(this.map));
      }, 50);
  }

  public route(start: any, finish: any) {
      // clear map here
      this.map.removeObjects(this.map.getObjects());

      var defaultLayers = this.platform.createDefaultLayers();
      var UI = H.ui.UI.createDefault(this.map, defaultLayers);
      var group = new H.map.Group();
      this.map.addObject(group);
      let verticeGroup = new H.map.Group({
          visibility: false
        });


      // let zonesWiseData = []; 
      // for(let i=0; i<this.allTrackDetails.length;i++)
      // { 
      //     let item = this.allTrackDetails[i].customerInfo;
      //     if(zonesWiseData.length == 0)
      //     {
      //       this.lat = item.address.location.coordinates[0];
      //       this.lng = item.address.location.coordinates[1];
      //       zonesWiseData.push({
      //         zoneID:item.zone.zoneId,
      //         zoneName:item.zone.zoneName,
      //         zoneCode:item.zone.zoneCode,
      //         zoneCity:item.zone.city,
      //         zoneLocation:[
      //           {
      //             lat:item.address.location.coordinates[0],
      //             lng:item.address.location.coordinates[1],
      //             customerName:item.customerName,
      //             customerCode:item.customerCode,
      //             totalWeight:item.totalWeight,
      //             totalVolume:item.totalVolume,
      //             orderType:item.orderType,
      //           }
      //         ]
      //       })
      //     }
      //     else
      //     {
      //        if(zonesWiseData.some(function(o){return o["zoneID"] === item.zone.zoneId;}) == true)
      //        {
      //           //It means zone is already there
      //           var index = zonesWiseData.findIndex(p => p.zoneID == item.zone.zoneId);
      //           zonesWiseData[index].zoneLocation.push(
      //             {
      //               lat:item.address.location.coordinates[0],
      //               lng:item.address.location.coordinates[1]
      //             }
      //           )

      //        }
      //        else
      //        {
      //           //It means zone is not present there
      //           zonesWiseData.push({
      //             zoneID:item.zone.zoneId,
      //             zoneName:item.zone.zoneName,
      //             zoneCode:item.zone.zoneCode,
      //             zoneCity:item.zone.city,
      //             zoneLocation:[
      //               {
      //                 lat:item.address.location.coordinates[0],
      //                 lng:item.address.location.coordinates[1]
      //               }
      //             ]
      //           });
      //        }
      //     }
      // }
      console.log("zonesWiseData")
      console.log(this.zonesWiseData)
      for(let i=0; i<this.zonesWiseData.length;i++)
      {
        let polygonArray = [];
        let waypointData = this.zonesWiseData[i].zoneLocation;
        for(let j=0;j<waypointData.length;j++)
        {
          let item = waypointData[j];
          polygonArray.push(item.lat);
          polygonArray.push(item.lng);
          polygonArray.push(0);
        }

        let polygon = new H.map.Polygon(
          new H.geo.Polygon(new H.geo.LineString(polygonArray)),
          {
            style: {fillColor: `rgba(${this.hexToRgb(this.colorArray[i]).r}, ${this.hexToRgb(this.colorArray[i]).g}, ${this.hexToRgb(this.colorArray[i]).b}, .8)`, lineWidth: 1}
          }
        );
        let mainGroup = new H.map.Group({
          volatility: true, // mark the group as volatile for smooth dragging of all it's objects
          objects: [polygon, verticeGroup]
        }),
        polygonTimeout;
        polygon.draggable = true;
        // create markers for each polygon's vertice which will be used for dragging
        polygon.getGeometry().getExterior().eachLatLngAlt(function(lat, lng, alt, index) {
          var vertice = new H.map.Marker(
            {lat, lng},
            {
              icon: new H.map.Icon(`./assets/images/IoT/icons/0700F9-start.png`, {anchor: {x: 10, y: 10}})
            }
          );
          vertice.draggable = true;
          vertice.setData({'verticeIndex': index})
          verticeGroup.addObject(vertice);
        });
        // add group with polygon and it's vertices (markers) on the map
        this.map.addObject(mainGroup);
      }
  }

  //get hexa color to rgb color conversion
  hexToRgb(hex)
  {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function(m, r, g, b) {
      return r + r + g + g + b + b;
    });
  
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }
}