import { Component, OnInit } from '@angular/core';
import { TripService } from 'src/app/services/trip/trip.service';
import { Subject } from 'rxjs';
import { MatTableDataSource, MatDialog } from '@angular/material';
import { takeUntil } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { GenericDialogBox } from '../../schedulingandplanning/edittripmodal/edittripmodal.component';

@Component({
  selector: 'app-tripmanagement',
  templateUrl: './tripmanagement.component.html',
  styleUrls: ['./tripmanagement.component.scss']
})
export class TripmanagementComponent implements OnInit {
  columnsToDisplay = [
    'tripName',
    'tripCreatedOn',
    'tripZone',
    'tripState',
    'originAddress',
    'orders',
    'transporter',
    'vehicleDetails',
    'capacityUtilization',
    'delete'
  ];
  protected ngUnsubscribe: Subject<void> = new Subject<void>()
  isLoading = true;
  tripDataArray = new MatTableDataSource();
  totalRecords: Number;
  fromDate: any;
  toDate: any;

  mapView: boolean = false;
  inPlanningCount: number = 0;
  scheduledCount: number = 0;
  intransitCount: number = 0;
  completedCount: number = 0;
  incomplete: number = 0;
  page: number = 0;
  typeOfTrip: any;
  customerZones = [];
  selectedVal: any;
  volUnit: string;
  isAllSelected: boolean = false;

  constructor(
    public tripService: TripService,
    public datePipe: DatePipe,
    private notifierService: NotifierService,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    let date, last, day, month, year;
    date = new Date();
    last = new Date(date.getTime() - (10 * 24 * 60 * 60 * 1000));
    day = last.getDate();
    month = last.getMonth();
    year = last.getFullYear();
    this.fromDate = this.datePipe.transform(new Date(year, month, day), 'yyyy-MM-dd');
    this.toDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.getData();
  }

  getData() {
    this.ngUnsubscribe.next();
    this.tripDataArray.data = [];
    this.isLoading = true;
    this.tripService.getAllTripsByTypesDateRange(['TRIP_STATE_IN_PLANNING', 'TRIP_STATE_SCHEDULED', 'TRIP_STATE_APPROVED'], -1, this.fromDate, this.toDate, 'nozone').pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.isLoading = false;
      console.log("trips in scheduled on page 0", res);
      this.totalRecords = res.totalElements;
      this.findCustomerZonesForTrip(res.content);
    });
  }
  findCustomerZonesForTrip(tripsArray) {
    tripsArray.forEach(job => {
      this.customerZones = [];
      job.jobs.forEach(job => {
        let index = -1;
        if (job.customerInfo && job.customerInfo.zone) {
          index = this.customerZones.indexOf(job.customerInfo.zone.zoneName);
          if (index == -1) {
            this.customerZones.push(job.customerInfo.zone.zoneName);
          }
        }
      });
      job.customerZones = this.customerZones;
    });
    this.calculateBoxesForEachTrip(tripsArray);
  }


  calculateBoxesForEachTrip(tripsArray) {
    tripsArray.forEach(trip => {
      let totalboxes = 0;
      trip.jobs.forEach(job => {
        totalboxes = totalboxes + job.numOfContainers;
      });
      trip.totalBoxes = totalboxes;
    });
    this.tripDataArray.data = tripsArray;
    console.log(tripsArray)
  }

  selectAll(event) {

  }


  deleteTrip(trip) {
    const dialogR = this.dialog.open(GenericDialogBox, {
      width: '300px',
      data: 'Are you sure you want to delete this trip ' + trip.tripName + '?'
    });

    dialogR.afterClosed().subscribe(result => {
      if (result) {
        this.spinner.show();
        this.tripService.deleteTrip(trip.tripId, "").subscribe(res => {
          this.spinner.hide();
          this.notifierService.notify("success", "Trip has been successfully deleted.");
          this.getData();
        }, error => {
          this.spinner.hide();
          this.notifierService.notify("error", "Error deleting trip. Please try again.");
          console.log(error);
        });
      }
    });
  }

  moveToIntransit(trip) {
    if (trip.tState != 'TRIP_STATE_IN_PLANNING') {
      const dialogR = this.dialog.open(GenericDialogBox, {
        width: '300px',
        data: 'Are you sure you want to move this trip to in-transit ' + trip.tripName + '?'
      });

      dialogR.afterClosed().subscribe(result => {
        if (result) {
          this.spinner.show();
          this.tripService.startTrip(trip.tripId,this.datePipe.transform(new Date(),'yyyy-MM-dd HH:mm:ss')).subscribe(res => {
            this.notifierService.notify("success", "Trip Updated Successfully.");
            this.spinner.hide();
            this.getData();
          }, Error => {
            this.notifierService.notify("error", "Error in scheduling the trip.");
            this.spinner.hide();
            console.log(Error);
          })
        }
      });
    }
  }
  applyFilter(filterValue: string) {
    this.tripDataArray.filter = filterValue.trim().toLowerCase();
    this.tripDataArray.filterPredicate = (data: any, filter: string) => {
      if (data) {
        if (data.tripName) {
          return data.tripName.toString().toLowerCase().indexOf(filter) != -1;
        }
      };
    }
  }
}
