import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatTableDataSource } from '@angular/material';
import { NotifierService } from 'angular-notifier';
import { StoreUserDetails } from 'src/app/auth/storeuserdetails';
import { TokenStorage } from 'src/app/auth/token.storage';
import { BillingService } from 'src/app/services/billing/billing.service';
import { ExcelgeneratorService } from 'src/app/services/excelgenerator/excelgenerator.service';
import { TransporterService } from 'src/app/services/transporter/transporter.service';
import { NgxDateRangePickerOptions } from 'src/ngx-daterangepicker';
import { BranchService } from 'src/app/services/branch/branch.service';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-billingreport',
  templateUrl: './billingreport.component.html',
  styleUrls: ['./billingreport.component.scss']
})
export class BillingreportComponent implements OnInit {
  dataLoading: boolean = true;
  allTransporters: any = [];
  allTransporterIds: any = [];
  fromDate: any;
  toDate: any;
  prevFromDate: string;
  prevTodate: string;
  dateRangePickeroptions: NgxDateRangePickerOptions;
  userRole: string;
  filteredBills: any = new MatTableDataSource();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  totalRecords: number = 0;
  isDateFilter: boolean = false;
  dateRangeValue: any = { to: "", from: "" };
  excelRecords = [];
  branchesSubscription: Subscription;
  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  columnsToDisplay = [
    'date',
    'branchName',
    'to',
    'shipmentNo',
    'transporter',
    'truck',
    'lr',
    'invoice',
    'cases',
    'freightRate',
    'unloadingcharges',
    'clubbingCharges',
    'amount',
    'pod',
    'status',
  ];

  constructor(
    private billingService: BillingService,
    private transporterService: TransporterService,
    private datePipe: DatePipe,
    private excelService: ExcelgeneratorService,
    private notifierService: NotifierService,
    private userDetails: StoreUserDetails,
    private branchService: BranchService
  ) { }

  ngOnInit() {

    this.setDatePickerToCurrentDate();

    this.fromDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.toDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    this.branchesSubscription = this.branchService.branchesObservable.subscribe(() => {
      this.ngUnsubscribe.next();
      if (window.sessionStorage.getItem("selectedBranchesCodes") && JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")).length == 0) {
        this.filteredBills.data = [];
        this.dataLoading = false;
      }
      else {
        this.filteredBills.data = [];

        this.userRole = window.sessionStorage.getItem('permissions').substr(2).slice(0, -2);
        
        if (this.userRole == 'transporter') {
          this.transporterService.getTransportersByUsername(this.userDetails.getUser()).subscribe(usertransporters => {
            let tmp: any;
            tmp = usertransporters;
            this.allTransporterIds = tmp.resource.resourceTypeRef;
            this.getBillData(this.allTransporterIds);
          }, () => {
            this.dataLoading = false;
            this.notifierService.notify('error', "Error fetching data..");
          });
        } 
        
        else if (this.allTransporterIds.length > 0) {
          this.getBillData(this.allTransporterIds);
        }
        else {
          this.getAllTransporters();
        }
      }
    });
  }

  setDatePickerToCurrentDate() {
    this.dateRangePickeroptions = {
      theme: 'default',
      labels: ['Start', 'End'],
      menu: null,
      dateFormat: 'YYYY-MM-DD',
      outputFormat: 'DD-MM-YYYY',
      startOfWeek: 1,
      outputType: 'object',
      locale: 'en',
      date: {
        from: {
          year: new Date().getFullYear(),
          month: new Date().getMonth() + 1,
          day: new Date().getDate()
        },
        to: {
          year: new Date().getFullYear(),
          month: new Date().getMonth() + 1,
          day: new Date().getDate()
        }
      }
    };
    this.dateRangeValue.from = this.datePipe.transform(new Date(), 'dd-MM-yyyy');
    this.dateRangeValue.to = this.datePipe.transform(new Date(), 'dd-MM-yyyy');
  }

  filterByDate() {
    if (this.dateRangeValue) {
      this.fromDate = this.dateRangeValue.from.substring(6, 10) + '-' + this.dateRangeValue.from.substring(3, 5) + '-' + this.dateRangeValue.from.substring(0, 2);
      this.toDate = this.dateRangeValue.to.substring(6, 10) + '-' + this.dateRangeValue.to.substring(3, 5) + '-' + this.dateRangeValue.to.substring(0, 2);
      if (this.toDate != this.prevTodate || this.fromDate != this.prevFromDate) {
        this.getBillData(this.allTransporterIds);
      }
      this.prevFromDate = this.fromDate;
      this.prevTodate = this.toDate;
      this.isDateFilter = true;
    }
  }

  clearAllFilter() {
    this.isDateFilter = false;
    this.paginator.pageIndex = 0;
    this.setDatePickerToCurrentDate();
    this.fromDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.toDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.getBillData(this.allTransporterIds);
  }


  getAllTransporters() {
    this.dataLoading = true;
    this.transporterService.getAllActiveTransporters().subscribe(allTrans => {
      this.allTransporters = [];
      this.allTransporters = allTrans;
      this.allTransporters.forEach(element => {
        this.allTransporterIds.push(element.transporterId);
      });
      this.getBillData(this.allTransporterIds);


    }, () => {
      this.dataLoading = false;
      this.notifierService.notify('error', "Error fetching data..");
    });
  }


  findUniqeLR(bills) {
    bills.forEach(bill => {
      let lrArray = [];
      bill.billDetails.forEach(billDetail => {
        lrArray.push(billDetail.lorryReceiptNumber);
      });
      bill.uniqueLRs = lrArray.filter((v, i, a) => a.indexOf(v) === i);
    });
  }

  getBillData(transporters) {
    this.dataLoading = true;
    this.filteredBills.data = []

    this.billingService.getAllBillsByTypesAndDateRange(this.fromDate, this.toDate, transporters, -1).pipe(takeUntil(this.ngUnsubscribe)).subscribe((bills: any) => {
      // console.log(bills)
      this.totalRecords = 0;
      this.totalRecords = bills.totalElements;
      this.findUniqeLR(bills.content);
      this.filteredBills.data = bills.content;
      this.dataLoading = false;

    }, () => {
      this.dataLoading = false;
      this.notifierService.notify('error', "Error fetching bills..");
    });
  }

  applyFilter(filterValue: string) {
    this.filteredBills.filter = filterValue.trim().toLowerCase();
  }

  exportAsXLSX() {
    this.dataForExcel(this.filteredBills.data);
    this.excelService.exportAsExcelFile(this.excelRecords, 'All Bills');
  }


  dataForExcel(filteredBills) {
    this.excelRecords = [];
    filteredBills.forEach(bill => {
      let date, from, to, transporter, lr, invoice, noofcases, totalamount, status, freightRate, unloadingCharges, clubbingCharges, shipmentNo;

      date = this.datePipe.transform(bill.createdDateTime, "dd-MM-yyyy hh:mm a");
      from = bill.origin;
      to = bill.destination;
      transporter = bill.transporterName;
      shipmentNo = bill.tripName.slice(5, bill.tripName.length)
      if (bill.billCosts) {
        freightRate = bill.billCosts.freightRate;
        unloadingCharges = bill.billCosts.unloadingCharges;
        clubbingCharges = bill.billCosts.clubbingCharges;
      }
      if (bill.billDetails) {
        bill.billDetails.forEach(detail => {
          if (lr && invoice) {
            lr = lr + "," + detail.lorryReceiptNumber;
            invoice = invoice + "," + detail.orderInvoiceNumber;
          }
          else {
            lr = detail.lorryReceiptNumber;
            invoice = detail.orderInvoiceNumber;
          }
        });
      }
      noofcases = bill.numContainers;
      totalamount = bill.billCosts.totalCharges;
      status = bill.bState.slice(11);
      if (bill) {
        this.excelRecords.push({
          'Date': date,
          'From': from,
          'To': to,
          'Shipment No': shipmentNo,
          'Transporter': transporter,
          'Truck No.': bill.vehicleNumber || '',
          'Truck Type': bill.vehicleType || '',
          'LR#': lr,
          'Invoice#': invoice,
          'No of Cases': noofcases,
          'Freight Rate': freightRate,
          'Unloading Charges': unloadingCharges,
          'Clubbing Charges': clubbingCharges,
          'Total Amount': totalamount,
          'Status': status,
          'POD %': bill.podCompletionPercent || 0
        })
      }
    });
  }

  getBillState(bill) {
    return this.billingService.getBillState(bill);
  }

  ngAfterViewInit() {
    this.filteredBills.paginator = this.paginator;
  }

  ngOnDestroy() {
    this.branchesSubscription.unsubscribe();
  }
}
