import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { FleetService } from '../../../services/fleet/fleet.service';
import { TokenStorage } from 'src/app/auth/token.storage';
import { NotifierService } from 'angular-notifier';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-addvehicletype',
  templateUrl: './addvehicletype.component.html',
  styleUrls: ['./addvehicletype.component.scss', '../../managefleet/addfleet/addfleet.component.scss']
})
export class AddvehicletypeComponent implements OnInit {
  vehicleForm: FormGroup;
  volUnit: string;
  constructor(
    private formBuilder: FormBuilder,
    private fleetService: FleetService,
    private token: TokenStorage,
    private notifierService: NotifierService,
    private router: Router,
    public dialog: MatDialog
  ) {

    if (this.token.getTenant() == 'kimberly-dist-prod') {
      this.volUnit = '(CM)';
    } else if (this.token.getTenant() == 'godrej-dist-prod') {
      this.volUnit = '(CCM)';
    } else {
      this.volUnit = '(CF)';
    }
    this.vehicleForm = formBuilder.group({
      'vehicleMakeModel': [null, [
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9- ]+$")
      ]],
      'vehicleType': [null, [
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9- ]+$")
      ]],

      'volumeCapacity': [null, [
        Validators.required,
        Validators.pattern('^[0-9]{1,15}(,[0-9]{10})*(([\\.,]{1}[0-9]{1,3})|())$')
      ]],
      'weightCapacity': [null, [
        Validators.required,
        Validators.pattern('^[0-9]{1,15}(,[0-9]{10})*(([\\.,]{1}[0-9]{1,3})|())$')
      ]],
      'vehicleTypeCode': [null, [
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9.-_ ]+$")
      ]]
    });
  }



  ngOnInit() {
  }

  addVehicleType() {
    console.log(this.vehicleForm.value);
    this.vehicleForm.value.vehicleType = this.titleCase(this.vehicleForm.value.vehicleType);
    this.vehicleForm.value.vehicleMakeModel = this.titleCase(this.vehicleForm.value.vehicleMakeModel);
    this.fleetService.addVehicleType(this.vehicleForm.value).subscribe(vehicleTypeAdded => {
      console.log("vehicletype add", vehicleTypeAdded);
      this.notifierService.notify("success", 'Vehicle Type Added SuccessFully');
      this.dialog.closeAll();
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate(["/transporter/vehicletype"]));
    }, Error => {
      console.log(Error)
      if (Error.status == 400)
        this.notifierService.notify("error", Error.error.Result);
      else
        this.notifierService.notify("error", "Error In Adding Vehicle Type. Please Try Again Later.");

    })
  }

  //cpaitalize 1st letter of each word in a string
  titleCase(str) {
    let words;
    words = str.toLowerCase().split(' ');

    for (var i = 0; i < words.length; i++) {
      var letters = words[i].split('');
      letters[0] = letters[0].toUpperCase();
      words[i] = letters.join('');
    }
    return words.join(' ');
  }

}