import { Component, OnInit, ViewChild } from '@angular/core';
import { MaterialcategoryService } from 'src/app/services/materialcategories/materialcategory.service';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog } from '@angular/material';
import { TokenStorage } from 'src/app/auth/token.storage';
import { ExcelgeneratorService } from 'src/app/services/excelgenerator/excelgenerator.service';
import { EditmaterialcategoryComponent } from '../editmaterialcategory/editmaterialcategory.component';

@Component({
  selector: 'app-allmaterialcategories',
  templateUrl: './allmaterialcategories.component.html',
  styleUrls: ['./allmaterialcategories.component.scss']
})
export class AllmaterialcategoriesComponent implements OnInit {
  columnsToDisplay = [
    'materialcategoryName',
    'edit'
  ];
  isLoading: boolean = false;
  isWarehouseManager: boolean = false;
  materialcategoryDataArray = new MatTableDataSource;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  excelRecords = [];



  constructor(
    private dialog: MatDialog,
    private materialcategoryService: MaterialcategoryService,
    private token: TokenStorage,
    private excelService: ExcelgeneratorService,
  ) {
     
  }

  ngOnInit() {
    debugger;
    if(window.sessionStorage.getItem('permissions').substr(2).slice(0, -2)=='warehouse_manager'){
      this.isWarehouseManager= true;
    }
    this.isLoading = true;
    this.materialcategoryDataArray.data = [];
    this.materialcategoryService.getAllMaterialCategories().subscribe(allMaterialCategories => {
      console.log(allMaterialCategories);
      this.isLoading = false;
      this.materialcategoryDataArray.data = allMaterialCategories
    }, Error => {
      console.log(Error)
      this.isLoading = false;
    })
    this.materialcategoryDataArray.sortingDataAccessor = (materialcategory: any, property) => {
      switch (property) {
        case 'materialcategoryName': return materialcategory.materialCategoryName
      }
    };
    this.materialcategoryDataArray.sort = this.sort;
    this.materialcategoryDataArray.paginator = this.paginator;
  }

  applyFilter(filterValue: string) {
    this.materialcategoryDataArray.filter = filterValue.trim().toLowerCase();
  }

  exportAsXLSX() {
    debugger;
    this.excelRecords = [];
    let materialcategoriesData = [];
    materialcategoriesData = this.materialcategoryDataArray.data;
    materialcategoriesData.forEach(materialcategory => {
     
      if (materialcategory) {
        this.excelRecords.push({
          'Material Category Name': materialcategory.materialCategoryName
        })
      }
    });
    this.excelService.exportAsExcelFile(this.excelRecords, 'materialcategoryrecords');
  }

  editMaterialCategory(materialcategory) {
    let editDialog = this.dialog.open(EditmaterialcategoryComponent, {
      width: '800px',
      data: materialcategory,
      id:'editMaterialCategoryWindow'
    });
    editDialog.updatePosition({
      top: '50px',
    });
    editDialog.afterClosed().subscribe(result => {
      console.log(result);
      if(result){
      this.ngOnInit();
      }
    });
  }
}
