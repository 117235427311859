import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Bill } from 'src/app/pages/billing/billing.component';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BillingService {

  constructor(
    private http: HttpClient,
  ) { }

  getBillsByTypesAndDateRange(fromDate: string, toDate: string, type: string, transporterRefs: number[], page: number) {
    console.log("type-----------" + type)


    let billTypes = [];
    if (type == 'approved') {
      billTypes.push(['BILL_STATE_SC_APPROVED', 'BILL_STATE_RAISED']);
    } else if (type == 'scReview') {
      billTypes.push([
        'BILL_STATE_IN_SC_REVIEW',
        'BILL_STATE_PENDING_SC_APPROVAL',
        'BILL_STATE_TRANSPORTER_APPROVED']);
    } else if (type == 'trpReview') {
      billTypes.push(['BILL_STATE_INITIAL', 'BILL_STATE_IN_TRANSPORTER_REVIEW']);
    }
    return this.http.get(environment.appUrl + '/bills/typesdaterange?page=' + page + '&fromDate=' + fromDate + '&toDate=' + toDate + '&types=' + billTypes + '&transporterRefs=' + transporterRefs + "&branchCodes=" + JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")));
  }

  getAllBillsByTypesAndDateRange(fromDate: string, toDate: string, transporterRefs: number[], page: number) {
    let billTypes = [];
    billTypes.push(['BILL_STATE_SC_APPROVED', 'BILL_STATE_RAISED', 'BILL_STATE_IN_SC_REVIEW', 'BILL_STATE_PENDING_SC_APPROVAL',
      'BILL_STATE_TRANSPORTER_APPROVED', 'BILL_STATE_INITIAL', 'BILL_STATE_IN_TRANSPORTER_REVIEW']);

    return this.http.get(environment.appUrl + '/bills/typesdaterange?page=' + page + '&fromDate=' + fromDate + '&toDate=' + toDate + '&types=' + billTypes + '&transporterRefs=' + transporterRefs + "&branchCodes=" + JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")));
  }


  getBill(id: number) {
    return this.http.get(environment.appUrl + '/bills/id/' + id);
  }

  saveBill(approveState: boolean, billDTO) {
    return this.http.put(environment.appUrl + '/bills/' + approveState, billDTO);
  }

  getBillAudits(billId: number) {
    return this.http.get(environment.appUrl + '/bills/audits/' + billId);
  }

  getBillState(bill: Bill) {
    let billState: string;
    if (bill.bState == 'BILL_STATE_IN_SC_REVIEW') {
      billState = "SC Review";
    } else if (bill.bState == 'BILL_STATE_IN_TRANSPORTER_REVIEW') {
      billState = "Transporter Review";
    } else if (bill.bState == 'BILL_STATE_PENDING_SC_APPROVAL') {
      billState = "SC Approval Pending";
    } else if (bill.bState == 'BILL_STATE_SC_APPROVED') {
      billState = "Approved";
    } else if (bill.bState == 'BILL_STATE_RAISED') {
      billState = "Bill Raised";
    }
    else if (bill.bState == 'BILL_STATE_INITIAL') {
      billState = "New";
    }
    else if (bill.bState == 'BILL_STATE_TRANSPORTER_APPROVED') {
      billState = "Transporter Approved";
    }
    return billState;
  }
  getBillCount(from, to, transporterRef): Observable<any> {
    console.log("In service - " + transporterRef)
    if (transporterRef.length<=0)
      return this.http.get(environment.appUrl + '/bills/count?fromDate=' + from + '&toDate=' + to + "&branchCodes=" + JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")))
    else
      return this.http.get(environment.appUrl + '/bills/count?fromDate=' + from + '&toDate=' + to + '&transporterRefs=' + transporterRef + "&branchCodes=" + JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")))
  }
}
