import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { TokenStorage } from 'src/app/auth/token.storage';
import { MaterialcategoryService } from 'src/app/services/materialcategories/materialcategory.service';
import { ValidatorService } from 'src/app/services/validator/validator.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-editmaterialcategory',
  templateUrl: './editmaterialcategory.component.html',
  styleUrls: ['./editmaterialcategory.component.scss']
})
export class EditmaterialcategoryComponent implements OnInit {
  materialcategoryForm: FormGroup;
  isSubmitButtonDisabled: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    public router: Router,
    private notifierService: NotifierService,
    private materialcategoryService: MaterialcategoryService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private token: TokenStorage,
    private spinnerService: NgxSpinnerService
    ) {
     
    this.materialcategoryForm = formBuilder.group({
      'materialCategoryId':[null],
      'materialCategoryName': [null, [
        Validators.required,
        ValidatorService.whitespaceValidator
      ]]
    });

    }

  ngOnInit() {
    console.log(this.data);
    // this.materialcategoryForm.controls['skuNumber'].disable({ onlySelf: true });
    this.materialcategoryForm.patchValue({
      materialCategoryId : this.data.materialCategoryId,
      materialCategoryName: this.data.materialCategoryName
    })
  }

  updateMaterialCategory() {
    this.isSubmitButtonDisabled = true;
    this.spinnerService.show();
    console.log("form value", this.materialcategoryForm.getRawValue());
    this.materialcategoryService.updateMaterialCategory(this.materialcategoryForm.getRawValue()).subscribe(materialcategoryadded => {
      this.notifierService.notify('success', 'Material Category updated successfully.');
      this.spinnerService.hide();
      this.dialog.getDialogById('editMaterialCategoryWindow').close(true);
    }, error => {
      console.log(error);
      this.spinnerService.hide();
      this.notifierService.notify("error", error.error.Result);
      this.materialcategoryForm.patchValue({
      });
      // alert(Error.error.Error);
    });
  }

}
