import { Component, OnInit, ViewChild } from '@angular/core';
import { ValidatorService } from 'src/app/services/validator/validator.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { BranchService } from 'src/app/services/branch/branch.service';
import { MatSnackBar, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { ZoneService } from 'src/app/services/zone/zone.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-addzone',
  templateUrl: './addzone.component.html',
  styleUrls: ['./addzone.component.scss']
})
export class AddzoneComponent implements OnInit {
  zoneForm: FormGroup;
  allBranchNames = [];
  disableSubmit: boolean = false;
  isLoading: boolean = false;
  isEditZone: boolean = false;
  isAddZone: boolean = false;
  editZoneId: any;
  editZoneRegion: any;
  associatedBranches = [];
  isAdmin: boolean = false;

  columnsToDisplay = [
    'zoneName',
    'zoneCode',
    'branch',
    'edit'
  ];

  allZones = new MatTableDataSource;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private formBuilder: FormBuilder,
    private branchService: BranchService,
    private snackBar: MatSnackBar,
    private zoneService: ZoneService,
    private router: Router
  ) {
    if(window.sessionStorage.getItem('permissions').substr(2).slice(0, -2) == 'admin')
     this.isAdmin = true;
   }

  ngOnInit() {
    this.getZones()
    this.initializeForm();
  }

  getZones() {
    this.isLoading = true;
    this.zoneService.getAllZones().subscribe(allZones => {
      this.allZones.data = allZones.reverse();
      this.isLoading = false;
      this.allZones.sortingDataAccessor = (zone: any, property) => {
        switch (property) {
          case 'zoneName': return zone.zoneName
        }
      };
      this.allZones.sort = this.sort;
      this.allZones.paginator = this.paginator;

    }, Error => {
      this.isLoading = false;
    })


  }

  initializeForm() {
    this.zoneForm = this.formBuilder.group({
      'zoneName': [null,
        Validators.compose([
          Validators.required,
          ValidatorService.whitespaceValidator
        ])],
      'zoneCode': [null,
        [
          Validators.required,
          Validators.maxLength(255),
          ValidatorService.whitespaceValidator
        ]],
      'branches': [null,
        Validators.compose([
          Validators.required,
          ValidatorService.whitespaceValidator
        ])],
      'city': [null, Validators.compose([
        Validators.pattern("^[a-zA-Z_ ]+$"),
        ValidatorService.whitespaceValidator
      ])],
      'state': [null, Validators.compose([
        Validators.pattern("^[a-zA-Z_ ]+$"),
        ValidatorService.whitespaceValidator
      ])]
    });
  }

  openEditZoneForm(zone) {
    this.zoneForm.reset();
    this.allBranchNames = [];
    this.branchService.getAllBranches().subscribe(allBranchNames => {
      this.allBranchNames = allBranchNames;

      this.allBranchNames.sort(function (a, b) {
        let branchName1 = a.branchName.toLowerCase(), branchName2 = b.branchName.toLowerCase()
        if (branchName1 < branchName2)
          return -1
        if (branchName1 > branchName2)
          return 1
        return 0
      })
      zone.branches.forEach(branch => {
        this.associatedBranches.push(branch.branchId);
      });
      this.zoneForm.controls['branches'].enable();
      let userBranches = [];
      this.branchService.branchesObservable.subscribe(branches => {
        userBranches = branches;
      })
      if(!this.isAdmin){
      this.allBranchNames.forEach(branch => {
        let isUserBranch;
        isUserBranch =  userBranches.find(uBranch => uBranch.branchId == branch.branchId);
        this.associatedBranches.forEach(associatedBranch => {
          if(branch.branchId == associatedBranch)
            branch.isDisabled = true && !this.isAdmin && !isUserBranch;
        });
        if(!branch.hasOwnProperty('isDisabled'))
          branch.isDisabled = false;
      });
    }
    });
    this.isEditZone = true;
    this.isAddZone = false;
    this.disableSubmit = false;
    this.associatedBranches = [];
    this.editZoneId = zone.zoneId,
    this.editZoneRegion = zone.region

    this.zoneForm.patchValue({
      'zoneName': zone.zoneName,
      'zoneCode': zone.zoneCode,
      'city': zone.city,
      'state': zone.state,
    })
  }

  editZone() {
    let branches = [];
    this.allBranchNames.forEach(branch => {
      this.associatedBranches.forEach(branchId => {
        if (branch.branchId == branchId) {
          branches.push(branch);
        }
      });
    });

    this.zoneForm.value.branches = branches;
    this.zoneForm.value.zoneId = this.editZoneId;
    this.zoneForm.value.region = this.editZoneRegion;
    if (this.zoneForm.valid) {
      this.disableSubmit = true;
      this.zoneService.updateZone(this.zoneForm.value).subscribe(res => {
        console.log(res);
        this.openSnackBar("Zone: " + this.zoneForm.value.zoneName + " updated successfully");
        this.disableSubmit = false;
        this.getZones();
      }, Error => {
        this.disableSubmit = false;
        if (Error.status == 400)
          this.openSnackBar(Error.error.Result)
        else
          this.openSnackBar("Error In Editing Zone Please Try Again Later");
      })
    }
  }

  applyFilter(filterValue: string) {
    this.allZones.filter = filterValue.trim().toLowerCase();
  }

  openAddZoneForm() {
    this.zoneForm.reset();
    this.isAddZone = true;
    this.isEditZone = false;
    this.allBranchNames = [];
    this.branchService.branchesObservable.subscribe(allBranchNames => {
      this.allBranchNames = allBranchNames;

      this.allBranchNames.sort(function (a, b) {
        let branchName1 = a.branchName.toLowerCase(), branchName2 = b.branchName.toLowerCase()
        if (branchName1 < branchName2)
          return -1
        if (branchName1 > branchName2)
          return 1
        return 0
      })
    });
  }

  addZone() {
    if (this.zoneForm.valid) {
      this.disableSubmit = true;
      console.log(this.zoneForm.value);
      this.zoneService.addZone(this.zoneForm.value).subscribe(res => {
        console.log(res);
        this.openSnackBar("Zone: " + this.zoneForm.value.zoneName + " added successfully");
        this.disableSubmit = false;
        this.zoneForm.reset();
        this.getZones();
      }, Error => {
        this.disableSubmit = false;
        if (Error.status == 400)
          this.openSnackBar(Error.error.Result)
        else
          this.openSnackBar("Error In Adding Zone Please Try Again Later");
      })
    }
  }

  openSnackBar(message) {
    this.snackBar.open(message, 'X', {
      duration: 10000,
      panelClass: ['extraClasses']
    });
  }
}
