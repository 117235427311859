import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { DeviceService } from 'src/app/services/device/device.service';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-alldevices',
  templateUrl: './alldevices.component.html',
  styleUrls: ['./alldevices.component.scss']
})
export class AlldevicesComponent implements OnInit {

  lat: number = 18.533989;
  lng: number = 73.825592;

  columnsToDisplay = [
    'checkbox',
    'name',
    'type',
    'status',
    'measure'
  ];

  devices = new MatTableDataSource;
  allDevices : any;
  allShown: boolean;

  constructor(public deviceService: DeviceService,
    private notifierService: NotifierService,
    ) { }

  ngOnInit() {

    this.allDevices = [];
    this.allShown = true;

    this.deviceService.getAllDevices().subscribe(res => {
      if (res.status == 204){
        this.notifierService.notify("error", "No Devices Found.");
      }
      else{
      this.devices.data = res.body;
      res.body.forEach(element => {
        let tmp = {
          deviceName: element.deviceName,
          currentLocation: element.currentLocation,
          visible: true
        }
        this.allDevices.push(tmp);
      });
    }
    });
  }

  deviceSelected(device, event){
    if(this.allShown){
      this.allShown = false;
      if(event.checked){
        this.allDevices.forEach(d => {
          if(d.deviceName !== device.deviceName){
            d.visible = false;
          }
        });
      }
    }else{
      if(event.checked){
        this.allDevices.forEach(d => {
          if(d.deviceName == device.deviceName){
            d.visible = true;
          }
        });
      }else{
        let count = 0;
        this.allDevices.forEach(d => {
          if(d.deviceName == device.deviceName){
            d.visible = false;
          }
          if(!d.visible){
            count++;
          }
        });
        if(count == this.allDevices.length){
          this.allShown = true;
          this.allDevices.forEach(d => {
            d.visible = true;
          });
        }
      }  
    }
  }

}
