import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { TokenStorage } from 'src/app/auth/token.storage';
import { ProductService } from 'src/app/services/products/product.service';
import { ValidatorService } from 'src/app/services/validator/validator.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-editproduct',
  templateUrl: './editproduct.component.html',
  styleUrls: ['./editproduct.component.scss']
})
export class EditproductComponent implements OnInit {
  productForm: FormGroup;
  isDuplicateSkuNumber: boolean = false;
  isQtyPackZero: boolean = false;
  isSubmitButtonDisabled: boolean = false;
  volUnit:any;
  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    public router: Router,
    private notifierService: NotifierService,
    private productservice: ProductService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private token: TokenStorage,
    private spinnerService: NgxSpinnerService
    ) {
      if (this.token.getTenant() == 'godrej-dist-prod' || this.token.getTenant() =='godrej-dist-test') {
        this.volUnit = 'Cubic CM';
      } else if (this.token.getTenant() == 'kimberly-dist-prod' || this.token.getTenant() == 'kimberly-dist-test') {
        this.volUnit = 'Cubic M';
      }
      else {
        this.volUnit = 'CF';
      }
    this.productForm = formBuilder.group({
      'productId': [null],
      'color': [null],
      'isActive': [null],
      'lastModifiedDate': [null],
      'materialCategory': [null],
      'materialType': [null],
      'productDescription': [null],
      'productName': [null, [
        Validators.required,
        ValidatorService.whitespaceValidator
      ]],
      'brand': [null, [
        ValidatorService.whitespaceValidator
      ]],
      'category': [null, [
        ValidatorService.whitespaceValidator
      ]],
      'length': [0, [
        Validators.required,
        Validators.minLength(0),
        Validators.maxLength(15),
        Validators.pattern("^[0-9]+$"),
        ValidatorService.whitespaceValidator
      ]],
      'height': [0, [
        Validators.required,
        Validators.minLength(0),
        Validators.maxLength(15),
        Validators.pattern("^[0-9]+$"),
        ValidatorService.whitespaceValidator
      ]],
      'width': [0, [
        Validators.required,
        Validators.minLength(0),
        Validators.maxLength(15),
        Validators.pattern("^[0-9]+$"),
        ValidatorService.whitespaceValidator
      ]],
      'weight': [0, [
        Validators.required,
        Validators.minLength(0),
        Validators.maxLength(15),
        Validators.pattern("^[0-9]+(\.[0-9]{0,3})?"),
        ValidatorService.whitespaceValidator
      ]],
      'price': [null, [
        Validators.minLength(0),
        Validators.maxLength(15),
        Validators.pattern("^[[0-9]+(\.[0-9]{0,3})?"),
        ValidatorService.whitespaceValidator
      ]],
      'skuNumber': [null, [
        Validators.required,
        ValidatorService.whitespaceValidator
      ]],
      'qtyPack': [null, [
        Validators.required,
        Validators.minLength(0),
        Validators.maxLength(15),
        Validators.pattern("^[0-9]+(\.[0-9]{0,2})?"),
        ValidatorService.whitespaceValidator
      ]],
      'hsnCode': [null, [
        ValidatorService.whitespaceValidator
      ]],
      'modelNumber': [null, [
        ValidatorService.whitespaceValidator
      ]],
      'totalVolume': [0, [
        ValidatorService.whitespaceValidator,
        Validators.minLength(0),
        Validators.maxLength(15),
        Validators.pattern("^[0-9]+(\.[0-9]{0,3})?"),
            ]]
    });

    }

  ngOnInit() {
    console.log(this.data);
    // this.productForm.controls['skuNumber'].disable({ onlySelf: true });
    this.productForm.patchValue({
      productId:this.data.productId,
      productName: this.data.productName,
      brand: this.data.brand,
      category: this.data.category,
      length: this.data.length,
      height: this.data.height,
      width: this.data.width,
      weight: this.data.weight,
      price: this.data.price,
      skuNumber: this.data.skuNumber,
      qtyPack: this.data.qtyPack,
      hsnCode: this.data.hsnCode,
      modelNumber: this.data.modelNumber,
      color: this.data.color,
      isActive: this.data.isActive,
      lastModifiedDate: this.data.lastModifiedDate,
      materialCategory: this.data.materialCategory,
      materialType: this.data.materialType,
      productDescription: this.data.productDescription,
      totalVolume: this.data.totalVolume
    })
  }

  checkzeroqtypack(event) {
    if (event.target.value == 0 && event.target.value != '') {
      this.isQtyPackZero = true;
      this.isSubmitButtonDisabled = true;
    }
    else {
      this.isQtyPackZero = false;
      this.isSubmitButtonDisabled = false;
    }
  }


  updateProduct() {
    this.isSubmitButtonDisabled = true;
    this.spinnerService.show();
    console.log("form value", this.productForm.getRawValue());
    this.productservice.updateProduct(this.productForm.getRawValue()).subscribe(productadded => {
      this.notifierService.notify('success', 'Product updated successfully.');
      this.spinnerService.hide();
      this.dialog.getDialogById('editProductWindow').close(true);
    }, error => {
      console.log(error);
      this.spinnerService.hide();
      this.notifierService.notify("error", error.error.Result);
      this.productForm.patchValue({
        skuNumber: this.data.skuNumber,
        qtyPack: this.data.qtyPack
      });
      // alert(Error.error.Error);
    });
  }

  makeisqtypacZeroFalse() {
    this.isQtyPackZero = false;
  }
}
