import { Component, OnInit, isDevMode } from '@angular/core';
import { TokenStorage } from 'src/app/auth/token.storage';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  isTestEnvironment: boolean =false;
  isHeretech: boolean =false;
  constructor(private token: TokenStorage
    ) { 
    if(isDevMode()){
      this.isTestEnvironment = true;
    }
    if (this.token.getTenant() == 'heretech-dist-test' || this.token.getTenant() == 'heretech-dist-prod') {
      this.isHeretech = true;
    }
  }

  ngOnInit() {
  }

}
