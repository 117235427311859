import { Component, OnInit, ViewChild } from '@angular/core';
import { OrderService } from 'src/app/services/order/order.service';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { NgxDateRangePickerOptions } from 'src/ngx-daterangepicker';
import { Subject, Subscription } from 'rxjs';
import { PdfgeneratorService } from 'src/app/services/pdfgenerator/pdfgenerator.service';
import { ExcelgeneratorService } from 'src/app/services/excelgenerator/excelgenerator.service';
import { DatePipe } from '@angular/common';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { takeUntil } from 'rxjs/operators';
import { BranchService } from 'src/app/services/branch/branch.service';

@Component({
  selector: 'app-podcompliancereport',
  templateUrl: './podcompliancereport.component.html',
  styleUrls: ['./podcompliancereport.component.scss']
})
export class PodcompliancereportComponent implements OnInit {
  podComplianceDataArray = new MatTableDataSource();
  columnsToDisplay = [
    'region',
    'plant',
    'countUploaded',
    'sumPodUpload',
    'podUploadCompliance',
  ];
  options: NgxDateRangePickerOptions;
  page: number = 0;
  excelRecords = [];
  totalRecords: number = 0;
  range = [1, 2, 3, 4, 5];
  daterRangevalue: any;
  fromDate: any;
  toDate: any;
  isDateFiltered: boolean = false;
  orders = [];
  prevFromDate: any;
  prevTodate: any;
  isLoading: boolean = false;

  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  branchesSubscription: Subscription;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(public orderService: OrderService,
    public generatePdf: PdfgeneratorService,
    public excelService: ExcelgeneratorService,
    public datePipe: DatePipe,
    public dialogService: DialogService,
    private branchService: BranchService
  ) { }

  ngOnInit() {
    this.createInitialFilter();
    this.branchesSubscription = this.branchService.branchesObservable.subscribe(() => {
      this.ngUnsubscribe.next();
      if (window.sessionStorage.getItem("selectedBranchesCodes") && JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")).length == 0) {
        this.podComplianceDataArray.data = [];
        this.isLoading = false;
      }
      else {
        this.podComplianceDataArray.data = [];
        this.podComplianceFilter();
      }
    });
  }

  ngAfterViewInit() {
    this.podComplianceDataArray.paginator = this.paginator;
  }

  exportAsXLSX() {
    this.excelRecords = [];
    let podComplianceData = [];
    podComplianceData = this.podComplianceDataArray.data;
    podComplianceData.forEach(podCompliance => {
      if (podCompliance) {
        this.excelRecords.push({
          'Region': podCompliance.branch_region || '',
          'Plant': podCompliance.branch_name || '',
          'Count Uploaded.': podCompliance.no_of_orders_uploaded || '',
          'Sum Of Pod Upload': podCompliance.no_of_pod_orders || '',
          'Pod Upload Compliance': podCompliance.pod_compliance_percent || '',
        })
      }
    });
    this.excelService.exportAsExcelFile(this.excelRecords, 'podComplianceReport');
  }
  public isMarked = (index, score) => {
    if (score >= index + 1) {
      return 'fa-star';
    }
    else {
      return 'fa-star-o';
    }
  }

  createInitialFilter() {
    this.options = {
      theme: 'default',
      labels: ['Start', 'End'],
      menu: null,
      dateFormat: 'YYYY-MM-DD',
      outputFormat: 'DD-MM-YYYY',
      startOfWeek: 1,
      outputType: 'object',
      locale: 'en',
      date: {
        from: {
          year: new Date().getFullYear(),
          month: new Date().getMonth() + 1,
          day: new Date().getDate()
        },
        to: {
          year: new Date().getFullYear(),
          month: new Date().getMonth() + 1,
          day: new Date().getDate()
        }
      }
    };
    this.fromDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.toDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.isDateFiltered = true;
  }

  podComplianceFilter() {
    if (this.isDateFiltered) {
      if (this.fromDate && this.toDate) {
        this.podComplianceDataArray.data = [];
        this.isLoading = true;
        this.getData(this.fromDate, this.toDate).pipe(takeUntil(this.ngUnsubscribe)).subscribe(orders => {
          this.isLoading = false;
          console.log("Pod Compliance", orders);
          this.podComplianceDataArray.data = orders;
          this.orders = orders;
          this.totalRecords = orders.length;
        }, Error => {
          this.isLoading = false;
        })
      }
    }
  }

  getData(from, to) {
    return this.orderService.getPodComplianceReport(from, to);
  }

  sortByDate() {
    if (this.daterRangevalue != undefined) {
      this.fromDate = this.daterRangevalue.from.substring(6, 10) + '-' + this.daterRangevalue.from.substring(3, 5) + '-' + this.daterRangevalue.from.substring(0, 2);
      this.toDate = this.daterRangevalue.to.substring(6, 10) + '-' + this.daterRangevalue.to.substring(3, 5) + '-' + this.daterRangevalue.to.substring(0, 2);
      if (this.toDate != this.prevTodate || this.fromDate != this.prevFromDate) {
        this.ngUnsubscribe.next();
        this.isDateFiltered = true;
        this.podComplianceFilter();
        this.prevFromDate = this.fromDate;
        this.prevTodate = this.toDate;
      }
    }
  }

  clearAllFilter() {
    this.paginator.pageIndex = 0;
    this.createInitialFilter();
    // this.podComplianceFilter();
    this.prevFromDate = this.fromDate;
    this.prevTodate = this.toDate;
    this.isDateFiltered = false;
  }

  ngOnDestroy() {
    this.branchesSubscription.unsubscribe();
  }
}
