import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AuditTrailService {

  constructor(private http: HttpClient) { }

  getTransactionAudit(from, to): Observable<any> {
      return this.http.get(environment.appUrl + '/transactionaudits/typesdaterange?fromDate=' + from + '&toDate=' + to)
  }

}
