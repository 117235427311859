import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';

@Component({
  selector: 'app-othergeofence',
  templateUrl: './othergeofence.component.html',
  styleUrls: ['./othergeofence.component.scss']
})
export class OthergeofenceComponent implements OnInit {
  allGeofenceData = new MatTableDataSource;
  allGeofenceCustomerData = [];
  circleColor = '#ffffb3';
  strokeColor  ='#ffff99';
  lat: number = 18.533989;
  lng: number = 73.825592;
  allShown: boolean;
  columnsToDisplay = [
    'geofenceName',
    'date',
    'geofenceType',
    'dimensions'
  ];
  constructor() { }

  ngOnInit() {
  }

}
