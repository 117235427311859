import { Component, OnInit } from '@angular/core';
import { DataSource } from '@angular/cdk/collections';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import { OrderService } from '../../../services/order/order.service';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-ordermapview',
  templateUrl: './ordermapview.component.html',
  styleUrls: ['./ordermapview.component.scss']
})
export class OrdermapviewComponent implements OnInit {
  unplannedCount: number = 0;
  inPlanningCount: number = 0;
  scheduledCount: number = 0;
  intransitCount: number = 0;
  failedCount: number = 0;
  deliveredCount: number = 0;
  zoom: number = 11;
  lat: number = 18.533989;
  lng: number = 73.825592;
  OrderMapMarker: any;
  markers: any;
  page: number = 0;
  markersArr = [];
  fromDate: any;
  toDate: any;
  constructor(
    public orderService: OrderService,
    public datePipe: DatePipe
  ) { }

  ngOnInit() {

    let type = 'ORDER_STATE_UNPLANNED';
    let month, day, year, date, backdate, today;
    year = new Date().getFullYear();
    month = new Date().getMonth();
    date = new Date().getDate();
    if ((month - 3) <= 0) {
      month = 12 + (month - 3);
      year = new Date().getFullYear() - 1;
    }
    this.fromDate = this.datePipe.transform(new Date(year, month, date), 'yyyy-MM-dd');
    this.toDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    this.generateMapData(type);

    this.orderService.getOrderCountByStatus(this.fromDate, this.toDate).subscribe(res => {
      console.log(res);
      if (this.searchArraryForCount(res, 'ORDER_STATE_UNPLANNED') != undefined) {
        this.unplannedCount = this.searchArraryForCount(res, 'ORDER_STATE_UNPLANNED').ordercount;
      }
      if (this.searchArraryForCount(res, 'ORDER_STATE_IN_PLANNING') != undefined) {
        this.inPlanningCount = this.searchArraryForCount(res, 'ORDER_STATE_IN_PLANNING').ordercount;
      }

      if (this.searchArraryForCount(res, 'ORDER_STATE_SCHEDULED') != undefined) {
        this.scheduledCount = this.searchArraryForCount(res, 'ORDER_STATE_SCHEDULED').ordercount;
      }

      if (this.searchArraryForCount(res, 'ORDER_STATE_IN_TRANSIT') != undefined) {
        this.intransitCount = this.searchArraryForCount(res, 'ORDER_STATE_IN_TRANSIT').ordercount;
      }

      if (this.searchArraryForCount(res, 'ORDER_STATE_FAILED') != undefined) {
        this.failedCount = this.searchArraryForCount(res, 'ORDER_STATE_FAILED').ordercount;
      }

      if (this.searchArraryForCount(res, 'ORDER_STATE_DELIVERED') != undefined) {
        this.deliveredCount = this.searchArraryForCount(res, 'ORDER_STATE_DELIVERED').ordercount;
      }
    });
  }

  searchArraryForCount(arr, value) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].orderstatus === value) {
        return arr[i];
      }
    }
  }

  orderData(type) {

    console.log(type);
    switch (type) {

      case "unplanned":

        this.generateMapData('ORDER_STATE_UNPLANNED');
        break;
      case "inplanning":
        this.generateMapData('ORDER_STATE_IN_PLANNING');
        break;
      case "scheduled":

        this.generateMapData('ORDER_STATE_SCHEDULED');
        break;
      case "intransit":

        this.generateMapData('ORDER_STATE_IN_TRANSIT');
        break;
      case "failed":

        this.generateMapData('ORDER_STATE_FAILED');
        break;
      case "delivered":
      default:

        this.generateMapData('ORDER_STATE_UNPLANNED');
    }
  }

  generateMapData(type) {
    this.markersArr = [];
    setTimeout(() => {
      this.orderService.getAllOrdersByTypesDateRange(type, this.page, this.fromDate, this.toDate, 'nozone').subscribe(res => {
        console.log("orders", res);
        for (let i = 0; i < res.length; i++) {
          // Iterate over numeric indexes from 0 to 5, as everyone expects.
          let newobj
          if (res[i].orderInfo.destinationAddress)
            newobj = res[i].orderInfo.destinationAddress.location;

          let labeldetails = res[i];
          if (newobj != null) {
            let lat, lng, orderID, receiverName = {};

            lat = newobj.coordinates[0];
            lng = newobj.coordinates[1];

            this.markersArr.push({
              lat: lat,
              lng: lng,
              orderId: labeldetails.orderInfo.orderInvoiceNumber,
              receiverName: labeldetails.orderInfo.receiverName
            })
          } else {
          }
        }
      })
    }, 500);

  }

  MapVIewSingle() {
    alert("mapiew");
  }
}