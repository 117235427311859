import { Component, OnInit } from '@angular/core';
import { TokenStorage } from 'src/app/auth/token.storage';

@Component({
  selector: 'app-transportrate',
  templateUrl: './transportrate.component.html',
  styleUrls: ['./transportrate.component.scss']
})
export class TransportrateComponent implements OnInit {
  isSa: boolean = false;
  isHeretech: boolean = false;

  constructor(private token: TokenStorage,
    ) {
    if(this.token.getTenant()=='sa-dist-prod'){
    this.isSa = true;
    }
    if (this.token.getTenant() == 'heretech-dist-test' || this.token.getTenant() == 'heretech-dist-prod') {
      this.isHeretech = true;
    };
   }

  ngOnInit() {
  }

}
