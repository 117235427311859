import { Component, OnInit, Inject } from '@angular/core';
import { FleetService } from '../../../services/fleet/fleet.service';
import { TripService } from '../../../services/trip/trip.service';
import { ResourceService } from '../../../services/resource/resource.service';
import { OrderService } from '../../../services/order/order.service';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { MessagesService } from '../../../services/messages/messages.service';
import { TransporterService } from '../../../services/transporter/transporter.service';
import { JobsService } from '../../../services/jobs/jobs.service';
import { NotifierService } from 'angular-notifier';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AddfleetComponent } from '../../managefleet/addfleet/addfleet.component';
import { AddresourceanduserComponent } from '../../addresourceanduser/addresourceanduser.component';
import { DeviceService } from 'src/app/services/device/device.service';
import { TokenStorage } from 'src/app/auth/token.storage';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { PlanService } from 'src/app/services/plan/plan.service';

@Component({
  selector: 'app-edittripmodal',
  templateUrl: './edittripmodal.component.html',
  styleUrls: ['./edittripmodal.component.scss']
})
export class EdittripmodalComponent implements OnInit {
  productIdArray = [];
  productIdContainer = [];
  productIdonly = [];
  public lat: Number = 18.552307
  public lng: Number = 73.724519


  public origin: {}
  public destination: {}
  public zoom: number = 13;
  public waypoints: any = []
  public productArrayInSelectedOrder = [];
  public productDetailsArray = [];

  tripName: string;
  tripsDataArray = [];
  ordersByTripIdArray = [];
  vehicleArray = [];
  selectedVehicle: string;
  vehicleType: string;
  selectedTransporter: string;
  selectedVehicleType: string;
  volumeCapacity: string;
  weightCapacity: string;
  ownership: string = 'ATTACHED_OR_VENDOR';
  isActionTaken: boolean = false;

  fleetSelectedForTrip: any;
  driverSelectedForTrip: any;

  driverArray = [];
  driverData: any;
  fleetData: any;
  driverName: string;
  driverPhoneNo: string;
  driverLicenseNo: string;
  selectedDriver: number;

  tripID: number;
  selectedFleet: string;

  isTransporterSelected: boolean = false;
  isFleetSelected: boolean = false;
  isDriverSelected: boolean = false;
  showSnackBarifDataExist: boolean = false;
  productId = [];
  allTransporters = [];

  customCollapsedHeight: string = '35px';
  customExpandedHeight: string = '35px';

  customerZones = [];
  jobsData = [];
  totalJobs: number;
  LRNum: any;
  areAllLRAssigned: boolean = false;
  isEditTripTable: boolean = false;
  isScheduleTripTable: boolean = false;
  allVehicleTypes = [];
  isVehicleTypeSelected: boolean = false;
  totalBoxesInTrip: number = 0;
  isDuplicateLR: boolean = false;
  duplicateLRJobId: any;
  commentVehicleTypeChange: any;
  isCommentOnVehicletypeChanged: boolean = false;
  selectedTrackingType: string = "mobile";
  selectedDevice: string;
  allDevices = [];
  isMobileTracking: boolean;
  isDeviceSelected: boolean = true;
  isMobileDeviceSelected: boolean = true;;
  inputVehicles = [];
  isCg: boolean = false;
  isMetro: boolean = false;
  isSuhana: boolean = false;
  isInvalidLR: boolean = false;
  invalidLRJobId;
  volUnit: string;
  isSaveClicked: boolean = false;

  config = {
    search: true,
    displayKey: 'driverName',
    searchOnKey: 'driverName',
    height: '40vh',
    placeholder: 'Select Driver'
  }

  configVehicle = {
    search: true,
    displayKey: 'vln',
    searchOnKey: 'vln',
    height: '40vh',
    placeholder: 'Select Vehicle'
  }

  configTransporter = {
    search: true,
    displayKey: 'transporterName',
    searchOnKey: 'transporterName',
    height: '40vh',
    placeholder: 'Select Vehicle'
  }

  constructor(@Inject(MAT_DIALOG_DATA)
  public data: any,
    public fleetService: FleetService,
    public tripService: TripService,
    public resourceService: ResourceService,
    public orderService: OrderService,
    public formBuilder: FormBuilder,
    public router: Router,
    public messagesService: MessagesService,
    public dialog: MatDialog,
    private dialogService: DialogService,
    public transporterService: TransporterService,
    public jobservice: JobsService,
    private notifierService: NotifierService,
    private deviceService: DeviceService,
    private token: TokenStorage,
    private spinnerService: NgxSpinnerService,
    private planService: PlanService
  ) {
    console.log(token.getTenant())
    this.tripID = this.data.data.tripId;
    console.log("trip data", this.data.data, this.data.component);

    if (this.token.getTenant() == 'kimberly-dist-prod') {
      this.volUnit = 'CM';
    } else if (this.token.getTenant() == 'godrej-dist-prod') {
      this.volUnit = 'CCM';
    } else {
      if (token.getTenant() == 'cg-dist-prod') {
        this.isCg = true;
      }
      this.volUnit = 'CF';
    }
    if (this.token.getTenant() == 'suhana-dist-prod' || this.token.getTenant() == 'suhana-dist-test') {
      this.isSuhana = true;
    }
  }

  ngOnInit() {
    if (this.data.data.vehicleType) {
      this.selectedVehicleType = this.data.data.vehicleType;
    }

    this.fleetService.getVehicleTypes().subscribe(vehicletypes => {
      console.log("vehicletypes", vehicletypes)
      this.inputVehicles = vehicletypes;
      vehicletypes.forEach(vehicle => {
        if (vehicle.vehicleType != this.selectedVehicleType) {
          this.allVehicleTypes.push({
            type: vehicle.vehicleType,
            code: vehicle.vehicleTypeCode
          });
        }
      });
      this.allVehicleTypes.sort(function (a, b) {
        let vehicle1 = a.type.toLowerCase(), vehicle2 = b.type.toLowerCase()
        if (vehicle1 < vehicle2) //sort string ascending
          return -1
        if (vehicle1 > vehicle2)
          return 1
        return 0
      });
    });

    if (this.data.component === 'scheduletriptable') {
      this.isScheduleTripTable = true;
      this.isEditTripTable = false;
    }

    this.tripName = this.data.data.tripName;
    this.volumeCapacity = this.data.data.totalVolume;
    this.weightCapacity = this.data.data.totalWeight;
    this.vehicleType = this.data.data.vehicleType;
    this.totalJobs = this.data.data.totalNumberOfJobs;
    if (this.data.data.assignedVehicleLicenseNumber) {
      this.selectedVehicle = this.data.data.assignedVehicleLicenseNumber;
      this.ownership = this.data.data.fleetOwnership;
      this.isFleetSelected = true;
    }
    if (this.data.data.assignedTransporterRef) {
      this.selectedTransporter = this.data.data.assignedTransporterRef;
      if (this.data.component === 'scheduletriptable') {
        this.getDriverForTransporter();
        this.getFleetForTransporter();
      }
      this.isTransporterSelected = true;
    }

    if (this.data.data.assignedDriverRef) {
      this.selectedDriver = this.data.data.assignedDriverRef;
      this.driverPhoneNo = this.data.data.assignedDriverPhoneNumber;
      this.driverName = this.data.data.assignedDriverName;
      this.isDriverSelected = true;
    }
    if (this.isScheduleTripTable) {
      this.data.data.jobs.forEach(job => {
        if (job.orders[0] && job.orders[0].lorryReceipt)
          job.LRNum = job.orders[0].lorryReceipt.lorryReceiptNumber;
        else
          job.LRNum = '';
      });
    }

    this.data.data.jobs.forEach(job => {
      this.totalBoxesInTrip = this.totalBoxesInTrip + job.numOfContainers;
    });

    if (this.token.getTenant() == 'metro-dist-prod' || this.token.getTenant() == 'metro-dist-test') {
      this.isMetro = true;
      this.areAllLRAssigned = true;
      /*  this.selectedTrackingType = "mobile";
       this.isMobileDeviceSelected = true; */
      this.isDeviceSelected = true;
    }
    else {
      let isLRnull = this.data.data.jobs.find(job => job.LRNum == '');
      if (isLRnull)
        this.areAllLRAssigned = false;
      else
        this.areAllLRAssigned = true;
    }
    this.jobsData = this.data.data.jobs;

    this.data.data.jobs.forEach(order => {
      let index = -1;
      if (order.customerInfo.zone) {
        index = this.customerZones.indexOf(order.customerInfo.zone.zoneName);
        if (index == -1) {
          this.customerZones.push(order.customerInfo.zone.zoneName);
        }
      }
    });
    this.transporterService.getAllTransporters().subscribe(allTrans => {
      this.allTransporters = allTrans;
      this.allTransporters.sort(function (a, b) {
        let transName1 = a.transporterName.toLowerCase(), transName2 = b.transporterName.toLowerCase()
        if (transName1 < transName2) //sort string ascending
          return -1
        if (transName1 > transName2)
          return 1
        return 0
      });
    });

  }

  vehicleSelected() {
    this.tripService.assignFleetToTrip(this.data.data.tripId, this.selectedVehicle).subscribe(res => {
      this.spinnerService.show();
      this.isActionTaken = true;
      console.log(res);
      this.isFleetSelected = true;
      this.spinnerService.hide();
      this.notifierService.notify("success", "Vehicle assigned successfully.");
    }, Error => {
      this.spinnerService.hide();
      this.notifierService.notify("error", "Error in assigning vehicle, please try again.");
      console.log(Error);
    })
  }

  transporterSelected() {
    this.isTransporterSelected = true;
    let findTransporter = this.allTransporters.find(trans => trans.transporterId == this.selectedTransporter);
    console.log(this.selectedTransporter, findTransporter);
    this.tripService.assignTransporterToTrip(this.data.data.tripId, findTransporter).subscribe(transporterAssigned => {
      console.log(transporterAssigned);
      this.isActionTaken = true;
      this.getDriverForTransporter();
      this.getFleetForTransporter();
    }, Error => {
      this.notifierService.notify("error", "Failed to assign transporter please try again later.");
      console.log(Error);
    });
  }


  getDriverForTransporter() {
    if (this.selectedTransporter) {
      this.transporterService.getDriversByTransporterId(this.selectedTransporter).subscribe(allDrivers => {
        // this.driverArray = allDrivers.map(transporter => )
        if (allDrivers.status == 204) {
          this.driverArray = [];
          this.notifierService.notify("error", 'No Active Resource Found For This Transporter.')
        }
        else {
          this.driverData = allDrivers.body;
          console.log("driver data", this.driverData);
          this.createDriverArray();
        }
      }, Error => {
        this.driverArray = [];
        this.notifierService.notify("error", Error.error.Result);
      });
    }
  }

  getFleetForTransporter() {
    this.fleetService.getFleetForTrip(this.ownership, this.vehicleType).subscribe(allFleets => {
      console.log("FLEETS", allFleets);
      this.vehicleArray = [];
      this.vehicleArray = allFleets;
    }, Error => {
      this.vehicleArray = [];
      this.notifierService.notify("error", Error.error.Result);
    });
  }

  driverSelected(currentSelectedDriver) {
    console.log(currentSelectedDriver)
    if (typeof currentSelectedDriver == 'object') {
      this.selectedDriver = currentSelectedDriver.resourceId;
      console.log(this.selectedDriver, currentSelectedDriver);
      let selectD = this.selectedDriver.toString();
      for (let i = 0; i < this.driverData.length; i++) {
        if (this.driverData[i].resourceId === parseInt(selectD)) {
          console.log(this.driverData[i]);
          this.driverSelectedForTrip = this.driverData[i];
          let name;
          name = this.driverData[i].firstName ? this.driverData[i].firstName + " " : '';
          name += this.driverData[i].lastName ? this.driverData[i].lastName : '';
          this.driverName = name;
          this.driverPhoneNo = this.driverData[i].phoneNumber1;
          this.driverLicenseNo = this.driverData[i].driverLicenseNumber
        }
      }

      this.tripService.assignDriverToTrip(this.data.data.tripId, this.driverSelectedForTrip).subscribe(res => {
        console.log(res);
        this.isActionTaken = true;
        this.isDriverSelected = true;
        this.createDriverArray();

        this.notifierService.notify("success", "Driver Allocated Successfully.");

      }, Error => {
        console.log(Error);
      });
    }
  }

  createDriverArray() {
    this.driverArray = [];
    if (this.driverData) {
      this.driverData.forEach(driver => {
        let driverName;
        if (driver.firstName && driver.phoneNumber)
          driverName = driver.firstName + "-" + driver.phoneNumber;
        else if (driver.firstName && driver.lastName)
          driverName = driver.firstName + " " + driver.lastName;
        else
          driverName = driver.firstName;

        this.driverArray.push({
          driverName: driverName,
          resourceId: driver.resourceId
        });
      });
      this.driverArray.sort(function (a, b) {
        let driverName1 = a.driverName.toLowerCase(), driverName2 = b.driverName.toLowerCase()
        if (driverName1 < driverName2) //sort string ascending
          return -1
        if (driverName1 > driverName2)
          return 1
        return 0
      });
    }
  }

  selectOwnership() {
    this.spinnerService.show();
    this.vehicleArray = [];
    this.selectedVehicle = '';
    this.fleetService.getFleetForTrip(this.ownership, this.vehicleType).subscribe(fleets => {
      this.vehicleArray = fleets;
      this.spinnerService.hide();
    }, error => {
      this.spinnerService.hide();
      this.notifierService.notify("error", "Error in fetching fleets. Please contact to Admin.");
    });
  }

  vehicleTypeSelected(vehicleType: string) {
    this.isVehicleTypeSelected = true;
    this.selectedVehicleType = vehicleType;
  }

  commentOnVehicleTypeChanged() {
    if (this.commentVehicleTypeChange) {
      this.isCommentOnVehicletypeChanged = true;
    } else {
      this.isCommentOnVehicletypeChanged = false;
    }
  }

  requestToChangeVehicleType() {
    let vehicleTypeObj = {}
    this.inputVehicles.forEach(vehicle => {
      if (vehicle.vehicleType === this.selectedVehicleType) {
        vehicleTypeObj = vehicle;
      }
    });
    console.log(vehicleTypeObj)
    this.tripService.requestVehicleTypeChange(this.data.data.tripId, vehicleTypeObj, this.commentVehicleTypeChange).subscribe(vehicleTypeChanged => {

      if (vehicleTypeChanged.status == 204) {
        this.notifierService.notify("error", "No Fleet available for this Vehicle Type.");
      } else {
        this.planService.getPlanFromTrip(this.data.data.tripId).subscribe(plan => {
          this.router.navigate(['/scheduling/edittrips', { planId: plan.planRef }]).then(() => {
          });
        });
        this.dialog.closeAll();
      }
    }, Error => {
      this.notifierService.notify("error", Error.error.Result);
    }
    )
  }

  unApproveTrip() {
    const dialogR = this.dialog.open(GenericDialogBoxWithReference, {
      width: 'fit-content',
      data: 'Are you sure want to Un-approve trip ' + this.data.data.tripName + ' ?'
    });

    dialogR.afterClosed().subscribe(result => {
      if (result) {
        this.spinnerService.show();
        this.tripService.unApproveTrip(this.data.data.tripId,result).subscribe(res => {
          this.dialog.closeAll();
         /*  this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
            this.router.navigate(['/scheduling/scheduletrips'])); */
            this.planService.getPlanFromTrip(this.data.data.tripId).subscribe(plan => {
              this.router.navigate(['/scheduling/edittrips', { planId: plan.planRef }]).then(() => {
              });
            },()=>{
              this.spinnerService.hide();
            });
          this.spinnerService.hide()
          console.log(res)
        }, () => {
          this.spinnerService.hide()
          this.notifierService.notify("error", "Error In Un-approving the trip. Please try again later.")
        })
      }
    });
  }

  ScheduleTrip() {
    console.log(this.data.data);
    console.log("Accept pressed " + this.data.data.tripId);
    this.spinnerService.show();
    this.tripService.scheduleTrip(this.data.data.tripId).subscribe(res => {
      this.spinnerService.hide();
      console.log(res);
      this.dialog.closeAll();
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate(['/scheduling/scheduletrips']));
      this.messagesService.sendMessage("Trip # " + this.tripName + " accepected and scheduled successfully")
    }, Error => {
      this.spinnerService.hide();
      console.log(Error);
    })

  }
  openDialog() {
    const dialogR = this.dialog.open(GenericDialogBoxWithReference, {
      width: 'fit-content',
      data: 'Are you sure want to delete trip ' + this.data.data.tripName + ' ?'
    });

    dialogR.afterClosed().subscribe(result => {
      if (result) {
        this.DeleteTrip(result);
        this.dialog.closeAll();
      }
    });
  }

  DeleteTrip(comment) {
    console.log("Delete pressed " + this.data.data.tripId);
    this.tripService.deleteTrip(this.data.data.tripId, comment).subscribe(res => {
      console.log(res);
      if (this.isEditTripTable) {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/scheduling/edittrips'])
        });
      }
      else {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/scheduling/scheduletrips'])
        });
      }
      this.messagesService.sendMessage("Trip # " + this.tripName + "deleted successfully");
    }, Error => {
      console.log(Error);
    });
  }


  assignLRno(job) {
    this.LRNum = job.LRNum;
    this.isSaveClicked = true;
    let isLRnull = this.data.data.jobs.find(job => job.LRNum == '');
    if (this.LRNum != undefined && this.LRNum != "" && !this.isInvalidLR) {
      console.log(job.LRNum)
      this.jobservice.putLRnoOfOrder(job.jobId, this.LRNum).subscribe(LrAssigned => {
        this.isSaveClicked = false;
        if (isLRnull)
          this.areAllLRAssigned = false;
        else
          this.areAllLRAssigned = true;

        this.isDuplicateLR = false;
        this.notifierService.notify("success", "LR has been successfully assigned.");
        console.log(LrAssigned);
      }, Error => {
        console.log(Error.error.Result);
        this.isSaveClicked = false;
        if (isLRnull)
          this.areAllLRAssigned = false;
        else
          this.areAllLRAssigned = true;
        if (Error.error.Result == 'Error: Lorry receipt already exists.') {
          this.isDuplicateLR = true;
          this.duplicateLRJobId = job.jobId;
        }
      });
    }
  }

  makeDuplicateLrFalse() {
    this.isDuplicateLR = false;
  }

  validateLR(event, job) {
    console.log(event, job)
    if (!event.target.value.match(/^[0-9a-zA-Z]+$/)) {
      this.invalidLRJobId = job.jobId;
      this.isInvalidLR = true;
    }
    else
      this.isInvalidLR = false;

  }

  approveTrip() {
    this.tripService.approveTrip(this.data.data.tripId).subscribe(res => {
      console.log(res);
      this.dialog.closeAll();
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate(['/scheduling/scheduletrips']));
      this.messagesService.sendMessage("Trip # " + this.tripName + " approved successfully")
    }, Error => {
      console.log(Error);
    });
  }


  functionredirect() {
    console.log("called")
    this.router.navigate(["/scheduling/scheduletrips"]);
  }

  orderDetails(data) {
    data.tripRefId = this.data.data.tripId;
    data.tripRefName = this.tripName;
    this.dialogService.orderDetails(data, 'tripDetails');
  }

  addFleet() {
    let addFleet = this.dialog.open(AddfleetComponent, {
      width: '800px',
      // height: '65vh',
      id: 'addFleet',
      data: {
        transpoter: this.selectedTransporter,
        vehicleType: this.vehicleType
      }
    });
    addFleet.updatePosition({
      top: '65px'
    });
    addFleet.afterClosed().subscribe(result => {
      if (result) {
        this.getFleetForTransporter()
      }
    });
  }


  addDriver() {
    let addDriver = this.dialog.open(AddresourceanduserComponent, {
      width: '800px',
      data: {
        transpoter: this.selectedTransporter,
      }
      // height: '100vh',
    });
    addDriver.updatePosition({
      top: '65px',
    });
    addDriver.afterClosed().subscribe(result => {
      console.log(result);
      if (result) {
        this.getDriverForTransporter();
      }
    });
  }

  trackingTypeChanged(trackingType: string) {
    if (trackingType == 'mobile') {
      this.isMobileDeviceSelected = true;
    }
    else
      this.isMobileDeviceSelected = false;
    this.selectedTrackingType = trackingType;
    this.allDevices = [];
    if (this.selectedTrackingType == 'gpsdevice') {
      this.isDeviceSelected = false;
      this.deviceService.getDevicesByType("DEVICE_TYPE_GPS_DEVICE").subscribe(res => {
        this.allDevices = res.body;
        if (this.allDevices) {
          this.allDevices.sort(function (a, b) {
            let deviceName1 = a.deviceName.toLowerCase(), deviceName2 = b.deviceName.toLowerCase()
            if (deviceName1 < deviceName2) //sort string ascending
              return -1
            if (deviceName1 > deviceName2)
              return 1
            return 0
          });
        }
        if (res.status == 204)
          this.notifierService.notify("error", "No Device Found.");
      });

    } else {
      this.isDeviceSelected = true;
      /* this.deviceService.("DEVICE_TYPE_MOBILE").subscribe(res => {
        this.allDevices = res;
      }); */
    }
  }

  deviceSelected(deviceId: string) {
    let tmp = [];
    tmp.push(deviceId);
    this.deviceService.setDevice(this.tripID, tmp).subscribe(() => {
      this.isDeviceSelected = true;
    }, () => {

    });
  }
}

export interface OrderListElement {
  zone: string;
  tripID: string;
  origin: string;
  destination: string;
  orders: string;
  transpoter: string;
  vehicleType: string;
  capacityUtilization: string;
  status: string;
}

export interface tripElement {
  receiverName: string;
  invoice: string;
  productDetails: string;
  numOfProducts: string;
}

@Component({
  selector: 'dialog-confirm',
  template: `
  <div style="padding:20px;">
    <p> Are you sure you want to remove this order from trip ?</p>
    <div class="row">
      <div class="col">
        <button mat-raised-button color="warn" (click)="onNoClick()">Cancel</button>
      </div>
      <div class="col">
        <button mat-raised-button color="primary" [mat-dialog-close]="true">OK</button>
      </div>
    </div>
  </div>
  `,
})
export class DialogConfirm {


  constructor(
    public dialogRef: MatDialogRef<DialogConfirm>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

}


@Component({
  selector: 'delete-dialog-confirm',
  template: `
  <div *ngIf= "data == 'EnterBillNum'; else confirmation">
    <div style="padding:20px;">
    <div style="display: flex; justify-content: center;">
      <input type="text" (mouseleave) = "storeBillNum($event.target.value)">
   </div>
    <div class="mt-2" style="display: flex; justify-content: space-between;">
        <button mat-raised-button color="warn" (click)="onNoClick()">Cancel</button>
        <button mat-raised-button color="primary" [disabled] = "!billNum" (click)="sendBillNum()">OK</button>
    </div>
  </div>
  </div>
  <ng-template #confirmation>
  <div style="padding:20px;">
    <p> {{data}}</p>
    <div class="row">
      <div class="col">
        <button mat-raised-button color="warn" (click)="onNoClick()">NO</button>
      </div>
      <div class="col">
        <button class="pull-right" mat-raised-button color="primary" [mat-dialog-close]="true">YES</button>
      </div>
    </div>
  </div>
</ng-template>
  `,
})
export class GenericDialogBox {

  billNum: any;
  constructor(
    public dialogR: MatDialogRef<GenericDialogBox>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.dialogR.close();
  }

  storeBillNum(billNum) {
    this.billNum = billNum;
  }

  sendBillNum() {
    this.dialogR.close(this.billNum);
  }
}






@Component({
  selector: 'generic-dialog-box-with-reference',
  template: `
  <div *ngIf= "data == 'EnterBillNum'; else confirmation">
    <div style="padding:20px;">
    <div style="display: flex; justify-content: center;">
      <input type="text" (mouseleave) = "storeBillNum($event.target.value)">
   </div>
    <div class="mt-2" style="display: flex; justify-content: space-between;">
        <button mat-raised-button color="warn" (click)="onNoClick()">Cancel</button>
        <button mat-raised-button color="primary" [disabled] = "!billNum" (click)="sendBillNum()">OK</button>
    </div>
  </div>
  </div>
  <ng-template #confirmation>
  <div style="padding:20px;">
    <p>{{data}}</p>
    <div class="row">
      <div class="col-12 p-2">
        <textarea class="form-control" placeholder="Remark" (mouseleave)="storeRef($event.target.value)" row="2"></textarea>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <button mat-raised-button color="warn" (click)="onNoClick()">NO</button>
      </div>
      <div class="col">
        <button class="pull-right" mat-raised-button color="primary" (click)="sendReference()" [disabled]="!referenceNote">YES</button>
      </div>
    </div>
  </div>
</ng-template>
  `,
})
export class GenericDialogBoxWithReference {

  billNum: any;
  referenceNote: any;
  constructor(
    public dialogR: MatDialogRef<GenericDialogBoxWithReference>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.dialogR.close();
  }

  storeBillNum(billNum) {
    this.billNum = billNum;
  }

  sendBillNum() {
    this.dialogR.close(this.billNum);
  }

  storeRef(referNote) {
    this.referenceNote = referNote;
  }

  sendReference() {
    this.dialogR.close(this.referenceNote);
  }
}