import { Component, OnInit, Input } from '@angular/core';
import { RoleService } from '../../../services/role/role.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-userrolemanage',
  templateUrl: './userrolemanage.component.html',
  styleUrls: ['./userrolemanage.component.scss']
})
export class UserrolemanageComponent implements OnInit {

  roleAdmin: FormGroup;
  roleCXO: FormGroup;
  roleManager: FormGroup;
  roleStoreManager: FormGroup;
  userId: string;
  roleName: string;
  roleId: number;
  disabled = true;
  constructor(
    public formBuilder: FormBuilder,
    public roleService: RoleService,
    public snackBar: MatSnackBar,
    public router: Router
  ) {

  }

  ngOnInit() {
    this.roleService.currentMessage.subscribe(data => {
      console.log(data);
      this.userId = data;
      if (data != null) {
        this.disabled = false;
      }
    })

  }

  assignRoleAdmin(bAssign) {
    console.log(bAssign);
    let assignRoleData = {
      userId: this.userId,
      roleName: "admin",
      roleId: 1,
      bAssign: bAssign
    }
    console.log(assignRoleData);
    this.assignRole(assignRoleData, bAssign);
  }

  assignRoleDeliveryAssociate(bAssign) {
    let assignRoleData = {
      userId: this.userId,
      roleName: "delivery_associate",
      roleId: 2,
      bAssign: bAssign
    }
    console.log(assignRoleData);
    this.assignRole(assignRoleData, bAssign);
  }

  assignRoleWarehouseManager(bAssign) {
    let assignRoleData = {
      userId: this.userId,
      roleName: "warehouse_manager",
      roleId: 3,
      bAssign: bAssign
    };
    console.log(assignRoleData);
    this.assignRole(assignRoleData, bAssign);
  }

  assignRolePlanner(bAssign) {
    let assignRoleData = {
      userId: this.userId,
      roleName: "planner",
      roleId: 4,
      bAssign: bAssign
    };
    console.log(assignRoleData);
    this.assignRole(assignRoleData, bAssign);
  }

  assignRoleTransporter(bAssign) {
    let assignRoleData = {
      userId: this.userId,
      roleName: "transporter",
      roleId: 5,
      bAssign: bAssign
    };
    console.log(assignRoleData);
    this.assignRole(assignRoleData, bAssign);
  }

  assignRoleCustomer(bAssign) {
    let assignRoleData = {
      userId: this.userId,
      roleName: "customer",
      roleId: 6,
      bAssign: bAssign
    };
    console.log(assignRoleData);
    this.assignRole(assignRoleData, bAssign);
  }

  assignRoleRegionalAdmin(bAssign) {
    let assignRoleData = {
      userId: this.userId,
      roleName: "regional_admin",
      roleId: 7,
      bAssign: bAssign
    };
    console.log(assignRoleData);
    this.assignRole(assignRoleData, bAssign);
  }

  assignRole(assignRoleData, bAssign) {
    this.roleService.assignRoleToUser(assignRoleData).subscribe(res => {
      console.log(res);
      if (bAssign) {
        this.snackBar.open("User Role has been assigned.", "ok", {
          duration: 2000
        });
      } else {
        this.snackBar.open("User Role has been revoked.", "ok", {
          duration: 2000
        });
      }
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate(["/userandrole/assignrole"]));
    });
  }



  ngOnDestroy() {
    this.roleService.messageSource.next(null);
  }
}


