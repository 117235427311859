import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import * as jspdf from 'jspdf';


@Injectable({
  providedIn: 'root'
})
export class PdfgeneratorService {

  constructor() { }

  pdfgenerator(canvas, numOfOrders) {
    var imgWidth = 208;
    const contentDataURL = canvas.toDataURL('image/png')
    let pdf = new jspdf('p', 'mm', 'a4');
    pdf.addImage(contentDataURL, 'PNG', 0, 0, imgWidth, (numOfOrders * 10) + 15)
    pdf.setFontType("bold");
    pdf.setFontSize(12);
    pdf.save('tripanalytics.pdf');
  }

}
