import { Component, OnInit, ViewChild } from '@angular/core';
import { GeofenceService } from 'src/app/services/geofence/geofence.service';
import { MatTableDataSource } from '@angular/material';
import { CustomerService } from 'src/app/services/customer/customer.service';
import { AgmMap, LatLngBounds } from '@agm/core';

declare var google: any;

@Component({
  selector: 'app-geofencing',
  templateUrl: './geofencing.component.html',
  styleUrls: ['./geofencing.component.scss']
})
export class GeofencingComponent implements OnInit {

  isCustomerGeoActive: boolean = true;
  isOtherGeoActive: boolean = false;
  lat: number = 18.533989;
  lng: number = 73.825592;
  circleColor = '#053ba59e';
  strokeColor = '#ffff99';
  allGeofenceData = new MatTableDataSource;

  config = {
    search: true,
    displayKey: 'customerName',
    searchOnKey: 'customerName',
    height: '40vh',
    placeholder: 'Select Customer'
  }

  columnsToDisplay = [
    'name',
    'type',
    'date',
  ];

  selectedVal: string;
  clickedLat = 0;
  clickedLong = 0;
  geofenceObject;
  radius: number = 500;
  name;
  description;
  allCustomers: any;
  selectedCustomer: any;
  @ViewChild('map') agmMap: AgmMap;

  constructor(public geofenceService: GeofenceService,
    public customerService: CustomerService) { }

  ngOnInit() {
    this.selectedVal = "addgeofence";
    this.geofenceService.getGeofenceObj().subscribe(geofenceObj => {
      this.geofenceObject = geofenceObj;
    });

    this.customerService.getAllCustomers().subscribe(allcusts => {
      this.allCustomers = allcusts;
    });
  }

  placeMarker(event) {
    console.log(event.coords.lat);
    console.log(event.coords.lng);
    this.clickedLat = Number(event.coords.lat);
    this.clickedLong = Number(event.coords.lng);
  }

  changeRadius(event) {
    this.radius = Number(event.srcElement.value);
    console.log(typeof (this.radius));
  }

  saveObj() {
    this.geofenceObject.location.coordinates = [];
    this.geofenceObject.location.coordinates.push(this.clickedLat);
    this.geofenceObject.location.coordinates.push(this.clickedLong);
    this.geofenceObject.location.type = "Point";
    this.geofenceObject.radius = this.radius;
    this.geofenceObject.bActive = null;
    this.geofenceObject.createdDateTime = null;
    this.geofenceObject.description = this.description;
    this.geofenceObject.geofenceId = null;
    if(this.isCustomerGeoActive)
      this.geofenceObject.name = this.selectedCustomer.customerName.
    else
      this.geofenceObject.name = this.name;
    this.geofenceObject.type = "circle";
    this.geofenceObject.updatedDateTime = null;
    this.geofenceService.AddGeofence(this.geofenceObject).subscribe(geofenceAdded => {
      console.log("geofence added response", geofenceAdded);
    })
  }

  clearMapAndGeofenceObj() {
    this.radius = 500;
    this.clickedLat = 0;
    this.clickedLong = 0;
    this.name = "";
    this.description = "";
  }

  customerGeoChanged(event) {
    if (event.checked)
      this.isCustomerGeoActive = true;
    else
      this.isCustomerGeoActive = false;
  }

  customerSelected() {
    if(this.selectedCustomer) {
      this.lat = this.selectedCustomer.address.location.coordinates[0];
      this.lng = this.selectedCustomer.address.location.coordinates[1];
    } 
  }
}
