import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs/Observable';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  base_url: string;
  constructor(private http: HttpClient) {
    this.base_url = environment.appUrl;
  }

  getNotifications(): Observable<any> {
    return this.http.get(this.base_url + "/notifications");
  }

  getNotifcationByReadState(): Observable<any> {
    return this.http.get(this.base_url + "/notificationsByReadState?isRead=false");
  }

  markAllRead(notifications): Observable<any> {
    return this.http.put(this.base_url + "/markNotificationsAsRead", notifications);
  }

  subscribeToTopic(token): Observable<any> {
    return this.http.post(this.base_url + "/" + token + "/rel/topics", null);
  }

}
