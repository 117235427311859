import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BranchService {

  base_url: string;
  private readonly userBranches = new BehaviorSubject<any>([]);
  branchesObservable = this.userBranches.asObservable();

  constructor(private http: HttpClient) {
    this.base_url = environment.appUrl + "/branches"
  }

  getAllBranches(): Observable<any> {
    return this.http.get(this.base_url);
  }

  //Digvijay Start
  getZonesByBranchId(branchId):Observable<any>{
    console.log(branchId);
    return this.http.get(this.base_url + "/zones/"+branchId);
  }
  //Digvijay End

  addBranch(newBranch):Observable<any>{
    JSON.stringify(newBranch);
    console.log(newBranch);
    return this.http.post(this.base_url, newBranch);
  }

  editBranch(newBranch):Observable<any>{
    JSON.stringify(newBranch);
    console.log(newBranch);
    return this.http.put(this.base_url, newBranch);
  }

  getAllBranchType(): Observable<any>{
    return this.http.get(this.base_url + "/branchtypes");
  }
  
  setUserbranches(branches) {
    this.userBranches.next(branches);
  }
}
