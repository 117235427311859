import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { TokenStorage } from 'src/app/auth/token.storage';
import { AuditTrailService } from 'src/app/services/audit/audit-trail.service';
import { NgxDateRangePickerOptions } from 'src/ngx-daterangepicker';
import { ExcelgeneratorService } from 'src/app/services/excelgenerator/excelgenerator.service';

@Component({
  selector: 'app-manage-audit-trail',
  templateUrl: './manage-audit-trail.component.html',
  styleUrls: ['./manage-audit-trail.component.scss']
})
export class ManageAuditTrailComponent implements OnInit {
  fromDate: any;
  toDate: any;
  prevFromDate: string;
  prevTodate: string;
  dateRangePickeroptions: NgxDateRangePickerOptions;
  dateRangeValue: any= { to: "", from: "" };
  isDateFilter: boolean = false;
  allLogDetails:any=[];
  allLogData:any=[];
  searchFilterText:string;
  excelRecords = [];

  constructor(
    private auditTrailService:AuditTrailService,
    private notifierService: NotifierService,
    private token: TokenStorage,
    private datePipe: DatePipe,
    private excelService: ExcelgeneratorService
    ) { }

  ngOnInit() {
    this.setDatePickerToCurrentDate(); 
    this.fromDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.toDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.getTransactionAudit();
  }

  setDatePickerToCurrentDate() {
    this.dateRangePickeroptions = {
      theme: 'default',
      labels: ['Start', 'End'],
      menu: null,
      dateFormat: 'YYYY-MM-DD',
      outputFormat: 'DD-MM-YYYY',
      startOfWeek: 1,
      outputType: 'object',
      locale: 'en',
      date: {
        from: {
          year: new Date().getFullYear(),
          month: new Date().getMonth() + 1,
          day: new Date().getDate()
        },
        to: {
          year: new Date().getFullYear(),
          month: new Date().getMonth() + 1,
          day: new Date().getDate()
        }
      }
    };
    this.dateRangeValue.from = this.datePipe.transform(new Date(), 'dd-MM-yyyy');
    this.dateRangeValue.to = this.datePipe.transform(new Date(), 'dd-MM-yyyy');
  }

  filterByDate() {
    if (this.dateRangeValue) {
      this.fromDate = this.dateRangeValue.from.substring(6, 10) + '-' + this.dateRangeValue.from.substring(3, 5) + '-' + this.dateRangeValue.from.substring(0, 2);
      this.toDate = this.dateRangeValue.to.substring(6, 10) + '-' + this.dateRangeValue.to.substring(3, 5) + '-' + this.dateRangeValue.to.substring(0, 2);
      if (this.toDate != this.prevTodate || this.fromDate != this.prevFromDate) {
         this.getTransactionAudit();
      }
      this.prevFromDate = this.fromDate;
      this.prevTodate = this.toDate;
      this.isDateFilter = true;
    }
  }

  clearAllFilter() {
    this.isDateFilter = false;
    this.setDatePickerToCurrentDate();
    this.fromDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.toDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.getTransactionAudit();
  }

  getTransactionAudit()
  {
    this.allLogDetails =[];
    this.auditTrailService.getTransactionAudit(this.fromDate, this.toDate).subscribe(res => {
      console.log(res)
      this.allLogDetails = res;
      this.allLogData = res;
    })
  }

  logDateCheck(newDate , OldDate)
  {
    console.log(newDate +"***"+ OldDate)
  }

  applyFilter()
  {
    let filterValue = this.searchFilterText.trim().toLowerCase();
    let tempAllLog = JSON.parse(JSON.stringify(this.allLogData));
    this.allLogDetails = tempAllLog.filter(obj => {
      return obj.auditType.replace('AUDIT_TYPE_', '').split('_').join(' ').trim().toLowerCase().includes(filterValue) || obj.entityName.trim().toLowerCase().includes(filterValue);
    });
  }
  exportAsXLSX() {
    this.excelRecords = [];
    this.allLogDetails.forEach(audit => {
      if (audit) {
        this.excelRecords.push({
          'Entity Name': audit.entityName || '',
          'Updated By': audit.updatedByusername || '',
          'Audit Type': audit.auditType || '',
          'Action': audit.auditMessage || '',
          'Remarks': audit.remarks || '',
          'Updated Date': this.datePipe.transform(audit.updatedDateTime, "dd-MM-yyyy hh:mm a") || '',
        });
      }
    });
    this.excelService.exportAsExcelFile(this.excelRecords, 'Auditlogs');
  }
}
