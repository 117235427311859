import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatDialog, MatPaginator } from '@angular/material';
import { TripService } from 'src/app/services/trip/trip.service';
import { DatePipe } from '@angular/common';
import { AngularFireDatabase } from '@angular/fire/database';
import { TokenStorage } from 'src/app/auth/token.storage';
import { NotifierService } from 'angular-notifier';
import { Subscription, Subject } from 'rxjs';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { BranchService } from 'src/app/services/branch/branch.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-triptracking',
  templateUrl: './triptracking.component.html',
  styleUrls: ['./triptracking.component.scss']
})
export class TriptrackingComponent implements OnInit {
  public lat: Number = 18.552307
  public lng: Number = 73.724519

  columnsToDisplay = [
    'select',
    'trip',
    'origin',
    'vehicle',
    'visit',
    'progress',
    'edit'
  ];

  fromDate: any;
  toDate: any;
  tripDataArray = new MatTableDataSource;
  locationsArray: any[];
  deliveredOrderLocation: any[];
  tripStartedStopped = [];
  markersArr = [];
  originDest = [];
  public origin: {};
  tripsLocationArray = [];
  public renderOptions = {
    suppressMarkers: true,
  };
  public destination: {};
  public waypoints = [];
  tripName: string;
  tripId: number;
  allTrips = [];
  page: number = 0;
  totalRecords: Number;
  @ViewChild(MatPaginator) paginator: MatPaginator;

 selectedTripVal: string;
  selectedVal: string = "0";
  tripCountArray = [];
  isLoading: boolean = true;
  locationSubscription: Subscription
  trip;
  branchesSubscription: Subscription;
  protected ngUnsubscribe: Subject<void> = new Subject<void>()
  colors = [{ color: "#0000FF", count: 0 }, { color: "#00FFFF", count: 0 },
  { color: "#8A2BE2", count: 0 }, { color: "#7FFF00", count: 0 },
  { color: "#FF7F50", count: 0 }, { color: "#DC143C", count: 0 },
  { color: "#B8860B", count: 0 }, { color: "#FF8C00", count: 0 },
  { color: "#FF1493", count: 0 }, { color: "#FF00FF", count: 0 },
  { color: "#FFD700", count: 0 }, { color: "#ADFF2F", count: 0 },
  { color: "#FF4500", count: 0 }, { color: "#FF0000", count: 0 },
  { color: "#FFFF00", count: 0 }];

  constructor(
    private tripService: TripService,
    private datePipe: DatePipe,
    private db: AngularFireDatabase,
    private token: TokenStorage,
    private notifierService: NotifierService,
    private dialogService: DialogService,
    private branchService: BranchService
  ) { }

  ngOnInit() {
    this.selectedTripVal = "TRIP_STATE_SCHEDULED";
    let date, last, day, month, year;
    date = new Date();
    last = new Date(date.getTime() - (parseInt(this.selectedVal) * 24 * 60 * 60 * 1000));
    day = last.getDate();
    month = last.getMonth();
    year = last.getFullYear();
    this.fromDate = this.datePipe.transform(new Date(year, month, day), 'yyyy-MM-dd');
    this.toDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    this.branchesSubscription = this.branchService.branchesObservable.subscribe(() => {
      if (window.sessionStorage.getItem("selectedBranchesCodes") && JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")).length == 0) {
        this.tripDataArray.data = [];
        this.tripCountArray = [];
        this.isLoading = false;
      }
      else {
        this.getTripCountArray();
        this.gettripData();
      }
    });
  }


  getTripCountArray() {
    this.tripService.getTripCountByStatus(this.fromDate, this.toDate).subscribe(tripCountArray => {
      if (tripCountArray) {
        this.tripCountArray = tripCountArray;
      }
    }, () => {
      this.notifierService.notify("error", "Error In Fetching Trip Count.")
    });
  }

  getCount(tripType: string) {
    for (let i = 0; i < this.tripCountArray.length; i++) {
      if (this.tripCountArray[i].tripstatus === tripType) {
        return this.tripCountArray[i].tripcount;
      }
    }
    return 0;
  }


  getRandomColor() {
    for (let i = 1; i < this.colors.length; i++) {
      if (this.colors[i].count < this.colors[i - 1].count) {
        this.colors[i].count++;
        return this.colors[i].color;
      }
    }
    this.colors[0].count++;
    return this.colors[0].color;
  }

  gettripData() {
    this.ngUnsubscribe.next();
    this.tripDataArray.data = [];
    this.isLoading = true;

    this.tripService.getAllTripsByTypesDateRange(this.selectedTripVal,  this.page, this.fromDate, this.toDate, 'nozone').pipe(takeUntil(this.ngUnsubscribe)).subscribe(trips => {
      this.isLoading = false;
      this.allTrips = trips.content;
      this.totalRecords = trips.totalElements;
      this.allTrips.forEach(trip => {
        let originAd;
        originAd = trip.originAddress.split(',');
        trip.originLocation = originAd[originAd.length - 1];
        trip.hexColor = this.getRandomColor().toUpperCase();
        if (this.selectedTripVal != "TRIP_STATE_SCHEDULED") {
          let totalJobs = trip.jobs.length;
          let deliveredJobs = 0;
          trip.jobs.forEach(job => {
            if (job.jobState == 'JOB_STATE_DELIVERED') {
              deliveredJobs++;
            }
          });
          trip.progress = (deliveredJobs / totalJobs) * 100;
        }
      });
      this.tripDataArray.data = this.allTrips;
    }, () => {
      this.isLoading = false;
      this.notifierService.notify('error', "Error fetching trips.");
    });
  }

  findTrips() {
    this.paginator._pageIndex = 0;
    this.page = 0;
    this.totalRecords = 0;

    let date, last, day, month, year;
    date = new Date();
    last = new Date(date.getTime() - (parseInt(this.selectedVal) * 24 * 60 * 60 * 1000));
    day = last.getDate();
    month = last.getMonth();
    year = last.getFullYear();
    this.fromDate = this.datePipe.transform(new Date(year, month, day), 'yyyy-MM-dd');
    this.toDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.getTripCountArray();
    if (window.sessionStorage.getItem("selectedBranchesCodes") && JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")).length == 0) {
      this.tripDataArray.data = [];
      this.isLoading = false;
    }
    else {
      this.ngUnsubscribe.next();
      this.isLoading = true;
      this.allTrips = [];
      this.tripDataArray.data = this.allTrips;
      this.tripsLocationArray.forEach(Trip => {
        Trip.isVisible = false;
      });

      if (this.selectedTripVal == 'TRIP_STATE_SCHEDULED') {
        this.columnsToDisplay = [
          'trip',
          'origin',
          'vehicle',
          'visit',
          'edit'
        ];
      } else {
        this.columnsToDisplay = [
          'select',
          'trip',
          'origin',
          'vehicle',
          'visit',
          'progress',
          'edit'
        ];
      }

      this.gettripData();

      if (this.locationSubscription) {
        this.locationSubscription.unsubscribe();
      }
    }
  }

  tripDetails(trip, event) {
    if (this.locationSubscription) {
      this.locationSubscription.unsubscribe();
    }

    this.allTrips.forEach(Trip => {
      Trip.isChecked = false;
      if (Trip.tripName === trip.tripName) {
        Trip.isChecked = true;
      }
    });

    this.tripsLocationArray.forEach(Trip => {
      Trip.isVisible = false;
    });
    this.tripDataArray.data = this.allTrips;
    if (!event.checked) {
      this.tripsLocationArray.forEach(availabletrip => {
        if (availabletrip.tripName === trip.tripName) {
          availabletrip.isVisible = false;
        }
      });
    }

    //this.locationsArray = [];
    //this.trip = null;
    if (event.checked) {
      let path = '/' + this.token.getTenant() + '/' + trip.tripName;
      this.locationSubscription = this.db.list(path).valueChanges().subscribe(res => {
        //this.locationsArray = res;
        //this.trip = trip;

        this.originDest = [];
        if (res.length > 0) {
          this.markersArr = res;
          this.originDest.push(this.markersArr[0]);
          this.originDest.push(this.markersArr[res.length - 1]);
          this.originDest[0].icon = '../../../assets/icons/warehouse1.png';
          this.originDest[1].icon = '../../../assets/icons/truck3d.png';

          this.locationsArray = res;
          //let bounds: LatLngBounds = new google.maps.LatLngBounds();
          console.log("locationsArray", this.locationsArray);
          this.deliveredOrderLocation = this.locationsArray.filter
            (deliveredLocations => deliveredLocations.status == 'delivered' || deliveredLocations.status == 'undelivered');
          this.tripStartedStopped = this.locationsArray.filter(loc => loc.status == 'trip_started' || loc.status == 'trip_stopped');
          this.tripStartedStopped.forEach(marker => {
            if (marker.status == "trip_started") {
              marker.icon = "../../../assets/images/IoT/Start.png";
            }
            if (marker.status == "trip_stopped") {
              marker.icon = "../../../assets/images/IoT/end.png";
            }
          }); 
          console.log(this.tripStartedStopped);
          let sequence = 1;
          this.deliveredOrderLocation.forEach(location => {
            location.seq = sequence.toString();
            sequence = sequence + 1;
            if (location.status == 'delivered')
              location.icon = "../../../assets/images/IoT/bus.png"
          });

          trip.jobs.forEach(job => {
            this.deliveredOrderLocation.forEach(location => {
              if (job.jobId == location.jobId) {
                location.customerName = job.customerInfo.customerName;
                location.address = job.customerInfo.address.houseNumber;
              }
            });
          });

          this.locationsArray = this.locationsArray.filter(point => !point.hasOwnProperty('status'));
          if (this.locationsArray) {
            this.waypoints = [];
            if (this.locationsArray.length <= (22 - this.deliveredOrderLocation.length)) {
              this.locationsArray.forEach(loc => {
                this.waypoints.push({
                  location: (parseFloat(loc.latitude) + "," + parseFloat(loc.longitude))
                });
              });
            }
            else {
              this.waypoints = [];
              let i = 0;
              let multiplicationfactor: number = Number(this.locationsArray.length) / 23 - (this.deliveredOrderLocation.length);

              if (multiplicationfactor <= 1)
                multiplicationfactor = 1;
              else
                multiplicationfactor = parseInt(multiplicationfactor.toString())
              console.log("multifactor", multiplicationfactor);

              for (i = 0; i < this.locationsArray.length; i = i + multiplicationfactor) {

                if (this.waypoints.length <= 22 - this.deliveredOrderLocation.length) {
                  this.waypoints.push({
                    location: (parseFloat(this.locationsArray[i].latitude) + "," + parseFloat(this.locationsArray[i].longitude))
                  });
                }
              }
            }

            this.deliveredOrderLocation.forEach(loc => {
              this.waypoints.push({ location: (parseFloat(loc.latitude) + "," + parseFloat(loc.longitude)) })
            });
            this.origin = {
              lat: parseFloat(this.markersArr[0].latitude),
              lng: parseFloat(this.markersArr[0].longitude)
            };
            this.destination = {
              lat: parseFloat(this.markersArr[res.length - 1].latitude),
              lng: parseFloat(this.markersArr[res.length - 1].longitude)
            };
            console.log("waypoints,origin and destination", this.waypoints, this.origin, this.destination);
          }
          let color;
          this.allTrips.forEach(Trip => {
            if (Trip.tripName === trip.tripName) {
              color = Trip.hexColor;
            }
          });
          let tripObj = {
            waypoints: this.waypoints,
            origin: this.origin,
            destination: this.destination,
            tripName: trip.tripName,
            deliveredOrderLocation: this.deliveredOrderLocation,
            tripStartedStopped: this.tripStartedStopped,
            isVisible: true,
            originDest: this.originDest,
            hexColor: color,
          }
          this.tripsLocationArray = [];
          this.tripsLocationArray.push(tripObj)
        } else {
          this.notifierService.notify("error", "No location data present for this trip.");
        }
      });
    }
  }

  pageTripChanged(event) {
    this.page = event.pageIndex;
    this.gettripData();
  }

  openTripDetailsWindow(data) {
    this.dialogService.tripDetails(data, '')
  }


  ngOnDestroy() {
    if (this.locationSubscription) {
      this.locationSubscription.unsubscribe();
    }
    this.branchesSubscription.unsubscribe();
  }
}
