import { Component, OnInit, ViewChild } from '@angular/core';
import { ProductService } from 'src/app/services/products/product.service';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog } from '@angular/material';
import { TokenStorage } from 'src/app/auth/token.storage';
import { ExcelgeneratorService } from 'src/app/services/excelgenerator/excelgenerator.service';
import { EditproductComponent } from '../editproduct/editproduct.component';

@Component({
  selector: 'app-allproducts',
  templateUrl: './allproducts.component.html',
  styleUrls: ['./allproducts.component.scss']
})
export class AllproductsComponent implements OnInit {
  columnsToDisplay = [
    'skuNumber',
    'productName',
    'hsncode',
    'brand',
    'category',
    'qtyPack',
    'weightVol',
    'edit'
  ];
  isLoading: boolean = false;
  isSuhana: boolean = false;
  isWarehouseManager: boolean = false;
  volUnit: string;
  prouctDataArray = new MatTableDataSource;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  excelRecords = [];



  constructor(
    private dialog: MatDialog,
    private productService: ProductService,
    private token: TokenStorage,
    private excelService: ExcelgeneratorService,
  ) {
    if (this.token.getTenant() == 'kimberly-dist-prod') {
      this.volUnit = 'CM';
    } else if (this.token.getTenant() == 'godrej-dist-prod') {
      this.volUnit = 'CCM';
    }
    else {
      this.volUnit = 'CF';
    }
    if (this.token.getTenant() == 'suhana-dist-prod' || this.token.getTenant() == 'suhana-dist-test') {
      this.isSuhana = true;
    } 
  }

  ngOnInit() {
    if(window.sessionStorage.getItem('permissions').substr(2).slice(0, -2)=='warehouse_manager'){
      this.isWarehouseManager= true;
    }
    this.isLoading = true;
    this.prouctDataArray.data = [];
    this.productService.getAllProducts().subscribe(allProducts => {
      console.log(allProducts);
      this.isLoading = false;
      this.prouctDataArray.data = allProducts
    }, Error => {
      console.log(Error)
      this.isLoading = false;
    })
    this.prouctDataArray.sortingDataAccessor = (product: any, property) => {
      switch (property) {
        case 'productName': return product.productName
      }
    };
    this.prouctDataArray.sort = this.sort;
    this.prouctDataArray.paginator = this.paginator;
  }

  applyFilter(filterValue: string) {
    this.prouctDataArray.filter = filterValue.trim().toLowerCase();
  }

  exportAsXLSX() {
    this.excelRecords = [];
    let productsData = [];
    productsData = this.prouctDataArray.data;
    productsData.forEach(product => {
      let hsnCode, weightVol;

      if (product.hsnCode)
        hsnCode = product.hsnCode;
      else
        hsnCode = "";


      if (product) {
        this.excelRecords.push({
          'Sku Number ': product.skuNumber,
          'Product Name': product.productName,
          'HSN Code': hsnCode,
          'Brand': product.brand || '',
          'Category': product.category || '',
          'Qty Pack': product.qtyPack,
          'Weight': product.weight,
          'Length':  product.length || '',
          'Height': product.height || '',
          'Width': product.width || '',
          'Volume': product.totalVolume,
          'Price': product.price || '',
          'Model No.': product.modelNumber || ''
        })
      }
    });
    this.excelService.exportAsExcelFile(this.excelRecords, 'productrecords');
  }

  editProduct(product) {
    let editDialog = this.dialog.open(EditproductComponent, {
      width: '800px',
      data: product,
      id:'editProductWindow'
    });
    editDialog.updatePosition({
      top: '50px',
    });
    editDialog.afterClosed().subscribe(result => {
      console.log(result);
      if(result){
      this.ngOnInit();
      }
    });
  }
}
