import { Injectable } from '@angular/core';

const TOKEN_KEY = 'AuthToken';
const TENANT_ID = 'Tenant';

@Injectable()
export class TokenStorage {

  constructor() {
  
   }

  signOut() {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.clear();
  }

  public saveToken(token: string) {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string {
    
    let tokenStr = sessionStorage.getItem(TOKEN_KEY);

    if (tokenStr != null) {
      let token = tokenStr.split(" ");
      
      return token[0];
    }

    return tokenStr;
  }

  public saveTenant(tenantName){
    window.sessionStorage.removeItem(TENANT_ID);
    window.sessionStorage.setItem(TENANT_ID, tenantName);
  }

  public getTenant(): string {
    let tokenStr = sessionStorage.getItem(TENANT_ID);
    return tokenStr;
  }
}
