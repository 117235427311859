import { Component } from '@angular/core';
import { MessagingService } from "./shared/messaging.service";
import { NgxPermissionsService } from 'ngx-permissions';


@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet> <notifier-container></notifier-container>',
})
export class AppComponent {
  title = 'app';
  message;

  constructor(private messagingService: MessagingService,
    private permissionsService: NgxPermissionsService
  ) { }

  ngOnInit() {
    if (window.sessionStorage.getItem('userdetails') != undefined) {
      let permissions = [];
      console.log("allroles", JSON.parse(window.sessionStorage.getItem('permissions')));
      permissions.push(JSON.parse(window.sessionStorage.getItem('permissions')));
      this.permissionsService.loadPermissions(permissions);
    }
    this.messagingService.requestPermission();
    this.messagingService.receiveMessage();
  }
}
