import { Component, OnInit } from '@angular/core';
import { BranchService } from 'src/app/services/branch/branch.service';
import { NotifierService } from 'angular-notifier';
import { EditbranchComponent } from '../editbranch/editbranch.component';
import { MatDialog } from '@angular/material';
import { ZoneService } from 'src/app/services/zone/zone.service';

@Component({
  selector: 'app-allbranches',
  templateUrl: './allbranches.component.html',
  styleUrls: ['./allbranches.component.scss']
})
export class AllbranchesComponent implements OnInit {

  branchData: any;
  filteredData = [];
  isLoading: boolean = false;

  constructor(
    private branchService: BranchService,
    private notifierService: NotifierService,
    private dialog: MatDialog,
    private zoneService: ZoneService
  ) { }

  ngOnInit() {
    this.getAllBranchData();
  }



  getAllBranchData() {
    this.isLoading = true;
    this.branchService.getAllBranches().subscribe(branchData => {
      let branchRefs = [];
      this.isLoading = false;
      console.log("AllBranches:", branchData);
      if (branchData.length > 0) {
        console.log("AllBranches:", branchData);
        this.branchData = branchData;
        branchData.forEach(branch => {
          branchRefs.push(branch.branchCode)
        })
        this.zoneService.getAllbranchesOfZone(branchRefs).subscribe(branchzones=>{
          let zoneArray: any;
          this.branchData.forEach(branch => {
            for(zoneArray in branchzones){
              if(zoneArray == branch.branchCode){
                branch.zones = branchzones[zoneArray]
              }
            }
          });      
        })
        this.filteredData = this.branchData;
      } else {
        this.notifierService.notify("success","No branch available.");
      }
    }, Error => {
      this.isLoading = false;
      this.notifierService.notify("error","Error fetching branches. please try again later.");
    })
  }

  applyFilter(filterValue: string) { 
    console.log(filterValue)
    this.filteredData =  this.branchData.filter(branch => {
      return branch.branchName.toLowerCase().includes(filterValue.trim().toLowerCase()) ? branch : null;
    }); 
  }

  editbranch(branchData) {
    let editDialog = this.dialog.open(EditbranchComponent, {
      width: '800px',
      maxHeight: '100vh',
      data: branchData,
      id: 'editBranchWindow'
    });

    editDialog.updatePosition({
      top: '50px'
    });
    editDialog.afterClosed().subscribe(result => {
      console.log(result);
      if(result){
      this.getAllBranchData();
      }
    })
  }
}
