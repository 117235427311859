import { Component, OnInit, Inject } from '@angular/core';
import { ResourceService } from '../../services/resource/resource.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { VALID } from '@angular/forms/src/model';
import { MatSnackBar, MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { NotifierService } from 'angular-notifier';
import { TransporterService } from 'src/app/services/transporter/transporter.service';
import { UsersService } from 'src/app/services/users/users.service';
import { ValidatorService } from 'src/app/services/validator/validator.service';
import { BranchService } from 'src/app/services/branch/branch.service';

@Component({
  selector: 'app-addresourceanduser',
  templateUrl: './addresourceanduser.component.html',
  styleUrls: ['./addresourceanduser.component.scss']
})
export class AddresourceanduserComponent implements OnInit {

  driverForm: FormGroup;
  isTransporterValue: boolean = false;
  allTransporters = [];
  allBranchesNames = [];
  showPwd: boolean = false;
  
  configTransporter = {
    search: true,
    displayKey: 'transporterName',
    searchOnKey: 'transporterName',
    height: '40vh',
    placeholder: 'Select Transporter'
  }
  constructor(
    public snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private transporterService: TransporterService,
    private branchService: BranchService,
    private userService : UsersService,
    public dialog : MatDialogRef<AddresourceanduserComponent>,
    private notifierService: NotifierService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {


  }
  ngOnInit() {
    this.initializedriverForm();
    this.transporterService.getAllActiveTransporters().subscribe(allTrans => {
      this.allTransporters = allTrans;
      this.allTransporters.sort(function (a, b) {
        let transName1 = a.transporterName.toLowerCase(), transName2 = b.transporterName.toLowerCase()
        if (transName1 < transName2) //sort string ascending
          return -1
        if (transName1 > transName2)
          return 1
        return 0
      });

      this.driverForm.patchValue({
        transporter:this.allTransporters.find(obj => obj.transporterId === parseInt(this.data.transpoter))
      });
    });
    this.branchService.branchesObservable.subscribe(allBranches => {
      console.log("branches", allBranches);
      this.allBranchesNames = allBranches;
      this.allBranchesNames.sort(function (a, b) {
        let branchName1 = a.branchName.toLowerCase(), branchName2 = b.branchName.toLowerCase();
        if (branchName1 < branchName2) { //sort string ascending
          return -1;
        } else if (branchName1 > branchName2) {
          return 1;
        } else {
          return 0;
        }
      });
    });
  }

  initializedriverForm() {
    this.driverForm = this.formBuilder.group({
      'firstName': [null, [
        Validators.required,
        Validators.pattern("^[a-zA-Z]+$"),
      ]],
      'lastName': [null, [
        Validators.pattern("^[a-zA-Z]+$"),
      ]],
      'phoneNumber': [null, [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern('^[0-9]*$')
      ]],
      'resourceType': [null],
      'branchNames': [null, [
        Validators.required]],
      'roles': [null],
      'transporter': [null],
      'username': [null, [
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9@_.-]+$"),
        Validators.minLength(8),
        Validators.maxLength(32),
        ValidatorService.whitespaceValidator
      ]],
      'password': [null, [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(32),
      ]],
    });
  }
  
  openSnackBar(message) {
    this.snackBar.open(message, "X", {
      duration: 5000,
    });
  }

  onSelectTransporter(event) {
    if (event.value) {
      this.isTransporterValue = true;
    }
    else
      this.isTransporterValue = false;
  }

  addNewUser() {

      this.driverForm.value.resourceTypeRef = this.driverForm.value.transporter.transporterId;
      this.driverForm.value.resourceType = "RESOURCE_TYPE_TRANSPORTER";
 
    console.log(this.driverForm.value);
    this.driverForm.value.resource = {
      firstName: this.driverForm.value.firstName,
      lastName: this.driverForm.value.lastName,
      phoneNumber: this.driverForm.value.phoneNumber,
      resourceType: this.driverForm.value.resourceType,
      resourceTypeRef: this.driverForm.value.resourceTypeRef,
      designation: null
    }

    delete this.driverForm.value.firstName;
    delete this.driverForm.value.lastName;
    delete this.driverForm.value.phoneNumber;
    delete this.driverForm.value.resourceType;
    delete this.driverForm.value.transporter;
    delete this.driverForm.value.resourceTypeRef;
    delete this.driverForm.value.phoneNumber;
    delete this.driverForm.value.transporter;
    this.driverForm.value.roles = [{id: 3, // Arjun changed id:4 to id:3
      roleName: "delivery_associate",
      updatedByusername: "",
      updatedDateTime: null}]

    console.log("form value", this.driverForm.value);

    this.userService.addUser(this.driverForm.value).subscribe(res => {
      console.log(res);
      this.notifierService.notify("success", "Driver Added Successfully");
      this.dialog.close("true");
    }, error => {
     this.driverForm.reset();
     this.driverForm.patchValue({
      transporter:this.allTransporters.find(obj => obj.transporterId === parseInt(this.data.transpoter))
    });
      console.log(error);
      if (error.status === 400)
        this.openSnackBar(error.error.Result);
      else
      this.notifierService.notify("error", "Error In Adding Driver");
    });
  }

  togglePwd() {
    this.showPwd = !this.showPwd;
  }

 }
