import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { TripdetailsComponent } from 'src/app/pages/trip/tripdetails/tripdetails.component';
import { AllorderdetailsComponent } from 'src/app/pages/order/allorderdetails/allorderdetails.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(private dialog : MatDialog) { }

  orderDetails(data,component) {
    if (!window.sessionStorage.getItem('orderDetailCount'))
      window.sessionStorage.setItem('orderDetailCount', '0')
    else {
      let count = Number(window.sessionStorage.getItem('orderDetailCount')) + 1;
      window.sessionStorage.setItem('orderDetailCount', count.toString())
    }
    let editDialog = this.dialog.open(AllorderdetailsComponent, {
      width: '85%',
      maxWidth:'85vw',
      data: {
        data: data,
        component: component,
      },
      id: 'orderDetail' + window.sessionStorage.getItem('orderDetailCount')
    });
    editDialog.updatePosition({
      top: '50px'
    });
    editDialog.afterClosed().subscribe(result => {
      console.log(result);
    });
  }

  tripDetails(data,component) {
    if (!window.sessionStorage.getItem('tripDetailCount'))
      window.sessionStorage.setItem('tripDetailCount', '0')
    else {
      let count = Number(window.sessionStorage.getItem('tripDetailCount')) + 1;
      window.sessionStorage.setItem('tripDetailCount', count.toString())
    }
    let tripdialog = this.dialog.open(TripdetailsComponent, {
      width: '80%',
      maxWidth:'80vw',
      data: {
        component: component,
        data: data,
      },
      id: 'tripDetail' + window.sessionStorage.getItem('tripDetailCount')

    });
    tripdialog.updatePosition({
      top: '50px',
    });
  }
}
