import { Component, OnInit } from '@angular/core';
import { MatAccordion } from '@angular/material';
import { TokenStorage } from 'src/app/auth/token.storage';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  panelOpenState: boolean;
  isAbcCorp: boolean = false;
  isPPGAP: boolean = false;
  isCg: boolean = false;
  isKcc: boolean = false;
  isHeretech: boolean = false;
  isSuhana: boolean = false;
  isCipla: boolean = false;
  constructor(private token: TokenStorage,
  ) {
    if (token.getTenant() == 'abc-dist-prod') {
      this.isAbcCorp = true;
    }
    else if (token.getTenant() == 'ppgap-dist-prod') {
      this.isPPGAP = true;
    }
    else if (token.getTenant() == 'cg-dist-prod') {
      this.isCg = true;
    }

    else if (token.getTenant() == 'kimberly-dist-prod') {
      this.isKcc = true;
    }
    else if (this.token.getTenant() == 'heretech-dist-test' || this.token.getTenant() == 'heretech-dist-prod') {
      this.isHeretech = true;
    }
    else if (this.token.getTenant() == 'suhana-dist-test' || this.token.getTenant() == 'suhana-dist-prod') {
      this.isSuhana = true;
    }
    // else if (this.token.getTenant() == 'cipla-dist-test' || this.token.getTenant() == 'cipla-dist-prod') {
      else if (this.token.getTenant() == 'cipla-dist-test'|| this.token.getTenant() == 'cipla-dist-test-siliguri' || this.token.getTenant() == 'cipla-dist-prod') {//Digvijay
      this.isCipla = true;
    }
  }

  ngOnInit() {
  }

  openSidebar() {
    document.getElementById("openSidebar").style.width = "228px";
    document.getElementById("closeSidebar").style.marginLeft = "228px";
    document.getElementById("openSidebar").style.marginTop = "1.2%";
  }

  //as collapsible onhover
  closeSidebar() {
    document.getElementById("openSidebar").style.width = "0";
    document.getElementById("closeSidebar").style.marginLeft = "0";
  }

  //to hideshow sidebar, function called from navbar component
  toHideShowSidebar(flagToHideSHow) {
    //if true it will be display else none ie to showHide
    if (flagToHideSHow) {
      document.getElementById("hideShowSidebar").style.display = "inline";
      document.getElementById("openSidebar").style.width = "228px";
      document.getElementById("closeSidebar").style.marginLeft = "228px";
      document.getElementById("openSidebar").style.marginTop = "1.3%";
    } else {
      document.getElementById("hideShowSidebar").style.display = "none";
    }



  }

}
