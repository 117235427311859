import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DeviceService } from 'src/app/services/device/device.service';

@Component({
  selector: 'app-adddevice',
  templateUrl: './adddevice.component.html',
  styleUrls: ['./adddevice.component.scss']
})
export class AdddeviceComponent implements OnInit {

  deviceForm: FormGroup;
  submitText = "Submit";

  constructor(
    private formBuilder: FormBuilder,
    private deviceService: DeviceService
  ) {
    this.deviceForm = formBuilder.group({
      'trackingType': [null,Validators.required],
      'deviceName': [null, Validators.required,],
      'deviceId': [null,Validators.required],
      'ownership':[null,Validators.required],
      'doi': [null]
    });
  }

  ngOnInit() {
  }

  addDevice(){
    this.submitText = "Submitting";
    let type: string;
    if(this.deviceForm.value.trackingType == 'gps-onboard' || this.deviceForm.value.trackingType == 'gps-portable'){
      type = 'DEVICE_TYPE_GPS_DEVICE';
    }else if(this.deviceForm.value.trackingType == 'mobile'){
      type = 'DEVICE_TYPE_MOBILE';
    }else{
      type = 'DEVICE_TYPE_SIM';
    }
    let device = {
      deviceName: this.deviceForm.value.deviceName,
      deviceUniqueId: this.deviceForm.value.deviceId,
      deviceType: type,
      owner: this.deviceForm.value.ownership
    };
    this.deviceService.addDevice(device).subscribe(res => {

    }, error => {
      console.log("Error adding device");
    });
  }

  trackingTypeChanged(){

  }

}
