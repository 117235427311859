import { Injectable } from '@angular/core';
import { Http, Headers, URLSearchParams, Response, HttpModule, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class JobsService {
  base_url: string;
  constructor(
    private http: HttpClient,
  ) {
    this.base_url = environment.appUrl + "/jobs";
  }

  putLRnoOfOrder(jobid, LRNum): Observable<any> {
    return this.http.put(this.base_url + "/" + jobid + "/assignLorryReceipt", LRNum);
  }

  getAllJobssByTypesDateRange(jobTypes, page: number, from: Date, to: Date, selectedZone: string): Observable<any> {
    return this.http.get(this.base_url + '/typesdaterange' + '?page=' + page + '&types=' + jobTypes + '&fromDate=' + from + '&toDate=' + to + '&zone=' + selectedZone);
  }

  getJobReports(from,to): Observable<any>{
    return this.http.get(environment.appUrl + '/reports/jobSummary?fromDate=' + from + '&toDate=' + to + "&branchCodes=" + JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")));
  }
  
  getPodAudit(jobId): Observable<any> {
    return this.http.get(environment.appUrl + "/transactionaudits/jobs/" + jobId + '/podcleanaudits');
  }
}
