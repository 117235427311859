import { Injectable } from '@angular/core';
import { Http, Headers, URLSearchParams, Response, HttpModule, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ResourceService {

  base_url: string;
  constructor(
    private http: HttpClient,
  ) {
    this.base_url = environment.appUrl + "/resources";
  }
  addResource(newResource): Observable<any> {
    return this.http.post(this.base_url, newResource);
  }

  updateResource(newResource): Observable<any> {
    JSON.stringify(newResource);
    return this.http.put(this.base_url, newResource);
  }

  getResourceById(resource): Observable<any> {
    return this.http.get(this.base_url + '/id/' + resource.resources);
  }

  ActivateResource(rid, bActivate): Observable<any> {
    return this.http.patch(this.base_url + '/id/' + rid + '/' + bActivate, null);
  }

  getAllAvailabilityForAllResources(): Observable<any> {
    return this.http.get(this.base_url + '/availability');
  }

  addResourceAvailability(newResourceAvailability): Observable<any> {
    return this.http.post(this.base_url + '/availability', newResourceAvailability);
  }

  getBranches(): Observable<any> {
    return this.http.get(environment.appUrl + '/branches');
  }

  addDriver(newDriver): Observable<any> {
    return this.http.post(environment.appUrl + '/driver', newDriver);
  }

  getManageableDesignation() {
    return this.http.get(this.base_url + '/manageableDesignations');
  }
}
