import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-inline-editable-cell',
  templateUrl: './inline-editable-cell.component.html',
  styleUrls: ['./inline-editable-cell.component.scss'],
})
export class InlineEditableCellComponent implements OnInit {
  
  @Input() value: number;
  @Input() isEditing: boolean; 
  @Output() clicked = new EventEmitter();
  @ViewChild("rate") rateInput : ElementRef;
  invalid: boolean = false;

  constructor() { }

  ngOnInit() {}

  ngAfterViewInit() {
    if(this.rateInput) {
      this.rateInput.nativeElement.focus();
    }
  }
 
  onBlur() {
    let val = this.value;
    if(this.value == null || this.value < 0 || this.value>999999999999 || !val.toString().match(('^[0-9]{1,15}(,[0-9]{10})*(([\\.,]{1}[0-9]{1,3})|())$'))) {
      this.invalid = true;
      this.rateInput.nativeElement.focus();
    }else {
      this.invalid = false;
      this.clicked.emit(this.value);
    }
  }

  beginEdit() {
    this.clicked.emit(null);
    setTimeout(() => {
      this.rateInput.nativeElement.focus();
    }, 17);
  }

}
