import { Component, OnInit } from '@angular/core';
import { CustomerService } from 'src/app/services/customer/customer.service';
import { UploadfileService } from 'src/app/services/uploadfile/uploadfile.service';
import { Subject, Observable, Subscription } from 'rxjs';


@Component({
  selector: 'app-uploadcustomerexcel',
  templateUrl: './uploadcustomerexcel.component.html',
  styleUrls: ['./uploadcustomerexcel.component.scss']
})
export class UploadcustomerexcelComponent implements OnInit {
  isFileUploading: boolean;
  showSpinner: boolean = false;
  fileName: any;
  excelProgressSubscription: Subscription;
  progress: string;
  subExcel: Subscription;
  showFileUploaded: any; //Digvijay



  constructor(
    private customerService: CustomerService,
    private uploadService: UploadfileService,

  ) { }
  protected ngUnsubscribe: Subject<void> = new Subject<void>();


  ngOnInit() {
    this.excelProgressSubscription = this.uploadService.progressObservable.subscribe(progress => {
      console.log(progress);
      this.progress = progress.progressStatus;
    });

    this.subExcel = this.uploadService.event.subscribe(val => {
      this.isFileUploading = false;
    });
  }
  submitFile(fileEvent) {
    debugger;
    console.log(fileEvent);
    if (fileEvent) {
      console.log(fileEvent.target.files[0]);
      this.isFileUploading = true;
      if (fileEvent.target.files.length > 0) {
        const file: File = fileEvent.target.files[0];
        this.uploadService.uploadCustomerExcelFile(file);
      }
    }
  }

  ngOnDestroy(){
    this.subExcel.unsubscribe();
    this.excelProgressSubscription.unsubscribe();
  }

  fileUpload(fileObject)  // Digvijay
  {
    console.log(fileObject);
    this.showFileUploaded = fileObject.target.files[0].name +" (" +fileObject.target.files[0].size +" bytes)";
  }
}
