import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import {
  MatPaginator, MatTableDataSource,
  MatDialog, MatDialogRef, MAT_DIALOG_DATA
} from '@angular/material';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import { DataSource } from '@angular/cdk/collections';
import { TripService } from '../../services/trip/trip.service';
import { TripdetailsComponent } from './tripdetails/tripdetails.component';

@Component({
  selector: 'app-trip',
  templateUrl: './trip.component.html',
  styleUrls: ['./trip.component.scss']
})
export class TripComponent implements OnInit {
  selectedVal: string
  mapView: boolean = false;
  @Output() notifyParent: EventEmitter<any> = new EventEmitter();
  constructor(
    public dialog: MatDialog,
    public tripService: TripService
  ) { }

  ngOnInit() {
    this.selectedVal ='1week';
  }

  ngAfterViewInit() {
   
  }
  toogleView() {
    if (!this.mapView) {
      this.mapView = true;
      console.log(this.mapView);
    } else {
      this.mapView = false
      console.log(this.mapView);
    }
  }

  onValChange(e) {
    this.notifyParent.emit('e');
  }
}

