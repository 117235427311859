import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-approvedbills',
  templateUrl: './approvedbills.component.html',
  styleUrls: ['./approvedbills.component.scss']
})
export class ApprovedbillsComponent implements OnInit {

  constructor() {}

  ngOnInit() {}

}

