import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-newbills',
  templateUrl: './newbills.component.html',
  styleUrls: ['./newbills.component.scss']
})
export class NewbillsComponent implements OnInit {

  constructor() {}

  ngOnInit() {}
}
