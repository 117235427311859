import { Component, OnInit, ViewChild } from '@angular/core';
import { TripService } from 'src/app/services/trip/trip.service';
import { AgmMap } from '@agm/core';
import { AngularFireDatabase } from '@angular/fire/database';

@Component({
  selector: 'app-ordertracking',
  templateUrl: './ordertracking.component.html',
  styleUrls: ['./ordertracking.component.scss']
})
export class OrdertrackingComponent implements OnInit {
  trackingData:any;
  trackingid: any;
  @ViewChild('AgmMap') agmMap: AgmMap;
  temp : Array<number> = [];
  iconIntransit = '../../../../assets/icons/truck3d.png'

  constructor(
    private tripService: TripService,
    public db: AngularFireDatabase)
    { }

  ngOnInit() {
    this.temp = [];
    this.trackingid = (window.location.href).split("/").pop();
    this.getTrackingInfo()
  }
  getTrackingInfo() {
    this.tripService.trackOrder(this.trackingid).subscribe(
      data=>{
        this.trackingData = data;
        console.log("data",this.trackingData)
      },
      Error=>{
        console.log("Tracking information not available!" +Error.status)
      }
    )
  }
}
