import { Component, OnInit } from '@angular/core';
import { TokenStorage } from 'src/app/auth/token.storage';
import { DomSanitizer} from '@angular/platform-browser';



@Component({
  selector: 'app-costanalytics',
  templateUrl: './costanalytics.component.html',
  styleUrls: ['./costanalytics.component.scss']
})
export class CostanalyticsComponent implements OnInit {
  isAbcCorp: boolean = false;
  isPPGAP: boolean = false;
  powerBiUrl;
  constructor(private token: TokenStorage,
    private sanitizer: DomSanitizer) 
    {
      if(this.token.getTenant() == 'abc-dist-prod'){
      this.isAbcCorp = true;
      this.powerBiUrl = this.sanitizer.bypassSecurityTrustResourceUrl("https://app.powerbi.com/view?r=eyJrIjoiMzUxYTdiYjItNTkwNS00ZWNmLWEzYmEtYTQ4YTg5MzY1MWM4IiwidCI6ImI0NTk5ZGVkLTM4N2EtNGI4OS1iMWZmLWVmN2YzMjk4MDZmNCJ9");
      
    }

      else if(this.token.getTenant() == 'ppgap-dist-prod'){
        this.isPPGAP = true;
        this.powerBiUrl = this.sanitizer.bypassSecurityTrustResourceUrl("https://app.powerbi.com/view?r=eyJrIjoiM2JlM2FiNjktZTQyZS00NDhjLTg0YWItYzhlNjg1MDVjZGYxIiwidCI6ImI0NTk5ZGVkLTM4N2EtNGI4OS1iMWZmLWVmN2YzMjk4MDZmNCJ9");
        }

        else if(this.token.getTenant() == 'kimberly-dist-prod'){
          this.isPPGAP = true;
          this.powerBiUrl = this.sanitizer.bypassSecurityTrustResourceUrl("https://app.powerbi.com/view?r=eyJrIjoiNGZmMTM3OTAtZWQ1NC00ZGNlLTk5NzAtZjAxZWE0NTgzZDkxIiwidCI6ImI0NTk5ZGVkLTM4N2EtNGI4OS1iMWZmLWVmN2YzMjk4MDZmNCJ9");
          }
    }
   

  ngOnInit() {
  }

}
