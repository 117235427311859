import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild } from '@angular/router';
import { TokenStorage } from './token.storage';
import { AuthService } from './auth-guard.service';
@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
        public router: Router,
        public authService: AuthService,
        private tokenStorage: TokenStorage) {

    }


    canActivate(): boolean {

        if (this.authService.isAuthenticated()) {
            return true;
        }
        console.log("not logged in");
        this.router.navigateByUrl('login');
        return false;
    
    }
}