import { Injectable } from '@angular/core';
import { Http, Headers, URLSearchParams, Response, HttpModule, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  base_url: string;
  constructor(
    private http: HttpClient,
  ) {
    this.base_url = environment.appUrl + "/product";
  }
  addProduct(productObj): Observable<any> {
    return this.http.post(this.base_url, productObj);
  }

  updateProduct(productObj): Observable<any> {
    return this.http.put(this.base_url, productObj);
  }
  

  checkDuplicateSkuNumber(productSkuNumber): Observable<any> {
    return this.http.get(this.base_url + "/sku/" + productSkuNumber);
  }

  getAllProducts(): Observable<any>{
    return this.http.get(this.base_url);
  }
}