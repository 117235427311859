import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-mapwindow',
  templateUrl: './mapwindow.component.html',
  styleUrls: ['./mapwindow.component.scss']
})
export class MapwindowComponent implements OnInit {
  tripList
  mapstyles
  warehouseIcon = "../../../assets/icons/warehouse1.png"

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.tripList = this.data.tripList;
    this.mapstyles = this.data.mapStyle;
    console.log(this.tripList)
  }


}
