import { Component, OnInit } from '@angular/core';
import { ProductService } from 'src/app/services/products/product.service';
import { UploadfileService } from 'src/app/services/uploadfile/uploadfile.service';
import { Subject, Observable, Subscription } from 'rxjs';


@Component({
  selector: 'app-uploadproductexcel',
  templateUrl: './uploadproductexcel.component.html',
  styleUrls: ['./uploadproductexcel.component.scss']
})
export class UploadproductexcelComponent implements OnInit {
  isFileUploading: boolean;
  showSpinner: boolean = false;
  fileName: any;
  excelProgressSubscription: Subscription;
  progress: string;
  subExcel: Subscription;
  
  constructor(
    private productService: ProductService,
    private uploadService: UploadfileService
  ) { }
  protected ngUnsubscribe: Subject<void> = new Subject<void>();


  ngOnInit() {
    this.excelProgressSubscription = this.uploadService.progressObservable.subscribe(progress => {
      console.log(progress);
      this.progress = progress.progressStatus;
    });

    this.subExcel = this.uploadService.event.subscribe(val => {
      this.isFileUploading = false;
    });
  }
  submitFile(fileEvent) {
    if (fileEvent) {
      console.log(fileEvent.target.files[0]);
      this.isFileUploading = true;
      if (fileEvent.target.files.length > 0) {
        const file: File = fileEvent.target.files[0];
        this.uploadService.uploadProductExcelFile(file);
      }
    }
  }
  ngOnDestroy(){
    this.subExcel.unsubscribe();
    this.excelProgressSubscription.unsubscribe();
  }
}
