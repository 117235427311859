import { Component, OnInit, ViewChild } from '@angular/core';
import { TripService } from '../../../services/trip/trip.service'; 
import { TokenStorage } from '../../../auth/token.storage';
import { AngularFireDatabase } from '@angular/fire/database';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { AgmMap, LatLngBounds } from '@agm/core';
declare const google: any;

@Component({
  selector: 'app-tripmapview',
  templateUrl: './tripmapview.component.html',
  styleUrls: ['./tripmapview.component.scss']
})
export class TripmapviewComponent implements OnInit {
zoom: number = 13;
lat: number = 18.533989;
lng: number = 73.825592;
OrderMapMarker: any;
markers: any;
markersArr = [];
subscriptionsArray: Array<Subscription> = [];
@ViewChild('AgmMap') agmMap: AgmMap;

mapstyles = [
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#1d2c4d"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#8ec3b9"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#1a3646"
      }
    ]
  },
  {
    "featureType": "administrative.country",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#4b6878"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#64779e"
      }
    ]
  },
  {
    "featureType": "administrative.province",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#4b6878"
      }
    ]
  },
  {
    "featureType": "landscape.man_made",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#334e87"
      }
    ]
  },
  {
    "featureType": "landscape.natural",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#023e58"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#283d6a"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#6f9ba5"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#1d2c4d"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#023e58"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#3C7680"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#304a7d"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#98a5be"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#1d2c4d"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#2c6675"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#255763"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#b0d5ce"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#023e58"
      }
    ]
  },
  {
    "featureType": "transit",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#98a5be"
      }
    ]
  },
  {
    "featureType": "transit",
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#1d2c4d"
      }
    ]
  },
  {
    "featureType": "transit.line",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#283d6a"
      }
    ]
  },
  {
    "featureType": "transit.station",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#3a4762"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#0e1626"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#4e6d70"
      }
    ]
  }
];

constructor(
  private tripService: TripService,
  private db: AngularFireDatabase,
  private token: TokenStorage,
  private datePipe: DatePipe
) { }

ngOnInit() {
  //send type data here
  this.generateMapData();
}

generateMapData() {
  this.markersArr = [];
  // let dateTwoDaysBack = new Date(new Date().getTime() - (2 * 24 * 60 * 60 * 1000));
  // let fromDate: any = this.datePipe.transform(dateTwoDaysBack, 'yyyy-MM-dd');
  let toDate: any = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

  this.tripService.getAllTripsByTypesDateRange(['TRIP_STATE_IN_TRANSIT'],0,toDate,toDate,'nozone').subscribe(data =>{
    //console.log(trips);
    let trips = data.content;
    for(let i = 0; i < trips.length; i++){
      if(trips[i].tState === 'TRIP_STATE_IN_TRANSIT'){
        //console.log(trips[i].tState+" "+trips[i].tripName);
        let path = '/' + this.token.getTenant() + '/' + trips[i].tripName;
        let sub = this.db.list(path).valueChanges().subscribe(res => {
          //console.log(res[res.length-1]);
          if(res[res.length-1]){
            let idx = this.markersArr.findIndex(obj => obj.tripName === trips[i].tripName);
            if(idx === -1 ){
              let temp = {
                tripName : trips[i].tripName,
                licenseNumber : trips[i].assignedVehicleLicenseNumber,
                icon : '../../../../assets/icons/truck3d.png',
                location : res[res.length - 1],
                driver : trips[i].assignedDriverName
              }
              this.markersArr.push(temp);
            }else{
              this.markersArr[idx].location = res[res.length-1];
            }
          }
        });
        this.subscriptionsArray.push(sub);
      }
    }
  })
}

ngOnDestroy() {
  this.subscriptionsArray.forEach(subscrption => {
    subscrption.unsubscribe();
  });
}

ngAfterViewInit() {
  if(this.agmMap){
    this.agmMap.mapReady.subscribe(map => {
      setTimeout(() => {
        console.log("Zoom Set");
        if(this.markersArr.length != 0){
          const bounds: LatLngBounds = new google.maps.LatLngBounds();
          for (const mm of this.markersArr) {
            bounds.extend(new google.maps.LatLng(mm.location.latitude, mm.location.longitude));
            bounds.extend(new google.maps.LatLng(mm.location.latitude, mm.location.longitude));
          }
          map.fitBounds(bounds);
        }
      }, 2000);
    });
  }
}

}


