import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from "./register/register.component";
import { ManageFleetComponent } from './pages/managefleet/managefleet.component';
import { PagesComponent } from './pages/pages.component';
import { AddfleetComponent } from './pages/managefleet/addfleet/addfleet.component';
import { AllfleetComponent } from './pages/managefleet/allfleet/allfleet.component';
import { AddtransporterComponent } from './pages/transporter/addtransporter/addtransporter.component';
import { AlltransporterComponent } from './pages/transporter/alltransporter/alltransporter.component';
import { TransporterComponent } from './pages/transporter/transporter.component';
import { UserandroleComponent } from './pages/userandrole/userandrole.component';
import { AdminComponent } from './pages/manageordersandjobs/admin/admin.component';
import { AlluserroleComponent } from './pages/userandrole/alluserrole/alluserrole.component';
import { AdduserComponent } from "./pages/userandrole/adduser/adduser.component";
import { AssignroleComponent } from './pages/userandrole/assignrole/assignrole.component';
import { OrderComponent } from './pages/order/order.component';
import { SchedulingandplanningComponent } from './pages/schedulingandplanning/schedulingandplanning.component';
import { CreatetriptableComponent } from './pages/schedulingandplanning/createtriptable/createtriptable.component';
import { EdittriptableComponent } from './pages/schedulingandplanning/edittriptable/edittriptable.component';
import { ScheduletriptableComponent } from './pages/schedulingandplanning/scheduletriptable/scheduletriptable.component';
import { TripComponent } from './pages/trip/trip.component';
import { TripmapviewComponent } from './pages/trip/tripmapview/tripmapview.component';
import { TriplistviewComponent } from './pages/trip/triplistview/triplistview.component';
import { OrdermapviewComponent } from './pages/order/ordermapview/ordermapview.component';
import { OrderlistviewComponent } from './pages/order/orderlistview/orderlistview.component';
import { HomeComponent } from './pages/home/home.component';
// import { SchedulefleetComponent } from './pages/managefleet/schedulefleet/schedulefleet.component';
import { SearchresultComponent } from './pages/searchresult/searchresult.component';
import { TripanalyticsComponent } from './pages/tripanalytics/tripanalytics.component';
import { OrderreportComponent } from './pages/orderreport/orderreport.component';
import { ManagecustomerComponent } from './pages/managecustomer/managecustomer.component';
import { AddcustomerComponent } from './pages/managecustomer/addcustomer/addcustomer.component';
import { AddproductComponent } from './pages/manageproducts/addproduct/addproduct.component';
import { ManageproductsComponent } from './pages/manageproducts/manageproducts.component';
import { AllplansComponent } from './pages/schedulingandplanning/allplanscomponent/allplans.component';
import { ManageordersandjobsComponent } from './pages/manageordersandjobs/manageordersandjobs.component';
import { ManagejobsComponent } from './pages/manageordersandjobs/managejobs/managejobs.component';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { UploadproductexcelComponent } from './pages/manageproducts/uploadproductexcel/uploadproductexcel.component';
import { CostanalyticsComponent } from './pages/costanalytics/costanalytics.component';
import { AllcustomersComponent } from './pages/managecustomer/allcustomers/allcustomers.component';
import { TriptrackingComponent } from './pages/triptracking/triptracking.component';
import { AuthGuard } from './auth/auth.guard';
import { JobreportComponent } from './pages/jobreport/jobreport.component';
import { UploadcustomerexcelComponent } from './pages/managecustomer/uploadcustomerexcel/uploadcustomerexcel.component';
import { TransportrateComponent } from './pages/transportrate/transportrate.component';
import { BaserateComponent } from './pages/transportrate/baserate/baserate.component';
import { AdditionalrateComponent } from './pages/transportrate/additionalrate/additionalrate.component';
import { DevicemanagementComponent } from './pages/devicemanagement/devicemanagement.component';
import { AlldevicesComponent } from './pages/devicemanagement/alldevices/alldevices.component';
import { AdddeviceComponent } from './pages/devicemanagement/adddevice/adddevice.component';
import { ManagedevicesComponent } from './pages/devicemanagement/managedevices/managedevices.component';
import { ManagegeofenceComponent } from './pages/managegeofence/managegeofence.component';
import { GeofencingComponent } from './pages/managegeofence/geofencing/geofencing.component';
import { OthergeofenceComponent } from './pages/managegeofence/othergeofence/othergeofence.component';
import { CustomergeofenceComponent } from './pages/managegeofence/customergeofence/customergeofence.component';
import { BillingComponent } from './pages/billing/billing.component';
import { PendingbillsComponent } from './pages/billing/pendingbills/pendingbills.component';
import { ApprovedbillsComponent } from './pages/billing/approvedbills/approvedbills.component';
import { NewbillsComponent } from './pages/billing/newbills/newbills.component';
import { NetworkmanagementComponent } from './pages/networkmanagement/networkmanagement.component';
import { AllbranchesComponent } from './pages/networkmanagement/allbranches/allbranches.component';
import { AddbranchComponent } from './pages/networkmanagement/addbranch/addbranch.component';
import { AllproductsComponent } from './pages/manageproducts/allproducts/allproducts.component';
import { AddvehicletypeComponent } from './pages/managevehicletype/addvehicletype/addvehicletype.component';
import { ManagevehicletypeComponent } from './pages/managevehicletype/managevehicletype.component';
import { AllvehicletypesComponent } from './pages/managevehicletype/allvehicletypes/allvehicletypes.component';
import { AddzoneComponent } from './pages/networkmanagement/addzone/addzone.component';
import { CostreportComponent } from './pages/costreport/costreport.component';
import { PodexplorerComponent } from './pages/podexplorer/podexplorer.component';
import { BulkuploadComponent } from './pages/bulkupload/bulkupload.component';
import { PodcompliancereportComponent } from './pages/podcompliancereport/podcompliancereport.component';
import { VendorBillsComponent } from './pages/billing/vendorBills/vendor-bills.component';
import { BillingreportComponent } from './pages/billingreport/billingreport.component';
import { ManageAuditTrailComponent } from './pages/manage-audit-trail/manage-audit-trail.component';
import { PlanreportComponent } from './pages/planreport/planreport.component';
import { TripmanagementComponent } from './pages/manageordersandjobs/tripmanagement/tripmanagement.component';
import { OrdertrackingComponent } from './pages/order/ordertracking/ordertracking.component';
import { ManagematerialcategoriesComponent } from './pages/managematerialcategories/managematerialcategories.component';
import { AddmaterialcategoryComponent } from './pages/managematerialcategories/addmaterialcategory/addmaterialcategory.component';
import { AllmaterialcategoriesComponent } from './pages/managematerialcategories/allmaterialcategories/allmaterialcategories.component';
import { EditmaterialcategoryComponent } from './pages/managematerialcategories/editmaterialcategory/editmaterialcategory.component';
import { UploadmaterialcategoryexcelComponent } from './pages/managematerialcategories/uploadmaterialcategoryexcel/uploadmaterialcategoryexcel.component'



const routes: Routes = [
  {
    path: '', component: PagesComponent,
    canActivate: [AuthGuard],
    children: [
      {
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            except: ['transporter', 'key_account_manager', 'customer']
          }
        },
        path: '', component: HomeComponent
      },
      {
        path: 'managefleet', component: ManageFleetComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['admin', 'planner', 'regional_admin', 'warehouse_manager']
          }
        },
        children: [
          { path: '', component: AllfleetComponent },
          { path: 'addnewfleet', component: AddfleetComponent },
        ]
      },
      {
        path: 'managevehicletypes', component: ManagevehicletypeComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['admin', 'planner', 'regional_admin', 'warehouse_manager']
          }
        },
        children: [
          { path: '', component: AllvehicletypesComponent },
          {
            path: 'addnewvehicletype', component: AddvehicletypeComponent,
            canActivate: [NgxPermissionsGuard],
            data: {
              permissions: {
                only: ['admin', 'planner', 'regional_admin']
              }
            },
          },
        ]
      },

      {
        path: 'dashboard', component: DashboardComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            except: ['transporter', 'key_account_manager', 'finance_admin', 'customer']
          }
        },
      },
      {
        path: 'networkmanagement', component: NetworkmanagementComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['admin', 'planner', 'regional_admin', 'warehouse_manager']
          }
        },
        children: [
          { path: '', component: AllbranchesComponent },
          {
            path: 'addbranch', component: AddbranchComponent,
            canActivate: [NgxPermissionsGuard],
            data: {
              permissions: {
                only: ['admin', 'planner', 'regional_admin']
              }
            }
          },
          {
            path: 'addzone', component: AddzoneComponent,
            canActivate: [NgxPermissionsGuard],
            data: {
              permissions: {
                only: ['admin', 'planner', 'regional_admin']
              }
            }
          }
        ]
      },
      {
        path: 'transporter', component: TransporterComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['admin', 'planner', 'regional_admin', 'warehouse_manager']
          }
        },
        children: [
          { path: '', component: AlltransporterComponent },
          {
            path: 'addnewtransporter', component: AddtransporterComponent,
            canActivate: [NgxPermissionsGuard],
            data: {
              permissions: {
                only: ['admin', 'planner', 'regional_admin']
              }
            },
          },
          { path: 'vehicletype', component: AllvehicletypesComponent },
          { path: 'fleet', component: AllfleetComponent }
        ]
      },
      {
        path: 'userandrole', component: UserandroleComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['admin', 'regional_admin', 'warehouse_manager', 'customer']
          }
        },
        children: [
          { path: '', component: AlluserroleComponent },
          {
            path: 'addnewuser', component: AdduserComponent,
            canActivate: [NgxPermissionsGuard],
            data: {
              permissions: {
// Arjun Start Adding   : below line commented               
               // only: ['admin', 'regional_admin', 'warehouse_manager'] //Default/Original
               // Added below line 
                only: ['admin']
// Arjun End .                
              }
            },
          },
        ]
      },

      {
        path: 'order', component: OrderComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['admin', 'regional_admin', 'warehouse_manager', 'key_account_manager', 'customer']
          }
        },
        children: [
          {
            path: '', component: OrderlistviewComponent
          },
          {
            path: 'ordermapview', component: OrdermapviewComponent
          }
        ]
      },
      {
        path: 'trip', component: TriptrackingComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['admin', 'regional_admin', 'warehouse_manager', 'transporter']
          }
        },
        /* children: [
          {
            path: '', component: TriplistviewComponent
          },
          {
            path: 'tripmapview', component: TripmapviewComponent
          }
        ] */
      },
      {
        //it is trip report component
        path: 'tripanalytics', component: TripanalyticsComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            except: ['delivery_associate', 'customer', 'transporter', 'key_account_manager']
          }
        }
      },
      {
        path: 'orderreport', component: OrderreportComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            except: ['delivery_associate', 'customer', 'transporter']
          }
        }
      },
      {
        path: 'jobreport', component: JobreportComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            except: ['delivery_associate', 'customer', 'transporter', 'key_account_manager']
          }
        }
      },
      {
        path: 'billingreport', component: BillingreportComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            except: ['delivery_associate', 'customer', 'key_account_manager', 'warehouse_manager']
          }
        }
      },
      {
        path: 'audittrail', component: ManageAuditTrailComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['admin', 'regional_admin']
          }
        }
      },
      {
        path: 'costreport', component: CostreportComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['admin', 'regional_admin']
          }
        }
      },
      {
        path: 'planreport', component: PlanreportComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['admin', 'regional_admin']
          }
        }
      },
      {
        path: 'costanalytics', component: CostanalyticsComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['admin', 'regional_admin']
          }
        }
      },
      {
        path: 'scheduling', component: SchedulingandplanningComponent,
        children: [
          {
            path: '', component: CreatetriptableComponent,
            canActivate: [NgxPermissionsGuard],
            data: {
              permissions: {
                only: ['admin', 'regional_admin', 'planner', 'warehouse_manager']
              }
            }
          },
          { path: 'allplans', component: AllplansComponent },
          { path: 'edittrips', component: EdittriptableComponent },
          { path: 'scheduletrips', component: ScheduletriptableComponent }
        ]
      },
      {
        path: 'searchresult', component: SearchresultComponent
      },
      {
        path: 'admin', component: AdminComponent,
      },
      {
        path: 'managecustomers', component: ManagecustomerComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['admin', 'planner', 'regional_admin', 'warehouse_manager', 'key_account_manager']
          }
        },
        children: [
          { path: '', component: AllcustomersComponent },
          {
            path: 'addcustomer', component: AddcustomerComponent,
            canActivate: [NgxPermissionsGuard],
            data: {
              permissions: {
                only: ['admin', 'planner', 'regional_admin']
              }
            }
          },
          {
            path: 'uploadcustomerexcel', component: UploadcustomerexcelComponent,
            canActivate: [NgxPermissionsGuard],
            data: {
              permissions: {
                only: ['admin', 'planner', 'regional_admin', 'warehouse_manager']
              }
            }
          }
        ]
      },
      {
        path: 'manageproducts', component: ManageproductsComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['admin', 'planner', 'regional_admin', 'warehouse_manager']
          }
        },
        children: [
          { path: '', component: AllproductsComponent },
          {
            path: 'addproduct', component: AddproductComponent,
            canActivate: [NgxPermissionsGuard],
            data: {
              permissions: {
                only: ['admin']
              }
            }
          },
          {
            path: 'uploadproductexcel', component: UploadproductexcelComponent,
            canActivate: [NgxPermissionsGuard],
            data: {
              permissions: {
                only: ['admin']
              }
            }
          }
        ]
      },
      {
        path: 'managematerialcategories', component: ManagematerialcategoriesComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['admin', 'planner', 'regional_admin', 'warehouse_manager']
          }
        },
        children: [
          { path: '', component: AllmaterialcategoriesComponent },
          {
            path: 'addmaterialcategory', component: AddmaterialcategoryComponent,
            canActivate: [NgxPermissionsGuard],
            data: {
              permissions: {
                only: ['admin']
              }
            }
          },
          {
            path: 'uploadmaterialcategoryexcel', component: UploadmaterialcategoryexcelComponent,
            canActivate: [NgxPermissionsGuard],
            data: {
              permissions: {
                only: ['admin']
              }
            }
          }
        ]
      },
      {
        path: 'manageordersandjobs', component: ManageordersandjobsComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['admin', 'planner', 'regional_admin', 'warehouse_manager']
          }
        },
        children: [
          { path: '', component: AdminComponent },
          /*   {
              path: 'jobs', component: ManagejobsComponent,
            } */
            {
              path: 'managetrips', component: TripmanagementComponent,
              canActivate: [NgxPermissionsGuard],
              data: {
                permissions: {
                  only: ['admin', 'regional_admin', 'warehouse_manager', 'warehouse_admin', 'transporter']
                }
              },
            },
        ]
      },
      {
        path: 'transportrate', component: TransportrateComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['admin', 'planner', 'regional_admin', 'transporter']
          }
        },
        children: [
          { path: '', component: BaserateComponent },
          { path: 'additionalrate', component: AdditionalrateComponent }
        ]
      },
      {
        path: 'iothub', component: DevicemanagementComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['admin', 'planner', 'warehouse_manager']
          }
        },
        children: [
          { path: '', component: AlldevicesComponent },
          { path: 'adddevice', component: AdddeviceComponent },
          { path: 'managedevices', component: ManagedevicesComponent }
        ]
      },
      {
        path: 'triptracking', component: TriptrackingComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['admin', 'planner', 'warehouse_manager']
          }
        },
      },
      /*  {
         path: 'geofencing', component: GeofencingComponent,
         canActivate: [NgxPermissionsGuard],
         data: {
           permissions: {
             only: ['admin','planner','warehouse_manager']
           }
         },
       } */
      {
        path: 'geofences', component: ManagegeofenceComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['admin', 'planner', 'warehouse_manager']
          }
        },
        children: [
          { path: '', component: GeofencingComponent },
          { path: 'customergeofence', component: CustomergeofenceComponent },
          { path: 'othergeofence', component: OthergeofenceComponent }
        ]
      },
      {
        path: 'billing', component: VendorBillsComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['admin', 'transporter', 'regional_admin']
          }
        },
        children: [
          { path: '', redirectTo: 'vendor', pathMatch: 'full' },
          
          { path: 'new', component: NewbillsComponent },
          { path: 'pending', component: PendingbillsComponent },
          { path: 'approved', component: ApprovedbillsComponent },
          { path: 'vendor', component: VendorBillsComponent },
        ]
      },


      {
        path: 'podexplorer', component: PodexplorerComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['admin', 'regional_admin', 'finance_admin', 'customer']
          }
        }
      },
      {
        path: 'bulkupload', component: BulkuploadComponent,
    /*     canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['admin', 'planner', 'warehouse_manager']
          }
        }, */
      },
      {
        path: 'podcompliance', component: PodcompliancereportComponent,
    /*     canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['admin', 'planner', 'warehouse_manager']
          }
        }, */
      },
    ]
  },
  { path: 'track/:trackid', component: OrdertrackingComponent },
  { path: 'navbar', component: NavbarComponent },
  { path: 'login', component: LoginComponent, pathMatch: 'full' },
  { path: 'register', component: RegisterComponent },
  { path: '', redirectTo: 'login', pathMatch: 'full' },

];


const config: ExtraOptions = {
  useHash: true,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
