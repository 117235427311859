import { Component, OnInit, ViewChild } from '@angular/core';
import { FleetService } from 'src/app/services/fleet/fleet.service';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog } from '@angular/material';
import { TokenStorage } from 'src/app/auth/token.storage';
import { AddvehicletypeComponent } from '../addvehicletype/addvehicletype.component';
import { Subject, Subscription } from 'rxjs';
import { BranchService } from 'src/app/services/branch/branch.service';
import { EditvehicletypeComponent } from '../editvehicletype/editvehicletype.component';

@Component({
  selector: 'app-allvehicletypes',
  templateUrl: './allvehicletypes.component.html',
  styleUrls: ['./allvehicletypes.component.scss']
})
export class AllvehicletypesComponent implements OnInit {
  branchesSubscription: Subscription;
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  columnsToDisplay = [
    'sapVehicleType',
    'vehicleType',
    'branches',
    'weight',
    'volume',
    'actions'
  ];

  isLoading: boolean = false;
  volUnit: string;
  isWarehouseManager: boolean = false;
  vehicleTypesDataArray = new MatTableDataSource;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private dialog: MatDialog,private fleetService: FleetService, private token: TokenStorage,
    private branchService: BranchService) {
    if (this.token.getTenant() == 'kimberly-dist-prod') {
      this.volUnit = '(CM)';
    } else if (this.token.getTenant() == 'godrej-dist-prod') {
      this.volUnit = '(CCM)';
    } else {
      this.volUnit = '(CF)';
    }
  }

  ngOnInit() {
    if(window.sessionStorage.getItem('permissions').substr(2).slice(0, -2)=='warehouse_manager'){
      this.isWarehouseManager= true;
    }
    this.isLoading = true;
    this.vehicleTypesDataArray.data = [];
    this.branchesSubscription = this.branchService.branchesObservable.subscribe(() => {
      this.ngUnsubscribe.next();
      if (window.sessionStorage.getItem("selectedBranchesCodes") && JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")).length == 0) {
        this.vehicleTypesDataArray.data = [];
        this.isLoading = false;
      }else{
    this.isLoading = true;
    this.fleetService.getVehicleTypes().subscribe(allVehicleTypes => {
      this.isLoading = false;
      console.log("allvehicletypes", allVehicleTypes)
      if(allVehicleTypes){
      this.vehicleTypesDataArray.data = allVehicleTypes;
      }
      else{
        this.vehicleTypesDataArray.data = [];
      }
    }, Error => {
      console.log(Error)
      this.isLoading = false;
    });
  }

    this.vehicleTypesDataArray.sortingDataAccessor = (vtype: any, property) => {
      switch (property) {
        case 'vehicleType': return vtype.vehicleType
        case 'weight': return vtype.weightCapacity
        case 'volume': return vtype.volumeCapacity
      }
    };
    this.vehicleTypesDataArray.sort = this.sort;
    this.vehicleTypesDataArray.paginator = this.paginator;
  });
  }

  applyFilter(filterValue: string) {
    this.vehicleTypesDataArray.filter = filterValue.trim().toLowerCase();
  }
  addNewVehicleType()
  {
      let addDialog = this.dialog.open(AddvehicletypeComponent, {
        width: '65%',
        maxWidth:'85vw',
      });
  
      addDialog.updatePosition({
        top: '50px'
      });
  }

  editvehicletype(vehicletype) {
    let editDialog = this.dialog.open(EditvehicletypeComponent, {
      width: '65%',
      maxWidth:'85vw',
      data: vehicletype,
      id :'editVehicleTypeWindow'
    });

    editDialog.updatePosition({
      top: '50px'
    });
    editDialog.afterClosed().subscribe(result => {
      console.log(result);
      if(result){
      this.ngOnInit();
      }
    });
  }
  ngOnDestroy() {
    this.branchesSubscription.unsubscribe();
  }
}