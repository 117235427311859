import { Injectable } from '@angular/core';


const TOKEN_KEY = 'userdetails';

@Injectable()
export class StoreUserDetails {

  constructor() {
  
   }

  removeUser() {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.clear();
  }

  public saveUser(token: string) {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY,  token);
  }

  public getUser(): string {
    return sessionStorage.getItem(TOKEN_KEY);
  }
}
