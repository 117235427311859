import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RateService {

  constructor(
    private http: HttpClient,
  ) { }

  getRatesByOriginAndTransporter(branchName: string, transporterId: number) {
    return this.http.get(environment.appUrl + '/rates/' + branchName + '/' + transporterId);
  }

  getAdditionalRatesByOriginAndTransporter(branchName: string, transporterId: number) {
    return this.http.get(environment.appUrl + '/additionalRates/' + branchName + '/' + transporterId);
  }

  updateBaseRate(rateDTOList){
    return this.http.put(environment.appUrl + '/rates',rateDTOList);
  }

  updateAdditionalRate(rateDTOList){
    return this.http.put(environment.appUrl + '/additionalRates',rateDTOList);
  }
}
