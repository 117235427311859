import { Component, OnInit, ViewChild } from '@angular/core';
import {
  MatPaginator, MatTableDataSource,
  MatSort
} from '@angular/material';
import { ExcelgeneratorService } from '../../services/excelgenerator/excelgenerator.service';
import { DatePipe } from '@angular/common';
import { NgxDateRangePickerOptions } from '../../../ngx-daterangepicker';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { CostService } from 'src/app/services/cost/cost.service';
import { BranchService } from 'src/app/services/branch/branch.service';

@Component({
  selector: 'app-costreport',
  templateUrl: './costreport.component.html',
  styleUrls: ['./costreport.component.scss']
})
export class CostreportComponent implements OnInit {
  columnsToDisplay = [
    'region',
    'state',
    'plantName',
    'plantCode',
    'channel',
    'distributorCode',
    'distributorName',
    'dateOfInvoicing',
    'shipmentNo',
    'invoiceNo',
    'Qty',
    'valueExcludingGst',
    'transporterName',
    'vehicleType',
    'vehicleNumber',
    'vehicleCapacity',
    'freightCost',
    'unloadingCost',
    'clubbingCost'
  ];

  options: NgxDateRangePickerOptions;
  costDataArray = new MatTableDataSource();
  excelRecords = [];
  totalRecords: number = 0;
  daterRangevalue: any;
  fromDate: any;
  toDate: any;
  isDateFiltered: boolean = false;
  orders = [];
  prevFromDate: any;
  prevTodate: any;
  isLoading: boolean = false;
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  branchesSubscription: Subscription;


  constructor(
    private excelService: ExcelgeneratorService,
    private datePipe: DatePipe,
    private costService: CostService,
    private branchService: BranchService
    ) { }

  ngOnInit() {
    this.createInitialFilter();
    this.branchesSubscription = this.branchService.branchesObservable.subscribe(() => {
      this.ngUnsubscribe.next();
      if (window.sessionStorage.getItem("selectedBranchesCodes") && JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")).length == 0) {
        this.costDataArray.data = [];
        this.isLoading = false;
      }
      else {
        this.costDataArray.data = [];
        this.costFilter();
      }
    });
  }

  ngAfterViewInit() {
    this.costDataArray.paginator = this.paginator;
  }

  exportAsXLSX() {
    this.excelRecords = [];
    let costData = [];
    costData = this.costDataArray.data;
    costData.forEach(cost => {
      let region, state, plantName, plantCode, channel, distributorCode, distributorName, dateOfInvoicing, shipmentNo,
        invoiceNo, Qty, valueExcludingGst, transporterName, vehicleType, vehicleNumber, vehicleCapacity, freightCost, unloadingCost,
        clubbingCost

      if (cost.plant_name)
        plantName = cost.plant_name;
      else
        plantName = ''

      if (cost.plant_code)
        plantCode = cost.plant_code;
      else
        plantCode = ''

      if (cost.region)
        region = cost.region;
      else
        region = ''

      if (cost.state)
        state = cost.state;
      else
        state = ''

      if (cost.channel)
        channel = cost.channel;
      else
        channel = ''

      if (cost.distributor_code)
        distributorCode = cost.distributor_code;
      else
        distributorCode = ''

      if (cost.distributor_name)
        distributorName = cost.distributor_name;
      else
        distributorName = ''


      if (cost.date_of_invoicing)
        dateOfInvoicing = this.datePipe.transform(cost.date_of_invoicing, "dd-MM-yyyy hh:mm a");
      else
        dateOfInvoicing = ''

      if (cost.shipment_number)
        shipmentNo = cost.shipment_number;
      else
        shipmentNo = ''

      if (cost.invoice_number)
        invoiceNo = cost.invoice_number;
      else
        invoiceNo = ''

      if (cost.qty)
        Qty = cost.qty;
      else
        Qty = ''

      if (cost.value_excluding_GST)
        valueExcludingGst = cost.value_excluding_GST;
      else
        valueExcludingGst = ''

      if (cost.transporter)
        transporterName = cost.transporter;
      else
        transporterName = ''

      if (cost.vehicle_type)
        vehicleType = cost.vehicle_type;
      else
        vehicleType = ''

      if (cost.vehicle_number)
        vehicleNumber = cost.vehicle_number;
      else
        vehicleNumber = ''

      if (cost.vehicle_capacity)
        vehicleCapacity = cost.vehicle_capacity;
      else
        vehicleCapacity = ''


      if (cost.freight_cost)
        freightCost = cost.freight_cost;
      else
        freightCost = 0

      if (cost.unloading_cost)
        unloadingCost = cost.unloading_cost;
      else
        unloadingCost = 0

      if (cost.clubbing_cost)
        clubbingCost = cost.clubbing_cost;
      else
        clubbingCost = 0

      if (cost) {
        this.excelRecords.push({
          'Region': region,
          'State': state,
          'Plant Name': plantName,
          'Plant Code': plantCode,
          'Channel': channel,
          'Distributor Code': distributorCode,
          'Distributor Name': distributorName,
          'Date Of Invoicing': dateOfInvoicing,
          'Shipment No': shipmentNo,
          'Invoice No': invoiceNo,
          'Quantity': Qty,
          'Value Excluding Gst': valueExcludingGst,
          'Transporter': transporterName,
          'Vehicle Type': vehicleType,
          'Vehicle Number': vehicleNumber,
          'Vehicle Capacity': vehicleCapacity,
          'Freight Cost': freightCost,
          'Unloading Cost': unloadingCost,
          'Clubbing Cost': clubbingCost
        })
      }
    });
    this.excelService.exportAsExcelFile(this.excelRecords, 'costReport');
  }


  createInitialFilter() {
    this.options = {
      theme: 'default',
      labels: ['Start', 'End'],
      menu: null,
      dateFormat: 'YYYY-MM-DD',
      outputFormat: 'DD-MM-YYYY',
      startOfWeek: 1,
      outputType: 'object',
      locale: 'en',
      date: {
        from: {
          year: new Date().getFullYear(),
          month: new Date().getMonth() + 1,
          day: new Date().getDate()
        },
        to: {
          year: new Date().getFullYear(),
          month: new Date().getMonth() + 1,
          day: new Date().getDate()
        }
      }
    };
    this.fromDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.toDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.isDateFiltered = true;
  }

  costFilter() {
    if (this.isDateFiltered) {
      if (this.fromDate && this.toDate) {
        this.costDataArray.data = [];
        this.isLoading = true;
        this.getData(this.fromDate, this.toDate).pipe(takeUntil(this.ngUnsubscribe)).subscribe(orders => {
          this.isLoading = false;
          console.log("orders in report", orders);
          this.costDataArray.data = orders;
          this.orders = orders;
          this.totalRecords = orders.length;
          this.costDataArray.sortingDataAccessor = (order: any, property) => {
            switch (property) {
              case 'status': return order.orderstatus;
              case 'receiver': return order.receiver_name;
            }
          };
        }, () => {
          this.isLoading = false;
        })
      }
    }
  }

  getData(from, to) {
    return this.costService.getCostReport(from, to);
  }

  sortByDate() {
    if (this.daterRangevalue != undefined) {
      this.fromDate = this.daterRangevalue.from.substring(6, 10) + '-' + this.daterRangevalue.from.substring(3, 5) + '-' + this.daterRangevalue.from.substring(0, 2);
      this.toDate = this.daterRangevalue.to.substring(6, 10) + '-' + this.daterRangevalue.to.substring(3, 5) + '-' + this.daterRangevalue.to.substring(0, 2);
      if (this.toDate != this.prevTodate || this.fromDate != this.prevFromDate) {
        this.ngUnsubscribe.next();
        this.isDateFiltered = true;
        this.costFilter();
        this.prevFromDate = this.fromDate;
        this.prevTodate = this.toDate;
      }
    }
  }

  clearAllFilter() {
    this.paginator.pageIndex = 0;
    this.createInitialFilter();
    this.isDateFiltered = false;
    this.prevFromDate = this.fromDate;
    this.prevTodate = this.toDate;
  }

  applyFilter(filterValue: string) {
    this.costDataArray.filter = filterValue.trim().toLowerCase();
  }

  ngOnDestroy() {
    this.branchesSubscription.unsubscribe();
  }
}
