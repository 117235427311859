import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExcelgeneratorService {

  constructor() { }

  public exportAsExcelFile(json: any[], excelname: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    var wscols = [
      { wch: 16 },
      { wch: 16 },
      { wch: 16 },
      { wch: 16 },
      { wch: 28 },
      { wch: 16 },
      { wch: 16 },
      { wch: 16 },
      { wch: 16 },
      { wch: 16 }
    ];
    worksheet["!cols"] = wscols;
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelname);
  }
  private saveAsExcelFile(buffer: any, excelname: string): void {
    let month = new Date().getMonth() + 1;
    let excelName = excelname + '_' + new Date().getDate() + "-" +
      month + "-" + new Date().getFullYear() +
      "T" + new Date().getHours() + ":" + new Date().getMinutes() +
      ":" + new Date().getSeconds();
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, excelName + EXCEL_EXTENSION);
  }

}
