import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TransporterService } from '../../../services/transporter/transporter.service';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { StoreUserDetails } from '../../../auth/storeuserdetails';
import { NotifierService } from 'angular-notifier';
import { ValidatorService } from 'src/app/services/validator/validator.service';
import { BranchService } from 'src/app/services/branch/branch.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-edittransporter',
  templateUrl: './edittransporter.component.html',
  styleUrls: ['./edittransporter.component.scss']
})
export class EdittransporterComponent implements OnInit {

  transporterForm: FormGroup;
  isAssociatedUnit: boolean = false;
  allBranches = [];
  associatedBranches = [];
  tranporterTypes: any[];
  isEdited: boolean = false;
  configTransporterType = {
    height: '40vh',
    search: true
  }

  constructor(
    private formBuilder: FormBuilder,
    private transporterService: TransporterService,
    private matDialog: MatDialog,
    private userDetails: StoreUserDetails,
    private branchService: BranchService,
    private notifierService: NotifierService,
    private spinnerService: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  updateTransporter() {
    let branches = [];
    this.allBranches.forEach(branch => {
      this.associatedBranches.forEach(branchId => {
        if (branch.branchId == branchId) {
          branches.push(branch);
        }
      });
    });

    this.transporterForm.value.branches = branches;

    if (this.transporterForm.valid) {
      this.isEdited = true;
      this.spinnerService.show();
      this.transporterService.updateTransporter(this.transporterForm.value).subscribe(() => {
        this.spinnerService.hide();
        console.log("Trans:", this.transporterForm.value);
        this.matDialog.getDialogById('editTransporterWindow').close(true);
        this.notifierService.notify('success', "Transporter: " + this.transporterForm.value.transporterName + " updated successfully");
      }, () => {
        this.spinnerService.hide();
        this.notifierService.notify('error', "Error saving changes, please try again.");
      })
    }
  }

  ngOnInit() {
    //Intialize form
    this.intializeForm();

    this.getTransporterTypes();
    this.data.branches.forEach(branch => {
      this.associatedBranches.push(branch.branchId);
    });

    this.branchService.branchesObservable.subscribe(allBranches => {
      this.allBranches = allBranches;

      this.allBranches.sort(function (a, b) {
        let branchArea1 = a.branchName.toLowerCase(), branchArea2 = b.branchName.toLowerCase()
        if (branchArea1 < branchArea2)
          return -1
        if (branchArea1 > branchArea2)
          return 1
        return 0
      });
    });

    //Show existing value in form
    this.transporterForm.patchValue({
      transporterName: this.data.transporterName,
      transporterAddress: this.data.transporterAddress,
      transporterPhoneNumber: this.data.transporterPhoneNumber,
      pincode: this.data.pincode,
      city: this.data.city,
      state: this.data.state,
      country: this.data.country,
      bIsActive: this.data.bIsActive,
      contactPersonName: this.data.contactPersonName,
      contactPersonPhoneNumber: this.data.contactPersonPhoneNumber,
      contactPersonEmailId: this.data.contactPersonEmailId,
      creationDateTime: this.data.creationDateTime,
      transporterId: this.data.transporterId,
      transporterCode: this.data.transporterCode,
      updatedByusername: this.userDetails.getUser(),
      transporterType: this.data.transporterType
    })
  }

  get contactPersonPhoneNumber() {
    return this.transporterForm.get('contactPersonPhoneNumber');
  }

  get transporterName() {
    return this.transporterForm.get('transporterName');
  }

  get transporterCode() {
    return this.transporterForm.get('transporterCode');
  }

  get transporterAddress() {
    return this.transporterForm.get('transporterAddress');
  }

  get pincode() {
    return this.transporterForm.get('pincode');
  }

  get city() {
    return this.transporterForm.get('city');
  }

  get transporterPhoneNumber() {
    return this.transporterForm.get('transporterPhoneNumber');
  }

  get state() {
    return this.transporterForm.get('state');
  }

  get country() {
    return this.transporterForm.get('country');
  }

  get contactPersonName() {
    return this.transporterForm.get('contactPersonName');
  }

  get contactPersonEmailId() {
    return this.transporterForm.get('contactPersonEmailId');
  }

  get transporterType() {
    return this.transporterForm.get('transporterType');
  }

  intializeForm() {
    this.transporterForm = this.formBuilder.group({
      'transporterName': [null, [
        Validators.required,
        Validators.pattern("^[A-Za-z0-9- ]+$"),
        ValidatorService.whitespaceValidator
      ]],
      'transporterAddress': [null,
        [
          Validators.required,
          ValidatorService.whitespaceValidator
        ]],
      'transporterPhoneNumber': [null, [
        Validators.required,
        Validators.minLength(10),
        Validators.pattern('^[0-9]+$')
      ]],
      'contactPersonName': [null, [
        Validators.pattern("^[a-zA-Z ]+$"),
        ValidatorService.whitespaceValidator
      ]],
      'contactPersonPhoneNumber': [null, [
        Validators.pattern('^[0-9]*$'),
        Validators.minLength(10),
        Validators.maxLength(10)
      ]],
      'city': [null, [Validators.required,
      Validators.pattern("^[a-zA-Z]+$"),
      ValidatorService.whitespaceValidator]],
      'pincode': [null,
        [
          Validators.pattern("^[0-9]{6,10}$")
        ]],
      'state': [null,
        [Validators.pattern("^[a-zA-Z]+$"),
        ValidatorService.whitespaceValidator
        ]],
      'country': [null,
        [
          Validators.pattern("^[a-zA-Z]+$"),
          ValidatorService.whitespaceValidator
        ]],
      'bIsActive': [null],
      'branches': [null, [Validators.required]],
      'contactPersonEmailId': [null, [Validators.email]],
      'creationDateTime': [null],
      'transporterId': [null],
      'updatedByusername': [null],
      'updatedDateTime': [null],
      'transporterCode': [null,
        [
          Validators.pattern("^[a-zA-Z0-9]+$")
        ]],
      'transporterType': [null]
    });
  }

  getTransporterTypes() {
    this.transporterService.getAllTransporterType().subscribe(types => {
      this.tranporterTypes = types;
    })
  }
}