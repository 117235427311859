import { Component, OnInit, Inject } from '@angular/core';
import { trigger, style, transition, animate } from '@angular/animations';
import { MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource, MatDialog } from '@angular/material';
import { NotifierService } from 'angular-notifier';
import { ImagePopup } from '../../order/allorderdetails/allorderdetails.component';
import { BillingService } from 'src/app/services/billing/billing.service';
import { OrderService } from 'src/app/services/order/order.service';
import { Charge, Bill, BillDetail, BillDocument } from '../billing.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { UploadfileService } from 'src/app/services/uploadfile/uploadfile.service';
import { TokenStorage } from 'src/app/auth/token.storage';
import { initChangeDetectorIfExisting } from '@angular/core/src/render3/instructions';

@Component({
  selector: 'app-billdetails',
  templateUrl: './billdetails.component.html',
  styleUrls: ['./billdetails.component.scss'],
  animations: [
    trigger('items', [
      transition(':enter', [
        style({ transform: 'scale(0.5)', opacity: 0 }),  // initial
        animate('0.75s cubic-bezier(.8, -0.6, 0.2, 1.5)',
          style({ transform: 'scale(1)', opacity: 1 }))  // final
      ]),
      transition(':leave', [
        style({ transform: 'scale(1)', opacity: 1, height: '*' }),
        animate('1s cubic-bezier(.8, -0.6, 0.2, 1.5)',
          style({
            transform: 'scale(0.5)', opacity: 0,
            height: '0px', margin: '0px'
          }))
      ])
    ])
  ],
})
export class BilldetailsComponent implements OnInit {


  columnsToDisplay = [
    'date',
    'route',
    'vehicle',
    'transporter',
    'cases',
    'weightVolume'
  ];

  charges: Charge[] = []

  addingNewCharge: boolean = false;
  selectNewCharge: boolean = false;

  billState: string;
  userRole: string;
  isTransporter: boolean = false;
  billAudits: any;

  enableApprove: boolean;
  enableSubmit: boolean;
  buttonText: string;
  volUnit: string;
  isChargeEdited: boolean = false;
  chargeInitalArray = [];
  isSuhana: boolean = false;
  constructor(
    private dialogR: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: Bill,
    private notifierService: NotifierService,
    private dialog: MatDialog,
    private billingService: BillingService,
    private orderService: OrderService,
    private spinnerService: NgxSpinnerService,
    private s3UploadService: UploadfileService,
    private token: TokenStorage,
  ) {
    if (this.token.getTenant() == 'kimberly-dist-prod') {
      this.volUnit = 'CM';
    } else if (this.token.getTenant() == 'godrej-dist-prod') {
      this.volUnit = 'CCM';
    } else {
      this.volUnit = 'CF';
    }
  }

  ngOnInit() {
    this.charges = this.getInitalBillCosts();
    this.chargeInitalArray = this.getInitalBillCosts();
    this.userRole = window.sessionStorage.getItem('permissions').substr(2).slice(0, -2);
    if (this.userRole == 'transporter') {
      this.isTransporter = true;
    }

    if (this.token.getTenant() == 'suhana-dist-test' || this.token.getTenant() == 'suhana-dist-prod') {
      this.isSuhana = true;
    }   
    console.log(this.data);
    this.userRole = window.sessionStorage.getItem('permissions').substr(2).slice(0, -2);

    if (this.data.bState == 'BILL_STATE_IN_SC_REVIEW') {
      if (this.userRole == 'transporter') {
        this.enableSubmit = false;
        this.enableApprove = false;
        this.buttonText = "Submit For SC Review";
      } else {
        this.buttonText = "Submit For Transporter Review";
        this.enableSubmit = true;
        if (this.data.podCompletionPercent == 100) {
          this.enableApprove = true;
        }
      }
      this.billState = "In SC Review";
    } else if (this.data.bState == 'BILL_STATE_IN_TRANSPORTER_REVIEW') {
      if (this.userRole == 'transporter') {
        if (this.data.podCompletionPercent == 100) {
          this.enableApprove = true;
          this.enableSubmit = true;
        }
      } else {
        this.enableSubmit = false;
        this.enableApprove = false;
      }
      this.billState = "In Transporter Review";
      this.buttonText = "Submit For SC Review";
    } else if (this.data.bState == 'BILL_STATE_PENDING_SC_APPROVAL') {
      if (this.userRole == 'transporter') {
        this.enableApprove = false;
        this.enableSubmit = false;
      } else {
        this.enableSubmit = true;
        if (this.data.podCompletionPercent == 100) {
          this.enableApprove = true;
        }
      }
      this.billState = "Pending SC Approval";
      this.buttonText = "Submit For Transporter Review";
    } else if (this.data.bState == 'BILL_STATE_TRANSPORTER_APPROVED') {
      if (this.userRole == 'transporter') {
        this.enableApprove = false;
        this.enableSubmit = false;
      } else {
        this.enableSubmit = true;
        if (this.data.podCompletionPercent == 100) {
          this.enableApprove = true;
        }
      }
      this.billState = "Transporter Approved";
      this.buttonText = "Submit For Transporter Review";
    }
    else if (this.data.bState == 'BILL_STATE_SC_APPROVED') {
      this.enableApprove = false;
      this.enableSubmit = false;
      this.billState = "Approved";
      this.buttonText = "Submit For Review";
    } else if (this.data.bState == 'BILL_STATE_INITIAL') {
      if (this.userRole == 'transporter') {
        if (this.data.podCompletionPercent == 100) {
          this.enableApprove = true;
          this.enableSubmit = true;
        }
      } else {
        this.enableSubmit = false;
        this.enableApprove = false;
      }
      this.billState = "New";
      this.buttonText = "Submit For SC Review";
    }

    else if (this.data.bState == 'BILL_STATE_RAISED') {
      this.enableApprove = false;
      this.enableSubmit = false;
      this.billState = "Bill Raised";
    }

    this.data.billDetails.forEach(detail => {
      detail.pods = new Array<BillDocument>();
      detail.documents = new Array<BillDocument>();
      detail.pods.push({
        src: null,
        isPresent: false,
        key: 1,
        url: null,
        extension: null
      });

      detail.pods.push({
        src: null,
        isPresent: false,
        key: 2,
        url: null,
        extension: null
      });

      detail.documents.push({
        src: null,
        isPresent: false,
        key: 1,
        url: null,
        extension: null
      });

      detail.documents.push({
        src: null,
        isPresent: false,
        key: 2,
        url: null,
        extension: null
      });
      this.getPODImageLinks(detail);
    });

    this.s3UploadService.event.subscribe(res => {
      this.spinnerService.hide();
    });
  }

  getInitalBillCosts() {
    return [
      {
        name: "Freight",
        value: this.data.billCosts.freightRate || 0,
        isInvalid: false
      },
      {
        name: "Clubbing",
        value: this.data.billCosts.clubbingCharges || 0,
        isInvalid: false
      },
      {
        name: "Unloading",
        value: this.data.billCosts.unloadingCharges || 0,
        isInvalid: false
      },
      {
        name: "Detention",
        value: this.data.billCosts.detentionCharges || 0,
        isInvalid: false
      },
      {
        name: "Others",
        value: this.data.billCosts.otherCharges1 || 0,
        isInvalid: false
      },
      {
        name: "Total",
        value: this.data.billCosts.totalCharges || 0,
        isInvalid: false
      }
    ];
  }

  getPODImageLinks(billDetail: BillDetail) {
    this.orderService.podImageData(billDetail.orderRef).subscribe(presignedUrls => {
      if (presignedUrls) {
        for (let i = 0; i < billDetail.pods.length; i++) {
          if (presignedUrls[i]) {

            billDetail.pods[i].isPresent = true;
            billDetail.pods[i].src = presignedUrls[i].url;
            billDetail.pods[i].url = presignedUrls[i].url;
            if (billDetail.pods[i].src.includes('.pdf'))
              billDetail.pods[i].extension = 'pdf';
            else
              billDetail.pods[i].extension = 'jpg';

          }
        }
      }
    });
  }

  openDocument(event) {

  }

  change(changeFor, event) {
    if (changeFor == 'customerBillNumber')
      this.data.customerBillNumber = event.target.value;
    else if (changeFor == 'remarks')
      this.data.remarks = event.target.value;
  }

  addNewCharge() {
    this.selectNewCharge = true;
  }

  chargeTypeSelected(event) {
    this.selectNewCharge = false;
    this.addingNewCharge = false;
    let temp: Charge = {
      name: event.target.value,
      value: 0,
      isInvalid: false
    }
    this.charges.splice(this.charges.length - 1, 0, temp);
  }

  chargeEdited(value: string, index: number) {
    let inputVal = Number(value);
    if (inputVal < 0 || isNaN(inputVal) || inputVal > 999999999999 || !value.toString().match(('^[0-9]{1,15}(,[0-9]{10})*(([\\.,]{1}[0-9]{1,3})|())$'))) {
      this.charges[index].isInvalid = true;
      this.enableApprove = false;
      this.enableSubmit = false;
      this.isChargeEdited = true;
    } else {
      //this is because if transporter edited charge and its invalid approve is false it does not get true even if toll/miscellious charge is valid
      this.charges[index].isInvalid = false;

      //isChargeEdited is to check whether to call save charge api or not
      //for SC both button enabled 
      if (!this.isTransporter && this.chargeInitalArray[index] && this.chargeInitalArray[index].value == inputVal) {
        this.isChargeEdited = false;
        this.enableApprove = true;
        this.enableSubmit = true;
      }

      else if (!this.isTransporter && this.chargeInitalArray[index] && this.chargeInitalArray[index].value != inputVal) {
        this.isChargeEdited = true;
        this.enableApprove = true;
        this.enableSubmit = true;
      }

      //for transporter both button enabled if no change in rates ow only submit button enabled
      else if (this.isTransporter && this.chargeInitalArray[index] && this.chargeInitalArray[index].value == inputVal) {
        this.enableApprove = true;
        this.enableSubmit = true;
        this.isChargeEdited = false;
      }

      //if charges are new toll or Miscellaneous and if other charges are already updated then approve should be disabled
      else if (this.isTransporter && this.charges[index].name == "Toll" || this.isTransporter && this.charges[index].name == "Miscellaneous") {
        let isValueEdited = this.checkIfValueEdited();
        if (isValueEdited)
          this.enableApprove = false;
        else
          this.enableApprove = true
        this.enableSubmit = true;
        this.isChargeEdited = true;
      }


      else if (this.isTransporter && this.chargeInitalArray[index] && this.chargeInitalArray[index].value != inputVal) {
        this.enableApprove = false;
        this.enableSubmit = true;
        this.isChargeEdited = true;
      }

      let temp = 0;
      this.charges[index].value = inputVal;
      this.charges.forEach(charge => {
        if (charge.name != 'Total') {
          temp += charge.value;
        }
      });
      this.charges[this.charges.length - 1].value = temp;
    }

  }

  //check if initial charges array is same as edited charges array
  checkIfValueEdited() {
    for (let i = 0; i < this.chargeInitalArray.length; i++) {
      for (let j = 0; j < this.charges.length; j++) {
        if (this.chargeInitalArray[i].value != this.charges[j].value && this.chargeInitalArray[i].name == this.charges[j].name && (this.charges[j].name != "Toll" || this.charges[j].name != "Miscellaneous") && this.charges[j].name != "Total") {
          return true;
        }
      }
    }
    return false;
  }

  discardNewCharge() {
    if (this.addingNewCharge) {
      this.charges.splice(this.charges.length - 2, 1);
    }
    this.addingNewCharge = false;
    this.selectNewCharge = false;
  }

  async handleFiles(event, billDetailIndex: number, podIndex: number) {

    let file = event.target.files[0];

    this.spinnerService.show();
    await this.s3UploadService.uploadImage(file, this.data.billDetails[billDetailIndex].jobRef);
    this.spinnerService.hide();

    this.data.billDetails[billDetailIndex].pods[podIndex].isPresent = true;

    let reader = new FileReader();

    reader.onloadend = () => {
      this.data.billDetails[billDetailIndex].pods[podIndex].url = reader.result.toString();
    }

    if (file) {
      reader.readAsDataURL(file);
    } else {
      this.data.billDetails[billDetailIndex].pods[podIndex].url = "";
    }
  }

  close() {
    if (this.dialogR) {
      this.dialogR.close();
    }
  }

  submitForReview() {
    if (this.isChargeEdited) {
      let invalid = false;
      this.charges.forEach(charge => {
        if (charge.isInvalid) {
          invalid = true;
        }
        if (charge.name == "Freight") {
          this.data.billCosts.freightRate = charge.value;
        }
        if (charge.name == "Clubbing") {
          this.data.billCosts.clubbingCharges = charge.value;
        }
        if (charge.name == "Unloading") {
          this.data.billCosts.unloadingCharges = charge.value;
        }
        if (charge.name == "Detention") {
          this.data.billCosts.detentionCharges = charge.value;
        }
        if (charge.name == "Others") {
          this.data.billCosts.otherCharges1 = charge.value;
        }
        if (charge.name == "Freight") {
          this.data.billCosts.freightRate = charge.value;
        }
        if (charge.name == "Total") {
          this.data.billCosts.totalCharges = charge.value;
        }
        if (charge.name == "Toll") {
          this.data.billCosts.tollCharges = charge.value;
        }
        if (charge.name == "Miscellaneous") {
          this.data.billCosts.otherCharges2 = charge.value;
        }
      });

      if (!invalid) {
        console.log(this.data)
        this.billingService.saveBill(false, [this.data]).subscribe(() => {
          console.log([this.data])
          this.spinnerService.show();
          this.billingService.saveBill(true, [this.data]).subscribe(res => {
            console.log(res);
            this.spinnerService.hide();
            this.notifierService.notify('success', 'Bill Submitted Successfully.');
            if (this.dialogR) {
              this.dialogR.close({ action: "approve" });
            }
          }, () => {
            this.spinnerService.hide();
            this.notifierService.notify('error', 'Error Submitted Bill.');
          });
        }, Error => {
          this.spinnerService.hide();
          console.log(Error)
        });
      }
    }
    else {
      console.log([this.data])
      this.spinnerService.show();
      this.billingService.saveBill(false, [this.data]).subscribe(res => {
        console.log(res);
        this.spinnerService.hide();
        this.notifierService.notify('success', 'Bill Submitted Successfully.');
        if (this.dialogR) {
          this.dialogR.close({ action: "approve" });
        }
      }, () => {
        this.spinnerService.hide();
        this.notifierService.notify('error', 'Error Submitted Bill.');
      });
    }
  }

  openImage(imageData, selectedIndex) {
    console.log(imageData);
    const dialogR = this.dialog.open(ImagePopup, {

      data: {
        imageData: imageData,
        selectedIndex: selectedIndex
      },
      width: "50%"
    });
    dialogR.updatePosition({
      top: '50px'
    });
  }

  approveOrRaiseBill() {
    if (this.isChargeEdited) {
      let invalid = false;
      this.charges.forEach(charge => {
        if (charge.isInvalid) {
          invalid = true;
        }
        if (charge.name == "Freight") {
          this.data.billCosts.freightRate = charge.value;
        }
        if (charge.name == "Clubbing") {
          this.data.billCosts.clubbingCharges = charge.value;
        }
        if (charge.name == "Unloading") {
          this.data.billCosts.unloadingCharges = charge.value;
        }
        if (charge.name == "Detention") {
          this.data.billCosts.detentionCharges = charge.value;
        }
        if (charge.name == "Others") {
          this.data.billCosts.otherCharges1 = charge.value;
        }
        if (charge.name == "Freight") {
          this.data.billCosts.freightRate = charge.value;
        }
        if (charge.name == "Total") {
          this.data.billCosts.totalCharges = charge.value;
        }
        if (charge.name == "Toll") {
          this.data.billCosts.tollCharges = charge.value;
        }
        if (charge.name == "Miscellaneous") {
          this.data.billCosts.otherCharges2 = charge.value;
        }
      });

      if (!invalid) {
        this.billingService.saveBill(true, [this.data]).subscribe(() => {
          console.log([this.data])
          this.spinnerService.show();
          this.billingService.saveBill(true, [this.data]).subscribe(res => {
            console.log(res);
            this.spinnerService.hide();
            this.notifierService.notify('success', 'Bill Approved Successfully.');
            if (this.dialogR) {
              this.dialogR.close({ action: "approve" });
            }
          }, () => {
            this.spinnerService.hide();
            this.notifierService.notify('error', 'Error Approving Bill.');
          });
        }, Error => {
          this.spinnerService.hide();
          console.log(Error);
        });
      }
    }
    else {
      console.log([this.data])
      this.spinnerService.show();
      this.billingService.saveBill(true, [this.data]).subscribe(res => {
        console.log(res);
        this.spinnerService.hide();
        this.notifierService.notify('success', 'Bill Approved Successfully.');
        if (this.dialogR) {
          this.dialogR.close({ action: "approve" });
        }
      }, () => {
        this.spinnerService.hide();
        this.notifierService.notify('error', 'Error Approving Bill.');
      });
    }
  }

  fetchBillAudits() {
    this.billingService.getBillAudits(this.data.billId).subscribe(audits => {
      console.log(audits);
      if (audits) {
        this.billAudits = audits;
        this.billAudits = this.billAudits.reverse();
      } else
        if (this.data.bState == "BILL_STATE_INITIAL") {
          let billInitial = {
            createdDateTime: this.data.createdDateTime,
            updatedByusername: this.data.updatedByusername,
            updatedState: "BILL_STATE_NEW"
          }
          this.billAudits = [billInitial];
        }
        else {
          this.billAudits = [];
        }
    }, () => {
      this.billAudits = [];
    });
  }
}

