import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pendingbills',
  templateUrl: './pendingbills.component.html',
  styleUrls: ['./pendingbills.component.scss']
})
export class PendingbillsComponent implements OnInit {

  constructor() {}

  ngOnInit() {}
}
