import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import {
  MatPaginator, MatTableDataSource,
  MatDialog, MatDialogRef, MAT_DIALOG_DATA, AUTOCOMPLETE_OPTION_HEIGHT
} from '@angular/material';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import { DataSource } from '@angular/cdk/collections';
import { OrderService } from '../../../services/order/order.service';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/compiler/src/core';
import { TripService } from '../../../services/trip/trip.service';
import { GenericDialogBox } from '../edittripmodal/edittripmodal.component';
import { MessagesService } from '../../../services/messages/messages.service';
import { Router } from '@angular/router';
import { TokenStorage } from 'src/app/auth/token.storage';




@Component({
  selector: 'app-scheduletripmodal',
  templateUrl: './scheduletripmodal.component.html',
  styleUrls: ['./scheduletripmodal.component.scss']
})
export class ScheduletripmodalComponent implements OnInit {
  public lat: Number = 18.552307
  public lng: Number = 73.724519

  public origin: {};
  public destination: {};
  public zoom: number = 13;
  public waypoints = [];
  tripName: string;

  packageDetailsColumnDisplay = [
    'jobId',
    'receiverDetails',
    'numOfBoxes',
    'numberOfOrders',
  ];
  jobDetailsArray = new MatTableDataSource;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  tripId: number;
  ordersByTripIdArray = [];
  productArrayInSelectedOrder = [];
  productInputDisplayArray = []

  tripStatus: string;
  destinationAddress: string;
  originAddress: string;
  zone: string;
  totalNumberOfOrders: string;
  tripDate: string;
  deliveryEstDate: string;
  inScheduled: boolean = false;
  inTransit: boolean = false;
  completed: boolean = false;
  vehicleLicenseNumber: string;
  vehicleType: string;
  tripStartTime: string;
  tripStopTime: string;
  driverName: string;
  mobileNumber: string;
  receiverDetailsArray = [];
  ispackageDetails: boolean = false;
  totalvolumecapacity: number;
  totalweightcapacity: number;
  transporter: string;
  volUnit: string;



  //invoiceNumber: string;
  // pdfLink: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public tripService: TripService,
    public orderService: OrderService,
    public http: HttpClient,
    public dialog: MatDialog,
    public messagesService: MessagesService,
    public router: Router,
    private token: TokenStorage,

  ) {
    console.log(this.data);
    if (this.token.getTenant() == 'kimberly-dist-prod') {
      this.volUnit = 'CM';
    } else if (this.token.getTenant() == 'godrej-dist-prod') {
      this.volUnit = 'CCM';
    } else {
      this.volUnit = 'CF';
    }
  }

  printDeliveryData(printOption) {
    console.log(printOption);
    let pdfLink = "";
    let invoiceNumber = "";
    console.log("in print");
    console.log(this.ordersByTripIdArray);
    for (let i = 0; i < this.ordersByTripIdArray.length; i++) {
      invoiceNumber = this.ordersByTripIdArray[i].orderInvoiceNumber;
      if (printOption === 'Print Delivery') {
        pdfLink = "http://116.72.253.118:9890/print/delnoteprint?invoiceID=".concat(invoiceNumber);
      }
      else if (printOption === 'Print Invoice') {
        pdfLink = "http://116.72.253.118:9890/print/invoiceprint?invoiceID=".concat(invoiceNumber);
      }
      window.open(pdfLink, "_blank");
    }
  }

  ngOnInit() {
    this.totalvolumecapacity = this.data.data.totalVolume;
    this.totalweightcapacity = this.data.data.totalWeight;
    this.vehicleType = this.data.data.vehicleType;
    this.tripId = this.data.data.tripId;
    this.tripName = this.data.data.tripName;
    this.transporter = this.data.data.transporterName;
    this.driverName = this.data.data.assignedDriverName;
    this.vehicleLicenseNumber = this.data.data.assignedVehicleLicenseNumber;
    this.mobileNumber = this.data.data.assignedDriverPhoneNumber;
    //this.tripStatus = (this.data.data.tState).slice(11);
    this.destinationAddress = this.data.data.destinationAddress;
    this.originAddress = this.data.data.originAddress;
    this.zone = this.data.data.tripZone;
    this.totalNumberOfOrders = this.data.data.totalNumberOfOrders;
    this.tripDate = this.data.data.tripCreatedDateTime;
    this.deliveryEstDate = this.data.data.tripPlannedEndDateTime;

    if (this.data.data.tState === "TRIP_STATE_SCHEDULED") {
      this.inScheduled = true;
    } else if (this.data.data.tState === "TRIP_STATE_IN_TRANSIT") {
      this.inTransit = true;
      this.tripStartTime = this.data.data.tripActualStartDateTime;
    } else if (this.data.data.tState === "TRIP_STATE_COMPLETED") {
      this.completed = true;
      this.tripStartTime = this.data.data.tripActualStartDateTime;
      this.tripStopTime = this.data.datatripActualEndDateTime;
    }
    else {

    }

    if (this.inScheduled || this.inTransit || this.completed) {
      if (this.data.data.driver != null) {
        this.vehicleLicenseNumber = this.data.data.fleet.vehicleLicenseNumber;
        this.vehicleType = this.data.data.vehicleType;
        this.driverName = this.data.data.driver.firstName + " " + this.data.data.driver.lastName;
        this.mobileNumber = this.data.data.driver.phoneNumber1;
      }
    }

    this.getDirection();
    this.zoom = 13;
    this.waypoints = [
      {
        location: "18.532667,73.8276983"
      },
      {
        location: "18.533654,73.8235143"
      },
      {
        location: "18.523258, 73.762949"
      }
    ];

    this.jobDetailsArray = this.data.data.jobs;

  }

  getDirection() {
    this.origin = { lat: 24.799448, lng: 120.979021 }
    this.destination = { lat: 24.799524, lng: 120.975017 }

    // this.origin = 'Taipei Main Station'
    // this.destination = 'Taiwan Presidential Office'
  }
  getAllProductsFromOrdersInTrip() {
    if (this.ordersByTripIdArray != null) {
      for (let i = 0; i < this.ordersByTripIdArray.length; i++) {
        console.log(this.ordersByTripIdArray);
        console.log(this.ordersByTripIdArray[i].orderUniqueId);

        console.log(this.receiverDetailsArray);
        this.orderService.getAllProductsInOrder(this.ordersByTripIdArray[i].orderUniqueId).subscribe(res => {
          console.log(res);

          for (let j = 0; j < res.length; j++) {
            this.ordersByTripIdArray[i].receiverName = this.nullCheck(this.ordersByTripIdArray[i].receiverName);
            this.ordersByTripIdArray[i].destinationAddress.houseNumber = this.nullCheck(this.ordersByTripIdArray[i].destinationAddress.houseNumber);
            this.ordersByTripIdArray[i].destinationAddress.area = this.nullCheck(this.ordersByTripIdArray[i].destinationAddress.area);
            this.ordersByTripIdArray[i].destinationAddress.city = this.nullCheck(this.ordersByTripIdArray[i].destinationAddress.city);
            this.ordersByTripIdArray[i].destinationAddress.landmark = this.nullCheck(this.ordersByTripIdArray[i].destinationAddress.landmark);
            this.ordersByTripIdArray[i].destinationAddress.pincode = this.nullCheck(this.ordersByTripIdArray[i].destinationAddress.pincode);
            res[j].product.productName = this.nullCheck(res[j].product.productName);
            if (res[j].product.productName === "")
              this.ispackageDetails = true;
            this.productArrayInSelectedOrder.push({
              invoiceNo: this.ordersByTripIdArray[i].orderInvoiceNumber,
              receiverDetails: this.ordersByTripIdArray[i].receiverName + "  " + this.ordersByTripIdArray[i].destinationAddress.houseNumber + "  " +
                this.ordersByTripIdArray[i].destinationAddress.area + "  " + this.ordersByTripIdArray[i].destinationAddress.city + "  " +
                this.ordersByTripIdArray[i].destinationAddress.landmark + "  " +
                this.ordersByTripIdArray[i].destinationAddress.pincode,
              packageDetails: res[j].product.productName,
              orderState: this.ordersByTripIdArray[i].oState.slice(12),
              deliverytime: this.ordersByTripIdArray[i].actualDeliveryDatetime
            })

            this.jobDetailsArray.data = this.productArrayInSelectedOrder;
            console.log('products', this.productArrayInSelectedOrder);
          }
        }, Error => {
          console.log(Error);
        })
      }
    }
  }

  nullCheck(field) {
    if (!field)
      return "";
    return field;
  }

  ngAfterViewInit() {
    console.log("afterViewIni");
  }

  openDialog() {
    const dialogR = this.dialog.open(GenericDialogBox, {
      width: '300px',
      data: 'Are you sure you want to delete this trip ' + this.data.data.tripName + '?'
    });

    dialogR.afterClosed().subscribe(result => {
      if (result) {
        this.dialog.closeAll();
        this.DeleteTrip();
      }
    });
  }
  DeleteTrip() {
    console.log("Delete pressed " + this.data.data.tripId);
    this.tripService.deleteTrip(this.data.data.tripId,"").subscribe(res => {
      console.log(res);
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/scheduling/scheduletrips']);
      });
      this.messagesService.sendMessage("Trip # " + this.tripName + "deleted succefully")
    }, Error => {
      console.log(Error);
    })
  }
  tabChanged(Event) {
    console.log(Event.index);
  }
}