import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-uploadmaterialcategoryexcel',
  templateUrl: './uploadmaterialcategoryexcel.component.html',
  styleUrls: ['./uploadmaterialcategoryexcel.component.scss']
})
export class UploadmaterialcategoryexcelComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
