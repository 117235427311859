import { Component, OnInit } from '@angular/core';
import { UsersService } from '../services/users/users.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  username: string;
  firstname: string;
  lastname: string;
  password: string;
  confirmpassword: string;
  email: string;
  mobile: number;

  constructor(private userservice: UsersService) { }

  ngOnInit() {
  }


  newRegister() {
    let registerData = {
      username: this.username,
      firstname: this.firstname,
      lastname: this.lastname,
      email: this.email,
      phoneNumber: this.mobile,
      password: this.password
    };

    console.log(registerData);

    this.userservice.newRegistration(registerData).subscribe(res => {
      console.log(res);
    }, Error => {
      console.log('Error' + Error);
    });


  }

}
