import { Injectable } from '@angular/core';
import { Http, Headers, URLSearchParams, Response, HttpModule, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MaterialcategoryService {
  base_url: string;
  constructor(
    private http: HttpClient,
  ) {
    this.base_url = environment.appUrl + "/materialcategories";
  }
  addMaterialCategory(materialcategoryObj): Observable<any> {
    return this.http.post(this.base_url, materialcategoryObj);
  }

  updateMaterialCategory(materialcategoryObj): Observable<any> {
    return this.http.put(this.base_url, materialcategoryObj);
  }
  
  getAllMaterialCategories(): Observable<any>{
    return this.http.get(this.base_url);
  }

  
  getMaterialCategoryGroupsByBranchId(branchId): Observable<any>{
    return this.http.get(environment.appUrl + '/materialcategorygroups/constraints/'+branchId);
  }

  getMaterialCategoriesByBranchId(branchId): Observable<any>{
    return this.http.get(this.base_url + '/constraints/'+branchId);
  }

  getMaterialCategoryConstraintsByBranchIdAndMaterialCategoryGroup(branchId,materialCategoryGroup): Observable<any>{
    return this.http.get(this.base_url + '/constraints/'+branchId+'/'+materialCategoryGroup);
  }

  getMaterialCategoryConstraintsDetails(): Observable<any>{
    return this.http.get(environment.appUrl + '/constraints/materialcategories');
  }
}