// import { Component, OnInit, ViewChild } from '@angular/core';
// import { MatPaginator, MatTableDataSource, MatSort, Sort } from '@angular/material';
// import { MatDialog } from '@angular/material/dialog';
// import { EdituserComponent } from '../edituser/edituser.component';
// import { UsersService } from '../../../services/users/users.service';
// import { Subscription, Subject } from 'rxjs';
// import { BranchService } from 'src/app/services/branch/branch.service';
// import { ExcelgeneratorService } from 'src/app/services/excelgenerator/excelgenerator.service';
// import { catchError, takeUntil } from 'rxjs/operators';
// @Component({
//   selector: 'app-alluserrole',
//   templateUrl: './alluserrole.component.html',
//   styleUrls: ['./alluserrole.component.scss']
// })
// export class AlluserroleComponent implements OnInit , OnDestroy {
//   showFiller = false;
//   isLoading: boolean = false;
//   excelRecords = [];

//   columnsToDisplay = [
//     'username',
//     'firstname',
//     'lastname',
//     'userRole',
//     'userOf',
//     'associatedUnit',
//     'designation',
//     'userSince',
//     'userActive',
//     'actions'
//   ];
//   usersDataArray = new MatTableDataSource<any>();
//   branchesSubscription: Subscription;
//   protected ngUnsubscribe: Subject<void> = new Subject<void>()
//   @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
//   @ViewChild(MatSort) sort: MatSort;

//   constructor(
//     public dialog: MatDialog,
//     private userService: UsersService,
//     private branchService: BranchService,
//     private excelService: ExcelgeneratorService,
    
//   ) { }

//   // Arjun Start Commented 
//   // ngOnInit() {
//   //   this.isLoading = true;
//   //   this.branchesSubscription = this.branchService.branchesObservable.subscribe(() => {
//   //     this.ngUnsubscribe.next();
//   //     if(window.sessionStorage.getItem("selectedBranchesCodes") && JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")).length == 0){
//   //       this.usersDataArray.data = [];
//   //       this.isLoading = false;
//   //     }
//   //     else{
//   //     this.usersDataArray.data = [];
//   //     this.isLoading = true;
//   //     this.userService.getAllUsers().pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
//   //       this.isLoading = false;
//   //       debugger
//   //       console.log("allusers", res);
//   //       this.usersDataArray.data = res.reverse();
//   //       this.usersDataArray.paginator = this.paginator;
//   //       debugger
//   //     }, Error => {
//   //       this.isLoading = false;
//   //       console.log(Error);
//   //     });
//   //   }
//   //   });

//   //   this.usersDataArray.sortingDataAccessor = (item: any, property) => {
//   //     switch (property) {
//   //       case 'username': return item.username;
//   //       case 'firstname': return item.resource.firstName;
//   //       case 'lastname':
//   //         if (item.resource.lastName) {
//   //           return item.resource.lastName;
//   //         }
//   //         else return;
//   //       case 'designation':
//   //         if (item.resource.designation) {
//   //           return item.resource.designation.resourceDesignation;
//   //         }
//   //         else return;
//   //     }
//   //   };
//   // this.usersDataArray.sort = this.sort;
//   // }
//   // Arjun End Commented 
//   // Arjun Start Add
//   ngOnInit() {
//     this.isLoading = true;
//     this.branchesSubscription = this.branchService.branchesObservable.subscribe(() => {
//       this.ngUnsubscribe.next();
//       if (window.sessionStorage.getItem("selectedBranchesCodes") && JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")).length == 0) {
//         this.usersDataArray.data = [];
//         this.isLoading = false;
//       } else {
//         this.usersDataArray.data = [];
//         this.isLoading = true;
//         this.userService.getAllUsers(0,10).pipe(
//           takeUntil(this.ngUnsubscribe),
//           catchError(error => {
//             this.isLoading = false;
//             console.log("Error fetching users:", error);
//             // Handle error here
//             return [];
//           })
//         ).subscribe((res: any) => {
//           this.isLoading = false;
//           console.log("allusers", res);
//           if (!res || !Array.isArray(res.content)) {
//             console.error("Returned data format is unexpected:", res);
//             this.usersDataArray.data = [];
//             return;
//           }
//           this.usersDataArray.data = res.content.reverse();
//           this.usersDataArray.paginator = this.paginator;
//         });
//       }
//     });
  
//     // Sorting accessor remains unchanged
//     this.usersDataArray.sortingDataAccessor = (item: any, property) => {
//       switch (property) {
//         case 'username': return item.username;
//         case 'firstname': return item.resource.firstName;
//         case 'lastname':
//           if (item.resource.lastName) {
//             return item.resource.lastName;
//           } else return;
//         case 'designation':
//           if (item.resource.designation) {
//             return item.resource.designation.resourceDesignation;
//           } else return;
//       }
//     };
  
//     this.usersDataArray.sort = this.sort;
//   }
  
   
//   onChange(Event, userid) {
//     console.log(Event.checked, userid);
//     if (Event.checked) {
//       this.userService.activateUser(userid, true).subscribe(res => {
//         console.log(res);
//       }, Error => {
//         console.log(Error);
//       });
//     } else {
//       this.userService.activateUser(userid, false).subscribe(res => {
//         console.log(res);
//       }, Error => {
//         console.log(Error);
//       });
//     }
//   }

//   applyFilter(filterValue: string) {
//     this.usersDataArray.filter = filterValue.trim().toLowerCase();
//     this.usersDataArray.filterPredicate = (data: any, filter: string) => {
//       if (data) {
//         return data.username.toString().toLowerCase().indexOf(filter) != -1 ||
//           data.resource.firstName.toString().toLowerCase().indexOf(filter) != -1 ||
//          ( data.resource.lastName && data.resource.lastName.toString().toLowerCase().indexOf(filter) != -1)
//       }
//     };
//   }

//   editUser(userDetail) {
//     let editDialog = this.dialog.open(EdituserComponent, {
//       width: '800px',
//       data: {
//         user: userDetail
//       },
//       id: 'editUserWindow'
//     });
//     editDialog.updatePosition({
//       top: '50px',
//     });
//     editDialog.afterClosed().subscribe(result => {
//       console.log(result);
//       if(result){
//       this.ngOnInit();
//       }
//     });
//   }
//   ngOnDestroy() {
//     this.branchesSubscription.unsubscribe();
//   }

//   //Added by Digvijay
//   exportAsXLSX() {
//     this.excelRecords = [];
//     let usersData = [];
//     usersData = this.usersDataArray.data;
//     usersData.forEach(user => {
//       let firstName, lastName, address, role="", resource, designation;

//       firstName = user.resource.firstName ? user.resource.firstName : "";
//       lastName = user.resource.lastName ? user.resource.lastName : "";
//       address = user.resource.address ? user.resource.address : "";
//       user.roles.forEach(userRole => {
//         role += userRole.roleName + ", ";
//       });
//       role = role.substring(0, role.length-2);
//       resource = user.resourceName ? user.resourceName : "";
//       designation = user.resource.designation? user.resource.designation.resourceDesignation : "";

//       user.branches.forEach(branch=> {
//         if(user){
//           this.excelRecords.push({
//             'Username': user.username,
//             'First Name': firstName,
//             'Last Name': lastName,
//             'Roles' : role,
//             'Branches': (branch.branchName && branch.branchCode) ? branch.branchName + " - " + branch.branchCode : branch.branchName,
//             'Designation': designation,
//             'User Of': resource,
//             'Active' : user.bIsActive,
//             'Address': address            
//           })
//         }
//       });
//     });
//     this.excelService.exportAsExcelFile(this.excelRecords, 'userrecords');
//   }
// //Digvijay End
// }

// export interface UsertListElement {
//   userName: string,
//   firstname: string,
//   lastname: string,
//   userRole: string,
//   email: string,
//   registrationDate: string,
//   userActive: string,
//   actions: any
// }

//  **********************************
 







// import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
// import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
// import { MatDialog } from '@angular/material/dialog';
// import { EdituserComponent } from '../edituser/edituser.component';
// import { UsersService } from '../../../services/users/users.service';
// import { Subscription, Subject } from 'rxjs';
// import { catchError, takeUntil } from 'rxjs/operators';
// import { BranchService } from 'src/app/services/branch/branch.service';
// import { ExcelgeneratorService } from 'src/app/services/excelgenerator/excelgenerator.service';

// @Component({
//   selector: 'app-alluserrole',
//   templateUrl: './alluserrole.component.html',
//   styleUrls: ['./alluserrole.component.scss']
// })
// export class AlluserroleComponent implements OnInit, OnDestroy {
//   showFiller = false;
//   isLoading: boolean = false;
//   excelRecords = [];
//   columnsToDisplay = [
//     'username',
//     'firstname',
//     'lastname',
//     'userRole',
//     'userOf',
//     'associatedUnit',
//     'designation',
//     'userSince',
//     'userActive',
//     'actions'
//   ];
//   usersDataArray = new MatTableDataSource<any>();
//   totalRecords = 0;
//   pageSize = 10;
//   currentPage = 0;
//   totalPages = 0;
//   branchesSubscription: Subscription;
//   protected ngUnsubscribe: Subject<void> = new Subject<void>();
//   @ViewChild(MatPaginator) paginator: MatPaginator;
//   @ViewChild(MatSort) sort: MatSort;
//   name: string = ''; // Add this line

//   constructor(
//     public dialog: MatDialog,
//     private userService: UsersService,
//     private branchService: BranchService,
//     private excelService: ExcelgeneratorService,
//   ) { }

//   ngOnInit() {
//     this.isLoading = true;
//     this.loadUsers(this.currentPage); // Load the first page with 10 users
//   }
  
//   getUsers(pageIndex: number, pageSize: number) {
//     this.userService.getUsersPagewise(pageIndex, pageSize).pipe(
//       takeUntil(this.ngUnsubscribe),
//       catchError(error => {
//         this.isLoading = false;
//         console.log('Error fetching users:', error);
//         return [];
//       })
//     ).subscribe((res: any) => {
//       this.isLoading = false;
//       if (!res || !Array.isArray(res.content)) {
//         console.error('Returned data format is unexpected:', res);
//         this.usersDataArray.data = [];
//         return;
//       }
//       this.usersDataArray.data = res.content;
//       this.totalRecords = res.totalElements;
//       this.totalPages = Math.ceil(this.totalRecords / this.pageSize);
//       this.currentPage = pageIndex;
//     });
//   }

//   loadUsers(page: number) {
//     this.isLoading = true;
//     this.userService.getUsersPagewise(page, this.pageSize).pipe(
//       takeUntil(this.ngUnsubscribe),
//       catchError(error => {
//         this.isLoading = false;
//         console.log("Error fetching users:", error);
//         return [];
//       })
//     ).subscribe((res: any) => {
//       this.isLoading = false;
//       console.log("allusers", res);
//       if (!res || !Array.isArray(res.content)) {
//         console.error("Returned data format is unexpected:", res);
//         this.usersDataArray.data = [];
//         return;
//       }
//       this.usersDataArray.data = res.content;
//       this.totalRecords = res.totalElements;
//       this.totalPages = Math.ceil(this.totalRecords / this.pageSize);
//       this.currentPage = page;
//     });
//   }

//   previousPage() {
//     if (this.currentPage > 0) {
//       this.currentPage--;
//       this.loadUsers(this.currentPage);
//     }
//   }

//   nextPage() {
//     if (this.currentPage < this.totalPages - 1) {
//       this.currentPage++;
//       this.loadUsers(this.currentPage);
//     }
//   }

//   onChange(Event, userid) {
//     console.log(Event.checked, userid);
//     if (Event.checked) {
//       this.userService.activateUser(userid, true).subscribe(res => {
//         console.log(res);
//       }, Error => {
//         console.log(Error);
//       });
//     } else {
//       this.userService.activateUser(userid, false).subscribe(res => {
//         console.log(res);
//       }, Error => {
//         console.log(Error);
//       });
//     }
//   }

//   applyFilter(filterValue: string) {
//     this.usersDataArray.filter = filterValue.trim().toLowerCase();
//     this.usersDataArray.filterPredicate = (data: any, filter: string) => {
//       if (data) {
//         return data.username.toString().toLowerCase().indexOf(filter) != -1 ||
//           data.resource.firstName.toString().toLowerCase().indexOf(filter) != -1 ||
//           (data.resource.lastName && data.resource.lastName.toString().toLowerCase().indexOf(filter) != -1)
//       }
//     };
//   }

//   editUser(userDetail) {
//     let editDialog = this.dialog.open(EdituserComponent, {
//       width: '800px',
//       data: { user: userDetail },
//       id: 'editUserWindow'
//     });
//     editDialog.updatePosition({ top: '50px' });
//     editDialog.afterClosed().subscribe(result => {
//       console.log(result);
//       if (result) {
//         this.ngOnInit();
//       }
//     });
//   }

//   ngOnDestroy() {
//     this.ngUnsubscribe.next();
//     this.ngUnsubscribe.complete();
//   }

//   searchUsersByName() {
//     this.isLoading = true;
//     const nameToSearch = this.name.trim().toLowerCase();
//     this.userService.findUserByName(nameToSearch).pipe(
//       takeUntil(this.ngUnsubscribe),
//       catchError(error => {
//         this.isLoading = false;
//         console.log("Error fetching users:", error);
//         return [];
//       })
//     ).subscribe((res: any) => {
//       this.isLoading = false;
//       if (!res || !Array.isArray(res)) {
//         console.error("Returned data format is unexpected:", res);
//         this.usersDataArray.data = [];
//         return;
//       }
//       this.usersDataArray.data = res;
//     });
//   }

//    //Added by Digvijay
//    exportAsXLSX() {
//     this.excelRecords = [];
//     let usersData = [];
//     usersData = this.usersDataArray.data;
//     usersData.forEach(user => {
//       let firstName, lastName, address, role="", resource, designation;

//       firstName = user.resource.firstName ? user.resource.firstName : "";
//       lastName = user.resource.lastName ? user.resource.lastName : "";
//       address = user.resource.address ? user.resource.address : "";
//       user.roles.forEach(userRole => {
//         role += userRole.roleName + ", ";
//       });
//       role = role.substring(0, role.length-2);
//       resource = user.resourceName ? user.resourceName : "";
//       designation = user.resource.designation? user.resource.designation.resourceDesignation : "";

//       user.branches.forEach(branch=> {
//         if(user){
//           this.excelRecords.push({
//             'Username': user.username,
//             'First Name': firstName,
//             'Last Name': lastName,
//             'Roles' : role,
//             'Branches': (branch.branchName && branch.branchCode) ? branch.branchName + " - " + branch.branchCode : branch.branchName,
//             'Designation': designation,
//             'User Of': resource,
//             'Active' : user.bIsActive,
//             'Address': address            
//           })
//         }
//       });
//     });
//     this.excelService.exportAsExcelFile(this.excelRecords, 'userrecords');
//   }
// //Digvijay End
// }

// ***************************************************8













import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatSort, PageEvent } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { EdituserComponent } from '../edituser/edituser.component';
import { UsersService } from '../../../services/users/users.service';
import { Subscription, Subject } from 'rxjs';
import { catchError, first, last, takeUntil } from 'rxjs/operators';
import { BranchService } from 'src/app/services/branch/branch.service';
import { ExcelgeneratorService } from 'src/app/services/excelgenerator/excelgenerator.service';
import {MatPaginatorModule} from '@angular/material/paginator';


 
@Component({
  selector: 'app-alluserrole',
  templateUrl: './alluserrole.component.html',
  styleUrls: ['./alluserrole.component.scss']
})
export class AlluserroleComponent implements OnInit, OnDestroy {
  showFiller = false;
  isLoading: boolean = false;
  excelRecords = [];
  columnsToDisplay = [
    'username',
    'firstname',
    'lastname',
    'userRole',
    'userOf',
    'associatedUnit',
    'designation',
    'userSince',
    'userActive',
    'actions'
  ];
  usersDataArray = new MatTableDataSource<any>();
 
  totalRecords = 0;
  pageSize = 25;
  currentPage = 0;
  totalPages = 0;
  branchesSubscription: Subscription;
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  name: string = ''; 

  constructor(
    public dialog: MatDialog,
    private userService: UsersService,
    private excelService: ExcelgeneratorService,
  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.loadUsers(this.currentPage, this.pageSize); // Load the first page with the default page size
  }
  
  getUsers(pageIndex: number, pageSize: number) {
    this.userService.getUsersPagewise(pageIndex, pageSize).pipe(
      takeUntil(this.ngUnsubscribe),
      catchError(error => {
        this.isLoading = false;
        console.log('Error fetching users:', error);
        return [];
      })
    ).subscribe((res: any) => {
      this.isLoading = false;
      if (!res || !Array.isArray(res.content)) {
        console.error('Returned data format is unexpected:', res);
        this.usersDataArray.data = [];
        return;
      }
      this.usersDataArray.data = res.content;
      this.totalRecords = res.totalElements;
      // this.totalRecords = res.totalPages;
      this.totalPages = Math.ceil(this.totalRecords / this.pageSize); 
      this.currentPage = pageIndex;
    });
  }

  loadUsers(page: number, size: number) {
    this.isLoading = true;
    this.userService.getUsersPagewise(page, size).pipe(
      takeUntil(this.ngUnsubscribe),
      catchError(error => {
        this.isLoading = false;
        console.log("Error fetching users:", error);
        return [];
      })
    ).subscribe((res: any) => {
      this.isLoading = false;
      console.log("allusers", res);
      if (!res || !Array.isArray(res.content)) {
        console.error("Returned data format is unexpected:", res);
        this.usersDataArray.data = [];
        this.totalRecords = 0; 
        this.totalPages = 0; 
        return;
      }
      this.usersDataArray.data = res.content;
      this.totalRecords = res.totalElements;
      // this.totalRecords = res.totalPages;
      // this.totalPages = Math.ceil(this.totalRecords / this.pageSize);
      this.totalPages = res.totalPages;
      this.currentPage = page;
      if (this.paginator) {
        this.paginator.pageIndex = page;
        this.paginator.length = this.totalRecords;
      }
    });
  }

  onPageChange(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.loadUsers(this.currentPage, this.pageSize);
  }

  previousPage() {
    if (this.currentPage > 0) {
      this.currentPage--;
      this.loadUsers(this.currentPage, this.pageSize);
    }else {
      this.loadUsers(0, this.pageSize); // Loading first page explicitly
    }
  }

  nextPage() {
    if (this.currentPage < this.totalPages - 1) {
      this.currentPage++;
      this.loadUsers(this.currentPage, this.pageSize);
    }else {
      this.loadUsers(this.totalPages - 1, this.pageSize); // Loading last page explicitly
    }
  }

  onChange(Event, userid) {
    console.log(Event.checked, userid);
    if (Event.checked) {
      this.userService.activateUser(userid, true).subscribe(res => {
        console.log(res);
      }, Error => {
        console.log(Error);
      });
    } else {
      this.userService.activateUser(userid, false).subscribe(res => {
        console.log(res);
      }, Error => {
        console.log(Error);
      });
    }
  }

  // applyFilter(filterValue: string) {
  //   this.usersDataArray.filter = filterValue.trim().toLowerCase();
  //   this.usersDataArray.filterPredicate = (data: any, filter: string) => {
  //     if (data) {
  //       return data.username.toString().toLowerCase().indexOf(filter) !== -1 ||
  //         data.resource.firstName.toString().toLowerCase().indexOf(filter) !== -1 ||
  //         (data.resource.lastName && data.resource.lastName.toString().toLowerCase().indexOf(filter) !== -1);
  //     }
  //     return false;
  //   };
  // }

  applyFilter(filterValue: string) {
    const trimmedValue = filterValue.trim().toLowerCase();
    this.name = trimmedValue; // Track the filter value
    if (trimmedValue) {
      // Apply filter when there is a value
      this.usersDataArray.filter = trimmedValue;
      this.usersDataArray.filterPredicate = (data: any, filter: string) => {
        if (data) {
          return (
            data.username.toString().toLowerCase().indexOf(filter) !== -1 ||
            data.resource.firstName.toString().toLowerCase().indexOf(filter) !== -1 ||
            (data.resource.lastName && data.resource.lastName.toString().toLowerCase().indexOf(filter) !== -1)
          );
        }
        return false;
      };
    } else {
      // Clear filter and load first page when filter value is empty
      this.usersDataArray.filter = '';
      this.loadUsers(0, this.pageSize); // Load first page explicitly
    }
  }
  
  editUser(userDetail) {
    let editDialog = this.dialog.open(EdituserComponent, {
      width: '800px',
      data: { user: userDetail },
      id: 'editUserWindow'
    });
    editDialog.updatePosition({ top: '50px' });
    editDialog.afterClosed().subscribe(result => {
      console.log(result);
      if (result) {
        this.ngOnInit();
      }
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  searchUsersByName() {
    this.isLoading = true;
    const nameToSearch = this.name.trim().toLowerCase();
    this.userService.findUserByName(nameToSearch).pipe(
      takeUntil(this.ngUnsubscribe),
      catchError(error => {
        this.isLoading = false;
        console.log("Error fetching users:", error);
        return [];
      })
    ).subscribe((res: any) => {
      this.isLoading = false;
      if (!res || !Array.isArray(res)) {
        console.error("Returned data format is unexpected:", res);
        this.usersDataArray.data = [];
        return;
      }
      this.usersDataArray.data = res;
    });
  }

   //Added by Digvijay
   exportAsXLSX() {
    this.excelRecords = [];
    let usersData = [];
    usersData = this.usersDataArray.data;
    usersData.forEach(user => {
      let firstName, lastName, address, role="", resource, designation;

      firstName = user.resource.firstName ? user.resource.firstName : "";
      lastName = user.resource.lastName ? user.resource.lastName : "";
      address = user.resource.address ? user.resource.address : "";
      user.roles.forEach(userRole => {
        role += userRole.roleName + ", ";
      });
      role = role.substring(0, role.length-2);
      resource = user.resourceName ? user.resourceName : "";
      designation = user.resource.designation? user.resource.designation.resourceDesignation : "";

      user.branches.forEach(branch=> {
        if(user){
          this.excelRecords.push({
            'Username': user.username,
            'First Name': firstName,
            'Last Name': lastName,
            'Roles' : role,
            'Branches': (branch.branchName && branch.branchCode) ? branch.branchName + " - " + branch.branchCode : branch.branchName,
            'Designation': designation,
            'User Of': resource,
            'Active' : user.bIsActive,
            'Address': address            
          })
        }
      });
    });
    this.excelService.exportAsExcelFile(this.excelRecords, 'userrecords');
  }
//Digvijay End
}


 // ***************************************************************************


 