import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Http, Headers, URLSearchParams, Response, HttpModule, RequestOptions } from '@angular/http';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class RoleService {

    messageSource = new BehaviorSubject(null);
    currentMessage = this.messageSource.asObservable();

    base_url: string;
    constructor(public http: HttpClient) {
        this.base_url = environment.appUrl + "/tenantusers";
    }

    sendUserId(message: string) {
        console.log(message);
        this.messageSource.next(message);
    }

    assignRoleToUser(data): Observable<any> {
        console.log(data);

        if (data.bAssign) {
            return this.http.post(this.base_url + '/id/' + data.userId + '/role', data.roleName);
        }
        else {
            return this.http.delete(this.base_url + '/id/' + data.userId + '/role/' + data.roleName);
        }
    }

}