import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { FleetService } from '../../../services/fleet/fleet.service';
import { TransporterService } from '../../../services/transporter/transporter.service';
import { MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ValidatorService } from 'src/app/services/validator/validator.service';
import { NotifierService } from 'angular-notifier';


@Component({
  selector: 'app-addfleet',
  templateUrl: './addfleet.component.html',
  styleUrls: ['./addfleet.component.scss']
})
export class AddfleetComponent implements OnInit {
  configOwnership = {
    height: '40vh',
    search: true
  }
  configTransporter = {
    search: true,
    displayKey: 'transNameAndCode',
    searchOnKey: 'transporterName',
    height: '40vh',
    clearOnSelection: true
  }
  configVehicleType = {
    search: true,
    displayKey: 'vehicletypeAndCode',
    searchOnKey: 'vehicleType',
    height: '40vh',
  }
  fleetForm: FormGroup;
  transporter: {};
  selectedTransporter: string;
  isVehicleType: boolean = false;
  isOwnership: boolean = false;
  ownershipList: any[];
  isTransporter: boolean = false;

  //activity log
  activityLog = [];
  activityCreatedLog = [];
  activityUpdatedLog = [];
  fleetAddedBy: string;
  fleetCreationDate: string;
  fleetUpdationDate: string;
  allTransporters = [];
  allVehicleTypes = [];
  selectedvehicletype: string;
  isDynamicAddFleet: boolean = false;
  constructor(
    public fleetService: FleetService,
    public transporterService: TransporterService,
    formBuilder: FormBuilder,
    public snackBar: MatSnackBar,
    public router: Router,
    private datePipe: DatePipe,
    public dialog: MatDialog,
    private notifierService: NotifierService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.fleetForm = formBuilder.group({
      'vehicleType': [null, [
        Validators.required
      ]],
      'vehicleLicenseNumber': [null, [
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9]+$"),
        ValidatorService.whitespaceValidator
      ]],
      'vehicleIdNumber': [null, [
        Validators.pattern("^[a-zA-Z0-9 ]+$"),
        ValidatorService.whitespaceValidator
      ]],
      'vehicleRegistrationDate': [null],
      'carryingType': [null],
      'transporter': [null,
        Validators.required
      ],
      'ownership': [null],
    })
  }


  ngOnInit() {
    this.fleetForm.controls['vehicleRegistrationDate'].disable();

    console.log("url --"+this.router.url)
    if (this.router.url != '/transporter/fleet')
    {
      this.isDynamicAddFleet = true;
    }
      
    this.getAllFleets();
    this.getTransporter();
    this.getFleetOwnership();
    this.fleetService.getVehicleTypes().subscribe(vehicleTypes => {
      this.allVehicleTypes = vehicleTypes;
      for (let vehicleType of this.allVehicleTypes) {
        if(vehicleType.vehicleType && vehicleType.vehicleTypeCode){
        vehicleType['vehicletypeAndCode'] = vehicleType.vehicleType + '-' + vehicleType.vehicleTypeCode;
        } 
        else{
          vehicleType['vehicletypeAndCode'] = vehicleType.vehicleType;
        }
      }
      this.allVehicleTypes.sort(function (a, b) {
        let vehicletype1 = a.vehicleType.toLowerCase(), vehicletype2 = b.vehicleType.toLowerCase()
        if (vehicletype1 < vehicletype2) //sort string ascending
          return -1
        if (vehicletype1 > vehicletype2)
          return 1
        return 0
      });

      if(this.isDynamicAddFleet){
        this.fleetForm.patchValue({
          vehicleType: this.allVehicleTypes.find(obj => obj.vehicleType === this.data.vehicleType)
        });
      }
    });
  }

  getTransporter() {
    this.transporterService.getAllActiveTransporters().subscribe(transporterDetails => {
      this.allTransporters = transporterDetails;
      console.log("allTransporters", this.allTransporters);
      for (let trans of this.allTransporters) {
        trans['transNameAndCode'] = trans.transporterName + '-' + trans.transporterCode;
      }
      this.allTransporters.sort(function (a, b) {
        let transName1 = a.transporterName.toLowerCase(), transName2 = b.transporterName.toLowerCase()
        if (transName1 < transName2) //sort string ascending
          return -1
        if (transName1 > transName2)
          return 1
        return 0
      });
      if(this.isDynamicAddFleet){
        console.log(this.allTransporters.find(obj => obj.transporterId === parseInt(this.data.transpoter)))
        this.fleetForm.patchValue({
          transporter:this.allTransporters.find(obj => obj.transporterId === parseInt(this.data.transpoter))
        });
      }
    })
    // if(this.isDynamicAddFleet && this.allTransporters){
    //   console.log("data--"+this.data.transpoter+"****"+this.allTransporters.length);
      
    //   for(let i=0;i<this.allTransporters.length;i++)
    //   {
    //     console.log(i)
    //     let obj = this.allTransporters[i];
    //     console.log(obj.transporterId+"----"+this.data.transpoter)
    //     if(obj.transporterId === parseInt(this.data.transpoter))
    //     {
    //       console.log(obj.transporterCode) ;
    //     }
    //   }
    //   this.fleetForm.patchValue({
    //     transporter:this.allTransporters.find(obj => obj.transporterId === this.data.transpoter)
    //   });
    // }

    
  }

  getAllFleets() {
    this.fleetService.getallFleets(0).subscribe(res => {
      for (let i = 0; i < 4; i++) {
        if (res[i]) {
          this.activityCreatedLog.push({
            vehicleLicenseNumber: res[i].vehicleLicenseNumber,
            createdBy: res[i].updatedByusername,
            creadtedDate: this.datePipe.transform(res[i].creationDateTime, 'yyyy-MM-dd'),
            creadtedTime: res[i].creationDateTime,
          });
          this.activityUpdatedLog.push({
            vehicleLicenseNumber: res[i].vehicleLicenseNumber,
            updatedBy: res[i].updatedByusername,
            updatedDate: this.datePipe.transform(res[i].updatedDateTime, 'yyyy-MM-dd'),
            updatedTime: res[i].updatedDateTime
          });
        }
      }
    }, () => {

    });
  }

  getFleetOwnership() {
    this.fleetService.getAllFleetOwnership().subscribe(ownershipList => {
      this.ownershipList = ownershipList;
      console.log("Ownership:", ownershipList);
    })
  }

  addFleet() {
    delete this.fleetForm.value.transporter.nameAndCode;
    if (this.fleetForm.value.vehicleIdNumber == "")
      this.fleetForm.value.vehicleIdNumber = null;
    this.fleetForm.value.vehicleRegistrationDate = this.datePipe.transform(this.fleetForm.controls['vehicleRegistrationDate'].value, 'yyyy-MM-dd');
    this.fleetService.addFleet(this.fleetForm.value).subscribe(() => {
      this.notifierService.notify("success", "Fleet: " + this.fleetForm.value.vehicleLicenseNumber + " added successfully")
      this.fleetForm.reset();
    //  this.dialog.closeAll();
      if (!this.isDynamicAddFleet){
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate(["/transporter/fleet"]));
        // this.router.navigateByUrl('/managefleet')
      }
        this.dialog.getDialogById("addFleet").close(true);  

    }, Error => {
      console.log(Error);
      this.notifierService.notify("error", Error.error.Error)
    })
  }

  //cpaitalize 1st letter of each word in a string
  titleCase(str) {
    let words;
    console.log(str.toString());
    words = str.toLowerCase().split(' ');

    for (var i = 0; i < words.length; i++) {
      var letters = words[i].split('');
      letters[0] = letters[0].toUpperCase();
      words[i] = letters.join('');
    }
    return words.join(' ');
  }
}