import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { TokenStorage } from 'src/app/auth/token.storage';
import { MaterialcategoryService } from 'src/app/services/materialcategories/materialcategory.service';
import { ValidatorService } from 'src/app/services/validator/validator.service';


@Component({
  selector: 'app-addmaterialcategory',
  templateUrl: './addmaterialcategory.component.html',
  styleUrls: ['./addmaterialcategory.component.scss']
})
export class AddmaterialcategoryComponent implements OnInit {
  materialCategoryForm: FormGroup;
  isSubmitButtonDisabled: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private materialcategoryService: MaterialcategoryService,
    private token: TokenStorage,
    private notifierService: NotifierService,
  ) {
    
    this.materialCategoryForm = formBuilder.group({
      'materialCategoryName': [null, [
        Validators.required,
        ValidatorService.whitespaceValidator
      ]]
    });

  }

  ngOnInit() {
  }

  addmaterialcategory() {
    this.isSubmitButtonDisabled = true;
    console.log("form value", this.materialCategoryForm.value);
    this.materialcategoryService.addMaterialCategory(this.materialCategoryForm.value).subscribe(materialcategoryadded => {
      console.log(materialcategoryadded);
      this.notifierService.notify('success', 'Material Category saved successfully.');
      this.materialCategoryForm.reset();
    }, error => {
      console.log(error);
      this.notifierService.notify("error", error.error.Error);
      this.materialCategoryForm.patchValue({
        materialCategoryName:null
      });
      this.materialCategoryForm.reset();
      // alert(Error.error.Error);
    });
    this.isSubmitButtonDisabled = false;
  }
}
