import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { TransporterService } from 'src/app/services/transporter/transporter.service';
import { RateService } from 'src/app/services/rate/rate.service';
import { NotifierService } from 'angular-notifier';
import { BranchService } from 'src/app/services/branch/branch.service';
import { Subscription } from 'rxjs';
import { ExcelgeneratorService } from 'src/app/services/excelgenerator/excelgenerator.service';
import { UploadfileService } from 'src/app/services/uploadfile/uploadfile.service';
import { TokenStorage } from 'src/app/auth/token.storage';


@Component({
  selector: 'app-additionalrate',
  templateUrl: './additionalrate.component.html',
  styleUrls: ['./additionalrate.component.scss']
})
export class AdditionalrateComponent implements OnInit, OnDestroy {

  columnsToDisplay = [
    "to",
    "loading",
    "unloading",
    "clubbing"
  ];

  tableShown: boolean = false;
  loading: boolean = false;
  isOriginSelected: boolean = false;

  from: any;
  selectedTransporter: number = 0;
  rateData = new MatTableDataSource<any>();
  origin = [];
  transporters = [];
  rateTypesArray = ["RATE_TYPE_LOADING", "RATE_TYPE_CLUBBING_1", "RATE_TYPE_CLUBBING_2", "RATE_TYPE_UNLOADING"];
  areAllChangesSaved: boolean = true;
  branchesSubscription: Subscription;
  excelRecords = [];
  isStart: boolean = true;

  isFileUploading: boolean;
  showSpinner: boolean = false;
  fileName: any;
  excelProgressSubscription: Subscription;
  progress: string;
  subExcel: Subscription;
  isSuhana: boolean = false;

  constructor(
    private transporterService: TransporterService,
    private rateService: RateService,
    private notifierService: NotifierService,
    private branchService: BranchService,
    private excelService: ExcelgeneratorService,
    private uploadService: UploadfileService,
    private token: TokenStorage
  ) {
  }

  ngOnInit() {
    if (this.token.getTenant() == "suhana-dist-prod" || this.token.getTenant() == "suhana-dist-test") {
      this.isSuhana = true;
    }
    this.branchesSubscription = this.branchService.branchesObservable.subscribe(branches => {
      this.origin = branches;
    });
    this.excelProgressSubscription = this.uploadService.progressObservable.subscribe(progress => {
      console.log(progress);
      this.progress = progress.progressStatus;
    });

    this.subExcel = this.uploadService.event.subscribe(val => {
      this.isFileUploading = false;
    });
  }

  ngOnDestroy() {
    if (this.branchesSubscription)
      this.branchesSubscription.unsubscribe();
    if (this.subExcel)
      this.subExcel.unsubscribe();
    if (this.excelProgressSubscription)
      this.excelProgressSubscription.unsubscribe();
  }


  submitFile(fileEvent) {
    console.log(fileEvent)
    if (fileEvent) {
      console.log(fileEvent.target.files[0]);
      this.isFileUploading = true;
      if (fileEvent.target.files.length > 0) {
        const file: File = fileEvent.target.files[0];
        this.uploadService.uploadRateExcelFile(file, '/additionalrates/');
      }
    }
  }


  originSelected(event) {
    this.isStart = false;
    console.log(event)
    this.from = event.target.value;
    this.tableShown = false;
    this.transporters = [];
    this.isOriginSelected = true;
    this.selectedTransporter = 0;
    this.transporterService.getTransporterByBranches([this.from]).subscribe(transporters => {
      if (transporters.length == 0) {
        this.notifierService.notify('default', "No transporters for this branch.");
      }
      this.transporters = transporters;
    }, () => {
      this.notifierService.notify("error", "Error fetching transporters.");
    });
  }

  transporterSelected(event) {
    this.rateData.data = [];
    this.tableShown = false;
    this.loading = true;
    this.selectedTransporter = event.target.value;

    this.rateService.getAdditionalRatesByOriginAndTransporter(this.from, this.selectedTransporter).subscribe((rates: any) => {
      this.rateData.data = rates;
      this.tableShown = true;
      this.loading = false;

      if (this.rateData.data == null || this.rateData.data.length == 0) {
        this.notifierService.notify("default", "No data to show.");
      }

      this.rateData.data.forEach(row => {
        row.additionalRatesList.forEach(rate => {
          if (rate) {
            rate.isEditing = false;
          }
        });
      });

      console.log(rates);
    }, () => {
      this.loading = false;
      this.notifierService.notify("error", "Error fetching data, please try again,");
    });
  }

  clicked(rateIndex: number, rowIndex: number, value) {
    this.rateData.filteredData[rowIndex].additionalRatesList[rateIndex].isEditing = !this.rateData.filteredData[rowIndex].additionalRatesList[rateIndex].isEditing;
    if (value >= 0 && value != null) {
      this.rateData.filteredData[rowIndex].additionalRatesList[rateIndex].rate = value;
      this.saveRates();
    }
  }

  addNewRate(rateIndex: number, rowIndex: number) {
    let temp = {
      rate: 1,
      rateType: this.rateTypesArray[rateIndex],
      fromDate: null,
      toDate: null,
      isEditing: true
    }
    this.rateData.filteredData[rowIndex].additionalRatesList[rateIndex] = temp;
  }

  saveRates() {
    this.rateService.updateAdditionalRate(this.rateData.data).subscribe(() => {
      this.notifierService.notify('success', "Rates saved successfully.");
    }, () => {
      this.notifierService.notify('error', "Error saving rates.")
    })
  }

  exportAsXLSX() {
    this.excelRecords = [];
    let rateRecords = [];
    let transporterName, transporterCode;
    transporterName = this.transporters.find(trans => trans.transporterId == this.selectedTransporter).transporterName;
    transporterCode = this.transporters.find(trans => trans.transporterId == this.selectedTransporter).transporterCode;

    rateRecords = this.rateData.data;
    rateRecords.forEach(rate => {
      let loadingCharge, unloadingCharge, clubbingCharge, destinationCode, destination, branchCode;
      if (rate.additionalRatesList[0] && rate.additionalRatesList[0].rate)
        loadingCharge = rate.additionalRatesList[0].rate
      else
        loadingCharge = ''
      if (rate.additionalRatesList[1] && rate.additionalRatesList[1].rate)
        unloadingCharge = rate.additionalRatesList[1].rate
      else
        unloadingCharge = ''
      if (rate.additionalRatesList[2] && rate.additionalRatesList[2].rate)
        clubbingCharge = rate.additionalRatesList[2].rate
      else
        clubbingCharge = ''

      if (rate.destinationCode)
        destinationCode = rate.destinationCode
      else
        destinationCode = ''

      if (rate.destination)
        destination = rate.destination
      else
        destination = ''


      if (rate.origin)
        branchCode = rate.origin
      else
        branchCode = ''

      if (rate) {
        this.excelRecords.push({
          'Branch Name': this.from,
          'Branch Code': branchCode,
          'Transporter Name': transporterName,
          'Transporter Code': transporterCode,
          'Route/Destination': destination,
          'Destination Code': destinationCode,
          'Loading Charges': loadingCharge,
          'Clubbing Charges 1': unloadingCharge,
          'Clubbing Charges 2': clubbingCharge
        })
      }
    });
    this.excelService.exportAsExcelFile(this.excelRecords, 'additionalrates');
  }

  applyFilter(filterValue: string) {
    this.rateData.filter = filterValue.trim().toLowerCase();
  }

}
