import { Component, OnInit, ViewChild } from '@angular/core';
import {
  MatPaginator, MatTableDataSource,
  MatDialog, MatSort
} from '@angular/material';
import { EditfleetComponent } from "../editfleet/editfleet.component";
import { FleetService } from '../../../services/fleet/fleet.service';
import { ResourceService } from '../../../services/resource/resource.service';
import 'rxjs/add/observable/of';
import { TokenStorage } from 'src/app/auth/token.storage';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AddfleetComponent } from '../addfleet/addfleet.component';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-allfleet',
  templateUrl: './allfleet.component.html',
  styleUrls: ['./allfleet.component.scss']
})
export class AllfleetComponent implements OnInit {
  driverArray = [];
  driverData: any;
  selectedDriver: number;
  isDriverSelected: boolean = false;
  driverSelectedForTrip: any;
  driverName: string;
  driverPhoneNo: string;
  driverLicenseNo: string;
  tripID: number;
  fleetResource: number = null;
  isLoading: boolean = false;
  isGodrej: boolean = false;
  isKcc: boolean = false;
  volUnit: string;
  totalRecords: number;
  page: number = 0;
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  fleetNo: any;
  prevFleetNo: any;
  allFleets: any;
  isFiltered: boolean = false;

  columnsToDisplay = [
    'vehicleLicenseNumber',
    'vehicleName',
    'capacityDetails',
    'transporter',
    'assignDriver',
    'activate',
    'actions'
  ];

  fleetDataArray = new MatTableDataSource;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  isWarehouseManager: boolean = false;
  constructor(
    private dialog: MatDialog,
    private fleetService: FleetService,
    private resourceService: ResourceService,
    private token: TokenStorage,
    private notifier: NotifierService
  ) {
    if (this.token.getTenant() == 'kimberly-dist-prod') {
      this.volUnit = 'CM';
    } else if (this.token.getTenant() == 'godrej-dist-prod') {
      this.volUnit = 'CCM';
    } else {
      this.volUnit = 'CF';
    }
  }

  ngOnInit() {
    if(window.sessionStorage.getItem('permissions').substr(2).slice(0, -2)=='warehouse_manager'){
      this.isWarehouseManager= true;
    }
    this.getAllFleets();
  }

  addNewFleetsModal() {
    let addDialog = this.dialog.open(AddfleetComponent, {
      width: '65%',
      maxWidth: '85vw',
      id: 'addFleet'
    });

    addDialog.updatePosition({
      top: '50px'
    });
  }

  applyFilter(filterValue: string) {
    this.fleetDataArray.filter = filterValue.trim().toLowerCase();
    this.fleetDataArray.filterPredicate = (data: any, filter: string) => {
      let isTransporterName = false, isFleet = false, isMakeModel = false;
      if (data) {
        if (data.transporter) {
          if (data.transporter.transporterName) {
            isTransporterName = data.transporter.transporterName.toString().toLowerCase().indexOf(filter) != -1;
          }
        }
        if (data.vehicleType) {
          if (data.vehicleType.vehicleMakeModel) {
            isMakeModel = data.vehicleType.vehicleMakeModel.toString().toLowerCase().indexOf(filter) != -1;
          }
        }
        isFleet = data.vehicleLicenseNumber.toString().toLowerCase().indexOf(filter) != -1;
      }
      return isTransporterName || isFleet || isMakeModel;
    };

  }

  driverSelected(fleet, id) {
    console.log(id);
    console.log("fleet" + fleet);

    let data = {
      resources: id
    }
    this.resourceService.getResourceById(data).subscribe(resource => {
      this.fleetService.assignPreferredDriverToFleet(fleet.vehicleLicenseNumber, resource).subscribe(res => {
        console.log(res);
      }, Error => {
        console.log(Error);
      })
    });
  }

  onChange(Event, licenseNumber) {
    console.log(Event.checked);
    if (Event.checked) {
      this.fleetService.ActivateFleet(licenseNumber, true).subscribe(res => {
        console.log(res);
      }, Error => {
        console.log(Error);
      });
    } else {
      this.fleetService.ActivateFleet(licenseNumber, false).subscribe(res => {
        console.log(res);
      }, Error => {
        console.log(Error);
      });
    }
  }

  getAllFleets() {
        this.getData();
  }

  getData() {
    this.ngUnsubscribe.next();
    this.fleetDataArray.data = [];
    this.allFleets = [];
    this.totalRecords = 0;
    this.isLoading = true;
    this.fleetService.getallFleets(this.page).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      console.log(res)
      this.isLoading = false;
      this.totalRecords = res.totalElements;
      if (res)
        this.fleetDataArray.data = res.content;
      this.fleetDataArray.sortingDataAccessor = (fleet: any, property) => {
        switch (property) {
          case 'vehicleName': return fleet.vehicleType.vehicleMakeModel
          case 'transporter': return fleet.transporter.transporterName
        }
      };
      this.fleetDataArray.sort = this.sort;
      this.allFleets = this.fleetDataArray.data;
      //  this.fleetDataArray.paginator = this.paginator;
    }, Error => {
      this.isLoading = false;
      console.log(Error);
    });
  }

  pageChanged(event) {
    this.page = event.pageIndex;
    this.getData();
  }

  editFleet(vehicleLicenseNumber) {
    let editDialog = this.dialog.open(EditfleetComponent, {
      width: '800px',
      data: {
        licenseNumber: vehicleLicenseNumber
      },
      id: 'editFleetWindow'
    });
    editDialog.updatePosition({
      top: '50px', right: '0px',
    });
    editDialog.afterClosed().subscribe(result => {
      if (result) {
        this.getAllFleets();
      }
    });
  }

  searchFleet() {
    if (this.prevFleetNo != this.fleetNo) {
      this.fleetService.searchFleet(this.fleetNo).subscribe(res => {
        console.log(res);
        this.fleetDataArray.data = [res];
        this.isFiltered = true;
      }, Error => {
        if (Error.status == 404) {
          this.notifier.notify("error", 'Fleet Not Found')
        }
        else {
          this.notifier.notify("error", 'Error in getting fleet.')
        }
      })
    }
    this.prevFleetNo = this.fleetNo;
  }
  clearAllFilter() {
    this.prevFleetNo = "";
    this.isFiltered = false;
    this.fleetDataArray.data = this.allFleets;
  }
}

