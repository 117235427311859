import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

declare var $: any;

@Component({
  selector: 'app-schedulingandplanning',
  templateUrl: './schedulingandplanning.component.html',
  styleUrls: ['./schedulingandplanning.component.scss']
})
export class SchedulingandplanningComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    $('#smartwizard').smartWizard({
      theme: 'arrows',
      transitionEffect: 'fade',
      showStepURLhash: false,

      anchorSettings: {
        anchorClickable: true, // Enable/Disable anchor navigation
        enableAllAnchors: false, // Activates all anchors clickable all times
        markDoneStep: false, // add done css
        enableAnchorOnDoneStep: false // Enable/Disable the done steps navigation
      }
    });
  }

}

@Component({
  selector: 'error-excel-upload',
  template: `
  <div style="padding:25px;">
    <div style=" white-space: pre-wrap;"><b>{{data.Result}}</b></div>
      <div *ngIf="readMore==false"> <div class="text-right">
       <a *ngIf = "data.ErrorSummary" (click)= "readMore=true" style="color: #007bff; text-decoration: none; background-color: transparent; cursor: pointer"> Read more...</a>
      </div>
    </div>
    <div *ngIf="readMore==true && data.ErrorSummary">
       <div style=" white-space: pre-wrap; font-weight:700">{{data.ErrorSummary}}</div>
       <div class="text-right">
       <a (click)= "readMore=false" style="color: #007bff ; text-decoration: none; background-color: transparent; cursor: pointer"> Read Less...</a>
    </div>
    </div>
    <div class="row mt-2">
      <div class="col text-center">
        <button class="btn" style="background: #3f51b5 !important; color: white; padding: 0px 10px;" [mat-dialog-close]="true">OK</button>
      </div>
    </div>
  </div>
  `,
})

export class ErrorExcelUpload {
  readMore: boolean = false;
  constructor(
    public error: MatDialogRef<ErrorExcelUpload>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  onNoClick(): void {
    this.error.close();
  }

}