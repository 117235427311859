import { Component, OnInit, Input, ViewChild, OnChanges } from '@angular/core';
import { NgxDateRangePickerOptions } from 'src/ngx-daterangepicker';
import { MatTableDataSource, MatDialog, MatPaginator } from '@angular/material';
import { BillingService } from 'src/app/services/billing/billing.service';
import { TransporterService } from 'src/app/services/transporter/transporter.service';
import { DatePipe } from '@angular/common';
import { ExcelgeneratorService } from 'src/app/services/excelgenerator/excelgenerator.service';
import { StoreUserDetails } from 'src/app/auth/storeuserdetails';
import { NotifierService } from 'angular-notifier';
import { BilldetailsComponent } from '../billdetails/billdetails.component';
import { Bill } from '../billing.component';
import { TokenStorage } from 'src/app/auth/token.storage';
import { GenericDialogBox } from '../../schedulingandplanning/edittripmodal/edittripmodal.component';
import { Subscription, Subject } from 'rxjs';
import { BranchService } from 'src/app/services/branch/branch.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-vendor-bills',
  templateUrl: './vendor-bills.component.html',
  styleUrls: ['./vendor-bills.component.scss']
})
export class VendorBillsComponent implements OnInit {
  isSa: boolean = false;
  isTransporter: boolean = false;
  trpReviewCount: number = 0;
  scReviewCount: number = 0;
  approvedCount: number = 0;
  fromDate: any;
  toDate: any;
  userRole: string;
  isTransporterFilter: boolean = false;
  isDateFilter: boolean = false;
  selectedFlag:string = 'trpReview';
  dateRangeValue: any= { to: "", from: "" };

  dateRangePickeroptions: NgxDateRangePickerOptions;
  selectedTransporter: any = null;
  transporterDropdownConfig = {
    search: true,
    displayKey: 'transporterName',
    searchOnKey: 'transporterName',
    height: '40vh',
    placeholder: 'Select Transporter'
  };
  allTransporters: any = [];
  transportDataFlag: boolean = false
  allTransportersList: any;


  columnsToDisplay = [
    'action',
    'date',
    'branchName',
    'to',
    'shipmentNo',
    'transporter',
    'truck',
    'lr',
    'invoice',
    'cases',
    'freightRate',
    'unloadingcharges',
    'clubbingCharges',
    'amount',
    'pod',
    'status',
    'print',
    'edit'
  ];
  transporterName: string;

  prevFromDate: string;
  prevTodate: string;


  filteredBills: any = new MatTableDataSource<Bill>();
  @ViewChild(MatPaginator) paginator: MatPaginator;

  allBills: Bill[];

  excelRecords = [];
  dataLoading: boolean = true;
  billType: string = 'trpReview';
  isMetro: boolean = false;
  totalRecords: number = 0;
  page: number = 0;
  billDataForSelectionCheck = []
  isPageAllSelected = [];
  isAllSelected: boolean;
  selectedBillsDataArray: Array<any> = [];
  getTransporterByUserNameRes: any;
  isSuhana: boolean = false;
  branchesSubscription: Subscription;
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  constructor(
    private dialog: MatDialog,
    private billingService: BillingService,
    private transporterService: TransporterService,
    private datePipe: DatePipe,
    private excelService: ExcelgeneratorService,
    private userDetails: StoreUserDetails,
    private notifierService: NotifierService,
    private token: TokenStorage,
    private branchService: BranchService
  ) { }

  ngOnInit() {

    if (this.token.getTenant() == 'suhana-dist-test' || this.token.getTenant() == 'suhana-dist-prod') {
      this.isSuhana = true;
      this.selectedFlag = 'scReview';
      this.billType ='scReview';
    }
    this.userRole = window.sessionStorage.getItem('permissions').substr(2).slice(0, -2);
    this.setDatePickerToCurrentDate();
    this.fromDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.toDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.branchesSubscription = this.branchService.branchesObservable.subscribe(() => {
      this.ngUnsubscribe.next();
      if (window.sessionStorage.getItem("selectedBranchesCodes") && JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")).length == 0) {
        this.filteredBills.data = [];
        this.trpReviewCount = 0;
        this.scReviewCount = 0;
        this.approvedCount = 0;
        this.dataLoading = false;
      }
      else {
        if (this.userRole == 'transporter') {
          this.isTransporter = true;
          if (!this.getTransporterByUserNameRes) {
          this.getTransporterObj();
          }
          else {
            this.getBillData([this.getTransporterByUserNameRes]);
            this.getBillCount([this.getTransporterByUserNameRes])
          }
        }
        else {
        /*   if (this.allTransporters.length > 0) {
            if (this.selectedTransporter.length > 0) {
              this.getBillData(this.selectedTransporter);
              this.getBillCount(this.selectedTransporter)
            }
            else {
              this.getBillData(this.allTransporters);
              this.getBillCount(this.allTransporters)
            }
          }
          else { */
            this.getAllTransporters();
          //}
        }
      }
    });
  }


  getTransporterObj() {
    this.transporterService.getTransportersByUsername(this.userDetails.getUser()).subscribe(usertransporters => {
      this.getTransporterByUserNameRes = usertransporters;
      //  this.getAllTransporters();
      this.getBillData([usertransporters]);
      this.getBillCount([usertransporters])
    }, () => {
      this.dataLoading = false;
      this.notifierService.notify('error', "Error fetching data..");
    });
  }


  sectionSelection(flag) {
    this.page = 0;
    this.paginator.pageIndex = 0;
    this.selectedFlag = flag;
    this.billType = flag;
    this.getBillData(this.selectedTransporter);
    this.getBillCount(this.selectedTransporter);
  }

  getBillCount(transporters) {
    if(transporters)
    {
      if(Object.keys(transporters).length === 0)
      {
        this.selectedTransporter = [];
        this.trpReviewCount = 0;
        this.scReviewCount = 0;
        this.approvedCount = 0;
        this.filteredBills.data = [];
        return false;
      }
    }
    if (this.userRole == 'transporter') {
      this.getTransporterByUserNameRes.resource.transporterId = this.getTransporterByUserNameRes.resource.resourceTypeRef;
      this.billingService.getBillCount(this.fromDate, this.toDate, this.getTransporterByUserNameRes.resource.transporterId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
        this.trpReviewCount = 0;
        this.scReviewCount = 0;
        this.approvedCount = 0;

        this.trpReviewCount = this.searchArraryForCount(res, 'BILL_STATE_INITIAL') + this.searchArraryForCount(res, 'BILL_STATE_IN_TRANSPORTER_REVIEW');

        this.scReviewCount = this.searchArraryForCount(res, 'BILL_STATE_IN_SC_REVIEW')
          + this.searchArraryForCount(res, 'BILL_STATE_PENDING_SC_APPROVAL');
        + this.searchArraryForCount(res, 'BILL_STATE_TRANSPORTER_APPROVED')

        this.approvedCount = this.searchArraryForCount(res, 'BILL_STATE_SC_APPROVED')
          + this.searchArraryForCount(res, 'BILL_STATE_RAISED');
      });
    }
    else {
      let tmp = [];
      transporters.forEach(element => {
        tmp.push(element.transporterId);
      });
      this.billingService.getBillCount(this.fromDate, this.toDate, tmp).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
        this.trpReviewCount = 0;
        this.scReviewCount = 0;
        this.approvedCount = 0;

        this.trpReviewCount = this.searchArraryForCount(res, 'BILL_STATE_INITIAL') + this.searchArraryForCount(res, 'BILL_STATE_IN_TRANSPORTER_REVIEW');

        this.scReviewCount = this.searchArraryForCount(res, 'BILL_STATE_IN_SC_REVIEW')
          + this.searchArraryForCount(res, 'BILL_STATE_PENDING_SC_APPROVAL');
        + this.searchArraryForCount(res, 'BILL_STATE_TRANSPORTER_APPROVED')

        this.approvedCount = this.searchArraryForCount(res, 'BILL_STATE_SC_APPROVED')
          + this.searchArraryForCount(res, 'BILL_STATE_RAISED');
      });
    }
  }

  searchArraryForCount(arr, value) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].billstatus === value) {
        return arr[i].total_number_of_bills;
      }
    }
    return 0;
  }

  getAllTransporters() {
    this.dataLoading = true;
    this.transporterService.getAllActiveTransporters().subscribe(allTrans => {
      this.allTransporters = [];
      this.allTransporters = allTrans;
      this.allTransportersList = allTrans
      this.selectedTransporter = allTrans;
      this.getBillData(this.allTransporters);
      this.getBillCount(this.allTransporters)
      this.allTransporters.sort(function (a, b) {
        let transName1 = a.transporterName.toLowerCase(), transName2 = b.transporterName.toLowerCase()
        if (transName1 < transName2) //sort string ascending
          return -1
        if (transName1 > transName2)
          return 1
        return 0
      });
    }, () => {
      this.dataLoading = false;
      this.notifierService.notify('error', "Error fetching data..");
    });
  }

  getTotalAmount() {
    let billcost = { totalCharges: 0 };
    if (this.filteredBills.data) {
      this.filteredBills.data.forEach(bill => {
        if (!bill.billCosts)
          bill.billCosts = billcost;
      });
      return this.filteredBills.data.map(t => t.billCosts.totalCharges).reduce((acc, value) => acc + value, 0);
    } else {
      return 0;
    }
  }

  getTotalCases() {
    if (this.filteredBills.data) {
      return this.filteredBills.data.map(bill => bill.numContainers).reduce((acc, value) => acc + value, 0);
    } else {
      return 0;
    }
  }

  getBillState(bill: Bill) {
    return this.billingService.getBillState(bill);
  }

  submitForReview() {
    if (this.selectedBillsDataArray.length > 0) {
      this.dataLoading = true;
      this.billingService.saveBill(false, this.selectedBillsDataArray).subscribe(res => {
        this.dataLoading = false;
        this.getBillData(this.allTransporters);
        this.notifierService.notify('success', 'Bills Submitted Successfully.');
      }, () => {
        this.dataLoading = false;
        this.filteredBills.data = this.allBills;
        this.notifierService.notify('error', 'Error Submitting Bills.');
      });
    }
  }

  approveBills() {
    if (this.selectedBillsDataArray.length > 0) {
      this.filteredBills.data = [];
      this.dataLoading = true;
      this.billingService.saveBill(true, this.selectedBillsDataArray).subscribe(res => {
        this.dataLoading = false;
        this.getBillData(this.allTransporters);
        this.notifierService.notify('success', 'Bills Approved Successfully.');
      }, () => {
        this.dataLoading = false;
        this.filteredBills.data = this.allBills;
        this.notifierService.notify('error', 'Error Approving Bills.');
      });
    }
  }

  raiseBills() {
    let showResult = this.dialog.open(GenericDialogBox, {
      width: '312px',
      maxHeight: '80vh',
      data: 'EnterBillNum'
    })

    showResult.afterClosed().subscribe(billNo => {
      if (billNo) {
        this.selectedBillsDataArray.forEach(bill => {
          bill.customerBillNumber = billNo;
        });
        this.approveBills();
      }
    });
  }

  openDetails(bill: Bill) {
    const ref = this.dialog.open(BilldetailsComponent, {
      data: bill,
      width: '80%',
      autoFocus: false,
    });

    ref.updatePosition({
      top: '50px',
      // right: '0px',
    });

    ref.afterClosed().subscribe(res => {
      if (res) {
        if (res.action = "submit" || res.action == "approve") {
          this.getBillData(this.allTransporters);
        }
      }
    });
  }

  print() {
    window.print();
  }

  filterByDate() {
    if (this.dateRangeValue) {
      this.fromDate = this.dateRangeValue.from.substring(6, 10) + '-' + this.dateRangeValue.from.substring(3, 5) + '-' + this.dateRangeValue.from.substring(0, 2);
      this.toDate = this.dateRangeValue.to.substring(6, 10) + '-' + this.dateRangeValue.to.substring(3, 5) + '-' + this.dateRangeValue.to.substring(0, 2);
      //if (this.toDate != this.prevTodate || this.fromDate != this.prevFromDate) {
        this.getBillData(this.selectedTransporter);
        this.getBillCount(this.selectedTransporter);
      //}
      this.prevFromDate = this.fromDate;
      this.prevTodate = this.toDate;
      this.isDateFilter = true;
    }
  }

  filterByTransporter() {
    console.log(this.selectedTransporter)
    this.page = 0;
    this.paginator.pageIndex = 0;
    if (this.selectedTransporter) {

      this.isTransporterFilter = true;
      this.allTransportersList = [];
      this.allTransportersList.push(this.selectedTransporter);
      this.getBillCount(this.allTransportersList);
      this.getBillData(this.allTransportersList);
      // this.filteredBills.data = this.allBills.filter(detail => detail.transporterName == this.selectedTransporter.transporterName);
    } else {
      this.allTransportersList = [];
      this.allTransportersList = JSON.parse(JSON.stringify(this.allTransporters));

      this.getBillCount(this.allTransporters);
      this.getBillData(this.allTransporters);

      // this.filteredBills.data = this.allBills;
    }
    // this.totalRecords = this.filteredBills.data.length;
  }


  getBillData(transporters) {
    this.filteredBills.data = [];
    this.dataLoading = true;
    if (this.userRole == 'transporter') {
      this.billingService.getBillsByTypesAndDateRange(this.fromDate, this.toDate, this.billType, [this.getTransporterByUserNameRes.resource.resourceTypeRef], this.page).pipe(takeUntil(this.ngUnsubscribe)).subscribe((bills: any) => {
        this.totalRecords = bills.totalElements;
        this.findUniqeLR(bills.content);
        this.filteredBills.data = bills.content;
        bills.content.forEach(function (obj) {
          obj.isChecked = false
        });
        this.billDataForSelectionCheck = bills.content;
        this.allBills = bills.content;
        this.dataLoading = false;
        if (this.page == 0)
          for (let i = 0; i < bills.totalPages; i++) {
            this.isPageAllSelected.push(0);
          }
      }, () => {
        this.dataLoading = false;
        this.notifierService.notify('error', "Error fetching bills..");
      });
      // }, () => {
      //   this.dataLoading = false;
      //   this.notifierService.notify('error', "Error fetching data..");
      // });
    } else {
      let tmp = [];
      transporters.forEach(element => {
        tmp.push(element.transporterId);
      });
      this.billingService.getBillsByTypesAndDateRange(this.fromDate, this.toDate, this.billType, tmp, this.page).pipe(takeUntil(this.ngUnsubscribe)).subscribe((bills: any) => {
        this.totalRecords = 0;
        this.totalRecords = bills.totalElements;
        this.findUniqeLR(bills.content);
        this.filteredBills.data = bills.content;
        bills.content.forEach(function (obj) {
          obj.isChecked = false
        });
        this.billDataForSelectionCheck = bills.content;
        this.allBills = bills.content;
        // if (this.selectedTransporter && this.selectedTransporter.transporterId) {
        //   this.filterByTransporter();
        // }
        this.dataLoading = false;

      }, () => {
        this.dataLoading = false;
        this.notifierService.notify('error', "Error fetching bills..");
      });
    }
  }


  pageChanged(event) {
    this.dataLoading = true;
    this.filteredBills.data = [];
    let selBill = this.selectedBillsDataArray;
    // this.isAllSelected = true;
    this.page = event.pageIndex;
    if (this.isPageAllSelected[this.page] == true) {
      this.isAllSelected = true;
    }
    else
      this.isAllSelected = false;
    if (this.userRole == 'transporter') {
      this.billingService.getBillsByTypesAndDateRange(this.fromDate, this.toDate, this.billType, [this.getTransporterByUserNameRes.resource.resourceTypeRef], this.page).subscribe((bills: any) => {
        this.totalRecords = bills.totalElements;
        this.findUniqeLR(bills.content);
        this.filteredBills.data = bills.content;
        bills.content.forEach(function (obj) {
          obj.isChecked = false
        });

        this.billDataForSelectionCheck = bills.content;
        /*this filters selected bills that is ticked bills so that when user
   clicks next or prev button the selected bills won't loss*/
        this.billDataForSelectionCheck.filter(r => {
          selBill.forEach(l => {
            if (l.billId == r.billId) {
              r.isChecked = true;
            }
          });
        });
        this.allBills = bills.content;
        this.filteredBills.data = bills.content;
        this.dataLoading = false;
      }, () => {
        this.dataLoading = false;
        this.notifierService.notify('error', "Error fetching bills..");
      });
    } else {
      let tmp = [];
      this.allTransportersList.forEach(element => {
        tmp.push(element.transporterId);
      });
      this.billingService.getBillsByTypesAndDateRange(this.fromDate, this.toDate, this.billType, tmp, this.page).subscribe((bills: any) => {
        this.totalRecords = bills.totalElements;
        this.findUniqeLR(bills.content);
        this.filteredBills.data = bills.content;
        bills.content.forEach(function (obj) {
          obj.isChecked = false
        });

        this.billDataForSelectionCheck = bills.content;
        /*this filters selected bills that is ticked bills so that when user
   clicks next or prev button the selected bills won't loss*/
        this.billDataForSelectionCheck.filter(r => {
          selBill.forEach(l => {
            if (l.billId == r.billId) {
              r.isChecked = true;
            }
          });
        });
        this.allBills = bills.content;
        this.filteredBills.data = bills.content;;
        // if (this.selectedTransporter && this.selectedTransporter.transporterId) {
        //   this.filterByTransporter();
        // }
        this.dataLoading = false;
      }, () => {
        this.dataLoading = false;
        this.notifierService.notify('error', "Error fetching bills..");
      });
    }

  }

  selectAll(event) {
    if (event.checked) {
      this.isPageAllSelected[this.page] = true;
      this.isAllSelected = true;
      this.billDataForSelectionCheck.forEach(function (obj) {
        if ((obj.podCompletionPercent != 100) || (obj.bState == 'BILL_STATE_RAISED'))
          obj.isChecked = false;
        else
          obj.isChecked = true;
      });

      this.billDataForSelectionCheck.forEach(bill => {
        //skip bill if already exist
        if ((bill.podCompletionPercent != 0) && (bill.bState != 'BILL_STATE_RAISED')) {
          let isBillExistIndex = this.selectedBillsDataArray.findIndex(i => i.billId == bill.billId);
          if (isBillExistIndex == -1) {
            this.selectedBillsDataArray.push(bill);
          }
        }
      });
    }
    else {
      this.isPageAllSelected[this.page] = false;
      this.isAllSelected = false;
      this.billDataForSelectionCheck.forEach(function (obj) { obj.isChecked = false; });

      //remove unselected bills from already selected bills
      this.billDataForSelectionCheck.forEach(bill => {
        let removeIndex = this.selectedBillsDataArray.findIndex(i => i.billId == bill.billId);
        this.selectedBillsDataArray.splice(removeIndex, 1);
      });
      this.filteredBills.data = this.billDataForSelectionCheck;
    }
  }

  selectBill(bill, Event) {
    let foundBill = this.billDataForSelectionCheck.find(billCheck => billCheck.billId == bill.billId);
    if (Event.checked) {
      let index = this.billDataForSelectionCheck.indexOf(foundBill);
      this.billDataForSelectionCheck[index].isChecked = true;
      this.selectedBillsDataArray.push(bill);
    } else if (!Event.checked) {
      if (this.isPageAllSelected[this.page] == true) {
        this.isAllSelected = false;
        this.isPageAllSelected[this.page] = false;
      }
      let index = this.billDataForSelectionCheck.indexOf(foundBill);
      let removeIndex = this.selectedBillsDataArray.findIndex(i => i.billId == bill.billId);
      this.billDataForSelectionCheck[index].isChecked = false;
      this.selectedBillsDataArray.splice(removeIndex, 1);
    }
    this.filteredBills.data = this.billDataForSelectionCheck;
    this.checkAllBillsAreAlreadySelectedFromFilter();

  }

  checkAllBillsAreAlreadySelectedFromFilter() {
    for (let i = 0; i < this.billDataForSelectionCheck.length; i++) {
      let findBill = this.selectedBillsDataArray.findIndex(sel => sel.billId === this.billDataForSelectionCheck[i].billId);
      if (findBill == -1) {
        this.isAllSelected = false;
        this.isPageAllSelected[this.page] = false;
        break;
      }
      else {
        this.isAllSelected = true;
        this.isPageAllSelected[this.page] = true;
      }
    }
  }

  clearAllFilter() {
    this.selectedTransporter = undefined;
    this.isTransporterFilter = false;
    this.isDateFilter = false;
    this.page = 0;
    this.paginator.pageIndex = 0;
    this.setDatePickerToCurrentDate();
    this.fromDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.toDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    if (this.userRole == 'transporter') {
      this.getBillData(this.allTransportersList);
      this.getBillCount(this.allTransportersList);
    } else {
      this.getAllTransporters();
    }
  }

  setDatePickerToCurrentDate() {
    this.dateRangePickeroptions = {
      theme: 'default',
      labels: ['Start', 'End'],
      menu: null,
      dateFormat: 'YYYY-MM-DD',
      outputFormat: 'DD-MM-YYYY',
      startOfWeek: 1,
      outputType: 'object',
      locale: 'en',
      date: {
        from: {
          year: new Date().getFullYear(),
          month: new Date().getMonth() + 1,
          day: new Date().getDate()
        },
        to: {
          year: new Date().getFullYear(),
          month: new Date().getMonth() + 1,
          day: new Date().getDate()
        }
      }
    };
    this.dateRangeValue.from = this.datePipe.transform(new Date(), 'dd-MM-yyyy');
    this.dateRangeValue.to = this.datePipe.transform(new Date(), 'dd-MM-yyyy');
  }

  exportAsXLSX() {
    this.dataForExcel(this.filteredBills.data);
    this.excelService.exportAsExcelFile(this.excelRecords, this.billType + 'Bills');
  }

  dataForExcel(filteredBills) {
    this.excelRecords = [];
    filteredBills.forEach(bill => {
      let date, from, to, transporter, trucktype, lr, invoice, noofcases, totalamount, status, freightRate, unloadingCharges, clubbingCharges, shipmentNo;

      date = this.datePipe.transform(bill.createdDateTime, "dd-MM-yyyy hh:mm a");
      from = bill.origin;
      to = bill.destination;
      transporter = bill.transporterName;
      shipmentNo = bill.tripName.slice(5, bill.tripName.length)
      if (bill.billCosts) {
        freightRate = bill.billCosts.freightRate;
        unloadingCharges = bill.billCosts.unloadingCharges;
        clubbingCharges = bill.billCosts.clubbingCharges;
      }
      if (bill.billDetails) {
        bill.billDetails.forEach(detail => {
          if (lr && invoice) {
            lr = lr + "," + detail.lorryReceiptNumber;
            invoice = invoice + "," + detail.orderInvoiceNumber;
          }
          else {
            lr = detail.lorryReceiptNumber;
            invoice = detail.orderInvoiceNumber;
          }
        });
      }
      noofcases = bill.numContainers;
      totalamount = bill.billCosts.totalCharges;
      status = bill.bState.slice(11);
      if (bill) {
        this.excelRecords.push({
          'Date': date,
          'From': from,
          'To': to,
          'Shipment No': shipmentNo,
          'Transporter': transporter,
          'Truck No.': bill.vehicleNumber || '',
          'Truck Type': bill.vehicleType || '',
          'LR#': lr,
          'Invoice#': invoice,
          'No of Cases': noofcases,
          'Freight Rate': freightRate,
          'Unloading Charges': unloadingCharges,
          'Clubbing Charges': clubbingCharges,
          'Total Amount': totalamount,
          'Status': status
        })
      }
    });
  }

  findUniqeLR(bills: Array<Bill>) {
    bills.forEach(bill => {
      let lrArray = [];
      bill.billDetails.forEach(billDetail => {
        lrArray.push(billDetail.lorryReceiptNumber);
      });
      bill.uniqueLRs = lrArray.filter((v, i, a) => a.indexOf(v) === i);
    });
  }

  applyFilter(filterValue: string) {
    this.filteredBills.filter = filterValue.trim().toLowerCase();
  }

  ngOnDestroy() {
    this.branchesSubscription.unsubscribe();
  }
}
