
import { Component, Input, OnChanges, SimpleChanges, ViewChild, Output, EventEmitter } from '@angular/core';
import { FileElement } from '../model/file-element';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { Observable } from 'rxjs/Observable';
import { MatDialog } from '@angular/material/dialog';
import { ImagePopup } from '../../order/allorderdetails/allorderdetails.component';
// import { NewFolderDialogComponent } from './modals/newFolderDialog/newFolderDialog.component';
// import { RenameDialogComponent } from './modals/renameDialog/renameDialog.component';

@Component({
  selector: 'app-filemanager',
  templateUrl: './filemanager.component.html',
  styleUrls: ['./filemanager.component.scss']
})
export class FileManagerComponent implements OnChanges {
  constructor(public dialog: MatDialog) {}

  @Input() fileElements: FileElement[];
  @Input() canNavigateUp: string;
  @Input() path: string;
  @Input() isLoading: boolean;

  /* @Output() folderAdded = new EventEmitter<{ name: string }>();
  @Output() elementRemoved = new EventEmitter<FileElement>();
  @Output() elementRenamed = new EventEmitter<FileElement>(); */
  @Output() navigatedDown = new EventEmitter<FileElement>();
  // @Output() elementMoved = new EventEmitter<{ element: FileElement; moveTo: FileElement }>();
  @Output() navigatedUp = new EventEmitter();
cols = 4;
  ngOnChanges(changes: SimpleChanges): void {
    if(!this.path)
     this.cols = 4;
  }

/*   deleteElement(element: FileElement) {
    this.elementRemoved.emit(element);
  } */

  navigate(element: FileElement) {
    if (element.isFolder) {
      this.navigatedDown.emit(element);
    }
    if (element.isLast)
     this.cols = 1;
  }

  navigateUp() {
    this.cols = 4;
    this.navigatedUp.emit();
  }

 /*  moveElement(element: FileElement, moveTo: FileElement) {
    this.elementMoved.emit({ element: element, moveTo: moveTo });
  } 

  openNewFolderDialog() {
    let dialogRef = this.dialog.open(NewFolderDialogComponent);
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.folderAdded.emit({ name: res });
      }
    }); 
  }

  openRenameDialog(element: FileElement) {
    let dialogRef = this.dialog.open(RenameDialogComponent);
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        element.name = res;
        this.elementRenamed.emit(element);
      }
    }); 
  }

 openMenu(event: MouseEvent, element: FileElement, viewChild: MatMenuTrigger) {
    event.preventDefault();
    viewChild.openMenu();
  } */

  openImage(imageData,selectedIndex) {
    const dialogR = this.dialog.open(ImagePopup, {
      
      data: {
        imageData:imageData,
        selectedIndex:selectedIndex
      },
      width:"50%"
    });
    dialogR.updatePosition({
      top: '50px'
    });
  }
}




