import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { TripService } from '../../services/trip/trip.service';
import { OrderService } from 'src/app/services/order/order.service';
import { FileService } from 'src/app/services/fileexplorer/file.service';
import { FileElement } from './model/file-element';
import { NgxDateRangePickerOptions } from 'src/ngx-daterangepicker';
import { DatePipe } from '@angular/common';
import { BranchService } from 'src/app/services/branch/branch.service';
import { CustomerService } from 'src/app/services/customer/customer.service';
import { takeUntil } from 'rxjs/operators';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { UploadfileService } from 'src/app/services/uploadfile/uploadfile.service';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { JobsService } from 'src/app/services/jobs/jobs.service';
import { ExcelgeneratorService } from 'src/app/services/excelgenerator/excelgenerator.service';

@Component({
  selector: 'app-podexplorer',
  templateUrl: './podexplorer.component.html',
  styleUrls: ['./podexplorer.component.scss']
})

export class PodexplorerComponent implements OnInit {
  public fileElements: Observable<FileElement[]>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(public fileService: FileService,
    private orderService: OrderService,
    private datePipe: DatePipe,
    private tripService: TripService,
    private branchService: BranchService,
    private customerService: CustomerService,
    private spinnerService: NgxSpinnerService,
    private s3UploadService: UploadfileService,
    public dialogService: DialogService,
    private jobService: JobsService,
    public excelService: ExcelgeneratorService
  ) { }

  currentRoot: FileElement;
  currentPath: string;
  canNavigateUp = false;
  folderIdArray = []
  isLoading: boolean = false;
  allBranchesNames = [];
  fromDate: any;
  toDate: any;
  prevFromDate: any;
  prevTodate: any;
  dateRangevalue: any = { to: "", from: "" };
  options: NgxDateRangePickerOptions;
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  allCustomerTypes = [];
  allTrips = [];
  selectedBranch = [];
  selectedCustomerType = [];
  branchesSubscription: Subscription;
  tripsDataArray = new MatTableDataSource();
  @ViewChild(MatSort) sort: MatSort;

  isFolderView: boolean = false;
  isListView: boolean = true;
  totalRecords: number = 0;
  podAuditLogs: any = undefined;
  excelRecords = [];

  columnsToDisplay = [
    'branch',
    'customer',
    'customerType',
    'date',
    'trip',
    'invoice',
    'lr',
    'podStatus',
    'auditStatus',
    'upload'
  ];

  ngOnInit() {
    this.setDatePickerToCurrentDate();
    this.getAllBranches();
    this.getAllCustomersForCustomerType();
    if (window.sessionStorage.getItem('customerid')) {
      this.isFolderView = false;
      this.isListView = true;
    }
  }

  tripDetails(tripId) {
    this.dialogService.tripDetails(tripId, 'orderDetails');
  }


  getAllBranches() {
    this.branchesSubscription = this.branchService.branchesObservable.subscribe(branches => {
      this.allBranchesNames = branches;
      this.allBranchesNames.sort(function (a, b) {
        let branchName1 = a.branchName.toLowerCase(), branchName2 = b.branchName.toLowerCase();
        if (branchName1 < branchName2) { //sort string ascending
          return -1;
        } else if (branchName1 > branchName2) {
          return 1;
        } else {
          return 0;
        }
      });
      if (window.sessionStorage.getItem("selectedBranchesCodes") && JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")).length == 0) {
        this.clearFolderStructure();
        this.tripsDataArray.data = [];
        this.totalRecords = 0;
      }
      else {
        this.clearFolderStructure();
        this.tripsDataArray.data = [];
        this.totalRecords = 0;
        this.findSelectedDateTrips();
      }
    });
  }

  applyFilter(filterValue: string) {

    this.tripsDataArray.filter = filterValue.trim().toLowerCase();

  }

  getAllCustomersForCustomerType() {
    this.customerService.getAllCustomerTypeName().subscribe(customerTypes => {
      if (window.sessionStorage.getItem('customerid')) {
        this.selectedCustomerType = customerTypes;
      }
      this.allCustomerTypes = customerTypes;
    })
  }

  setDatePickerToCurrentDate() {
    this.options = {
      theme: 'default',
      labels: ['Start', 'End'],
      menu: null,
      dateFormat: 'YYYY-MM-DD',
      outputFormat: 'DD-MM-YYYY',
      startOfWeek: 1,
      outputType: 'object',
      locale: 'en',
      date: {
        from: {
          year: new Date().getFullYear(),
          month: new Date().getMonth() + 1,
          day: new Date().getDate()
        },
        to: {
          year: new Date().getFullYear(),
          month: new Date().getMonth() + 1,
          day: new Date().getDate()
        }
      }
    };
    this.fromDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.toDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.dateRangevalue.from = this.datePipe.transform(new Date(), 'dd-MM-yyyy');
    this.dateRangevalue.to = this.datePipe.transform(new Date(), 'dd-MM-yyyy');
    this.prevFromDate = this.fromDate;
    this.prevTodate = this.toDate;
  }


  sortByDate() {
    if (this.dateRangevalue) {
      this.fromDate = this.dateRangevalue.from.substring(6, 10) + '-' + this.dateRangevalue.from.substring(3, 5) + '-' + this.dateRangevalue.from.substring(0, 2);
      this.toDate = this.dateRangevalue.to.substring(6, 10) + '-' + this.dateRangevalue.to.substring(3, 5) + '-' + this.dateRangevalue.to.substring(0, 2);
      //call the function only if todate or fromdate or zone value changes 
      if (this.toDate != this.prevTodate || this.fromDate != this.prevFromDate) {
        this.ngUnsubscribe.next();
        this.findSelectedDateTrips();
        this.prevFromDate = this.fromDate;
        this.prevTodate = this.toDate;
      }
    }
  }

  findSelectedDateTrips() {
    this.clearFolderStructure();
    this.currentRoot = null;
    this.currentPath = ""
    this.canNavigateUp = false;
    this.isLoading = true;
    this.allTrips = [];
    this.tripsDataArray.data = [];
    this.tripService.getTripsForPod(this.fromDate, this.toDate).pipe(takeUntil(this.ngUnsubscribe)).subscribe(trips => {
      console.log("All Trips", trips)
      if (window.sessionStorage.getItem('customerid')) {
        this.allTrips = trips.filter(trip => trip.customerid == window.sessionStorage.getItem('customerid'));
      } else {
        this.allTrips = trips;
      }
      if (this.selectedBranch.length > 0 && this.selectedCustomerType.length > 0)
        this.createTripFolder();
      this.isLoading = false;
    }, Error => {
      this.clearFolderStructure();
      this.isLoading = false;
    });
  }

  branchSelected(event) {
    this.selectedBranch = event;
    if (this.selectedCustomerType.length > 0 && this.selectedBranch.length > 0) {
      this.createTripFolder()
    }
    else {
      this.clearFolderStructure();
      this.tripsDataArray.data = [];
      this.totalRecords = 0;
    }
  }


  customerTypeSelected(event) {
    this.selectedCustomerType = event;
    if (this.selectedBranch.length > 0 && this.selectedCustomerType.length > 0) {
      // this.createTripFolder()
      this.createTripFolder();
    }
    else {
      this.clearFolderStructure();
      this.tripsDataArray.data = [];
      this.totalRecords = 0;
    }
  }

  createTripFolder() {
    this.clearFolderStructure();
    let filteredOrders = [], filteredTrips = [];
    this.folderIdArray = [];
    //to find trips for selected branch
    this.selectedBranch.forEach(branch => {
      let tripForBranch = []
      tripForBranch = this.allTrips.filter(trip => trip.branch_name == branch);
      filteredTrips = filteredTrips.concat(tripForBranch)
    });

    this.selectedCustomerType.forEach(cType => {
      let jobForCustomerType = []
      jobForCustomerType = filteredTrips.filter(job => job.customer_type == cType)
      filteredOrders = filteredOrders.concat(jobForCustomerType)
    });

    //for list view data
    this.totalRecords = filteredOrders.length;
    this.tripsDataArray.data = filteredOrders;
    console.log(filteredOrders)
    this.tripsDataArray.sortingDataAccessor = (item: any, property) => {
      switch (property) {
        case 'branch': return item.branch_name + " " + item.branch_code;
        case 'customer': return item.customer_name;
      }
    };

    setTimeout(() => this.tripsDataArray.paginator = this.paginator);
    this.tripsDataArray.sort = this.sort;
    filteredOrders.forEach(order => {
      if (!this.folderIdArray[order.trip_name]) {
        this.folderIdArray[order.trip_name] = this.fileService.add({ name: order.trip_name, isFolder: true, parent: 'root' });
      }
      if (!(this.folderIdArray[order.customer_name] && this.folderIdArray[order.customer_name].parent == this.folderIdArray[order.trip_name].id)) {
        this.folderIdArray[order.customer_name] = this.fileService.add({ name: order.customer_name, isFolder: true, parent: this.folderIdArray[order.trip_name].id });
      }
      this.fileService.add({ name: order.order_invoice_number, isFolder: true, parent: this.folderIdArray[order.customer_name].id, isLast: true, isLoaded: false, orderId: order.order_unique_id });
    })
    this.updateFileElementQuery();

  }

  navigateToFolder(element: FileElement) {
    this.currentRoot = element;
    if (element.isLast && !element.isLoaded) {
      this.getPODImageLinks(element)
    }
    else {
      this.updateFileElementQuery();
      this.currentPath = this.pushToPath(this.currentPath, element.name);
      this.canNavigateUp = true;
    }
  }

  getPODImageLinks(element: FileElement) {
    let podList = [], tpodList = [];
    this.isLoading = true;
    this.updateFileElementQuery();
    this.orderService.eSignImage(element.orderId).subscribe(esign => {
      if (esign && esign.length > 0) {
        this.fileService.add({ name: esign[0], isEsign: true, isFolder: false, parent: element.id, isLast: false })
      }
    }, () => {
      this.isLoading = false;
    });
    this.orderService.podImageData(element.orderId).subscribe(res => {
      if (res && res.length > 0) {
        res.forEach(podlink => {
          if (podlink.url.includes('transporter')) {
            tpodList.push(podlink);
          }
          else
            podList.push(podlink);
        });
        if (tpodList.length > 0)
          this.fileService.add({ name: tpodList, isTransporter: true, isFolder: false, parent: element.id, isLast: false })
        if (podList.length > 0)
          this.fileService.add({ name: podList, isTransporter: false, isFolder: false, parent: element.id, isLast: false })
        this.isLoading = false;
        element.isLoaded = true;
        this.fileService.update(element.id, element);
        this.updateFileElementQuery();
        this.currentPath = this.pushToPath(this.currentPath, element.name.toString());
        this.canNavigateUp = true;
      }
    }, () => {
      this.isLoading = false;
    });
  }

  navigateUp() {
    if (this.currentRoot && this.currentRoot.parent === 'root') {
      this.currentRoot = null;
      this.canNavigateUp = false;
      this.updateFileElementQuery();
    } else {
      this.currentRoot = this.fileService.get(this.currentRoot.parent);
      this.updateFileElementQuery();
    }
    this.currentPath = this.popFromPath(this.currentPath);
  }


  updateFileElementQuery() {
    this.fileElements = this.fileService.queryInFolder(this.currentRoot ? this.currentRoot.id : 'root');
  }

  pushToPath(path: string, folderTripName: string) {
    let p = path ? path : '';
    p += `${folderTripName}/`;
    return p;
  }

  popFromPath(path: string) {
    let p = path ? path : '';
    let split = p.split('/');
    split.splice(split.length - 2, 1);
    p = split.join('/');
    return p;
  }

  clearFolderStructure() {
    this.fileService.clear();
    this.updateFileElementQuery();
    this.currentRoot = null;
    this.currentPath = ""
    this.canNavigateUp = false;
  }

  ngOnDestroy() {
    this.branchesSubscription.unsubscribe();
  }

  ngAfterViewInit() {
    // this.tripsDataArray.sortingDataAccessor = (item: any, property) => {
    //   switch (property) {
    //     case 'branch': return item.branch_name + " " + item.branch_code;
    //     case 'customer': return item.customer_name;
    //   }
    // };
    // this.tripsDataArray.sort = this.sort;
    this.tripsDataArray.paginator = this.paginator;

  }

  toggleView(value) {
    if (value == 'folder') {
      this.isFolderView = true;
      this.isListView = false;
    }
    else {
      this.isFolderView = false;
      this.isListView = true;
      this.applyFilter("");
      this.tripsDataArray.paginator = this.paginator;
    }
  }
  async handleFiles(event, jobRef, index) {
    let file = event.target.files[0];
    this.spinnerService.show();
    await this.s3UploadService.uploadImage(file, jobRef);
    this.spinnerService.hide();
  }

  orderDetails(data) {
    this.dialogService.orderDetails(data, 'orderReport');
  }

  fetchBillAudits(jobId) {
    this.podAuditLogs = undefined;
    this.jobService.getPodAudit(jobId).subscribe(res => {
      console.log(res);
      this.podAuditLogs = res;
    }, Error => {
    })
  }

  setPodAuditUndefined() {
    this.podAuditLogs = undefined;
  }

  exportAsXLSX() {
    this.excelRecords = [];
    let podExplorerData = [];
    podExplorerData = this.tripsDataArray.data;
    podExplorerData.forEach(order => {
      let isPodAvailable = "No", auditStatus = "";
      if (order.pod_completion_percent > 0) {
        isPodAvailable = "Yes"
      }
      if (order.is_pod_valid) {
        auditStatus = "Clean"
      }
      else if (order.is_pod_valid == false) {
        auditStatus = "Unclean"
      }
      if (order) {
        if (window.sessionStorage.getItem('customerid')) {
          this.excelRecords.push({
            'Branch': order.branch_name || "",
            'Customer': order.customer_name || "",
            'Customer Type': order.customer_type || "",
            'Date': this.datePipe.transform(order.trip_created_date_time, "dd-MM-yyyy hh:mm a"),
            'Invoice #': order.order_invoice_number || "",
            'LR #': order.lorry_receipt || "",
            'POD Available?': isPodAvailable,
            'Clean/Unclean': auditStatus,
            'User Name': order.pod_uploaded_by || "",
            'User Type': order.role_type || "",
            'Date of Change': order.date_of_change || "",
          });
        } else {
          this.excelRecords.push({
            'Branch': order.branch_name || "",
            'Customer': order.customer_name || "",
            'Customer Type': order.customer_type || "",
            'Date': this.datePipe.transform(order.trip_created_date_time, "dd-MM-yyyy hh:mm a"),
            'Trip #': order.trip_name || "",
            'Invoice #': order.order_invoice_number || "",
            'LR #': order.lorry_receipt || "",
            'POD Available?': isPodAvailable,
            'Clean/Unclean': auditStatus,
            'User Name': order.pod_uploaded_by || "",
            'User Type': order.role_type || "",
            'Date of Change': order.date_of_change || "",
          });
        }
      }
    });
    this.excelService.exportAsExcelFile(this.excelRecords, 'PodSummary');
  }

}