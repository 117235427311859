import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PlanService {

  constructor(
    private http: HttpClient,
  ) { }

  createPlan(autoPlannerInput: any): Observable<any> {
    return this.http.post(environment.appUrl + '/autoplan', autoPlannerInput, { observe: 'response' });
  }

  getPlanById(planId): Observable<any> {
    return this.http.get(environment.appUrl + '/plans/id/' + planId);
  }

  getCurrentPlan(): Observable<any> {
    return this.http.get(environment.appUrl + '/plans/current');
  }

  getComparePlan(planId): Observable<any> {
    return this.http.get(environment.appUrl + '/plans/compare/' + planId);
  }

  getPlansByDateAndState(planState, pageNumber: number, from: Date, to: Date) {
    return this.http.get(environment.appUrl + '/plans/typedaterange?page=' + pageNumber + '&types=' + planState + '&fromDate=' + from + '&toDate=' + to);
  }

  approvePlan(planId: number): Observable<any> {
    return this.http.put(environment.appUrl + '/plans/' + planId + '/approve', null);
  }

  deletePlan(planId: number): Observable<any> {
    return this.http.delete(environment.appUrl + '/plans/' + planId + '/delete');
  }

  getPlansCompletionStatus(hashId): Observable<any> {
    return this.http.get(environment.appUrl + '/plans/progress/' + hashId, { observe: 'response' });
  }

  getReportPlanSummary(fromDate, toDate): Observable<any> {
    return this.http.get(environment.appUrl + '/reports/plansummary?fromDate=' + fromDate + '&toDate=' + toDate + "&branchCodes=" + JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")));
  }

  getPlanFromTrip(tripId): Observable<any> {
    return this.http.get(environment.appUrl + '/plans/trip/' + tripId)
  }

}
