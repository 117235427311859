import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { CustomerService } from 'src/app/services/customer/customer.service';
import { OrderService } from 'src/app/services/order/order.service';
import { ValidatorService } from 'src/app/services/validator/validator.service';
import { BranchService } from 'src/app/services/branch/branch.service';
import { ZoneService } from 'src/app/services/zone/zone.service';
import { TokenStorage } from 'src/app/auth/token.storage';
import { NotifierService } from 'angular-notifier';


@Component({
  selector: 'app-customer',
  templateUrl: './addcustomer.component.html',
  styleUrls: ['./addcustomer.component.scss']
})
export class AddcustomerComponent implements OnInit {

  config = {
    placeholder: 'Select'
  }

  customerForm: FormGroup;
  buList = ['Industrial'];


  //activity log
  activityLog = [];
  activityCreatedLog = [];
  activityUpdatedLog = [];
  allZones = [];
  isDuplicateCustomerCode: boolean = false;
  disableSubmitButton = false;
  allBranches = [];
  allCustomerTypes = [];
  customerType: boolean;
  isSuhana:boolean = false;

  constructor(
    formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private router: Router,
    private customerService: CustomerService,
    private branchService: BranchService,
    private zoneService: ZoneService,
    private token: TokenStorage,
    private notifierService: NotifierService,
  ) {
    if (this.token.getTenant() == 'suhana-dist-test' || this.token.getTenant() == 'suhana-dist-prod') {
      this.isSuhana = true;
    }
    this.customerForm = formBuilder.group({
      'customerCode': [null, [
        Validators.required,
        ValidatorService.whitespaceValidator
      ]],
      'customerName': [null, [
        Validators.required,
        ValidatorService.whitespaceValidator
      ]],
      'customerType': [null, [Validators.required]],
      'deliveryLocationType': [null, [
        Validators.required
      ]],
      'houseNumber': [null, [
        Validators.required,
        ValidatorService.whitespaceValidator
      ]],
      'city': [null,
        [Validators.pattern("^[a-zA-Z]+$"),
        ValidatorService.whitespaceValidator
        ]],
      'country': [null,
        [Validators.pattern("^[a-zA-Z]+$"),
        ValidatorService.whitespaceValidator
        ]],
      'area': [null, [
        ValidatorService.whitespaceValidator
      ]],
      'zone': [null, [
        Validators.required
      ]],
      'contactPerson': [null, [
        Validators.pattern("^[a-zA-Z ]+$"),
        Validators.pattern("^[a-zA-Z]+( [a-zA-Z]+)*$"),
        ValidatorService.whitespaceValidator
      ]],
      'phoneNo': [null, [
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern('^[0-9]*$')
      ]],
      'email': [null, [
        Validators.email]
      ],
      'pincode': [null, [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(10),
        Validators.pattern('^[0-9]*$')
      ]],
      'bu': [null],
      'branches': [null, [
        Validators.required]],
      'unloadingcharges': [null, [
        Validators.minLength(1),
        Validators.maxLength(15),
        Validators.pattern('^[0-9]*$')
      ]]
    });

  }
  openSnackBar(message) {
    this.snackBar.open(message, 'ok', {
      duration: 2000,
      panelClass: ['extraClasses']
    });
  }

  ngOnInit() {
    this.zoneService.getAllZones().subscribe(zones => {
      this.allZones = zones;
      console.log(zones);
    });

    this.customerService.getAllCustomersType().subscribe(allCustomerTypes => {
      this.allCustomerTypes = [];
      this.allCustomerTypes = allCustomerTypes;
    });

    this.branchService.branchesObservable.subscribe(allBranches => {
      this.allBranches = allBranches;

      this.allBranches.sort(function (a, b) {
        let branchArea1 = a.branchName.toLowerCase(), branchArea2 = b.branchName.toLowerCase()
        if (branchArea1 < branchArea2)
          return -1
        if (branchArea1 > branchArea2)
          return 1
        return 0
      })
    });
  }

  onSelectCustomerType() {
    this.customerType = true;
  }

  addCustomer() {
    debugger;
    this.disableSubmitButton = true;
    let address = {}, customerConstraints = {};
    customerConstraints = {
      customerConstraintsId: null,
      deliveryTimeEnd: null,
      deliveryTimeStart: null,
      needsDedicatedVehicle: null,
      unloadingCharges: Number(this.customerForm.value.unloadingcharges)
    }
    address = {
      houseNumber: this.customerForm.value.houseNumber,
      area: this.customerForm.value.area,
      city: this.customerForm.value.city,
      pincode: this.customerForm.value.pincode,
      country: this.customerForm.value.country,
    }
    delete this.customerForm.value.houseNumber;
    delete this.customerForm.value.area;
    delete this.customerForm.value.city;
    delete this.customerForm.value.pincode;
    delete this.customerForm.value.country;
    delete this.customerForm.value.unloadingcharges;

    this.customerForm.value.customerConstraints = customerConstraints;
    this.customerForm.value.address = address;
    console.log("form value", this.customerForm.value);

    this.customerService.AddCustomer(this.customerForm.value).subscribe(() => {
      this.openSnackBar("Customer: " + this.customerForm.value.customerName + " added successfully.");
      this.notifierService.notify('success', 'Customer saved successfully.');
      
      this.router.navigateByUrl("/managecustomers");
    }, Error => {
      console.log(Error.error.Error);
      this.notifierService.notify('error', Error.error.Error);
      this.disableSubmitButton = false;
    });
  }

  checkForDuplicateCode(event) {
    this.customerService.checkDuplicateCustomerCode(event.srcElement.value).subscribe(isAvailable => {
      console.log(isAvailable);
      if (isAvailable) {
        this.isDuplicateCustomerCode = true;
      }
      else
        this.isDuplicateCustomerCode = false;
    });
  }
  typingCustomerCode() {
    this.isDuplicateCustomerCode = false;
  }
}