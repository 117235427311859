import { Component } from '@angular/core';
import { UsersService } from "../services/users/users.service";
import { MatDialog } from '@angular/material';
import { ForgotpasswordComponent } from '../forgotpassword/forgotpassword.component';
import { Router } from '@angular/router';
import { TokenStorage } from '../auth/token.storage';
import { StoreUserDetails } from '../auth/storeuserdetails';
import { NotificationService } from '../services/notification/notification.service';
import { MessagingService } from '../shared/messaging.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { ErrorExcelUpload } from '../pages/schedulingandplanning/schedulingandplanning.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent {

  userName: string;
  password: string;
  loginError: boolean = false;
  loginErrorMessage: string;
  showPwd: boolean = false;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private usersService: UsersService,
    public tokenStorage: TokenStorage,
    public userDetails: StoreUserDetails,
    private notificationService: NotificationService,
    private messagingService: MessagingService,
    private permissionsService: NgxPermissionsService,
  ) { }

  openDialog() {
    let dialogRef = this.dialog.open(ForgotpasswordComponent, {
      width: '600px',
    });

    dialogRef.afterClosed().subscribe(() => {
    });
  }

  loginUser() {
    let loginData = {
      username: this.userName,
      password: this.password
    }
    debugger;
    console.log("username and password : "+ this.userName + " - " + this.password);
    if (this.userName === undefined || this.password === undefined) {
      this.loginError = true;
      this.loginErrorMessage = "Username/Password cannot be empty."
    } else {
      this.usersService.makeLogin(loginData).subscribe(res => {
        console.log(res);
        this.userDetails.saveUser(this.userName);
        this.tokenStorage.saveToken(res);
        if (this.messagingService.token) {
          this.notificationService.subscribeToTopic(this.messagingService.token).subscribe(() => {
          });
        }

        this.usersService.getTenantUsers(this.userName).subscribe(userdetails => {
          if (userdetails) {
            this.tokenStorage.saveTenant(userdetails.tenantName);
          }
          this.usersService.getRolesByUsers().subscribe(allRoles => {
            let permissions = [];
            if (allRoles && allRoles.length != 0) {
              if (allRoles[allRoles.length - 1].roleName === 'delivery_associate') {
                this.dialog.open(ErrorExcelUpload, {
                  width: '312px',
                  maxHeight: '80vh',
                  data: {
                    Result: "User access denied.\nPlease contact your Administrator.",
                  }
                });
                window.sessionStorage.removeItem('Tenant');
                window.sessionStorage.clear();
                this.router.navigateByUrl('login');
              } else {
                permissions.push(allRoles[allRoles.length - 1].roleName);
                window.sessionStorage.setItem('permissions', JSON.stringify(permissions))
                this.permissionsService.loadPermissions(permissions);
                if (allRoles[allRoles.length - 1].roleName == 'customer') {
                  window.sessionStorage.setItem('customerid', userdetails.resource.resourceTypeRef)
                  this.router.navigate(['order']);
                }
                else if (allRoles[allRoles.length - 1].roleName == 'transporter') {
                  this.router.navigate(['billing']);
                }
                else if (allRoles[allRoles.length - 1].roleName == 'key_account_manager') {
                  this.router.navigate(['order']);
                }
                else if (allRoles[allRoles.length - 1].roleName == 'finance_admin') {
                  this.router.navigate(['podexplorer']);
                }
                else {
                  this.router.navigate(['/']);
                }
              }
            }else{
              console.log("Role Issue:", allRoles);
            }
          });
        });
      }, errorResponse => {
        this.loginError = true;
        if (errorResponse.status == 401) {
          this.loginErrorMessage = errorResponse.error;
        } else {
          this.loginErrorMessage = "Invalid Login.";
        }
      });
    }
  }

  togglePwd() {
    this.showPwd = !this.showPwd;
  }
}