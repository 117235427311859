import { Component, OnInit } from '@angular/core';
import { GeofenceService } from 'src/app/services/geofence/geofence.service';
import { MatTableDataSource } from '@angular/material';
import { CustomerService } from 'src/app/services/customer/customer.service';

@Component({
  selector: 'app-customergeofence',
  templateUrl: './customergeofence.component.html',
  styleUrls: ['./customergeofence.component.scss']
})
export class CustomergeofenceComponent implements OnInit {
  allGeofenceData = new MatTableDataSource;
  allGeofenceCustomerData = [];
  circleColor = '#ffffb3';
  strokeColor  ='#ffff99';
  lat: number = 18.533989;
  lng: number = 73.825592;
  allShown: boolean;
  columnsToDisplay = [
    'checkbox',
    'customerName',
    'geofenceName',
    'geofenceType',
    'dimensions'
  ];
  constructor(public geofenceService: GeofenceService,
    public customerService: CustomerService) { }

  ngOnInit() {
    this.allShown = false;
    this.customerService.getAllCustomers().subscribe(allgeofences=>{
      console.log("allgeofences",allgeofences);
      allgeofences.forEach(customer => {
        customer.visible = false;
        if(customer.geofences[0])
          customer.geofences[0].type = (customer.geofences[0].type).split("_").pop();
     });
      this.allGeofenceCustomerData = allgeofences;
      this.allGeofenceData.data = allgeofences;
   });
  }

  customerSelected(customer, event){
    console.log(customer,this.allShown)
    if(this.allShown){
      this.allShown = false;
      if(event.checked){
        this.allGeofenceCustomerData.forEach(d => {
          if(d.geofences[0] &&  customer.geofences[0]){
          if(d.geofences[0].geofenceId !== customer.geofences[0].geofenceId){
            d.visible = false;
          }
        }
        });
      }
    }else{
      if(event.checked){
        this.allGeofenceCustomerData.forEach(d => {
          if(d.geofences[0] &&  customer.geofences[0]){
          if(d.geofences[0].geofenceId == customer.geofences[0].geofenceId){
            d.visible = true;
          }
        }
        });
      }else{
        let count = 0;
        this.allGeofenceCustomerData.forEach(d => {
          if(d.geofences[0] &&  customer.geofences[0]){
          if(d.geofences[0].geofenceId == customer.geofences[0].geofenceId){
            d.visible = false;
          }
          if(!d.visible){
            count++;
          }
        }
        });
        if(count == this.allGeofenceCustomerData.length){
          this.allShown = true;
          this.allGeofenceCustomerData.forEach(d => {
            d.visible = true;
          });
        }
      }  
    }
  }

}
