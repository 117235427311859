import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatDialog, MatSort } from '@angular/material';
import { CustomerService } from 'src/app/services/customer/customer.service';
import 'rxjs/add/observable/of';
import { EditcustomerComponent } from '../editcustomer/editcustomer.component';
import { FleetService } from 'src/app/services/fleet/fleet.service';
import { TokenStorage } from 'src/app/auth/token.storage';
import { ExcelgeneratorService } from 'src/app/services/excelgenerator/excelgenerator.service';
import { Subscription, Subject } from 'rxjs';
import { BranchService } from 'src/app/services/branch/branch.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-allcustomers',
  templateUrl: './allcustomers.component.html',
  styleUrls: ['./allcustomers.component.scss']
})
export class AllcustomersComponent implements OnInit {
  customerDataArray = new MatTableDataSource();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  vehicleTypes = [];
  isKcc: boolean = false;
  isLoading: boolean = false;
  excelRecords = [];
  branchesSubscription: Subscription;
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  isHeretech: boolean = false;
  isSuhana: boolean = false;
  isWarehouseManager: boolean = false;
  columnsToDisplay = [
    'customerName',
    'customerCode',
    'customerType',
    'branches',
    'city',
    'zone',
    'unloadingCharges',
    'serviceWindow',
    'geoCoded',
    'edit'
  ];

  constructor(private dialog: MatDialog,
    private customerService: CustomerService,
    private fleetService: FleetService,
    private token: TokenStorage,
    private excelService: ExcelgeneratorService,
    private branchService: BranchService
  ) {
    if (this.token.getTenant() == 'kimberly-dist-prod' || this.token.getTenant() == 'kimberly-dist-test') {
      this.isKcc = true;
    }
    if (this.token.getTenant() == 'heretech-dist-test' || this.token.getTenant() == 'heretech-dist-prod') {
      this.isHeretech = true;
    }

    if (this.token.getTenant() == 'suhana-dist-test' || this.token.getTenant() == 'suhana-dist-prod') {
      this.isSuhana = true;
    }
  }

  ngOnInit() {
    if(window.sessionStorage.getItem('permissions').substr(2).slice(0, -2)=='warehouse_manager'){
      this.isWarehouseManager= true;
    }
    this.isLoading = true;
    this.branchesSubscription = this.branchService.branchesObservable.subscribe(() => {
      this.ngUnsubscribe.next();
      if (window.sessionStorage.getItem("selectedBranchesCodes") && JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")).length == 0) {
        this.customerDataArray.data = [];
        this.isLoading = false;
      }
      else {
    this.getAllCustomers();
      }
    });

    this.customerDataArray.paginator = this.paginator;
    this.customerDataArray.sortingDataAccessor = (item: any, property) => {
      switch (property) {
        case 'customerName': return item.customerName;
        case 'city': return item.address.city;
        case 'zone':
          if (item.zone)
            return item.zone.zoneName;
        case 'unloadingCharges':
          if (item.customerConstraints)
            return item.customerConstraints.unloadingCharges;
      }
    };
    this.customerDataArray.sort = this.sort;


    if (!this.isKcc) {
      this.fleetService.getVehicleTypes().subscribe(vehicletypes => {
        vehicletypes.forEach(vehicle => {
          this.vehicleTypes.push(vehicle.vehicleType);
        });
        this.vehicleTypes.sort(function (a, b) {
          let vehicle1 = a.toLowerCase(), vehicle2 = b.toLowerCase()
          if (vehicle1 < vehicle2) //sort string ascending
            return -1
          if (vehicle1 > vehicle2)
            return 1
          return 0
        });
      });
    }

  }

  getAllCustomers(){
    this.customerDataArray.data = [];
    this.isLoading = true;
    this.customerService.getAllCustomers().pipe(takeUntil(this.ngUnsubscribe)).subscribe(allCustomers => {
      this.isLoading = false;
      console.log("all", allCustomers)
      this.customerDataArray.data = allCustomers.reverse();
    }, Error => {
      this.isLoading = false;
    });
  }

  applyFilter(filterValue: string) {
    this.customerDataArray.filter = filterValue.trim().toLowerCase();
    this.customerDataArray.filterPredicate = (data: any, filter: string) => {
      if (data) {
        return (data.address && data.address.city && data.address.city.toString().toLowerCase().indexOf(filter) != -1) ||
          data.customerName.toString().toLowerCase().indexOf(filter) != -1 ||
          data.customerCode.toString().toLowerCase().indexOf(filter) != -1 ||
          data.customerType.toString().toLowerCase().indexOf(filter) != -1
      }
    };
  }

  exportAsXLSX() {
    this.excelRecords = [];
    let customersData = [];
    customersData = this.customerDataArray.data;
    customersData.forEach(customer => {
      let customerType, branches, city, zoneName, zoneCode, unloadingCharges;


      if (customer.customerType)
        customerType = customer.customerType;
      else
        customerType = "";

      if (customer.address && customer.address.city)
        city = customer.address.city;
      else
        city = "";

      if (customer.zone && customer.zone.zoneName)
        zoneName = customer.zone.zoneName;
      else
        zoneName = ""

      if (customer.zone && customer.zone.zoneCode)
        zoneCode = customer.zone.zoneCode;
      else
        zoneCode = ""
    //Commented by Digvijay 
        // customer.branches.forEach(branch => {
        //   if (branch.branchName && branch.branchCode)
        //     branches = branch.branchName + "-" + branch.branchCode + "\n"
        //   else
        //     branches = branch.branchName + "\n"
        // });

      if (customer.customerConstraints && customer.customerConstraints.unloadingCharges)
        unloadingCharges = customer.customerConstraints.unloadingCharges
      else
        unloadingCharges = ''

      // if (customer) {
      //   this.excelRecords.push({
      //     'Name': customer.customerName,
      //     'Code': customer.customerCode,
      //     'Type': customerType,
      //     'Branches': branchesList,
      //     'City': city,
      //     'Zone Name': zoneName,
      //     'Zone Code': zoneCode,
      //     'Unloading Charges': unloadingCharges
      //   })
      // }
      
      //Digvijay - Commented above code and added below code
      customer.branches.forEach(branch=> {
        if(customer){
          this.excelRecords.push({
            'Name': customer.customerName,
            'Code': customer.customerCode,
            'Type': customerType,
            'Branches': (branch.branchName && branch.branchCode) ? branch.branchName + "-" + branch.branchCode : branch.branchName,
            'City': city,
            'Zone Name': zoneName,
            'Zone Code': zoneCode,
            'Unloading Charges': unloadingCharges
          })
        }
      });
    });
    this.excelService.exportAsExcelFile(this.excelRecords, 'customerrecords');
  }

  editCustomer(customer) {
    let editDialog = this.dialog.open(EditcustomerComponent, {
      width: '800px',
      data: customer,
      id: 'editCustomerWindow'
    });
    editDialog.updatePosition({
      top: '50px',
    });
    editDialog.afterClosed().subscribe(result => {
      console.log(result);
      if(result){
      this.getAllCustomers();
      }
    });
  }

  ngOnDestroy() {
    this.branchesSubscription.unsubscribe();
  }
}
