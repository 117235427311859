import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatSnackBar, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { UsersService } from '../../../services/users/users.service';
import { ResourceService } from '../../../services/resource/resource.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ValidatorService } from 'src/app/services/validator/validator.service';
import { BranchService } from 'src/app/services/branch/branch.service';
import { ResolveEnd } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotifierService } from 'angular-notifier';



@Component({
  selector: 'app-edituser',
  templateUrl: './edituser.component.html',
  styleUrls: ['./edituser.component.scss']
})
export class EdituserComponent implements OnInit {

  userForm: FormGroup;
  allBranchesNames = [];
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  showPwd: boolean = false;
  isCompany: boolean = true;
  isCustomer: boolean = false;
  isTransporter: boolean = false;
  allTransporters = [];
  allCustomers: any;
  allDesignations: any;
  isCustomerValue: boolean = false;
  isTransporterValue: boolean = false;
  isDesignation: boolean = true;
  alreadySelectedBranches = [];
  alreadySelectedRoles = [];
  allRoles = [];
  isEdited: boolean = false;

  configDesignation = {
    search: true,
    displayKey: 'resourceDesignation',
    searchOnKey: 'resourceDesignation',
    height: '40vh',
    placeholder: 'Select Designation'
  }


  constructor(
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private userService: UsersService,
    private resourceService: ResourceService,
    private dialog: MatDialog,
    private branchService: BranchService,
    private spinnerService: NgxSpinnerService,
    private notifierService: NotifierService,
    @Inject(MAT_DIALOG_DATA) public data: any

  ) {
    this.userForm = formBuilder.group({
      'firstName': [null, [
        Validators.required,
        Validators.pattern("^[A-Za-z]+$"),
      ]],
      'lastName': [null, [
        Validators.pattern("^[A-Za-z]+$"),
      ]],
      'address': [null, [
        ValidatorService.whitespaceValidator
      ]],
      'phoneNumber': [null, [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern('^[0-9]*$')
      ]],
      'emailId': [null, Validators.email
      ],
      'resourceType': [null],
      'designation': [null],
      'language': [null],
      'branchNames': [null, [
        Validators.required]],
      'roles': [null, [
        Validators.required]],
      'username': [[
        Validators.required,
      ]],
      'password': [null, [
        Validators.minLength(8),
        Validators.maxLength(32),
      ]],

    })
  }

  ngOnInit() {
    console.log(this.data);
    this.data = this.data.user;
    this.userService.getManageableRoles().subscribe(roles => {
      this.allRoles = roles;
    })

    if (this.data.resource.designation) {
      let category = this.data.resource.designation.category;
      this.resourceService.getManageableDesignation().subscribe(designations => {
        console.log("designations", designations);
        this.allDesignations = designations;
        this.allDesignations = this.allDesignations.filter(function (des) {
          return des.category === category;
        });
      })
    }

    this.branchService.branchesObservable.pipe(takeUntil(this.ngUnsubscribe)).subscribe(allBranches => {
      this.allBranchesNames = allBranches;
      this.allBranchesNames.sort(function (a, b) {
        let branchName1 = a.branchName.toLowerCase(), branchName2 = b.branchName.toLowerCase();
        if (branchName1 < branchName2) { //sort string ascending
          return -1;
        } else if (branchName1 > branchName2) {
          return 1;
        } else {
          return 0;
        }
      });
    });
    this.data.branches.forEach(branch => {
      this.alreadySelectedBranches.push(branch.branchName);
    });

    this.data.roles.forEach(role => {
      this.alreadySelectedRoles.push(role.roleName);
    });

    this.userForm.patchValue({
      firstName: this.data.resource.firstName,
      lastName: this.data.resource.lastName,
      address: this.data.resource.address,
      phoneNumber: this.data.resource.phoneNumber,
      emailId: this.data.emailId,
      resourceType: this.data.resource.resourceType,
      resourceTypeRef: this.data.resource.resourceTypeRef,
      designation: this.data.resource.designation,
      language: this.data.resource.language,
      branchNames: this.alreadySelectedBranches,
      username: this.data.username,
      roles: this.alreadySelectedRoles
    })
  }

  getDesignations(category) {
    this.allDesignations = this.allDesignations.filter(function (des) {
      return des.category === category;
    });
    console.log("filtered designations", this.allDesignations);
  }

  togglePwd() {
    this.showPwd = !this.showPwd;
  }

  editUser() {
    this.spinnerService.show();
    let roles = [];
    this.allRoles.forEach(roleObj => {
      this.alreadySelectedRoles.forEach(role => {
        if (role == roleObj.roleName) {
          roles.push(roleObj);
        }
      });
    });

    this.userForm.value.roles = roles;

    this.userForm.value.resource = {
      firstName: this.userForm.value.firstName,
      lastName: this.userForm.value.lastName,
      phoneNumber: this.userForm.value.phoneNumber,
      emailId: this.userForm.value.emailId,
      address: this.userForm.value.address,
      resourceType: this.data.resource.resourceType,
      resourceTypeRef: this.data.resource.resourceTypeRef,
      designation: this.userForm.value.designation,
      resourceId: this.data.resource.resourceId
    }

    delete this.userForm.value.firstName;
    delete this.userForm.value.lastName;
    delete this.userForm.value.phoneNumber;
    delete this.userForm.value.emailId;
    delete this.userForm.value.address;
    delete this.userForm.value.resourceType;
    delete this.userForm.value.customerType;
    delete this.userForm.value.resourceTypeRef;
    delete this.userForm.value.designation;
    delete this.userForm.value.language;
    delete this.userForm.value.phoneNumber;
    delete this.userForm.value.emailId;
    if (!this.userForm.value.password || this.userForm.value.password.trim() == '') {
      delete this.userForm.value.password
    }
    console.log("form value", this.userForm.value);
    this.userService.editUser(this.userForm.value).subscribe(res => {
      this.isEdited = true;
      console.log(res);
      this.spinnerService.hide();
      this.notifierService.notify('success', 'User edited successfully.');
      this.dialog.getDialogById('editUserWindow').close(true);
    }, error => {
      this.spinnerService.hide();
      console.log(error);
      if (error.status === 400)
        this.notifierService.notify('error', error.error.Result);
      else
        this.notifierService.notify('error', 'Error in Editing user ! Please try again later');
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
