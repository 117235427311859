import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { MatDialog, MatDialogRef, } from '@angular/material/dialog';
import { RoleService } from '../../../services/role/role.service';
import { UsersService } from '../../../services/users/users.service';
@Component({
  selector: 'app-assignrole',
  templateUrl: './assignrole.component.html',
  styleUrls: ['./assignrole.component.scss']
})
export class AssignroleComponent implements OnInit {

  showFiller = false;

  columnsToDisplay = [
    'username',
    'firstname',
    'lastname',
    'associatedUnit',
    'role'
  ];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  totalRecords: number;
  usersDataArray = new MatTableDataSource;
  selectedRowIndex: number = -1;
  constructor(
    public dialog: MatDialog,
    public roleService: RoleService,
    public userService: UsersService,
  ) {

  }

  ngOnInit() {
    this.userService.getAllUsers().subscribe(res => {
      this.totalRecords = res.length;
      this.usersDataArray.data = res.reverse();
    });

    
  this.usersDataArray.sortingDataAccessor = (item: any, property) => {
      switch (property) {
        case 'username': return item.username;
        case 'firstname': return item.resource.firstName;
        case 'lastname':
          if(item.resource.lastName)
          {
             return item.resource.lastName;
          }
        }
    };
    this.usersDataArray.sort = this.sort; 
  }

  manageRole(userid) {
    console.log("User Id = " + userid);
    this.selectedRowIndex = userid;
    this.roleService.sendUserId(userid);
  }

  ngAfterViewInit() {
    if (this.usersDataArray)
      this.usersDataArray.paginator = this.paginator;
  }

  applyFilter(filterValue: string) {
    this.usersDataArray.filter = filterValue.trim().toLowerCase();
  }

}
