import { Injectable } from '@angular/core';
import { Http, Headers, URLSearchParams, Response, HttpModule, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  base_url: string;
  constructor(
    private http: HttpClient,
  ) {
    this.base_url = environment.appUrl + "/customer";
  }

  getAllCustomers(): Observable<any> {
    if ((window.sessionStorage.getItem("selectedBranchesCodes") && window.sessionStorage.getItem("totalUserBranches") == JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")).length) || !window.sessionStorage.getItem("selectedBranchesCodes") || JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")).length == 0) {
      return this.http.get(environment.appUrl + "/customers");
    }
    else {
      return this.http.get(environment.appUrl + "/customers?branchCodes=" + JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")));
    }
  }

  AddCustomer(customerObj): Observable<any> {
    return this.http.post(this.base_url, customerObj);
  }

  checkDuplicateCustomerCode(customercode): Observable<any> {
    return this.http.get(this.base_url + "/" + customercode);
  }

  updateCustomer(customer): Observable<any> {
    return this.http.put(this.base_url, customer);
  }

  getAllCustomersType(): Observable<any> {
    return this.http.get(environment.appUrl + "/customertype");
  }

  getAllCustomerTypeName(): Observable<any> {
    return this.http.get(environment.appUrl + "/customertype/name");
  }

  updateCustomerConstraints(customer): Observable<any> {
    return this.http.put(this.base_url + '/constraints', customer);
  }

  getCustomerConstraints(): Observable<any> {
    return this.http.get(environment.appUrl + '/constraints/customer');
  }
}