import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidatorService {

  constructor() { }

  
  public static whitespaceValidator(control: FormControl) {
    let isValid : boolean;
    if (control && control.value && (control.value.length > 0)) {
      if (control.value.toString().trim().length === 0) {
        isValid = false;
      } else {
        isValid = true;
      }  
    } else {
      isValid = true;
    }
    return isValid ? null : { 'whitespace': true };
  }
}
