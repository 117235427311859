import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material";

@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss']          
  })
  export class MapComponent implements OnInit {

    constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
    ) { }
  
    ngOnInit() {
      console.log(this.data);
    }
  }