import { Component, OnInit, ViewChild, Inject, Optional, Injector, Renderer2, Output, EventEmitter, Input } from '@angular/core';
import {
  MatPaginator, MatTableDataSource,
  MatDialog, MatDialogRef, MAT_DIALOG_DATA
} from '@angular/material';
import 'rxjs/add/observable/of';
import { OrderService } from '../../../services/order/order.service';
import { TokenStorage } from '../../../../../src/app/auth/token.storage';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { DomSanitizer } from '@angular/platform-browser';
import { NotifierService } from 'angular-notifier';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { UploadfileService } from 'src/app/services/uploadfile/uploadfile.service';
import { DatePipe } from '@angular/common';
import * as JSZip from 'jszip';
import * as JSZipUtils from 'jszip-utils';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-allorderdetails',
  templateUrl: './allorderdetails.component.html',
  styleUrls: ['./allorderdetails.component.scss']
})
export class AllorderdetailsComponent implements OnInit {
  columnsToDisplay = [
    'SKU',
    'Category',
    'packageCapacity'
  ];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  orderId: number;
  jobref: number;
  receiverName: string;
  orderState: string;
  podStatus: string;
  podremarks: string;
  invoiceNumber: string;
  bookingId: string;
  orderCreatedTimestamp: string;
  deliveryDatetime: string;
  totalweightCapacity: number;
  totalvolumeCapacity: number;
  dispatchDate: string;
  orderBillingDate: string;
  actualDeliveryDate: string;
  deliveryETADateTime: string;
  houseNumber: string;
  area: string;
  city: string;
  pincode: string;
  zone: string;
  zoom: number = 13;
  lat: number = 18.533989;
  lng: number = 73.825592;
  order: any;
  productDataArray = new MatTableDataSource;
  orderNumber: string;
  ordersByTripIdArray = [];
  productArrayInSelectedOrder = [];
  productInputDisplayArray = [];
  public receiverAddress: any;
  public originAddress: any;
  customCollapsedHeight: string = '35px';
  customExpandedHeight: string = '35px';
  width: string;
  height: string;
  driverName: string;
  deliveryFailureReason: string;
  customerFeedback: string;
  score: number = 0;
  volUnit: string;
  range = [1, 2, 3, 4, 5]
  LRnum: any;
  numberOfBoxes: number = 0;
  isPackageDetailsToBeShown: boolean = false;
  Kcc: string;
  isMetro: boolean = false;
  isGodrej: boolean = false;
  podList = [];
  transporterPodList = [];
  isInvoiceEntered: boolean = false;
  orderInvoiceNumber: any;
  eSign: any;
  isOpenedFromTripDetails: boolean = false;
  tripName: string;
  tripId: number;
  poNumber: string;
  isHeretech: boolean = false;
  selectedTab: string = 'PRODUCT_DETAILS';
  issueInDelivery: any = [
    {
      name: 'Item Shortages',
      flag: false
    },
    {
      name: 'Item Damage',
      flag: false
    },
    {
      name: 'Delayed Delivery',
      flag: false
    }
  ];
  selectionPODAuditFlag: number = -1;
  tellUsMore: any;
  podAuditFlag: boolean = false;
  index = 0;
  isSuhana: boolean = false;
  isCipla: boolean = false;
  isAbccorp: boolean = false;
  markDeliveredDateTime: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public orderService: OrderService,
    public dialog: MatDialog,
    private token: TokenStorage,
    private injector: Injector,
    private sanitizer: DomSanitizer,
    private notifierService: NotifierService,
    public router: Router,
    private spinnerService: NgxSpinnerService,
    private s3UploadService: UploadfileService,
    private datePipe: DatePipe,
    private renderer: Renderer2,
  ) {
    if (window.sessionStorage.getItem('orderDetailCount')) {
      this.isOpenedFromTripDetails = true;
      let count = Number(window.sessionStorage.getItem('orderDetailCount')) - 1;
      if (dialog.getDialogById('orderDetail' + count.toString())) {
        dialog.getDialogById('orderDetail' + count.toString()).close()
      }
    }

    this.order = this.data.data;

    if (this.data.component == 'tripDetails')
      this.isOpenedFromTripDetails = true;

    if (this.token.getTenant() == 'kimberly-dist-prod') {
      this.volUnit = 'CM';
      this.Kcc = this.token.getTenant();
    }
    else if (this.token.getTenant() == 'metro-dist-prod' || this.token.getTenant() == 'metro-dist-test') {
      this.isMetro = true;
      this.isPackageDetailsToBeShown = true;
      this.volUnit = 'CF';
    }
    else if (this.token.getTenant() == 'godrej-dist-prod' || this.token.getTenant() == 'godrej-dist-test') {
      this.volUnit = 'CCM';
      this.isGodrej = true;
    }
    else if (this.token.getTenant() == 'heretech-dist-prod' || this.token.getTenant() == 'heretech-dist-test') {
      this.isHeretech = true;
      this.volUnit = 'CF';
    }
    else {
      this.volUnit = 'CF';
    }

    if (this.token.getTenant() == 'suhana-dist-test' || this.token.getTenant() == 'suhana-dist-prod') {
      this.isSuhana = true;
    }
    // if (this.token.getTenant() == 'cipla-dist-test' || this.token.getTenant() == 'cipla-dist-prod') {
      if (this.token.getTenant() == 'cipla-dist-test-siliguri'||this.token.getTenant() == 'cipla-dist-test' || this.token.getTenant() == 'cipla-dist-prod') {//Digvijay
      this.isCipla = true;
    }
    console.log("isSuhana--" + this.isSuhana)
  }

  ngOnInit() {
    this.markDeliveredDateTime = this.toDateString(new Date());

    if (this.token.getTenant() == 'abc-dist-prod' || this.token.getTenant() == 'abc-dist-test') {
      this.isPackageDetailsToBeShown = true;
      this.isAbccorp = true;
    }
    if (this.data.component == 'tripDetails' || this.data.component == 'EditPlan') {
      this.orderDetails();
    } else {
      this.orderService.getOrderDetails(this.data.data).subscribe(orderData => {
        this.data.data = orderData;
        this.order = orderData.orderInfo;
        this.orderDetails();
      });
    }

  }

  private toDateString(date: Date): string {
    return (date.getFullYear().toString() + '-'
      + ("0" + (date.getMonth() + 1)).slice(-2) + '-'
      + ("0" + (date.getDate())).slice(-2))
      + 'T' + date.toTimeString().slice(0, 5);
  }

  orderDetails() {
    console.log("order details", this.data);
    this.tripName = this.data.data.tripRefName;
    this.tripId = this.data.data.tripRefId;
    this.poNumber = this.order.purchaseOrder;
    if (this.order.lorryReceipt)
      this.LRnum = this.order.lorryReceipt.lorryReceiptNumber;
    this.orderId = this.order.orderUniqueId;
    this.jobref = this.data.data.jobRef;
    this.receiverName = this.order.customerInfo.customerName;
    this.receiverAddress = this.order.customerInfo.address;
    this.originAddress = this.order.originBranch.branchName + " " + this.order.originBranch.branchCode;
    if (this.order.customerInfo.zone)
      this.zone = this.order.customerInfo.zone.zoneName;
    else
      this.zone = this.order.customerInfo.address.city;
    this.orderState = (this.order.oState).slice(12);
    this.invoiceNumber = this.order.orderInvoiceNumber;
    this.bookingId = this.order.orderInvoiceID;

    this.orderCreatedTimestamp = this.order.orderCreatedDateTime;
    this.orderBillingDate = this.order.orderDispatchDatetime;
    this.deliveryDatetime = this.order.deliveryETADateTime;
    this.deliveryETADateTime = this.order.deliveryETADateTime;
    this.totalvolumeCapacity = this.order.totalVolume;
    this.totalweightCapacity = this.order.totalWeight;
    // this.dispatchDate = this.order.orderDispatchDatetime;
    if (this.order.orderTransaction) {
      this.actualDeliveryDate = this.order.orderTransaction.orderDeliveredDateTime;
      this.dispatchDate = this.order.orderTransaction.orderInTransitDateTime;
    }

    this.numberOfBoxes = this.order.num_of_containers;

    this.getAllProductsFromOrdersInTrip();
    if (this.orderState === "DELIVERED") {
      this.getPODImageLinks();
    }

    if (this.orderState === "FAILED") {
      this.orderService.getJobDetails(this.jobref).subscribe(jobRes => {
        this.deliveryFailureReason = jobRes.reason;
      });
    }


    if (this.orderState === "DELIVERED") {
      this.orderService.getCustomerFeedack(this.orderId).subscribe(feedback => {
        if (feedback) {
          this.customerFeedback = feedback.feedbackComments;
          let rating = feedback.feedbackRating.substr(feedback.feedbackRating.length - 1);
          this.score = rating;
        }
      });

      if (this.data.data.podStatus == true)
        this.podStatus = 'Clean';
      else if (this.data.data.podStatus == false)
        this.podStatus = 'Unclean';
      else
        this.podStatus = 'N/A';
    }
    this.podremarks = this.data.data.podRemarks;
  }

  public isMarked = (index) => {
    if (this.score >= index + 1) {
      return 'fa-star';
    }
    else {
      return 'fa-star-o';
    }
  }

  markDeliveredOrder(orderId) {
    this.spinnerService.show();
    this.orderService.updateDeliveryStatus(orderId, this.datePipe.transform(this.markDeliveredDateTime, "yyyy-MM-dd HH:mm:ss")).subscribe(orderDelivered => {
      // console.log(orderDelivered);
      this.notifierService.notify('success', "Order Delivered Successfully.");
      this.orderService.getOrderDetails(this.orderId).subscribe(orderData => {
        this.spinnerService.hide();
        this.data.data = orderData;
        this.order = orderData.orderInfo;
        this.orderDetails();
      }, Error => {
        this.spinnerService.hide();
      });
    }, Error => {
      this.spinnerService.hide();
      this.notifierService.notify('error', "Failed to update order delivery status.");
    })
  }


  getPODImageLinks() {
    this.orderService.podImageData(this.orderId).subscribe(res => {
      this.spinnerService.hide();
      this.podList = [];
      this.transporterPodList = [];
      res.forEach(podlink => {
        if (podlink.url.includes('transporter')) {
          podlink.imageUrl = podlink.url;
          let extension = podlink.url.split('?')[0].split('.').pop();
          podlink.url = podlink.url;
          podlink.extension = extension
          this.transporterPodList.push(podlink);
        }
        else {
          podlink.imageUrl = podlink.url;
          let extension = podlink.url.split('?')[0].split('.').pop();
          podlink.url = podlink.url;
          podlink.extension = extension;
          this.podList.push(podlink);
        }
      });
    }, Error => {
      this.spinnerService.hide();
      console.log(Error);
    });
    this.orderService.eSignImage(this.orderId).subscribe(esign => {
      if (esign && esign.length > 0)
        this.eSign = esign;
    });
  }

  openImage(imageData, selectedIndex) {
    const dialogR = this.dialog.open(ImagePopup, {

      data: {
        imageData: imageData,
        selectedIndex: selectedIndex
      },
      width: "50%",
      height: "98%"
    });
    dialogR.updatePosition({
      top: '50px'
    });
  }

  getAllProductsFromOrdersInTrip() {
    if (this.data.component == 'orderReport' || this.data.component == 'orderlistview') {
      this.productDataArray.data = this.data.data.prodQList;
    }
    else {
      this.orderService.getAllProductsInOrder(this.orderId).subscribe(res => {
        this.productDataArray.data = res;
      }, Error => {
        console.log(Error);
      });
    }
  }

  setOrderInvoiceNumber(event) {
    if (event.target.value) {
      this.isInvoiceEntered = true;
      if (!this.data.data.hasOwnProperty('orderInfo')) {
        let info = this.data.data;
        this.data.data = {};
        this.data.data.orderInfo = info;
        this.data.data.prodQList = this.productDataArray.data;
        this.data.data.orderInfo.orderInvoiceNumber = event.target.value;
      }
      else
        this.data.data.orderInfo.orderInvoiceNumber = event.target.value;

    }
    else {
      this.isInvoiceEntered = false;
    }
  }

  validateDetails() {
    if (this.tellUsMore.trim() == "") {
      this.tellUsMore = "";
    }
  }

  updateOrderInvoiceNum() {
    if (this.isInvoiceEntered == true) {
      this.orderService.orderUpdate(this.data.data).subscribe(orderUpdated => {
        console.log(orderUpdated);
      }, Error => {
        console.log(Error);
      });
    }
  }

  tripDetails(tripId) {
    this.injector.get(DialogService).tripDetails(tripId, 'orderDetails')
  }

  toggleButtonTrigger(val) {
    // alert(val)
    this.selectedTab = val;
  }

  selectionPODAudit(flag) {
    this.selectionPODAuditFlag = flag
  }

  selected_issues(index, flag) {
    if (flag)
      this.issueInDelivery[index].flag = false;
    else
      this.issueInDelivery[index].flag = true;
  }

  savePODAudit() {
    let podValid;
    if (this.selectionPODAuditFlag == 0)
      podValid = true;
    else
      podValid = false;

    let remark = "";

    if (!podValid) {
      this.issueInDelivery.forEach(item => {
        if (item.flag) {
          if (remark == "") {
            remark = item.name;
          }
          else {
            remark += ' and ' + item.name;
          }
        }
      });
    }

    if (remark == "") {
      remark = this.tellUsMore;
    }
    else {
      remark += ' and ' + this.tellUsMore;
    }
    console.log("podValid")
    console.log(podValid)
    console.log("remark")
    console.log(this.jobref)
    //updatePODAudit


    this.orderService.updatePODAudit(this.jobref, podValid, remark).subscribe(data => {
      console.log(data);
      this.notifierService.notify("success", "POD Audit updated successfully!");
      this.podAuditFlag = true;
      // this.dialog.closeAll();
      // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      //   this.router.navigate(["/order"]));

    }, Error => {
      console.log(Error);
    });
  }

  async handleFiles(event, orderId, jobRef) {
    /*   this.index = this.index + 1;
      let file = event.target.files[0];
  
      this.spinnerService.show();
      const imageData = new FormData();
  
      imageData.append("imageFile", file, file.name);
      await this.s3UploadService.uploadPodViaSftp(imageData, orderId, jobRef);
      if (this.orderState === "DELIVERED") {
        this.getPODImageLinks();
      } */

    this.index = this.index + 1;
    let file = event.target.files[0];

    this.spinnerService.show();
    await this.s3UploadService.uploadImage(file, jobRef);
    if (this.orderState === "DELIVERED") {
      this.getPODImageLinks();
    }
  }

  downLoadPOD() {
    var zip = new JSZip();
    var count = 0;
    var zipFilename = this.invoiceNumber.toString() + ".zip";
    console.log(this.transporterPodList)
    let links = [], allPod = [];
    if (this.transporterPodList.length > 0 && this.podList.length > 0) {
      allPod = this.transporterPodList.concat(this.podList)
    }
    if (this.transporterPodList.length <= 0 && this.podList.length > 0) {
      allPod = this.podList;
    }
    if (this.transporterPodList.length > 0 && this.podList.length <= 0) {
      allPod = this.transporterPodList;
    }
    allPod.forEach(link => {
      links.push(link.url.substring(0, link.url.indexOf('?')))
      //links.push(link.url)
    })
    console.log(links)

    links.forEach(function (url, i) {
      var filename = links[i];
      filename = filename.replace(/.*\//g, "");
      // loading a file and add it in a zip file
      JSZipUtils.getBinaryContent(url, function (err, data) {
        if (err) {
          throw err; // or handle the error
        }
        zip.folder("").file(filename, data, { binary: true, createFolders: false });
        count++;
        if (count == links.length) {
          zip.generateAsync({ type: 'blob' }).then(function (content) {
            console.log(content);
            saveAs(content, zipFilename);
          });
        }
      });
    });
  }
}

//image popup component for POD
@Component({
  selector: 'image-popup',
  template: `
  <div>
  <button class="float-right" mat-dialog-close style="color:blue;">
    <mat-icon>close</mat-icon>
  </button>
</div>
  <div style="padding:20px;">
  <div class="row">
  <div class="col-1 text-white" style="display: flex !important;align-items: center !important;">
  <span *ngIf="data.selectedIndex > 0" (click)="previousData()"><mat-icon class="btn-slider">chevron_left</mat-icon></span>
  </div>
   <div class="col-10" style="overflow: auto">
   <div style=" max-height:480px">
      <img id="geeks" *ngIf="extension != 'pdf'" [src]="path" class="imagepreview" style="width:480px;height:480px;"/>
</div>
      <p class="m-1 text-center row" style=" position:fixed; bottom: 0; left: 40%" *ngIf="extension != 'pdf'">
        <img class="m-3 m-r-20" src="assets/images/zoom-in.png" matTooltip="Zoom In" (click)="zoomin()" style="width:25px;"/>
        <img class="m-3 m-r-20" src="assets/images/zoom-out.png" matTooltip="Zoom Out" (click)="zoomout()" style="width:25px;"/>
        <img class="m-3 m-r-20" src="assets/images/rotate-right.png" matTooltip="Rotate Right"  style="width:25px;" (click)="rotateImage('right')"/>
        <img class="m-3 m-r-20" src="assets/images/rotate-left.png" matTooltip="Rotate Left" style="width:25px;" (click)="rotateImage('left')"/>
      </p>
      <iframe *ngIf="extension == 'pdf'" [src]="domSanitize(path)" height="560" style="width:100%"></iframe>
   </div>
   <div class="col-1 text-white" style="display: flex !important;align-items: center !important;">
    <span *ngIf="data.selectedIndex < data.imageData.length-1"  (click)="nextData()" ><mat-icon class="btn-slider">chevron_right</mat-icon></span>
    </div>
   </div>
  </div>
  `,
  styles: ['button:focus {outline:0;} .btn-slider{background: #0b5486;border-radius: 50%;padding-top: 1px;cursor: pointer;}']
})

export class ImagePopup implements OnInit {
  constructor(
    public dialogR: MatDialogRef<ImagePopup>,
    private sanitizer: DomSanitizer,
    private renderer: Renderer2,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }
  path: string;
  extension: string;
  angle = 0;
  zoomClick = 0;

  ngOnInit() {
    console.log(this.data)
    this.path = this.data.imageData[this.data.selectedIndex].url;
    if (this.data.imageData[this.data.selectedIndex].extension) {
      this.extension = this.data.imageData[this.data.selectedIndex].extension;
    }
  }

  previousData() {
    this.path = '';
    this.extension = '';

    setTimeout(() => {
      this.data.selectedIndex--;
      console.log("Previous Image - " + this.data.selectedIndex)
      console.log(this.data.imageData[this.data.selectedIndex])
      this.path = this.data.imageData[this.data.selectedIndex].url;
      this.extension = this.data.imageData[this.data.selectedIndex].extension;
    }, 1);
  }

  nextData() {
    this.path = '';
    this.extension = '';

    setTimeout(() => {
      this.data.selectedIndex++;
      console.log("Next Image - " + this.data.selectedIndex)
      console.log(this.data.imageData[this.data.selectedIndex])
      this.path = this.data.imageData[this.data.selectedIndex].url;
      this.extension = this.data.imageData[this.data.selectedIndex].extension;
    }, 1);
  }


  rotateImage(direction) {
    this.angle += direction == 'left' ? -90 : 90;
    this.renderer.setStyle(document.querySelector('.imagepreview'), 'transform', `rotate(${this.angle}deg)`);
  }

  zoomin() {
    if (this.zoomClick < 16) {
      var GFG = document.getElementById("geeks");
      var currHeight = GFG.clientHeight;
      var currWidth = GFG.clientWidth;
      GFG.style.height = (currHeight + 20) + "px";
      GFG.style.width = (currWidth + 20) + "px";
      this.zoomClick++;
    }
  }

  zoomout() {
    if (this.zoomClick > -4) {
      var GFG = document.getElementById("geeks");
      var currHeight = GFG.clientHeight;
      var currWidth = GFG.clientWidth;
      GFG.style.height = (currHeight - 20) + "px";
      GFG.style.width = (currWidth - 20) + "px";
      this.zoomClick--;
    }

  }

  domSanitize(src) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(src)
  }
}