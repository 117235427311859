import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { JobsService } from 'src/app/services/jobs/jobs.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatTableDataSource } from '@angular/material';
import { TripService } from 'src/app/services/trip/trip.service';
import { NotifierService } from 'angular-notifier';
import { DialogService } from 'src/app/services/dialog/dialog.service';



@Component({
  selector: 'app-managejobs',
  templateUrl: './managejobs.component.html',
  styleUrls: ['./managejobs.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class ManagejobsComponent implements OnInit {

  columnsToDisplay = [
    'jobid',
    'status',
    'receiverDetails',
    'transporterDetails',
  ];
  jobsDataArray = new MatTableDataSource;
  page: number = 0;
  totalRecords: number; fromDate: any;
  toDate: any;
  jobsDataArrayForCheck = [];
  isLoading: boolean = false;
  selectedJobsDataArray: Array<any> = [];



  constructor(public datePipe: DatePipe,
    public jobService: JobsService,
    private tripService: TripService,
    private dialogService: DialogService,
    private notifierService: NotifierService
  ) { }

  ngOnInit() {
    let month, day, year, date, backdate, today;
    year = new Date().getFullYear();
    month = new Date().getMonth();
    date = new Date().getDate();
    if ((month - 3) <= 0) {
      month = 12 + (month - 3);
      year = new Date().getFullYear() - 1;
    }
    this.fromDate = this.datePipe.transform(new Date(year, month, date), 'yyyy-MM-dd');
    this.toDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.isLoading = true;
    this.getData().subscribe(allJobs => {
      if (allJobs) {
        this.totalRecords = allJobs.totalElements;
        let allO = allJobs.content;
        allO.forEach(function (obj) { obj.isChecked = false });
        allJobs.content.forEach(job => {
          this.tripService.getTripById(job.tripRef).subscribe(tripdetails => {
            job.transporter = tripdetails.transporterName,
              job.vehicle = tripdetails.assignedVehicleLicenseNumber,
              job.driver = tripdetails.assignedDriverName
          })

        });
        const rows = [];
        allJobs.content.forEach(element => rows.push(element, { detailRow: true, element }));
        this.isLoading = false;
        this.jobsDataArray.data = rows;
        this.jobsDataArrayForCheck = allO;
        console.log("data", allJobs);
      }
    });


  }

  isExpansionDetailRow = (i: number, row: Object) => row.hasOwnProperty('detailRow');
  expandedElement: any;

  getData() {
    //let types=['ORDER_STATE_UNPLANNED','ORDER_STATE_IN_PLANNING','ORDER_STATE_IN_TRANSIT','ORDER_STATE_SCHEDULED']
    return this.jobService.getAllJobssByTypesDateRange('JOB_STATE_IN_TRANSIT,JOB_STATE_SCHEDULED', this.page, this.fromDate, this.toDate, 'nozone');
  }

  pageChanged(event) {
    this.page = event.pageIndex;
    this.isLoading = true;
    this.jobsDataArray.data = [];
    this.getData().subscribe(allOrders => {
      if (allOrders) {
        let allO = allOrders.content;
        this.totalRecords = allOrders.totalElements;
        allO.forEach(function (obj) { obj.isChecked = false });
        const rows = [];
        allOrders.content.forEach(element => rows.push(element, { dispatchDate: element.orderInfo.deliveryETADateTime, detailRow: true, element }));
        let selOrder = this.selectedJobsDataArray;
        console.log(this.selectedJobsDataArray);
        rows.forEach(function (obj) { obj.isChecked = false });
        this.jobsDataArrayForCheck = allO;

        /*this filters selected orders that is ticked orders so that when user
         clicks next or prev button the selected order won't loss*/
        this.jobsDataArrayForCheck.filter(r => {
          selOrder.forEach(l => {
            if (l.orderUniqueId === r.orderInfo.orderUniqueId) {
              r.isChecked = true;
            }
          });
        });
        this.isLoading = false;
        this.jobsDataArray.data = rows;
      }
    }, Error => {
      this.jobFetchError();
    });

  }

  jobFetchError() {
    this.jobsDataArray.data = [];
    this.jobsDataArrayForCheck = [];
    this.isLoading = false;
    this.notifierService.notify("Error", "Error In Fetching Jobs. Please try after some time.")
  }

  orderDetails(data) {
    this.dialogService.orderDetails(data,'tripDetails');
  }

}
