import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatTableDataSource } from '@angular/material';
import { NotifierService } from 'angular-notifier';
import { StoreUserDetails } from 'src/app/auth/storeuserdetails';
import { TokenStorage } from 'src/app/auth/token.storage';
import { ExcelgeneratorService } from 'src/app/services/excelgenerator/excelgenerator.service';
import { PlanService } from 'src/app/services/plan/plan.service';
import { NgxDateRangePickerOptions } from 'src/ngx-daterangepicker';
import { BranchService } from 'src/app/services/branch/branch.service';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-planreport',
  templateUrl: './planreport.component.html',
  styleUrls: ['./planreport.component.scss']
})
export class PlanreportComponent implements OnInit {
  dataLoading: boolean = true;
  fromDate: any;
  toDate: any;
  prevFromDate: string;
  prevTodate: string;
  dateRangePickeroptions: NgxDateRangePickerOptions;
  filteredPlans: any = new MatTableDataSource();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  totalRecords: number = 0;
  isDateFilter: boolean = false;
  dateRangeValue: any = { to: "", from: "" };
  excelRecords = [];
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  branchesSubscription: Subscription;
  columnsToDisplay = [
    'Trip',
    'vehicle',
    'tripWeightVolume',
    'weightUtilization',
    'volumeUtilization',
    'zone',
    'tripcost'
  ];


  constructor(
    private planService: PlanService,
    private datePipe: DatePipe,
    private excelService: ExcelgeneratorService,
    private notifierService: NotifierService,
    private branchService: BranchService
  ) { }

  ngOnInit() {
    this.setDatePickerToCurrentDate();

    this.fromDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.toDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.branchesSubscription = this.branchService.branchesObservable.subscribe(() => {
      this.ngUnsubscribe.next();
      if (window.sessionStorage.getItem("selectedBranchesCodes") && JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")).length == 0) {
        this.filteredPlans.data = [];
        this.dataLoading = false;
      }
      else {
        this.filteredPlans.data = [];
        this.getPlanData();
      }
    });
  }

  setDatePickerToCurrentDate() {
    this.dateRangePickeroptions = {
      theme: 'default',
      labels: ['Start', 'End'],
      menu: null,
      dateFormat: 'YYYY-MM-DD',
      outputFormat: 'DD-MM-YYYY',
      startOfWeek: 1,
      outputType: 'object',
      locale: 'en',
      date: {
        from: {
          year: new Date().getFullYear(),
          month: new Date().getMonth() + 1,
          day: new Date().getDate()
        },
        to: {
          year: new Date().getFullYear(),
          month: new Date().getMonth() + 1,
          day: new Date().getDate()
        }
      }
    };
    this.dateRangeValue.from = this.datePipe.transform(new Date(), 'dd-MM-yyyy');
    this.dateRangeValue.to = this.datePipe.transform(new Date(), 'dd-MM-yyyy');
  }

  filterByDate() {
    if (this.dateRangeValue) {
      this.fromDate = this.dateRangeValue.from.substring(6, 10) + '-' + this.dateRangeValue.from.substring(3, 5) + '-' + this.dateRangeValue.from.substring(0, 2);
      this.toDate = this.dateRangeValue.to.substring(6, 10) + '-' + this.dateRangeValue.to.substring(3, 5) + '-' + this.dateRangeValue.to.substring(0, 2);
      if (this.toDate != this.prevTodate || this.fromDate != this.prevFromDate) {
        this.getPlanData();
      }
      this.prevFromDate = this.fromDate;
      this.prevTodate = this.toDate;
      this.isDateFilter = true;
    }
  }

  clearAllFilter() {
    this.isDateFilter = false;
    this.paginator.pageIndex = 0;
    this.setDatePickerToCurrentDate();
    this.fromDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.toDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.getPlanData();
  }

  getPlanData() {
    this.dataLoading = true;
    this.filteredPlans.data = []

    this.planService.getReportPlanSummary(this.fromDate, this.toDate).pipe(takeUntil(this.ngUnsubscribe)).subscribe((plans: any) => {
      // console.log(bills)
      this.totalRecords = 0;
      this.totalRecords = plans.length;
      this.filteredPlans.data = plans;
      this.dataLoading = false;

    }, () => {
      this.dataLoading = false;
      this.notifierService.notify('error', "Error fetching plans..");
    });
  }

  applyFilter(filterValue: string) {
    this.filteredPlans.filter = filterValue.trim().toLowerCase();
  }

  ngAfterViewInit() {
    this.filteredPlans.paginator = this.paginator;
  }

  exportAsXLSX() {
    this.excelRecords = [];
    let tripsData = [];
    tripsData = this.filteredPlans.data;
    tripsData.forEach(trip => {

      if (trip) {
        this.excelRecords.push({
          'Trip Name': trip.tripName || '',
          'Vehicle Type': trip.trip_vehicle_type,
          'Trip Weight / Volume': trip.tripWeight + " KG | " + trip.tripVolume + " CF",
          'Weight Utilization	': trip.trip_wt_utilisation || '',
          'Volume Utilization	': trip.trip_volume_utilisation || '',
          'Zone': trip.zone_name,
          'Trip Cost': trip.trip_cost,
        })
      }
    });
    this.excelService.exportAsExcelFile(this.excelRecords, 'optimizationReport');
  }

  ngOnDestroy() {
    this.branchesSubscription.unsubscribe();
  }
}
