export const environment = {
  production: false,
       // appUrl: "http://localhost:8080",
       //appUrl: "http://192.168.0.118:8080",
       appUrl: "https://suhanaspringapp.azurewebsites.net",
    // appUrl: "https://kimberlyspringapp.azurewebsites.net",
    // appUrl: 'http://20.219.77.135:8080', /// Azure VM API 
    //  appUrl: 'https://yoryospringapp.azurewebsites.net', /// Azure Spring App 
    // appUrl: "https://enterpriseapi.yoryosolutions.com",
  // firebase: {
  //   apiKey: "AIzaSyCVPlWKeskWr20Q_S-0jr113Ilv_Gf762k",
  //   authDomain: "yoryoandroidapp.firebaseapp.com",
  //   databaseURL: "https://yoryoandroidapp.firebaseio.com",
  //   projectId: "yoryoandroidapp",
  //   storageBucket: "yoryoandroidapp.appspot.com",
  //   messagingSenderId: "249812801134"
  // },

  // Satnam
  firebase: {
    apiKey: "AIzaSyB8wAPB2B6L215nb_9jj-9WzMTDjq0feng",
    authDomain: "http://yoryo-android-firebase-app.firebaseapp.com",
    databaseURL: "https://yoryo-android-firebase-app-default-rtdb.firebaseio.com",
    projectId: "yoryo-android-firebase-app",
    storageBucket: "http://yoryo-android-firebase-app.appspot.com",
    messagingSenderId: "187783228818",
    appId: "1:187783228818:web:754eadfd2e0702e227e54b",
    measurementId: "G-D6PRMECGRN"
    },

  //
  

// // for prod
//   S3Key: {
//     accessKeyId: 'AKIAXQQEGXTQGPQKKGEO',
//     secretAccessKey: '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',
//     region: 'ap-south-1'
//   },

  // for prod
  S3Key: {
    accessKeyId: 'AKIAXQQEGXTQFJXNSLPS',
    secretAccessKey: '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',
    region: 'ap-south-1'
  },
  
// for Test
// S3Key: {
//   accessKeyId: 'AKIART4PAC6VIJNSTQGQ',
//   secretAccessKey: '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',
//   region: 'ap-south-1'
// },


mapKey: 'AIzaSyBWUVonYTzHPK4vs0fyawpy2bWXUgtRhKM'
  // mapKey: 'AIzaSyBN5XTb58YmHCyBxHEsoEPTuphqbBd2Yi0'
  //  mapKey: 'AIzaSyAiPt7YZIM2j9z52_N8N4mlgu60Z6pmJ6Y'
   //mapKey: 'AIzaSyDDjQebD8vWPhDwzUyuO0Zl9d78p8jGgvU' //By Digvijay
};