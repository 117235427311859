import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { SearchService } from '../../services/search/search.service';
import {
  MatTableDataSource,
  MatDialog} from '@angular/material';
import { OrderService } from 'src/app/services/order/order.service';
import { DomSanitizer } from '@angular/platform-browser';
import { TokenStorage } from 'src/app/auth/token.storage';
import { UploadfileService } from 'src/app/services/uploadfile/uploadfile.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService } from 'src/app/services/dialog/dialog.service';

@Component({
  selector: 'app-searchresult',
  templateUrl: './searchresult.component.html',
  styleUrls: ['./searchresult.component.scss']
})
export class SearchresultComponent implements OnInit {

  searchText: string;
  searchOption: string;
  noResult: boolean = false;
  orderLength: number;
  searchResult = [];
  displayedColumns: string[] = ['productDetails', 'qty'];
  order: any;
  orderId: number;
  receiverName: string;
  orderState: string;
  orderCreatedTimestamp: string;
  deliveryEtaDateTime: string;
  totalweightCapacity: number;
  totalvolumeCapacity: number;
  dispatchDate: string;
  actualDeliveryDate: string;

  zoom: number = 13;
  lat: number = 18.533989;
  lng: number = 73.825592;
  productDataArray = new MatTableDataSource;
  ordersByTripIdArray = [];
  productArrayInSelectedOrder = [];
  productInputDisplayArray = [];
  public receiverAddress: any;
  public originAddress: any;
  customCollapsedHeight: string = '35px';
  customExpandedHeight: string = '35px';
  deliveryFailureReason: string;
  customerFeedback: string;
  score: number = 0;
  volUnit: string;
  range = [1, 2, 3, 4, 5]
  LRnum: any;
  numberOfBoxes: number = 0;
  isPackageDetailsToBeShown: boolean = false;
  isKcc: boolean = false;;
  isMetro: boolean = false;
  isGodrej: boolean = false;
  podList = [];
  transporterPodList = [];
  isInvoiceEntered: boolean = false;
  orderInvoiceNumber: any;
  eSign: any;
  isOpenedFromTripDetails: boolean = false;
  tripName: string;
  tripId: number;
  poNumber: string;
  isHeretech: boolean = false;
  selectedTab: string = 'PRODUCT_DETAILS';
  index = 0;
  zone: any;

  columnsToDisplay = [
    'SKU',
    'Category',
    'packageCapacity'
  ];
  constructor(
    private activatedRoute: ActivatedRoute,
    public searchService: SearchService,
    public dialog: MatDialog,
    public orderService: OrderService,
    private token: TokenStorage,
    private spinnerService: NgxSpinnerService,
    private dialogService: DialogService,
    private injector: Injector,

  ) { }

  ngOnInit() {
    if (this.token.getTenant() == 'heretech-dist-prod' || this.token.getTenant() == 'heretech-dist-test') {
      this.isHeretech = true;
      this.volUnit = 'CF';
    }
    else if (this.token.getTenant() == 'kimberly-dist-prod' || this.token.getTenant() == 'kimberly-dist-test') {
      this.isKcc = true;
      this.volUnit = 'CM';
    }
    else if (this.token.getTenant() == 'metro-dist-prod' || this.token.getTenant() == 'metro-dist-test') {
      this.isMetro = true;
      this.isPackageDetailsToBeShown = true;
      this.volUnit = 'CF';
    }
    else if (this.token.getTenant() == 'godrej-dist-prod' || this.token.getTenant() == 'godrej-dist-test') {
      this.volUnit = 'CCM';
      this.isGodrej = true;
    }
    else {
      this.volUnit = 'CF';
    }
    this.activatedRoute.params.subscribe((param: Params) => {
      this.searchText = param['searchText'];
      this.searchOption = param['searchOption'];

      this.searchData(this.searchText, this.searchOption);

    });
  }
  searchData(searchText, searchOption) {
    debugger;
    this.searchResult = [];
    this.noResult = false;
    this.spinnerService.show();
    this.searchService.search(searchText, searchOption).subscribe(searchResult => {
      console.log("searchResult", searchResult);
      debugger;
      this.spinnerService.hide();
      this.productDataArray.data = [];
      if (searchResult.length < 1) {
        this.noResult = true;
      }
      this.searchResult = searchResult;
      this.orderLength = searchResult.length;
      for (let i = 0; i < searchResult.length; i++) {
        this.order = searchResult[i].orderInfo;

        if (searchResult[i].orderInfo.oState === 'ORDER_STATE_DELIVERED') {
          this.searchResult[i].isDelivered = true;
        }
        else
          this.searchResult[i].isDelivered = false;
        this.orderDetails(this.searchResult[i]);
      }
      this.noResult = false;
    }, Error => {
      this.spinnerService.hide();
      console.log(Error);
      this.noResult = true;
    })
  }

  orderDetails(searchorder) {
    this.poNumber = this.order.purchaseOrder;
    if (this.order.lorryReceipt)
      this.LRnum = this.order.lorryReceipt.lorryReceiptNumber;
    this.orderId = this.order.orderUniqueId;
    this.receiverName = this.order.customerInfo.customerName;
    this.receiverAddress = this.order.customerInfo.address;
    this.originAddress = this.order.originBranch.branchName + " " + this.order.originBranch.branchCode;
    if (this.order.customerInfo.zone)
      this.zone = this.order.customerInfo.zone.zoneName;
    else
      this.zone = this.order.customerInfo.address.city;
    this.orderState = (this.order.oState).slice(12);
    this.orderCreatedTimestamp = this.order.orderCreatedDateTime;
    this.deliveryEtaDateTime = this.order.deliveryETADateTime;
    this.dispatchDate = this.order.orderDispatchDatetime;
    if (this.order.orderTransaction) {
      this.actualDeliveryDate = this.order.orderTransaction.orderDeliveredDateTime;
      //  this.dispatchDate = this.order.orderTransaction.orderInTransitDateTime;
    }

    this.productDataArray.data = searchorder.prodQList;


/*     if (this.orderState === "FAILED") {
      this.orderService.getJobDetails(searchorder.jobRef).subscribe(jobRes => {
        this.deliveryFailureReason = jobRes.reason;
      });
    } */


/*     if (this.orderState === "DELIVERED") {
      this.orderService.getCustomerFeedack(searchorder.orderInfo.orderUniqueId).subscribe(feedback => {
        if (feedback) {
          this.customerFeedback = feedback.feedbackComments;
          let rating = feedback.feedbackRating.substr(feedback.feedbackRating.length - 1);
          this.score = rating;
        }
      });
    } */
  }

  tripDetails(tripId) {
    this.injector.get(DialogService).tripDetails(tripId, 'orderDetails')
  }

  openOrderDetails(data) {
    this.dialogService.orderDetails(data.orderInfo.orderUniqueId, 'orderlistview');
  }

}



export interface productList {
  qty: String,
  productDetails: string;
}