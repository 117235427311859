import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-managematerialcategories',
  templateUrl: './managematerialcategories.component.html',
  styleUrls: ['./managematerialcategories.component.scss']
})
export class ManagematerialcategoriesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
