import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-devicemanagement',
  templateUrl: './devicemanagement.component.html',
  styleUrls: ['./devicemanagement.component.scss']
})
export class DevicemanagementComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    
  }

}
