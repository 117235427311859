import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { UsersService } from '../../../services/users/users.service';
import { ResourceService } from '../../../services/resource/resource.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TransporterService } from 'src/app/services/transporter/transporter.service';
import { CustomerService } from 'src/app/services/customer/customer.service';
import { BranchService } from 'src/app/services/branch/branch.service';
import { ValidatorService } from 'src/app/services/validator/validator.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-adduser',
  templateUrl: './adduser.component.html',
  styleUrls: ['./adduser.component.scss']
})
export class AdduserComponent implements OnInit {

  userForm: FormGroup;
  activityCreatedLog = [];
  activityUpdatedLog = [];
  allBranchesNames = [];
  branchNames = [];
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  showPwd: boolean = false;
  isCompany: boolean = true;
  isCustomer: boolean = false;
  isTransporter: boolean = false;
  allTransporters = [];
  allCustomers = [];
  allDesignations: any;
  filteredDesignations = [];
  isCustomerValue: boolean = false;
  isTransporterValue: boolean = false;
  isDesignation: boolean = false;
  allRoles = [];
  filteredRoles = [];
  isWarehouseManager: boolean = false;

  configCustomer = {
    search: true,
    displayKey: 'nameAndCode',
    searchOnKey: 'nameAndCode',
    height: '40vh',
    placeholder: 'Select Customer'
  }

  configDesignation = {
    search: true,
    displayKey: 'resourceDesignation',
    searchOnKey: 'resourceDesignation',
    height: '40vh',
    placeholder: 'Select Designation'
  };

  configTransporter = {
    search: true,
    displayKey: 'transNameAndCode',
    searchOnKey: 'transporterName',
    height: '40vh',
    placeholder: 'Select Transporter',
    clearOnSelection: false
  }

  constructor(
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private userService: UsersService,
    private resourceService: ResourceService,
    private router: Router,
    private datePipe: DatePipe,
    private transporterService: TransporterService,
    private customerService: CustomerService,
    private branchService: BranchService,
    private permissionsService: NgxPermissionsService,
    private spinnerService: NgxSpinnerService
  ) {
  }

  initializeUserForm() {
    this.userForm = this.formBuilder.group({
      'firstName': [null, [
        Validators.required,
        Validators.pattern("^[A-Za-z]+$"),
      ]],
      'lastName': [null, [
        Validators.pattern("^[A-Za-z]+$"),
      ]],
      'address': [null, [
        ValidatorService.whitespaceValidator
      ]],
      'phoneNumber': [null, [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern('^[0-9]*$')
      ]],
      'emailId': [null, [
        Validators.email
      ]],
      'resourceType': [null],
      'designation': [null],
      'language': [null],
      'branchNames': [null],
      'roles': [null, [
        Validators.required]],
      'customerType': [null, []],
      'username': [null, [
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9-._@]+$"),
        Validators.minLength(6),
        Validators.maxLength(32),
        ValidatorService.whitespaceValidator
      ]],
      'password': [null, [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(32),
      ]],
    });
  }

  openSnackBar(message) {
    this.snackBar.open(message, 'X', {
      duration: 5000,
    });
  }

  ngOnInit() {
    this.getAllUsers();
    this.initializeUserForm();
    this.userService.getManageableRoles().subscribe(roles => {
      this.allRoles = roles;

      this.resourceService.getManageableDesignation().subscribe(designations => {
        this.allDesignations = designations;
        if (this.permissionsService.getPermission('warehouse_manager') != undefined) {
          this.isWarehouseManager = true;
          let transObj = {
            value: 'transporter'
          }
          this.createUSerFor(transObj)
        }
        else {
          this.isWarehouseManager = false;
          let companyObj = {
            value: 'company'
          }
          this.createUSerFor(companyObj)
        }
      })
    })
    this.branchService.branchesObservable.subscribe(allBranches => {
      this.allBranchesNames = allBranches;
      this.allBranchesNames.sort(function (a, b) {
        let branchName1 = a.branchName.toLowerCase(), branchName2 = b.branchName.toLowerCase();
        if (branchName1 < branchName2) { //sort string ascending
          return -1;
        } else if (branchName1 > branchName2) {
          return 1;
        } else {
          return 0;
        }
      });
    });
  }


  getDesignations(category) {
    this.configDesignation = {
      search: true,
      displayKey: 'resourceDesignation',
      searchOnKey: 'resourceDesignation',
      height: '40vh',
      placeholder: 'Select Designation'
    }
    this.filteredDesignations = this.allDesignations;
    this.filteredDesignations = this.filteredDesignations.filter(function (des) {
      return des.category === category;
    });
  }


  filterRoles(category) {
    if (category == 'transporter') {
      this.filteredRoles = this.allRoles.filter(function (role) {
        return (role.roleName === category || role.roleName == 'delivery_associate');
      });
    }
    else if (category == 'company') {
      this.filteredRoles = [];
      this.allRoles.forEach(comapnyRole => {
        if (comapnyRole.roleName === 'customer' || comapnyRole.roleName === 'transporter' || comapnyRole === 'delivery_associate') {
        } else {
          this.filteredRoles.push(comapnyRole);
        }
      })
    }
    else {
      this.filteredRoles = this.allRoles.filter(function (role) {
        return role.roleName === category;
      });
    }
  }

  togglePwd() {
    this.showPwd = !this.showPwd;
  }
  onSelectCustomer(event) {
    if (event.value) {
      this.isCustomerValue = true;
      this.isTransporterValue = false;
      if (this.isCustomer) {
        this.branchNames = [];
        event.value.branches.forEach(element => {
          this.branchNames.push(element.branchName);
        });
      }
    }
    else
      this.isCustomerValue = false;
  }

  onSelectTransporter(event) {
    if (event.value) {
      this.isTransporterValue = true;
      this.isCustomerValue = false;
    }
    else
      this.isTransporterValue = false;
  }


  ngOnDestroy() {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();
  }

  getAllUsers() {
    this.userService.getAllUsers().pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      for (let i = 0; i < 4; i++) {
        if (res[i]) {
          if (res[i].registrationDateTime === res[i].updatedDateTime) {
            this.activityCreatedLog.push({
              username: res[i].username,
              createdBy: res[i].updatedByusername,
              creadtedDate: this.datePipe.transform(res[i].registrationDateTime, 'yyyy-MM-dd'),
              creadtedTime: res[i].registrationDateTime,
            });
          } else {
            this.activityUpdatedLog.push({
              username: res[i].username,
              updatedBy: res[i].updatedByusername,
              updatedDate: this.datePipe.transform(res[i].updatedDateTime, 'yyyy-MM-dd'),
              updatedTime: res[i].updatedDateTime
            });
          }
        }
      }
    }, error => {
      console.log(error);
    });
  }

  addNewUser() {
    if (this.isCompany)
      this.userForm.value.resourceType = "RESOURCE_TYPE_SC_MANAGEMENT";
    else if (this.isTransporter) {
      this.userForm.value.resourceTypeRef = this.userForm.value.customerType.transporterId;
      this.userForm.value.resourceType = "RESOURCE_TYPE_TRANSPORTER";
    }
    else if (this.isCustomer) {
      this.userForm.value.resourceTypeRef = this.userForm.value.customerType.id;
      this.userForm.value.resourceType = 'RESOURCE_TYPE_CUSTOMER';
      this.userForm.value.branchNames = this.branchNames;
    }

    if(this.userForm.value.designation.length==0){
      this.userForm.value.designation = null;
    }
    this.userForm.value.resource = {
      firstName: this.userForm.value.firstName,
      lastName: this.userForm.value.lastName,
      phoneNumber: this.userForm.value.phoneNumber,
      emailId: this.userForm.value.emailId,
      address: this.userForm.value.address,
      resourceType: this.userForm.value.resourceType,
      resourceTypeRef: this.userForm.value.resourceTypeRef,
      designation: this.userForm.value.designation
    }

    delete this.userForm.value.firstName;
    delete this.userForm.value.lastName;
    delete this.userForm.value.phoneNumber;
    delete this.userForm.value.emailId;
    delete this.userForm.value.address;
    delete this.userForm.value.resourceType;
    delete this.userForm.value.customerType;
    delete this.userForm.value.resourceTypeRef;
    delete this.userForm.value.designation;
    delete this.userForm.value.language;
    delete this.userForm.value.phoneNumber;
    delete this.userForm.value.emailId;

    console.log("form value", this.userForm.value);

    this.userService.addUser(this.userForm.value).subscribe(() => {
      this.openSnackBar("User added successfully");
      this.userForm.reset();
      this.router.navigateByUrl('/userandrole');
    }, error => {
      console.log(error);
      if (error.status === 400)
        this.openSnackBar(error.error.Result);
      else
        this.openSnackBar("Error in adding user ! Please try again later");
    });
  }

  createUSerFor(event) {
    this.userForm.reset();
    this.getDesignations(event.value);
    if (event.value === 'company') {
      this.filterRoles(event.value);
      this.isCompany = true;
      this.isCustomer = false;
      this.isTransporter = false;
      this.isTransporterValue = false;
      this.isCustomerValue = false;
    }
    else if (event.value === 'customer') {
      this.filterRoles(event.value);
      this.isCompany = false;
      this.isCustomer = true;
      this.isTransporter = false;
      if(this.allCustomers.length==0){
        this.spinnerService.show();
      this.customerService.getAllCustomers().subscribe(allcusts => {
        this.spinnerService.hide();
        this.allCustomers = [];
        this.allCustomers = allcusts;
        this.allCustomers.forEach(customer => {
          customer.nameAndCode = customer.customerName + "-" + customer.customerCode;
        })
      },Error=>{
        this.spinnerService.hide();
      });
    }
    }
    else if (event.value === 'transporter') {
      this.filterRoles(event.value);
      this.isCompany = false;
      this.isCustomer = false;
      this.isTransporter = true;
      if(this.allTransporters.length==0){
        this.spinnerService.show();
      this.transporterService.getAllActiveTransporters().subscribe(allTrans => {
        this.spinnerService.hide();
        this.allTransporters = allTrans;
        for (let trans of this.allTransporters) {
          trans['transNameAndCode'] = trans.transporterName + '-' + trans.transporterCode;
        }
        this.allTransporters.sort(function (a, b) {
          let transName1 = a.transporterName.toLowerCase(), transName2 = b.transporterName.toLowerCase()
          if (transName1 < transName2) //sort string ascending
            return -1
          if (transName1 > transName2)
            return 1
          return 0
        });
      },Error=>{
        this.spinnerService.hide();
      });
    }
    }

  }
}