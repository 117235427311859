import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BingmapService {

  private subject = new Subject<any>();

  constructor() { }

  injectBingMapMainScript() {
    let script = document.createElement('script');
    script.src = 'https://www.bing.com/api/maps/mapcontrol?key=As6Tsyuts7N26Wbc3MRmfV9UPmv0dgbSeUBv5XRjcpbwLcz4fduwFxXrRpAMo-Ip';
    script.async = true;

    document.body.appendChild(script);

    script.onload = () => {
      this.subject.next(true);
    };
  }

  injectionSubject(): Observable<any> {
    return this.subject.asObservable();
  }
}
