import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import 'rxjs/add/observable/of';
import { TripService } from '../../../services/trip/trip.service';
import { DatePipe } from '@angular/common';
import { TokenStorage } from 'src/app/auth/token.storage';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { Subscription, Subject } from 'rxjs';
import { BranchService } from 'src/app/services/branch/branch.service';
import { takeUntil } from 'rxjs/operators';



@Component({
  selector: 'app-triplistview',
  templateUrl: './triplistview.component.html',
  styleUrls: ['./triplistview.component.scss']
})
export class TriplistviewComponent implements OnInit {
  columnsToDisplay = [
    'tripName',
    'tripCreatedOn',
    'tripZone',
    'originAddress',
    'orders',
    'transporter',
    'vehicleDetails',
    'capacityUtilization'
  ];
  tripDataArray = new MatTableDataSource();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  mapView: boolean = false;
  inPlanningCount: number = 0;
  scheduledCount: number = 0;
  intransitCount: number = 0;
  completedCount: number = 0;
  incomplete: number = 0;
  page: number = 0;
  totalRecords: Number;
  typeOfTrip: any;
  fromDate: any;
  toDate: any;
  customerZones = [];
  selectedVal: any;
  volUnit: string;
  isLoading = true;
  branchesSubscription: Subscription;
  protected ngUnsubscribe: Subject<void> = new Subject<void>()

  constructor(
    public tripService: TripService,
    public datePipe: DatePipe,
    private token: TokenStorage,
    private dialogService: DialogService,
    private branchService: BranchService
  ) {
    if (this.token.getTenant() == 'kimberly-dist-prod') {
      this.volUnit = 'CM';
    } else if (this.token.getTenant() == 'godrej-dist-prod') {
      this.volUnit = 'CCM';
    } else {
      this.volUnit = 'CF';
    }
  }

  ngOnInit() {
    this.typeOfTrip = ['TRIP_STATE_IN_PLANNING', 'TRIP_STATE_APPROVED'];
    this.selectedVal = "0";

    this.findTrips(0);
    this.branchesSubscription = this.branchService.branchesObservable.subscribe(() => {
      if (window.sessionStorage.getItem("selectedBranchesCodes") && JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")).length == 0) {
        this.tripDataArray.data = [];
        this.isLoading = false;
      }
      else {
        this.getData();
      }
    });

    this.tripDataArray.sortingDataAccessor = (item: any, property) => {
      switch (property) {
        case 'vehicleDetails': return item.vehicleType + " " + item.assignedVehicleLicenseNumber;
        case 'tripZone': return item.customerZones;
        case 'tripCreatedOn': return item.tripCreatedDateTime;
        case 'originAddress': return item.branchName;
      }
    };
    this.tripDataArray.sort = this.sort;
  }


  findTotalRecords() {
    this.tripService.getTripCountByStatus(this.fromDate, this.toDate).subscribe(res => {
      console.log("trip count by status", res);
      this.inPlanningCount = 0;
      this.scheduledCount = 0;
      this.intransitCount = 0;
      this.completedCount = 0;
      this.incomplete = 0;
      if (this.searchArraryForCount(res, 'TRIP_STATE_IN_PLANNING') != undefined) {
        if (this.searchArraryForCount(res, 'TRIP_STATE_APPROVED') != undefined)
          this.inPlanningCount = this.searchArraryForCount(res, 'TRIP_STATE_IN_PLANNING').tripcount + this.searchArraryForCount(res, 'TRIP_STATE_APPROVED').tripcount;
        else
          this.inPlanningCount = this.searchArraryForCount(res, 'TRIP_STATE_IN_PLANNING').tripcount;
      }
      else if (this.searchArraryForCount(res, 'TRIP_STATE_APPROVED') != undefined) {
        if (this.searchArraryForCount(res, 'TRIP_STATE_IN_PLANNING') != undefined)
          this.inPlanningCount = this.searchArraryForCount(res, 'TRIP_STATE_APPROVED').tripcount + this.searchArraryForCount(res, 'TRIP_STATE_IN_PLANNING').tripcount;
        else
          this.inPlanningCount = this.searchArraryForCount(res, 'TRIP_STATE_APPROVED').tripcount;
      }
      if (this.searchArraryForCount(res, 'TRIP_STATE_SCHEDULED') != undefined) {
        this.scheduledCount = this.searchArraryForCount(res, 'TRIP_STATE_SCHEDULED').tripcount;
      }
      if (this.searchArraryForCount(res, 'TRIP_STATE_IN_TRANSIT') != undefined) {
        this.intransitCount = this.searchArraryForCount(res, 'TRIP_STATE_IN_TRANSIT').tripcount;
      }
      if (this.searchArraryForCount(res, 'TRIP_STATE_COMPLETED') != undefined) {
        this.completedCount = this.searchArraryForCount(res, 'TRIP_STATE_COMPLETED').tripcount;
      }
      if (this.searchArraryForCount(res, 'TRIP_STATE_INCOMPLETE') != undefined) {
        this.incomplete = this.searchArraryForCount(res, 'TRIP_STATE_INCOMPLETE').tripcount;
      }
    });
  }


  searchArraryForCount(arr, value) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].tripstatus === value) {
        return arr[i];
      }
    }
  }

  toogleView() {
    if (!this.mapView) {
      this.mapView = true;
      console.log(this.mapView);
    } else {
      this.mapView = false
      console.log(this.mapView);
    }
  }
  tripData(type) {
    this.page = 0;
    this.paginator._pageIndex = 0;
    //console.log(type);
    switch (type) {
      case "inplanning":
        this.typeOfTrip = ['TRIP_STATE_IN_PLANNING', 'TRIP_STATE_APPROVED'];
        this.getData();
        break;
      case "scheduled":
        this.typeOfTrip = 'TRIP_STATE_SCHEDULED';
        this.getData();
        break;
      case "intransit":
        this.typeOfTrip = 'TRIP_STATE_IN_TRANSIT';
        this.getData();
        break;

      case "incomplete":
        this.typeOfTrip = 'TRIP_STATE_INCOMPLETE';
        this.getData();
        break;
      case "completed":
        this.typeOfTrip = 'TRIP_STATE_COMPLETED';
        this.getData();
        break;
      default:
        this.typeOfTrip = ['TRIP_STATE_IN_PLANNING', 'TRIP_STATE_APPROVED'];
        this.getData();
        break;
    }
  }


  getData() {
    this.ngUnsubscribe.next();
    this.tripDataArray.data = [];
    this.isLoading = true;
    if (this.page == 0)
      this.totalRecords = 0;
    this.tripService.getAllTripsByTypesDateRange(this.typeOfTrip, this.page, this.fromDate, this.toDate, 'nozone').pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.isLoading = false;
      console.log("trips in scheduled on page 0", res);
      this.totalRecords = res.totalElements;
      this.findCustomerZonesForTrip(res.content);
    });
  }

  pageChanged(event) {
    this.page = event.pageIndex;
    this.getData();
  }

  tripDetails(data) {
    this.dialogService.tripDetails(data, '')
  }

  findTrips(days) {
    this.ngUnsubscribe.next();
    let date, last, day, month, year;
    date = new Date();
    last = new Date(date.getTime() - (days * 24 * 60 * 60 * 1000));
    day = last.getDate();
    month = last.getMonth();
    year = last.getFullYear();
    this.fromDate = this.datePipe.transform(new Date(year, month, day), 'yyyy-MM-dd');
    this.toDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.isLoading = true;
    this.tripDataArray.data = [];
    this.totalRecords = 0;
    this.findTotalRecords();
    this.tripService.getAllTripsByTypesDateRange(this.typeOfTrip, this.page, this.fromDate, this.toDate, 'nozone').pipe(takeUntil(this.ngUnsubscribe)).subscribe(trips => {
      this.isLoading = false;
      console.log("trips", trips)
      this.tripDataArray.data = trips.content;
      this.totalRecords = trips.totalElements;
      this.totalRecords = 0;
      this.page = 0;
      this.paginator._pageIndex = 0;
      this.findCustomerZonesForTrip(trips.content);
    });
  }

  findCustomerZonesForTrip(tripsArray) {
    tripsArray.forEach(job => {
      this.customerZones = [];
      job.jobs.forEach(order => {
        let index = -1;
        if (order.customerInfo.zone) {
          index = this.customerZones.indexOf(order.customerInfo.zone.zoneName);
          if (index == -1) {
            this.customerZones.push(order.customerInfo.zone.zoneName);
          }
        }
      });
      job.customerZones = this.customerZones;
    });
    this.calculateBoxesForEachTrip(tripsArray);
  }


  calculateBoxesForEachTrip(tripsArray) {
    tripsArray.forEach(trip => {
      let totalboxes = 0;
      trip.jobs.forEach(job => {
        totalboxes = totalboxes + job.totalNumberOfContainers;
      });
      trip.totalBoxes = totalboxes;
    });
    this.tripDataArray.data = tripsArray;
  }

  ngOnDestroy() {
    this.branchesSubscription.unsubscribe();
  }

}