import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  base_url: string;
  constructor(
    private http: HttpClient,
  ) {
    this.base_url = environment.appUrl;
  }

  getAllDevices(): Observable<any> {
    return this.http.get(this.base_url + '/connecteddevices', { observe: 'response' });
  }

  setDevice(tripId: number, devices: Array<string>): Observable<any> {
    return this.http.put(this.base_url + '/trips/' + tripId + '/setTrackingDevices?devices=' + devices, null);
  }

  getDevicesByType(type: string): Observable<any> {
    return this.http.get(this.base_url + '/connecteddevices/' + type, { observe: 'response' });
  }

  addDevice(device: any): Observable<any> {
    return this.http.post(this.base_url + '/connecteddevices', device);
  }
  
}
