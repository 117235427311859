import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { AgmMap, LatLngBounds } from '@agm/core';
import { TokenStorage } from 'src/app/auth/token.storage';
declare const google: any;

@Component({
  selector: 'app-googlemap',
  templateUrl: './googlemap.component.html',
  styleUrls: ['./googlemap.component.scss']
})
export class GooglemapComponent implements OnInit {

  @Input() tripData: any;
  @Input() private locationData: any[];
  @ViewChild('AgmMap') agmMap: AgmMap;
  private setZoomTimeOutInMS: number = 5000;
  processedLocationData = [];
  markersArray = [];
  volUnit: string;

  renderOptions = {
    polylineOptions: {
      strokeColor: "#3367D6",
      strokeOpacity: 1,
      strokeWeight: 4,
      geodesic: true,
      /* icons: [{
        icon: {
          path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
          strokeOpacity: 1,
          scale: 4
        },
        offset: '0',
        repeat: '100%'
      }]  */
    },
    suppressMarkers: true
  };

  //Styles for google map
  mapstyles: any = [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#1d2c4d"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#8ec3b9"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#1a3646"
        }
      ]
    },
    {
      "featureType": "administrative.country",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#4b6878"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#64779e"
        }
      ]
    },
    {
      "featureType": "administrative.province",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#4b6878"
        }
      ]
    },
    {
      "featureType": "landscape.man_made",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#334e87"
        }
      ]
    },
    {
      "featureType": "landscape.natural",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#023e58"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#283d6a"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#6f9ba5"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#1d2c4d"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#023e58"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#3C7680"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#304a7d"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#98a5be"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#1d2c4d"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#2c6675"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#255763"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#b0d5ce"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#023e58"
        }
      ]
    },
    {
      "featureType": "transit",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#98a5be"
        }
      ]
    },
    {
      "featureType": "transit",
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#1d2c4d"
        }
      ]
    },
    {
      "featureType": "transit.line",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#283d6a"
        }
      ]
    },
    {
      "featureType": "transit.station",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#3a4762"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#0e1626"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#4e6d70"
        }
      ]
    }
  ];

  constructor(
    private token: TokenStorage,
  ) {
    if (this.token.getTenant() == 'kimberly-dist-prod') {
      this.volUnit = 'CM';
    } else if (this.token.getTenant() == 'godrej-dist-prod') {
      this.volUnit = 'CCM';
    } else {
      this.volUnit = 'CF';
    }
  }

  ngOnInit() {
    this.initializeMap();
  }

  initializeMap() {

    console.log("Locations Array", this.locationData);
    if (this.locationData && this.locationData.length > 0) {
      this.setZoomTimeOutInMS = Math.ceil(this.locationData.length / 25) * 500;

      for (let i = 0, max = 25 - 1; i < this.locationData.length; i = i + max) {
        let tmp = [];
        let partArray = this.locationData.slice(i, i + max + 1);

        partArray.forEach(loc => {
          tmp.push({
            location: {
              lat: loc.latitude,
              lng: loc.longitude,
            }
          });
        });

        let apiCallData = {
          origin: {
            lat: partArray[0].latitude,
            lng: partArray[0].longitude
          },
          destination: {
            lat: partArray[partArray.length - 1].latitude,
            lng: partArray[partArray.length - 1].longitude
          },
          waypoints: tmp,
        };

        this.processedLocationData.push(apiCallData);
      }

      let startStopMarker: any = {};
      startStopMarker.icon = '../../../../assets/icons/warehouse1.png';

      if (this.tripData.tState == 'TRIP_STATE_IN_TRANSIT') {
        this.locationData[this.locationData.length - 1].sequence = " ";
        this.locationData[this.locationData.length - 1].icon = "../../../../assets/icons/truck3d.png";
        this.locationData[this.locationData.length - 1].timestamp = this.locationData[this.locationData.length - 1].timestamp;
        this.markersArray.push(this.locationData[this.locationData.length - 1]);
      }

      let index = -1;
      let sequence = 0;
      this.markersArray.push(startStopMarker);
      for (let i = 0; i < this.locationData.length; i++) {

        if (this.locationData[i].status == 'trip_started') {
          startStopMarker.startTime = this.locationData[i].timestamp;
          startStopMarker.latitude = this.locationData[i].latitude;
          startStopMarker.longitude = this.locationData[i].longitude;
        } else if (this.locationData[i].status == 'trip_stopped') {
          startStopMarker.stopTime = this.locationData[i].timestamp;
        } else if (this.locationData[i].status == 'delivered') {
          index = i;
          sequence++;
          this.locationData[i].sequence = sequence + "";
          this.locationData[i].icon = "../../../../assets/icons/green.png";
          this.locationData[i].timestamp = this.locationData[i].timestamp;
          this.locationData[i].status = "Order Delivered";
          this.tripData.jobs.forEach(job => {
            if (job.jobId == this.locationData[i].jobId) {
              this.locationData[i].customerName = job.customerInfo.customerName;
              this.locationData[i].address = job.customerInfo.address.houseNumber;
            }
          });
          this.markersArray.push(this.locationData[i]);
        } else if (this.locationData[i].status == 'undelivered') {
          index = i;
          sequence++;
          this.locationData[i].sequence = sequence + "";
          this.locationData[i].icon = "../../../../assets/icons/FF0000.png"
          this.locationData[i].status = "Order Delivery Failed";
          this.locationData[i].timestamp = this.locationData[i].timestamp;
          this.tripData.jobs.forEach(job => {
            if (job.jobId == this.locationData[i].jobId) {
              this.locationData[i].customerName = job.customerInfo.customerName;
              this.locationData[i].address = job.customerInfo.address.houseNumber;
            }
          });
          this.markersArray.push(this.locationData[i]);
        }
      }
    }
  }

  ngAfterViewInit() {
    if (this.agmMap) {
      this.agmMap.mapReady.subscribe(map => {
        setTimeout(() => {
          const bounds: LatLngBounds = new google.maps.LatLngBounds();
          for (const mm of this.locationData) {
            bounds.extend(new google.maps.LatLng(mm.latitude, mm.longitude));
            bounds.extend(new google.maps.LatLng(mm.latitude, mm.longitude));
          }
          map.fitBounds(bounds);
        }, this.setZoomTimeOutInMS);
      });
    }
  }
}