import { Component, OnInit } from '@angular/core';
import { TokenStorage } from 'src/app/auth/token.storage';
import { Router } from '@angular/router';
import { BillingService } from 'src/app/services/billing/billing.service';
import { DatePipe } from '@angular/common';
import { NgxDateRangePickerOptions } from 'src/ngx-daterangepicker';
import { NotifierService } from 'angular-notifier';
import { TransporterService } from 'src/app/services/transporter/transporter.service';

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit {

  isSa: boolean = false;
  isTransporter: boolean = false;
  trpReviewCount: number = 0;
  scReviewCount: number = 0;
  approvedCount: number = 0;
  fromDate: any;
  toDate: any;
  isSuhana: boolean = false;
  userRole: string;
  isTransporterFilter: boolean = false;
  isDateFilter: boolean = false;
  selectedFlag:string = 'trpReview';
  dateRangeValue: any= { to: "", from: "" };

  dateRangePickeroptions: NgxDateRangePickerOptions;
  selectedTransporter: any=null;
  transporterDropdownConfig = {
    search: true,
    displayKey: 'transporterName',
    searchOnKey: 'transporterName',
    height: '40vh',
    placeholder: 'Select Transporter'
  };
  allTransporters:any = [];
  transportDataFlag:boolean = false
  allTransportersList: any;

  constructor(private token: TokenStorage,
    private router: Router,
    private notifierService: NotifierService,
    private transporterService: TransporterService,
    private billingService: BillingService,
    private datePipe: DatePipe) {
    if (this.token.getTenant() == 'sa-dist-prod') {
      this.isSa = true;
    }
  }

  ngOnInit() {
    if (this.token.getTenant() == 'suhana-dist-test' || this.token.getTenant() == 'suhana-dist-prod') {
      this.isSuhana = true;
      this.selectedFlag = 'scReview';
    }


    this.userRole = window.sessionStorage.getItem('permissions').substr(2).slice(0, -2);
    if (this.userRole == 'transporter') {
      this.isTransporter = true;
    }
    this.fromDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.toDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.dateRangeValue.from = this.datePipe.transform(new Date(), 'dd-MM-yyyy');
    this.dateRangeValue.to = this.datePipe.transform(new Date(), 'dd-MM-yyyy');
    // this.findBills(60);
    this.getBillCount();
    this.getAllTransporters();
    this.dateRangePickeroptions = {
      theme: 'default',
      labels: ['Start', 'End'],
      menu: null,
      dateFormat: 'YYYY-MM-DD',
      outputFormat: 'DD-MM-YYYY',
      startOfWeek: 1,
      outputType: 'object',
      locale: 'en',
      date: {
        from: {
          year: new Date().getFullYear(),
          month: new Date().getMonth() + 1,
          day: new Date().getDate()
        },
        to: {
          year: new Date().getFullYear(),
          month: new Date().getMonth() + 1,
          day: new Date().getDate()
        }
      }
    };
  }

  filterByDate()
  {
    this.isDateFilter = true;
    this.fromDate = this.dateRangeValue.from.substring(6, 10) + '-' + this.dateRangeValue.from.substring(3, 5) + '-' + this.dateRangeValue.from.substring(0, 2);
    this.toDate = this.dateRangeValue.to.substring(6, 10) + '-' + this.dateRangeValue.to.substring(3, 5) + '-' + this.dateRangeValue.to.substring(0, 2);
    console.log("this.selectedTransporter")

    console.log(this.selectedTransporter)
    this.getBillCount();
  }

  sectionSelection(flag)
  {
    this.selectedFlag = flag;
  }

  clearAllFilter() {
    this.selectedTransporter = null;
    this.isTransporterFilter = false;
    this.isDateFilter = false;
    this.ngOnInit();
  }

  // findBills(days) {
  //   let date, last, day, month, year;
  //   date = new Date();
  //   last = new Date(date.getTime() - (days * 24 * 60 * 60 * 1000));
  //   day = last.getDate();
  //   month = last.getMonth();
  //   year = last.getFullYear();
  //   this.fromDate = this.datePipe.transform(new Date(year, month, day), 'yyyy-MM-dd');
  //   this.toDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
  //   this.getBillCount();
  // }

  getAllTransporters() {
    
    console.log(this.transportDataFlag)
    this.transporterService.getAllActiveTransporters().subscribe(allTrans => {
      console.log(allTrans)
      this.transportDataFlag=true;
      this.allTransportersList = allTrans;
      console.log(this.transportDataFlag)
      this.allTransporters = allTrans;
      // this.getBillData(this.allTransporters);
      this.allTransporters.sort(function (a, b) {
        let transName1 = a.transporterName.toLowerCase(), transName2 = b.transporterName.toLowerCase()
        if (transName1 < transName2) //sort string ascending
          return -1
        if (transName1 > transName2)
          return 1
        return 0
      });
    }, () => {
      this.notifierService.notify('error', "Error fetching data..");
    });
  }

  transpoterFilter()
  {
    this.isTransporterFilter = true;
    this.allTransportersList =[];
    this.allTransportersList.push(this.selectedTransporter);
    this.getBillCount();
  }

  getBillCount() {
    this.billingService.getBillCount(this.fromDate, this.toDate,"").subscribe(res => {
      this.trpReviewCount = 0;
      this.scReviewCount = 0;
      this.approvedCount = 0;

      this.trpReviewCount = this.searchArraryForCount(res, 'BILL_STATE_INITIAL') + this.searchArraryForCount(res, 'BILL_STATE_IN_TRANSPORTER_REVIEW');

      this.scReviewCount = this.searchArraryForCount(res, 'BILL_STATE_IN_SC_REVIEW')
        + this.searchArraryForCount(res, 'BILL_STATE_PENDING_SC_APPROVAL');
      + this.searchArraryForCount(res, 'BILL_STATE_TRANSPORTER_APPROVED')

      this.approvedCount = this.searchArraryForCount(res, 'BILL_STATE_SC_APPROVED')
        + this.searchArraryForCount(res, 'BILL_STATE_RAISED');   
    });
  }

  searchArraryForCount(arr, value) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].billstatus === value) {
        return arr[i].total_number_of_bills;
      }
    }
    return 0;
  }
}

export interface Bill {
  billId: number,
  origin: string,
  createdDateTime: string,
  branchName: string,
  branchCode: string,
  destination: string,
  transporterName: string,
  vehicleNumber: string,
  vehicleType: number,
  billDetails: Array<BillDetail>,
  numContainers: number,
  readMore: boolean
  billCosts: BillCosts,
  podCompletionPercent: number,
  uniqueLRs: Array<string>,
  bState: string,
  remarks: string,
  totalWeight: number,
  totalVolume: number,
  tripName: string;
  customerBillNumber: any;
  updatedByusername: string;
}

export interface BillDetail {
  lorryReceiptNumber: string,
  orderInvoiceNumber: string,
  orderRef: number,
  pods: Array<BillDocument>,
  documents: Array<BillDocument>,
  jobRef: number,
  customerName: string,
  billArtifacts: any,
  numContainers: number
}

export interface BillCosts {
  totalCharges: number,
  freightRate: number,
  clubbingCharges: number,
  unloadingCharges: number,
  detentionCharges: number,
  otherCharges1: number,
  otherCharges2: number,
  tollCharges: number
}

export interface Charge {
  name: string,
  value: number,
  isInvalid: boolean
}

export interface BillAudit {
  name: string,
  value: number,
}

export interface BillDocument {
  key: number,
  src: string,
  isPresent: boolean,
  url: any,
  extension: any
}