import { Injectable } from '@angular/core';
import {HttpInterceptor, HttpRequest, HttpHandler, HttpSentEvent, HttpHeaderResponse, HttpProgressEvent,
  HttpResponse, HttpUserEvent, HttpErrorResponse} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import {TokenStorage} from './token.storage';
import 'rxjs/add/operator/do';
import { UsersService } from 'src/app/services/users/users.service';
import { StoreUserDetails } from './storeuserdetails';
import { MatDialog } from '@angular/material'
import { ErrorExcelUpload } from '../pages/schedulingandplanning/schedulingandplanning.component';

const TOKEN_HEADER_KEY = 'Authorization';

@Injectable()
export class Interceptor implements HttpInterceptor {

  constructor(private token: TokenStorage, private router: Router,
    private usersService: UsersService,
    private userDetails: StoreUserDetails,
    public dialog: MatDialog
    ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    let authReq = req;
    if (this.token.getToken() != null) {
      authReq = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + this.token.getToken())});
      console.log(authReq);
    } else {
      console.log("not found");
    }

    return next.handle(authReq).do(
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            console.log(err);
            console.log('req url :: ' + req.url);
            if (err.status === 401) {
              this.router.navigate(['user']);
            }
          }
        }, Error => {
          if(Error.status == 417 || Error.status == 412)  {
            let errorText = "";
            if(Error.status == 412 || Error.error == null || Error.error == "")
            errorText = "Please Login Again...";
            else
            errorText = Error.error;
            if(this.token.getToken() != null) {
              this.usersService.logoutUser(this.token.getToken()).subscribe(res =>{
                  this.token.signOut();
                  this.userDetails.removeUser();
                  this.router.navigate(['login']);
                  this.dialog.open(ErrorExcelUpload, {
                    width: '470px',
                    maxWidth: '40vw',
                    maxHeight: '80vh',
                    data: {
                      Result: errorText,
                    }
                  });
              }, Error => {
                  console.log(Error);
              }
            )}else{
              this.dialog.open(ErrorExcelUpload, {
                width: '470px',
                maxWidth: '40vw',
                maxHeight: '80vh',
                data: {
                  Result: errorText,
                }
              });
            }
          }else if(Error.status == 401) {
            console.log(Error);
            this.dialog.open(ErrorExcelUpload, {
              width: '470px',
              maxWidth: '40vw',
              maxHeight: '80vh',
              data: {
                Result: "Incorrect Username/Password.",
              }
            });
          }
        }
      );
  }
}
