import { Injectable, } from '@angular/core';
import { Http, Headers, URLSearchParams, Response, HttpModule, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TransporterService {

  base_url: string;
  constructor(
    private http: HttpClient,
  ) {
    this.base_url = environment.appUrl + "/transporters";
  }

  getTransporter(): Observable<any> {
    return this.http.get(this.base_url);
  }

  getAllTransporters(): Observable<any> {
    if ((window.sessionStorage.getItem("selectedBranchesCodes") && window.sessionStorage.getItem("totalUserBranches") == JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")).length) || !window.sessionStorage.getItem("selectedBranchesCodes") || JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")).length == 0) {
      return this.http.get(this.base_url);
    }
    else {
      return this.http.get(this.base_url + "?branchCodes=" + JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")));
    }
  }

  addTransporter(newTransporter): Observable<any> {
    JSON.stringify(newTransporter);
    return this.http.post(this.base_url + '?branchNames=' + newTransporter.branchNames, newTransporter);
  }

  updateTransporter(updatedTransporter): Observable<any> {
    JSON.stringify(updatedTransporter);
    return this.http.put(this.base_url, updatedTransporter);
  }

  getTransporterById(id): Observable<any> {
    return this.http.get(this.base_url + '/id/' + id.transporter);
  }

  getTransporterByName(name): Observable<any> {
    return this.http.get(this.base_url + "/name/" + name);
  }

  getDriversByTransporterId(transporterId): Observable<any> {
    return this.http.get(this.base_url + "/" + transporterId + "/drivers", { observe: 'response' });
  }

  getFleetsByTransporterId(transporterId, type): Observable<any> {
    return this.http.get(this.base_url + "/id" + "/" + transporterId + '/fleets/' + type, { observe: 'response' });
  }

  getTransportersByUsername(username) {
    return this.http.get(environment.appUrl + "/tenantusers/" + username);
  }

  getAllTransportSegment(): Observable<any> {
    return this.http.get(this.base_url + "/transportleg");
  }

  getTransporterByBranches(branches: string[]): Observable<any> {
    return this.http.get(this.base_url + "/branches?branchNames=" + branches);
  }

  activateTransporter(transporterId, bActivate): Observable<any> {
    return this.http.patch(this.base_url + '/id/' + transporterId + '/status/' + bActivate, null);
  }

  getAllActiveTransporters(): Observable<any> {
    return this.http.get(this.base_url + "/available?branchCodes=" + JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")));
  }

  getAllTransporterType(): Observable<any> {
    return this.http.get(this.base_url + "/transportertypes");
  }
//Added by Digvijay
  mapTransporterBranches(): Observable<any> {
    return this.http.get(this.base_url + "/maptransporterbranches");
  }
  //Digvijay End
}