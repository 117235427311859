import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatDialog, MatSort } from '@angular/material';
import 'rxjs/add/observable/of';
import { OrderService } from '../../../services/order/order.service';
import { DatePipe } from '@angular/common';
import { TokenStorage } from 'src/app/auth/token.storage';
import { NotifierService } from 'angular-notifier';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BranchService } from 'src/app/services/branch/branch.service';
import { TripService } from 'src/app/services/trip/trip.service';
import { AngularFireDatabase } from '@angular/fire/database';

@Component({
  selector: 'app-orderlistview',
  templateUrl: './orderlistview.component.html',
  styleUrls: ['./orderlistview.component.scss']
})
export class OrderlistviewComponent implements OnInit {

  columnsToDisplay = [
    'invoice',
    'zone',
    'scheduledDate',
    'deliveryEta',
    'deliveryDate',
    'origin',
    'receiverDetails',
    'customertype',
    'packageDetails'
  ];
  orderDataArray = new MatTableDataSource();
  unplannedCount: number = 0;
  inPlanningCount: number = 0;
  scheduledCount: number = 0;
  intransitCount: number = 0;
  failedCount: number = 0;
  deliveredCount: number = 0;
  filter: string = 'date';
  ordersArray = [];
  selectedVal: string;
  mapView: boolean = false;
  @Output() notifyParent: EventEmitter<any> = new EventEmitter();
  page: number = 0;
  totalRecords: number;
  typeOfOrder: string;
  fromDate: any;
  toDate: any;
  isLoading = true;
  volUnit: string;
  branchesSubscription: Subscription;
  protected ngUnsubscribe: Subject<void> = new Subject<void>()
isHeretech: boolean = false;
isCipla: boolean = false;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() data: string;
  @ViewChild(MatSort) sort: MatSort;
 //code merge params
 selectedFlag:any= 'ordersTab';
 public lat: Number = 18.552307
  public lng: Number = 73.724519
  colors = [{ color: "#0000FF", count: 0 }, { color: "#00FFFF", count: 0 },
  { color: "#8A2BE2", count: 0 }, { color: "#7FFF00", count: 0 },
  { color: "#FF7F50", count: 0 }, { color: "#DC143C", count: 0 },
  { color: "#B8860B", count: 0 }, { color: "#FF8C00", count: 0 },
  { color: "#FF1493", count: 0 }, { color: "#FF00FF", count: 0 },
  { color: "#FFD700", count: 0 }, { color: "#ADFF2F", count: 0 },
  { color: "#FF4500", count: 0 }, { color: "#FF0000", count: 0 },
  { color: "#FFFF00", count: 0 }];
  tripDataArray = new MatTableDataSource;
  locationsArray: any[];
  deliveredOrderLocation: any[];
  tripStartedStopped = [];
  markersArr = [];
  originDest = [];
  public origin: {};
  tripsLocationArray = [];
  public renderOptions = {
    suppressMarkers: true,
  };
  public destination: {};
  public waypoints = [];
  tripName: string;
  tripId: number;
  allTrips = [];

  selectedTripValue: string;
  tripCountArray = [];
  locationSubscription: Subscription
  trip;
  liveTrackingAllTrips: any=[];
  constructor(public dialog: MatDialog,
    public orderService: OrderService,
    public datePipe: DatePipe,
    private token: TokenStorage,
    private notifierService: NotifierService,
    private dialogService: DialogService,
    private branchService: BranchService,
    private tripService: TripService,
    private db: AngularFireDatabase,
  ) {

    this.getNotification('evt');
    if (this.token.getTenant() == 'kimberly-dist-prod') {
      this.volUnit = 'CM';
    } else if (this.token.getTenant() == 'godrej-dist-prod') {
      this.volUnit = 'CCM';
    } else {
      this.volUnit = 'CF';
    }
    if (this.token.getTenant() == 'heretech-dist-prod' || this.token.getTenant() == 'heretech-dist-test') {
      this.isHeretech = true;
    }

    // if (this.token.getTenant() == 'cipla-dist-prod' || this.token.getTenant() == 'cipla-dist-test') {
    if (this.token.getTenant() == 'cipla-dist-prod' || this.token.getTenant() == 'cipla-dist-test'||this.token.getTenant() == 'cipla-dist-test-siliguri') {//Digvijay
      this.isCipla = true;
    }

  }

  ngOnInit() {
    this.selectedVal = '0';
    // this.typeOfOrder = 'ORDER_STATE_UNPLANNED';
    this.fromDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.toDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.sectionSelection('ordersTab');
    //this.findOrders(0);
    // this.branchesSubscription = this.branchService.branchesObservable.subscribe(() => {
    //   if (window.sessionStorage.getItem("selectedBranchesCodes") && JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")).length == 0) {
    //     this.orderDataArray.data = [];
    //     this.isLoading = false;
    //     this.setValuesToZero();
    //   }
    //   else {
    //     this.getData();
    //     this.getCardsCount();
    //   }
    // });


    // this.orderDataArray.sortingDataAccessor = (item: any, property) => {
    //   switch (property) {
    //     case 'receiverDetails': return item.orderInfo.customerInfo.customerName + " " + item.orderInfo.customerInfo.address.area;
    //     case 'zone': return item.orderInfo.customerInfo.zone.zoneName;
    //     case 'customertype': return item.orderInfo.customerInfo.customerType;
    //     case 'origin': return item.orderInfo.originBranch.branchName + " " + item.orderInfo.originBranch.branchCode;
    //   }
    // };
    // this.orderDataArray.sort = this.sort;
  }

  sectionSelection(flag)
  {
    this.selectedFlag = flag;
    this.page = 0;
    this.totalRecords = 0;
    
    if(this.selectedFlag == 'ordersTab')
    {
      this.columnsToDisplay = [
        'invoice',
        'zone',
        'scheduledDate',
        'deliveryEta',
        'deliveryDate',
        'origin',
        'receiverDetails',
        'customertype',
        'packageDetails'
      ];
      this.typeOfOrder = 'ORDER_STATE_UNPLANNED';

    this.branchesSubscription = this.branchService.branchesObservable.subscribe(() => {
      if (window.sessionStorage.getItem("selectedBranchesCodes") && JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")).length == 0) {
        this.orderDataArray.data = [];
        this.isLoading = false;
        this.setValuesToZero();
      }
      else {
        this.getData();
        this.getCardsCount();

      }
    });
    }
    else if(this.selectedFlag == 'tripsTab')
    {
      this.columnsToDisplay = [
        'select',
        'trip',
        'origin',
        'vehicle',
        'visit',
        'progress',
        'edit'
      ];;
      this.selectedTripValue = "TRIP_STATE_IN_TRANSIT";
      // let date, last, day, month, year;
      // date = new Date();
      // last = new Date(date.getTime() - (parseInt(this.selectedVal) * 24 * 60 * 60 * 1000));
      // day = last.getDate();
      // month = last.getMonth();
      // year = last.getFullYear();
      // this.fromDate = this.datePipe.transform(new Date(year, month, day), 'yyyy-MM-dd');
      // this.toDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

      this.branchesSubscription = this.branchService.branchesObservable.subscribe(() => {
        if (window.sessionStorage.getItem("selectedBranchesCodes") && JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")).length == 0) {
          this.tripDataArray.data = [];
          this.tripCountArray = [];
          this.isLoading = false;
        }
        else {
          this.getTripCountArray();
          this.gettripData();
        }
      });
    }
    else if(this.selectedFlag == 'liveTrackingTab')
    {
      this.getlivetrackingtripData();
    }
  }

  getTripCountArray() {
    this.tripService.getTripCountByStatus(this.fromDate, this.toDate).subscribe(tripCountArray => {
      if (tripCountArray) {
        this.tripCountArray = tripCountArray;
      }
    }, () => {
      this.notifierService.notify("error", "Error In Fetching Trip Count.")
    });
  }

  gettripData() {
    this.ngUnsubscribe.next();
    this.tripDataArray.data = [];
    this.isLoading = true;
    console.log("=====================this.selectedTripValue============================")
    console.log(this.selectedTripValue)
    this.tripService.getAllTripsByTypesDateRange(this.selectedTripValue,  this.page, this.fromDate, this.toDate, 'nozone').pipe(takeUntil(this.ngUnsubscribe)).subscribe(trips => {
      this.isLoading = false;
      this.allTrips = trips.content;
      this.totalRecords = trips.totalElements;
      this.allTrips.forEach(trip => {
        let originAd;
        originAd = trip.originAddress.split(',');
        trip.originLocation = originAd[originAd.length - 1];
        trip.hexColor = "#273754"//this.getRandomColor().toUpperCase();
        if (this.selectedTripValue != "TRIP_STATE_SCHEDULED") {
          let totalJobs = trip.jobs.length;
          let deliveredJobs = 0;
          trip.jobs.forEach(job => {
            if (job.jState == 'JOB_STATE_DELIVERED') {
              deliveredJobs++;
            }
          });
          trip.progress = (deliveredJobs / totalJobs) * 100;
        }
      });
      this.tripDataArray.data = this.allTrips;
    }, () => {
      this.isLoading = false;
      this.notifierService.notify('error', "Error fetching trips.");
    });
  }

  getRandomColor() {
    for (let i = 1; i < this.colors.length; i++) {
      if (this.colors[i].count < this.colors[i - 1].count) {
        this.colors[i].count++;
        return this.colors[i].color;
      }
    }
    this.colors[0].count++;
    return this.colors[0].color;
  }

  pageTripChanged(event) {
    this.page = event.pageIndex;
    this.gettripData();
  }
  setValuesToZero() {
    this.unplannedCount = 0;
    this.inPlanningCount = 0;
    this.scheduledCount = 0;
    this.intransitCount = 0;
    this.failedCount = 0;
    this.deliveredCount = 0;
  }

  getCardsCount() {
    this.orderService.getOrderCountByStatus(this.fromDate, this.toDate).subscribe(res => {
      console.log("order count by status", res);
      this.setValuesToZero();
      if (this.searchArraryForCount(res, 'ORDER_STATE_UNPLANNED') != undefined) {
        this.unplannedCount = this.searchArraryForCount(res, 'ORDER_STATE_UNPLANNED').ordercount;
      }
      if (this.searchArraryForCount(res, 'ORDER_STATE_IN_PLANNING') != undefined) {
        this.inPlanningCount = this.searchArraryForCount(res, 'ORDER_STATE_IN_PLANNING').ordercount;
      }

      if (this.searchArraryForCount(res, 'ORDER_STATE_SCHEDULED') != undefined) {
        this.scheduledCount = this.searchArraryForCount(res, 'ORDER_STATE_SCHEDULED').ordercount;
      }

      if (this.searchArraryForCount(res, 'ORDER_STATE_IN_TRANSIT') != undefined) {
        this.intransitCount = this.searchArraryForCount(res, 'ORDER_STATE_IN_TRANSIT').ordercount;
      }

      if (this.searchArraryForCount(res, 'ORDER_STATE_FAILED') != undefined) {
        this.failedCount = this.searchArraryForCount(res, 'ORDER_STATE_FAILED').ordercount;
      }

      if (this.searchArraryForCount(res, 'ORDER_STATE_DELIVERED') != undefined) {
        this.deliveredCount = this.searchArraryForCount(res, 'ORDER_STATE_DELIVERED').ordercount;
      }
    });
  }

  onValChange(e) {
    this.notifyParent.emit('e');
  }

  ngAfterViewInit() {
  }
  getNotification(evt) {
  }
  calculateNumOfItemsInOrder(orderArray) {
    orderArray.forEach(function (order) {
      let numOfItemsInOrder = 0;
      order.prodQList.forEach(function (prod) {
        numOfItemsInOrder = numOfItemsInOrder + prod.productQuantity;
      });
      order.numOfItemsInOrder = numOfItemsInOrder
    });
  }

  searchArraryForCount(arr, value) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].orderstatus === value) {
        return arr[i];
      }
    }
  }

  filterList() {
    console.log(this.filter);
    if (this.filter === "week") {

    }
    console.log(this.ordersArray);
  }

  orderData(type) {
    this.page = 0;
    this.paginator._pageIndex = 0;
    switch (type) {
      case "unplanned":
        this.typeOfOrder = 'ORDER_STATE_UNPLANNED';
        this.getData();
        break;
      case "inplanning":
        this.typeOfOrder = 'ORDER_STATE_IN_PLANNING';
        this.getData();
        break;
      case "scheduled":
        this.typeOfOrder = 'ORDER_STATE_SCHEDULED';
        this.getData();
        break;
      case "intransit":
        this.typeOfOrder = 'ORDER_STATE_IN_TRANSIT';
        this.getData();
        break;
      case "failed":
        this.typeOfOrder = 'ORDER_STATE_FAILED';
        this.getData();
        break;
      case "delivered":
        this.typeOfOrder = 'ORDER_STATE_DELIVERED';
        this.getData();
        break;
      default:
        this.typeOfOrder = 'ORDER_STATE_UNPLANNED';
        this.getData();
        break;
    }
  }

  getData() {
    this.ngUnsubscribe.next();
    this.orderDataArray.data = [];
    this.isLoading = true;
    if (this.page == 0)
      this.totalRecords = 0;
    this.orderService.getAllOrdersByTypesDateRange(this.typeOfOrder, this.page, this.fromDate, this.toDate, 'nozone').pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.isLoading = false;
      this.orderDataArray.data = res.content;
      this.ordersArray = res.content;
      this.totalRecords = res.totalElements;
      this.orderDataArray.sortingDataAccessor = (item: any, property) => {
        switch (property) {
              case 'receiverDetails': return item.customerInfo.customerName + " " + item.customerInfo.address.area;
              case 'zone': return item.customerInfo.zone.zoneName;
              case 'customertype': return item.customerInfo.customerType;
              case 'origin': return item.originBranch.branchName + " " + item.originBranch.branchCode;
            }
      };
      this.orderDataArray.sort = this.sort;
    }, Error => {
      this.isLoading = false;
      this.notifierService.notify("error", "Error in fetching orders.")
    });
  }

  pageChanged(event) {
    this.page = event.pageIndex;
    this.getData();
  }


  orderDetails(data) {
    this.dialogService.orderDetails(data.orderUniqueId, 'orderlistview');
  }


  findOrders(days) {
    let date, last, day, month, year;
    date = new Date();
    last = new Date(date.getTime() - (days * 24 * 60 * 60 * 1000));
    day = last.getDate();
    month = last.getMonth();
    year = last.getFullYear();
    this.fromDate = this.datePipe.transform(new Date(year, month, day), 'yyyy-MM-dd');
    this.toDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.getCardsCount();

    if (window.sessionStorage.getItem("selectedBranchesCodes") && JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")).length == 0) {
      this.orderDataArray.data = [];
      this.tripDataArray.data = [];
      this.isLoading = false;
      this.setValuesToZero();
    }
    else {
      this.ngUnsubscribe.next();
      this.isLoading = true;
      this.orderDataArray.data = [];
      this.totalRecords = 0;


      if(this.selectedFlag == 'ordersTab')
      {
      
      this.orderService.getAllOrdersByTypesDateRange(this.typeOfOrder, this.page, this.fromDate, this.toDate, 'nozone').pipe(takeUntil(this.ngUnsubscribe)).subscribe(orders => {
        this.isLoading = false;
        this.orderDataArray.data = orders.content;
        this.totalRecords = orders.totalElements;
        this.page = 0;
        console.log("Orders", orders);
      }, Error => {
        this.isLoading = false;
        this.notifierService.notify("error", "Error in fetching orders.")
      });
    }
    //trip tracking filter changes
   if(this.selectedFlag =='tripsTab'){
    this.getTripCountArray();

    this.allTrips = [];
    this.tripDataArray.data = this.allTrips;
    this.tripsLocationArray.forEach(Trip => {
      Trip.isVisible = false;
    });
    
    if (this.selectedTripValue == 'TRIP_STATE_SCHEDULED') {
      this.columnsToDisplay = [
        'trip',
        'origin',
        'vehicle',
        'visit',
        'edit'
      ];
    } else {
      this.columnsToDisplay = [
        'select',
        'trip',
        'origin',
        'vehicle',
        'visit',
        'progress',
        'edit'
      ];
    }
    

    this.getTripCountArray();
    this.gettripData();

    if (this.locationSubscription) {
      this.locationSubscription.unsubscribe();
    }
    }

    if(this.selectedFlag == 'liveTrackingTab')
      {
        this.getlivetrackingtripData();
      }
    }
  }

  tripDetails(trip, event) {
    if (this.locationSubscription) {
      this.locationSubscription.unsubscribe();
    }

    this.allTrips.forEach(Trip => {
      Trip.isChecked = false;
      if (Trip.tripName === trip.tripName) {
        Trip.isChecked = true;
      }
    });

    this.tripsLocationArray.forEach(Trip => {
      Trip.isVisible = false;
    });
    this.tripDataArray.data = this.allTrips;
    if (!event.checked) {
      this.tripsLocationArray.forEach(availabletrip => {
        if (availabletrip.tripName === trip.tripName) {
          availabletrip.isVisible = false;
        }
      });
    }

    //this.locationsArray = [];
    //this.trip = null;
    if (event.checked) {
      let path = '/' + this.token.getTenant() + '/' + trip.tripName;
      this.locationSubscription = this.db.list(path).valueChanges().subscribe(res => {
        //this.locationsArray = res;
        //this.trip = trip;
        this.originDest = [];
        if (res.length > 0) {
          this.markersArr = res;
          this.originDest.push(this.markersArr[0]);
          this.originDest.push(this.markersArr[res.length - 1]);
          this.originDest[0].icon = '../../../assets/icons/warehouse1.png';
          this.originDest[1].icon = '../../../assets/icons/truck3d.png';

          this.locationsArray = res;
          //let bounds: LatLngBounds = new google.maps.LatLngBounds();
          console.log("locationsArray", this.locationsArray);
          this.deliveredOrderLocation = this.locationsArray.filter
            (deliveredLocations => deliveredLocations.status == 'delivered' || deliveredLocations.status == 'undelivered');
          this.tripStartedStopped = this.locationsArray.filter(loc => loc.status == 'trip_started' || loc.status == 'trip_stopped');
          this.tripStartedStopped.forEach(marker => {
            if (marker.status == "trip_started") {
              marker.icon = "../../../assets/images/IoT/icons/warehouse.png";
            }
            if (marker.status == "trip_stopped") {
              marker.icon = "../../../assets/images/IoT/red-dot.png";
            }
          }); 
          console.log(this.tripStartedStopped);
          let sequence = 1;
          this.deliveredOrderLocation.forEach(location => {
            location.seq = sequence.toString();
            sequence = sequence + 1;
            if (location.status == 'delivered')
              location.icon = "../../../assets/images/IoT/bus.png"
          });

          trip.jobs.forEach(job => {
            this.deliveredOrderLocation.forEach(location => {
              if (job.jobId == location.jobId) {
                location.customerName = job.customerInfo.customerName;
                location.address = job.customerInfo.address.houseNumber;
              }
            });
          });

          this.locationsArray = this.locationsArray.filter(point => !point.hasOwnProperty('status'));
          if (this.locationsArray) {
            this.waypoints = [];
            if (this.locationsArray.length <= (22 - this.deliveredOrderLocation.length)) {
              this.locationsArray.forEach(loc => {
                this.waypoints.push({
                  location: (parseFloat(loc.latitude) + "," + parseFloat(loc.longitude))
                });
              });
            }
            else {
              this.waypoints = [];
              let i = 0;
              let multiplicationfactor: number = Number(this.locationsArray.length) / 23 - (this.deliveredOrderLocation.length);

              if (multiplicationfactor <= 1)
                multiplicationfactor = 1;
              else
                multiplicationfactor = parseInt(multiplicationfactor.toString())
              console.log("multifactor", multiplicationfactor);

              for (i = 0; i < this.locationsArray.length; i = i + multiplicationfactor) {

                if (this.waypoints.length <= 22 - this.deliveredOrderLocation.length) {
                  this.waypoints.push({
                    location: (parseFloat(this.locationsArray[i].latitude) + "," + parseFloat(this.locationsArray[i].longitude))
                  });
                }
              }
            }

            this.deliveredOrderLocation.forEach(loc => {
              this.waypoints.push({ location: (parseFloat(loc.latitude) + "," + parseFloat(loc.longitude)) })
            });
            this.origin = {
              lat: parseFloat(this.markersArr[0].latitude),
              lng: parseFloat(this.markersArr[0].longitude)
            };
            this.destination = {
              lat: parseFloat(this.markersArr[res.length - 1].latitude),
              lng: parseFloat(this.markersArr[res.length - 1].longitude)
            };
            console.log("waypoints,origin and destination", this.waypoints, this.origin, this.destination);
          }
          let color;
          this.allTrips.forEach(Trip => {
            if (Trip.tripName === trip.tripName) {
              color = "#273754";//Trip.hexColor;
            }
          });
          let tripObj = {
            waypoints: this.waypoints,
            origin: this.origin,
            destination: this.destination,
            tripName: trip.tripName,
            deliveredOrderLocation: this.deliveredOrderLocation,
            tripStartedStopped: this.tripStartedStopped,
            isVisible: true,
            originDest: this.originDest,
            hexColor: color,
          }
          this.tripsLocationArray = [];
          this.tripsLocationArray.push(tripObj)
          console.log("this.tripsLocationArray")
          console.log(this.tripsLocationArray)
        } else {
          this.notifierService.notify("error", "No location data present for this trip.");
        }
      });
    }
  }


  getlivetrackingtripData() {
    this.ngUnsubscribe.next();
    this.tripDataArray.data = [];
    this.isLoading = true;

    this.tripService.getAllTripsByTypesDateRange("TRIP_STATE_IN_TRANSIT",  -1, this.fromDate, this.toDate, 'nozone').pipe(takeUntil(this.ngUnsubscribe)).subscribe(trips => {
      this.isLoading = false;
      let liveTrackingAllTripsData = trips.content;
      this.totalRecords = trips.totalElements;
      let tempData = [];
        if(liveTrackingAllTripsData.length>0)
        {
          this.lat = liveTrackingAllTripsData[0].origin.coordinates[0];
          this.lng = liveTrackingAllTripsData[0].origin.coordinates[1];
          for(let i=0;i<liveTrackingAllTripsData.length;i++)  
          {
            let trip = liveTrackingAllTripsData[i];
            console.log(trip)
            let customerNameList= [];
            for (let j = 0; j < trip.jobs.length; j++) {
             
              if(!customerNameList.includes(trip.jobs[j].customerInfo.customerName))
              {
                customerNameList.push(trip.jobs[j].customerInfo.customerName)
              }
            }

            let path = '/' + this.token.getTenant() + '/' + trip.tripName;
            this.locationSubscription = this.db.list(path).valueChanges().subscribe(res => {

              // console.log(res);
            tempData.push({
                tripId:trip.tripId,
                tripName:trip.tripName,
                totalNumberOfJobs:trip.totalNumberOfJobs,
                totalNumberOfOrders:trip.totalNumberOfOrders,
                totalVolume:trip.totalVolume,
                totalWeight:trip.totalWeight,
                origin:res[res.length - 1],
                originAddress:trip.originAddress,
                customers:customerNameList?customerNameList.join(', '):null,
                icon:'../../../assets/icons/truck3d.png',
                updatedDateTime:trip.updatedDateTime,
              })
            });
          }
          this.liveTrackingAllTrips = tempData;
        }
      console.log("this.liveTrackingAllTrips");
      console.log(this.liveTrackingAllTrips);
    }, () => {
      this.isLoading = false;
      this.notifierService.notify('error', "Error fetching trips.");
    });
  }


  openTripDetailsWindow(data) {
    this.dialogService.tripDetails(data, 'tripDetail')
  }

  findTrips() {
    this.paginator._pageIndex = 0;
    this.page = 0;
    this.totalRecords = 0;

    // let date, last, day, month, year;
    // date = new Date();
    // last = new Date(date.getTime() - (parseInt(this.selectedVal) * 24 * 60 * 60 * 1000));
    // day = last.getDate();
    // month = last.getMonth();
    // year = last.getFullYear();
    // this.fromDate = this.datePipe.transform(new Date(year, month, day), 'yyyy-MM-dd');
    // this.toDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.getTripCountArray();
    if (window.sessionStorage.getItem("selectedBranchesCodes") && JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")).length == 0) {
      this.tripDataArray.data = [];
      this.isLoading = false;
    }
    else {
      this.ngUnsubscribe.next();
      this.isLoading = true;
      this.allTrips = [];
      this.tripDataArray.data = this.allTrips;
      this.tripsLocationArray.forEach(Trip => {
        Trip.isVisible = false;
      });

      if (this.selectedTripValue == 'TRIP_STATE_SCHEDULED') {
        this.columnsToDisplay = [
          'trip',
          'origin',
          'vehicle',
          'visit',
          'edit'
        ];
      } else {
        this.columnsToDisplay = [
          'select',
          'trip',
          'origin',
          'vehicle',
          'visit',
          'progress',
          'edit'
        ];
      }

      this.gettripData();

      if (this.locationSubscription) {
        this.locationSubscription.unsubscribe();
      }
    }
  }
  getCount(tripType: string) {
    for (let i = 0; i < this.tripCountArray.length; i++) {
      if (this.tripCountArray[i].tripstatus === tripType) {
        return this.tripCountArray[i].tripcount;
      }
    }
    return 0;
  }
  ngOnDestroy() {
    this.branchesSubscription.unsubscribe();
  }

}

export interface OrderListElement {
  zone: string,
  receiver: string,
  orderInvoiceNumber: string,
  deliveryETADate: string,
  packageDetails: string,
  originAddress: string,
  destinationAddress: string,
  numOfItems: string,
  action: string
}