import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-userandrole',
  templateUrl: './userandrole.component.html',
  styleUrls: ['./userandrole.component.scss']
})
export class UserandroleComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
