import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { MatDialog, } from '@angular/material/dialog';
import { EdittransporterComponent } from "../edittransporter/edittransporter.component";
import { TransporterService } from '../../../services/transporter/transporter.service';
import { NotifierService } from 'angular-notifier';
import { Subscription, Subject } from 'rxjs';
import { BranchService } from 'src/app/services/branch/branch.service';
import { takeUntil } from 'rxjs/operators';
import { AddtransporterComponent } from '../addtransporter/addtransporter.component';
import { TokenStorage } from '../../../auth/token.storage';

@Component({
  selector: 'app-alltransporter',
  templateUrl: './alltransporter.component.html',
  styleUrls: ['./alltransporter.component.scss']
})
export class AlltransporterComponent implements OnInit {

  showFiller = false;
  isLoading: boolean = false;

  columnsToDisplay = [
    'transporterName',
    'transporterCode',
    'transporterType',
    'associatedUnit',
    'transporterAddress',
    'transporterPhoneNumber',
    'contactPersonName',
    'activate',
    'actions'
  ];

  transporterDataArray = new MatTableDataSource;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  branchesSubscription: Subscription;
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  isWarehouseManager: boolean = false;

  constructor(
    private dialog: MatDialog,
    private transporterService: TransporterService,
    private notifierService: NotifierService,
    private branchService: BranchService,
    private token: TokenStorage,

  ) { }

  ngOnInit() {
    if (window.sessionStorage.getItem('permissions').substr(2).slice(0, -2) == 'warehouse_manager') {
      this.isWarehouseManager = true;
    }
    // Added By Digivjay
    if(this.token.getTenant() == 'kimberly-dist-prod' || this.token.getTenant() == 'kimberly-dist-test'){
      this.mapTransporterBranches(); 
    }
    //Digvijay End
    this.getAllTransporters();
  }

  addNewTransporterModal() {

    let addDialog = this.dialog.open(AddtransporterComponent, {
      width: '85%',
      maxWidth: '85vw',
    });


    addDialog.updatePosition({
      top: '50px'
    });

  }

  getAllTransporters() {
    //call get all fleet service here
    //change base_url inside fleet serivces
    this.branchesSubscription = this.branchService.branchesObservable.subscribe(() => {
      this.ngUnsubscribe.next();
      if (window.sessionStorage.getItem("selectedBranchesCodes") && JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")).length == 0) {
        this.transporterDataArray.data = [];
        this.isLoading = false;
      }
      else {
        this.transporterDataArray.data = [];
        this.isLoading = true;
        this.isLoading = true;
        this.transporterService.getAllTransporters().pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
          this.isLoading = false;
          this.transporterDataArray.data = res;
          this.transporterDataArray.sortingDataAccessor = (transporter: any, property) => {
            switch (property) {
              case 'transporterName': return transporter.transporterName;
              case 'transporterType': return transporter.transporterType;
              case 'transporterCode': return transporter.transporterCode;
            }
          };
          this.transporterDataArray.sort = this.sort;
          this.transporterDataArray.paginator = this.paginator;
        }, () => {
          this.notifierService.notify('error', "Error fetching data");
          this.isLoading = false;
        });
      }
    });
    console.log(this.transporterDataArray.data);
  }

  onChange(Event, transId) {
    console.log(Event.checked);
    if (Event.checked) {
      this.transporterService.activateTransporter(transId, true).subscribe(res => {
        console.log(res);
      }, Error => {
        console.log(Error);
      });
    } else {
      this.transporterService.activateTransporter(transId, false).subscribe(res => {
        console.log(res);
      }, Error => {
        console.log(Error);
      });
    }
  }

  edittransporter(transporter) {
    let editDialog = this.dialog.open(EdittransporterComponent, {
      width: '65%',
      maxWidth: '85vw',
      data: transporter,
      id: 'editTransporterWindow'
    });

    editDialog.updatePosition({
      top: '50px',
    });
    editDialog.afterClosed().subscribe(result => {
      console.log(result);
      if (result) {
        this.getAllTransporters();
      }
    })
  }

  applyFilter(filterValue: string) {
    this.transporterDataArray.filter = filterValue.trim().toLowerCase();
  }

  ngOnDestroy() {
    this.branchesSubscription.unsubscribe();
  }

// Added By Digvijay for Automatic mapping of transporter and branches. 
  mapTransporterBranches() {
      this.transporterService.mapTransporterBranches().subscribe(res => {
        this.dialog.closeAll();
        }, (error) => {
        if(error.error.Error){
          this.notifierService.notify('error', error.error.Error);
        }
        else{
          this.notifierService.notify('error', "Error adding transporter branches mapping.");
        }
      });
  }
  // Digivjay End. 

}