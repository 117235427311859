import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatSort, MatDialog } from '@angular/material';
import { DatePipe, DecimalPipe } from '@angular/common';
import 'rxjs/add/observable/of';
import { TripService } from '../../services/trip/trip.service';
import { PdfgeneratorService } from '../../services/pdfgenerator/pdfgenerator.service';
import { ExcelgeneratorService } from '../../services/excelgenerator/excelgenerator.service';
import { NgxDateRangePickerOptions } from 'src/ngx-daterangepicker';
import { TokenStorage } from 'src/app/auth/token.storage';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { BranchService } from 'src/app/services/branch/branch.service';

@Component({
  selector: 'app-tripanalytics',
  templateUrl: './tripanalytics.component.html',
  styleUrls: ['./tripanalytics.component.scss']
})
export class TripanalyticsComponent implements OnInit {
  columnsToDisplay = [
    'regionAndCode',
    'tripName',
    'tripState',
    'noOfOrders',
    'tripDetails',
    'tripStartEndTime',
    'tripTime',
    'odometer',
    'volumeUtilization',
  ];
  tripDataArray = new MatTableDataSource();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  options: NgxDateRangePickerOptions;
  page: number = 0;
  totalRecords: Number;
  excelRecords = [];
  selected = '7';
  daterRangevalue: any;
  fromDate: any;
  toDate: any;
  isDateFiltered: boolean = false;
  trips = [];
  volUnit: string;
  prevFromDate: any;
  prevTodate: any;
  isLoading: boolean = false;
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  branchesSubscription: Subscription;

  constructor(
    public dialog: MatDialog,
    public tripService: TripService,
    public generatePdf: PdfgeneratorService,
    public excelService: ExcelgeneratorService,
    public datePipe: DatePipe,
    public decimalPipe: DecimalPipe,
    private token: TokenStorage,
    private dialogService: DialogService,
    private branchService: BranchService
  ) {
    if (this.token.getTenant() == 'kimberly-dist-prod' || this.token.getTenant() == 'kimberly-dist-test') {
      this.volUnit = ' M3';
    } else if (this.token.getTenant() == 'godrej-dist-prod') {
      this.volUnit = 'CCM';
    } else {
      this.volUnit = 'CF';
    }
  }

  ngOnInit() {
    this.createInitialFilter();
    this.branchesSubscription = this.branchService.branchesObservable.subscribe(() => {
      this.ngUnsubscribe.next();
      if (window.sessionStorage.getItem("selectedBranchesCodes") && JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")).length == 0) {
        this.tripDataArray.data = [];
        this.isLoading = false;
      }
      else {
        this.tripDataArray.data = [];
        this.tripFilter();
      }
    });
  }

  exportAsXLSX() {
    this.excelRecords = [];
    let tripalyticsAnData = [];
    tripalyticsAnData = this.tripDataArray.data;
    tripalyticsAnData.forEach(trip => {
      let vehicleReportingTime = this.datePipe.transform(trip.vehicle_reporting_time, "dd-MM-yyyy hh:mm a");
      let triptime: string, tripdistance: string, deliveredby: string, tripScheduled: string, tripStart: string, tripEnd: string, tripWeight: string, tripVolume: string, vehicleNo: string, vehicleType: string,
        odometerStart: string, odometerEnd: string;

      if (trip.trip_time)
        triptime = trip.trip_time + " hrs";
      else
        triptime = " ";

      if (trip.trip_distance)
        tripdistance = trip.trip_distance + " kms";
      else
        tripdistance = " ";

      if (trip.delivered_by)
        deliveredby = trip.delivered_by;
      else
        deliveredby = " ";

      if (trip.start_date_time)
        tripStart = this.datePipe.transform(trip.start_date_time, "dd-MM-yyyy hh:mm a");
      else
        tripStart = "";

      if (trip.end_date_time)
        tripEnd = this.datePipe.transform(trip.end_date_time, "dd-MM-yyyy hh:mm a");
      else
        tripEnd = "";

      if (trip.scheduled_date_time)
        tripScheduled = this.datePipe.transform(trip.scheduled_date_time, "dd-MM-yyyy hh:mm a");
      else
        tripScheduled = "";

      if (trip.trip_weight)
        tripWeight = this.decimalPipe.transform(trip.trip_weight, "1.0-2");
      else
        tripWeight = "";

      if (trip.trip_volume)
        tripVolume = this.decimalPipe.transform(trip.trip_volume, "1.0-2");
      else
        tripVolume = "";

      if (trip.vehicle_number)
        vehicleNo = trip.vehicle_number;
      else
        vehicleNo = " ";

      if (trip.vehicle_type)
        vehicleType = trip.vehicle_type;
      else
        vehicleType = "";

      if (trip.odometerStart)
        odometerStart = trip.odometerStart;
      else
        odometerStart = "";

      if (trip.odometerEnd)
        odometerEnd = trip.odometerEnd;
      else
        odometerEnd = "";

      if (trip) {
        this.excelRecords.push({
          'Region': trip.warehouseRegion || '',
          'Plant Code': trip.warehouseCode || '',
          'Trip Name': trip.trip_name,
          'Shipment No': trip.trip_name.slice(5),
          'Branch': trip.trip_warehouse,
          'Status': trip.status.slice(11),
          'Orders #': trip.total_number_of_orders,
          'Delivered By': deliveredby,
          'Driver Phone No.': trip.driver_phone_number,
          'Vehicle No.': vehicleNo,
          'Vehicle Type': vehicleType,
          'Customer Name': trip.customerName || '',
          'Customer Code': trip.customerCode || '',
          'Customer Type': trip.customerType || '',
          'Trip Scheduled': tripScheduled,
          'Trip Start': tripStart,
          'Trip End': tripEnd,
          'Travel Time': triptime,
          'Trip Distance': tripdistance,
          'Odometer Start Reading': odometerStart,
          'Odometer End Reading': odometerEnd,
          'Vehicle Reporting Time': vehicleReportingTime,
          'Weight': tripWeight,
          'Weight Utilization': trip.wt_utilization,
          'Volume': tripVolume,
          'Volume Utilization': trip.volume_utilization
        });
      }
    });
    this.excelService.exportAsExcelFile(this.excelRecords, 'tripReport');
  }

  /*  public captureScreen() {
     var data = document.getElementById('contentToConvert');
     html2canvas(data).then(canvas => {
       this.generatePdf.pdfgenerator(canvas, this.tripDataArray.data.length);
     });
   } */


  ngAfterViewInit() {
    this.tripDataArray.paginator = this.paginator;
  }
  createInitialFilter() {
    this.options = {
      theme: 'default',
      labels: ['Start', 'End'],
      menu: null,
      dateFormat: 'YYYY-MM-DD',
      outputFormat: 'DD-MM-YYYY',
      startOfWeek: 1,
      outputType: 'object',
      locale: 'en',
      date: {
        from: {
          year: new Date().getFullYear(),
          month: new Date().getMonth() + 1,
          day: new Date().getDate()
        },
        to: {
          year: new Date().getFullYear(),
          month: new Date().getMonth() + 1,
          day: new Date().getDate()
        }
      }
    };
    this.fromDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.toDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.isDateFiltered = true;
  }

  tripFilter() {
    if (this.isDateFiltered) {
      this.isLoading = true;
      this.getData(this.fromDate, this.toDate).pipe(takeUntil(this.ngUnsubscribe)).subscribe(trips => {
        this.isLoading = false;
        console.log("Trips in report", trips);
        this.tripDataArray.data = trips;
        this.totalRecords = trips.length;
        this.tripDataArray.sortingDataAccessor = (item: any, property) => {
          switch (property) {
            case 'tripState': return item.status;
          }
        };
        this.tripDataArray.sort = this.sort;
      }, Error => {
        this.isLoading = false;
      })
    }
  }


  getData(from: any, to: any) {
    return this.tripService.getTripReport(from, to);
  }

  sortByDate() {
    if (this.daterRangevalue != undefined) {
      this.fromDate = this.daterRangevalue.from.substring(6, 10) + '-' + this.daterRangevalue.from.substring(3, 5) + '-' + this.daterRangevalue.from.substring(0, 2);
      this.toDate = this.daterRangevalue.to.substring(6, 10) + '-' + this.daterRangevalue.to.substring(3, 5) + '-' + this.daterRangevalue.to.substring(0, 2);
      if (this.toDate != this.prevTodate || this.fromDate != this.prevFromDate) {
        this.ngUnsubscribe.next();
        this.isDateFiltered = true;
        this.tripFilter();
        this.prevFromDate = this.fromDate;
        this.prevTodate = this.toDate;
      }
    }
  }

  clearAllFilter() {
    this.paginator.pageIndex = 0;
    this.createInitialFilter();
    this.isDateFiltered = false;
    this.prevFromDate = this.fromDate;
    this.prevTodate = this.toDate;
  }

  tripDetails(tripId: any) {
    this.dialogService.tripDetails(tripId, 'tripreport')
  }

  applyFilter(filterValue: string) {
    this.tripDataArray.filter = filterValue.trim().toLowerCase();
  }
  ngOnDestroy() {
    this.branchesSubscription.unsubscribe();
  }
}