import { Injectable } from '@angular/core';
import { Http, Headers, URLSearchParams, Response, HttpModule, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  base_url: string;
  constructor(
    private http: HttpClient
  ) {
    this.base_url = environment.appUrl + "/search/"
  }

  search(searchText, searchOption): Observable<any> {

    console.log(searchOption)

    if (searchOption != "invoicenumber" && searchOption != "invoiceid")
      return this.http.get(this.base_url + searchOption + "/" + searchText);
    else if (searchOption == "invoicenumber")
      return this.http.get(this.base_url + searchOption + "?invoicenumber=" + searchText);
    else if (searchOption == "invoiceid")
      return this.http.get(this.base_url + searchOption + "?invoiceId=" + searchText);
  }

}
