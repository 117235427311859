import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BranchService } from '../../../services/branch/branch.service';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { TransporterService } from 'src/app/services/transporter/transporter.service';
import { ValidatorService } from 'src/app/services/validator/validator.service';


@Component({
  selector: 'app-addbranch',
  templateUrl: './addbranch.component.html',
  styleUrls: ['./addbranch.component.scss']
})
export class AddbranchComponent implements OnInit {
  config = {
    placeholder: 'Select Branchtype'
  }
  branchForm: FormGroup;
  transportSegment: boolean = false;
  branchType: boolean;
  allTransportSegment: any[];
  allBranchType: any[];
  constructor(
    private formBuilder: FormBuilder,
    private branchService: BranchService,
    private snackBar: MatSnackBar,
    private router: Router,
    private transporterService: TransporterService

  ) { }

  openSnackBar(message) {
    this.snackBar.open(message, 'X', {
      duration: 10000,
      panelClass: ['extraClasses']
    });
  }

  initializeForm() {
    this.branchForm = this.formBuilder.group({
      'branchName': [null,
        Validators.compose([
          Validators.required,
          ValidatorService.whitespaceValidator
        ])],
      'branchType': [null,
        [
          Validators.maxLength(255),
          ValidatorService.whitespaceValidator
        ]],
      'branchCode': [null,
        Validators.compose([
          Validators.required,
          Validators.pattern("^[[A-Za-z0-9\\-\\_\\/ ]+$"),
          ValidatorService.whitespaceValidator
        ])],
      'area': [null,
        Validators.compose([
          Validators.required,
          Validators.pattern("^[a-zA-Z0-9\\/\\- ]+$"),
          ValidatorService.whitespaceValidator
        ])],
      'address': [null,
        Validators.compose([
          Validators.required,
          ValidatorService.whitespaceValidator
        ])],
      'contactPersonPhoneNumber': [null,
        Validators.compose([
          Validators.minLength(10),
          Validators.pattern('^[0-9]+$'),
          ValidatorService.whitespaceValidator
        ])],
      'contactPersonName': [null,
        Validators.compose([
          Validators.required,
          Validators.pattern("^[a-zA-Z ]+$"),
          ValidatorService.whitespaceValidator
        ])],
      'pinCode': [null,
        Validators.compose([
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(10),
          Validators.pattern('^[0-9]+$'),
          ValidatorService.whitespaceValidator
        ])],
      'city': [null,
        Validators.compose([
          Validators.pattern('^[a-zA-Z ]*$'),
          ValidatorService.whitespaceValidator
        ])],
      'state': [null,
        [
          Validators.pattern("^[a-zA-Z ]+$"),
          ValidatorService.whitespaceValidator
        ]],
      'region': [null],
      'country': [null, [
        Validators.pattern("^[a-zA-Z ]+$"),
        ValidatorService.whitespaceValidator
      ]],
      'contactPersonEmailId': [null, [
        Validators.email,
        ValidatorService.whitespaceValidator
      ]],
      'transportLeg': [null, Validators.required]
    });
  }

  onSelectTransportSegment() {
    this.transportSegment = true;
  }

  addBranch() {
    if (this.branchForm.valid) {
      console.log(this.branchForm.value);
      this.branchService.addBranch(this.branchForm.value).subscribe(res => {
        console.log(res);
        this.openSnackBar("Branch: " + this.branchForm.value.branchName + " added successfully");
        this.branchForm.reset();
        this.router.navigateByUrl('/networkmanagement')
      }, (error) => {
        if(error.error.Result){
          this.openSnackBar(error.error.Result);
        }
        else{
          this.openSnackBar("Error adding Branch");
        }
      });
    }
  }

  onSelectBranchType() {
    this.branchType = true;
  }

  ngOnInit() {
    this.initializeForm();
    this.branchService.getAllBranchType().subscribe(allBranchType => {
      this.allBranchType = [];
      this.allBranchType = allBranchType;
    });


    this.transporterService.getAllTransportSegment().subscribe(allTransportSegment => {
      this.allTransportSegment = [];
      this.allTransportSegment = allTransportSegment;
    });
  }

}
