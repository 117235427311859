import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TransporterService } from '../../../services/transporter/transporter.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { NotifierService } from 'angular-notifier';
import { BranchService } from 'src/app/services/branch/branch.service';
import { ValidatorService } from 'src/app/services/validator/validator.service';
import { MatDialog } from '@angular/material';


@Component({
  selector: 'app-addtransporter',
  templateUrl: './addtransporter.component.html',
  styleUrls: ['./addtransporter.component.scss']
})
export class AddtransporterComponent implements OnInit {

  transporterForm: FormGroup;
  activityCreatedLog = [];
  activityUpdatedLog = [];
  allBranches = [];
  tranporterTypes = [];
  configTransporterType = {
    height: '40vh',
    search: true
  }

  constructor(
    private formBuilder: FormBuilder,
    private transporterService: TransporterService,
    private router: Router,
    private datePipe: DatePipe,
    private branchService: BranchService,
    private notifierService: NotifierService,
    public dialog: MatDialog
  ) { }

  addTransporter() {
    if (this.transporterForm.valid) {
      console.log(this.transporterForm.value);
      this.transporterService.addTransporter(this.transporterForm.value).subscribe(res => {
        this.notifierService.notify('success', "Transporter: " + this.transporterForm.value.transporterName + "added successfully");
        this.transporterForm.reset();
        // this.router.navigateByUrl('/transporter')
        this.dialog.closeAll();
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigate(["/transporter"]));
      // }, () => {
        // this.notifierService.notify('error', "Error adding transporter");
        // Digvijay - Commented above code and added below code
      }, (error) => {
        if(error.error.Error){
          this.notifierService.notify('error', error.error.Error);
        }
        else{
          this.notifierService.notify('error', "Error adding transporter");
        }
      });
    }
  }

  getTransporterTypes() {
    this.transporterService.getAllTransporterType().subscribe(transporterTypes => {
      this.tranporterTypes = transporterTypes;
    })
  }

  initializeForm() {
    this.transporterForm = this.formBuilder.group({
      'transporterName': [null, [
        Validators.required,
        Validators.pattern("^[A-Za-z0-9- ]+$"),
        ValidatorService.whitespaceValidator
      ]],
      'transporterAddress': [null, [Validators.required, ValidatorService.whitespaceValidator]],
      'transporterPhoneNumber': [null, Validators.compose([
        Validators.required, Validators.minLength(10), Validators.maxLength(10),
        ValidatorService.whitespaceValidator
      ])],
      'contactPersonName': [null, [
        Validators.pattern("^[a-zA-Z ]+$"),
        ValidatorService.whitespaceValidator
      ]],
      'contactPersonPhoneNumber': [null, Validators.compose([
        Validators.pattern('^[0-9]*$'),
        Validators.minLength(10), Validators.maxLength(10)
      ])],
      'pincode': [null, Validators.compose([
        Validators.pattern("^[0-9]{6,10}$"),
        Validators.minLength(6),
        Validators.maxLength(10)
      ])],
      'city': [null, Validators.compose([
        Validators.required,
        Validators.pattern("^[a-zA-Z]+$"),
        ValidatorService.whitespaceValidator
      ])],
      'state': [null, Validators.compose([
        Validators.pattern("^[a-zA-Z]+$"),
        ValidatorService.whitespaceValidator
      ])],
      'country': [null, Validators.compose([
        Validators.pattern("^[a-zA-Z]+$"),
        ValidatorService.whitespaceValidator
      ])],
      'contactPersonEmailId': [null, [
        Validators.email
      ]],
      'branches': [null, [
        Validators.required]],
      'transporterCode': [null, [
        Validators.pattern("^[A-Za-z0-9-/ ]+$"),
        ValidatorService.whitespaceValidator
      ]],
      'transporterType': [null]
    });
  }

  ngOnInit() {
    this.getAllTransporter();
    this.getTransporterTypes();
    this.initializeForm();

    this.branchService.branchesObservable.subscribe(allBranches => {
      this.allBranches = allBranches;

      this.allBranches.sort(function (a, b) {
        let branchArea1 = a.branchName.toLowerCase(), branchArea2 = b.branchName.toLowerCase()
        if (branchArea1 < branchArea2)
          return -1
        if (branchArea1 > branchArea2)
          return 1
        return 0
      })
    });
  }

  getAllTransporter() {
    this.transporterService.getAllTransporters().subscribe(res => {
      console.log("activitylog", res);
      for (let i = 0; i < 4; i++) {
        if (res[i]) {
          if (res[i].creationDateTime === res[i].updatedDateTime) {
            this.activityCreatedLog.push({
              driverName: res[i].transporterName,
              createdBy: res[i].updatedByusername,
              creadtedDate: this.datePipe.transform(res[i].creationDateTime, 'dd-MM-yyyy'),
              creadtedTime: res[i].creationDateTime,
            });
          }
          else {
            this.activityUpdatedLog.push({
              driverName: res[i].transporterName,
              updatedBy: res[i].updatedByusername,
              updatedDate: this.datePipe.transform(res[i].updatedDateTime, 'dd-MM-yyyy'),
              updatedTime: res[i].updatedDateTime
            });
          }
        }
      }
    }, error => {
      console.log(error);
    });
  }

}
