import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { MessagingService } from './shared/messaging.service';
import { AsyncPipe } from '../../node_modules/@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material/material.module';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpModule } from '@angular/http';
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction'
import { AppRoutingModule } from './app-routing.module';
import { DatePipe, DecimalPipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

//services
import { UsersService } from "./services/users/users.service";
import { FleetService } from './services/fleet/fleet.service';
import { ResourceService } from './services/resource/resource.service';
import { RoleService } from './services/role/role.service';
import { TransporterService } from './services/transporter/transporter.service';
import { TripService } from './services/trip/trip.service';
import { SearchService } from './services/search/search.service';
import { CostService } from './services/cost/cost.service';
//messageservice
import { MessagesService } from './services/messages/messages.service';
import { UploadfileService } from './services/uploadfile/uploadfile.service';
import { FileService } from './services/fileexplorer/file.service'

//components
import { LoginComponent } from './login/login.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { RegisterComponent } from './register/register.component';

import { ManageFleetComponent } from './pages/managefleet/managefleet.component';
import { PagesComponent } from './pages/pages.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { AddfleetComponent } from './pages/managefleet/addfleet/addfleet.component';
import { AllfleetComponent } from './pages/managefleet/allfleet/allfleet.component';
import { EditfleetComponent } from './pages/managefleet/editfleet/editfleet.component';
import { TransporterComponent } from './pages/transporter/transporter.component';
import { AddtransporterComponent } from './pages/transporter/addtransporter/addtransporter.component';
import { AlltransporterComponent } from './pages/transporter/alltransporter/alltransporter.component';
import { EdittransporterComponent } from './pages/transporter/edittransporter/edittransporter.component';
import { UserandroleComponent } from './pages/userandrole/userandrole.component';
import { AlluserroleComponent } from './pages/userandrole/alluserrole/alluserrole.component';
import { AdduserComponent } from './pages/userandrole/adduser/adduser.component';
import { AssignroleComponent } from './pages/userandrole/assignrole/assignrole.component';
import { EdituserComponent } from './pages/userandrole/edituser/edituser.component';
import { UserrolemanageComponent } from './pages/userandrole/userrolemanage/userrolemanage.component';
import { OrderComponent } from './pages/order/order.component';
import { AllorderdetailsComponent } from './pages/order/allorderdetails/allorderdetails.component';
import { SchedulingandplanningComponent } from './pages/schedulingandplanning/schedulingandplanning.component';
import { CreatetriptableComponent } from './pages/schedulingandplanning/createtriptable/createtriptable.component';
import { EdittriptableComponent } from './pages/schedulingandplanning/edittriptable/edittriptable.component';
import { ScheduletriptableComponent } from './pages/schedulingandplanning/scheduletriptable/scheduletriptable.component';
import { EdittripmodalComponent, GenericDialogBoxWithReference } from './pages/schedulingandplanning/edittripmodal/edittripmodal.component';
import { ScheduletripmodalComponent } from './pages/schedulingandplanning/scheduletripmodal/scheduletripmodal.component';
import { TripComponent } from './pages/trip/trip.component';
import { TripdetailsComponent } from './pages/trip/tripdetails/tripdetails.component';
import { TripmapviewComponent } from './pages/trip/tripmapview/tripmapview.component';
import { OrdermapviewComponent } from './pages/order/ordermapview/ordermapview.component';
import { OrderlistviewComponent } from './pages/order/orderlistview/orderlistview.component';
import { TriplistviewComponent } from './pages/trip/triplistview/triplistview.component';
import { HomeComponent } from './pages/home/home.component';
// import { SchedulefleetComponent } from './pages/managefleet/schedulefleet/schedulefleet.component';
import { DialogConfirm } from './pages/schedulingandplanning/edittripmodal/edittripmodal.component';
import { GenericDialogBox } from './pages/schedulingandplanning/edittripmodal/edittripmodal.component';
import { DeleteOrderConfirm } from './pages/manageordersandjobs/admin/admin.component';
import { ChangeStatusDialogConfirm } from './pages/manageordersandjobs/admin/admin.component';
import { ImagePopup } from './pages/order/allorderdetails/allorderdetails.component';
import { ErrorExcelUpload } from './pages/schedulingandplanning/schedulingandplanning.component';

import { Interceptor } from "./auth/interceptor";
import { TokenStorage } from "./auth/token.storage";
import { AuthGuard } from './auth/auth.guard';
import { AuthService } from './auth/auth-guard.service';
import { StoreUserDetails } from './auth/storeuserdetails';

import { NgxDateRangePickerModule } from '../ngx-daterangepicker';
import { OrderDetailsAndEditOrderComponent } from './pages/schedulingandplanning/orderdetailsandeditorder/orderdetailsandeditorder.component';
import { SearchresultComponent } from './pages/searchresult/searchresult.component';
import { TripanalyticsComponent } from './pages/tripanalytics/tripanalytics.component';
import { OrderreportComponent } from './pages/orderreport/orderreport.component';
import { NotificationService } from './services/notification/notification.service';
import { ManagecustomerComponent } from './pages/managecustomer/managecustomer.component';
import { AddcustomerComponent } from './pages/managecustomer/addcustomer/addcustomer.component';
import { AddproductComponent } from './pages/manageproducts/addproduct/addproduct.component';
import { ManageproductsComponent } from './pages/manageproducts/manageproducts.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ManageordersandjobsComponent } from './pages/manageordersandjobs/manageordersandjobs.component';
import { ManagejobsComponent } from './pages/manageordersandjobs/managejobs/managejobs.component';
import { AdminComponent } from './pages/manageordersandjobs/admin/admin.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AllplansComponent } from './pages/schedulingandplanning/allplanscomponent/allplans.component';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { SettingsComponent } from './pages/schedulingandplanning/settingscomponent/settingscomponent.component';
import { CreatePlanConfirm } from './pages/schedulingandplanning/createtriptable/createtriptable.component';
import { NgxLoggerLevel, LoggerModule } from 'ngx-logger';
import { AddjobComponent } from './pages/schedulingandplanning/addjob/addjob.component';
import { AddresourceanduserComponent } from './pages/addresourceanduser/addresourceanduser.component';
import { JobreportComponent } from './pages/jobreport/jobreport.component';
import { UploadproductexcelComponent } from './pages/manageproducts/uploadproductexcel/uploadproductexcel.component';
import { UploadcustomerexcelComponent } from './pages/managecustomer/uploadcustomerexcel/uploadcustomerexcel.component';
import { CostanalyticsComponent } from './pages/costanalytics/costanalytics.component';
import { AllcustomersComponent } from './pages/managecustomer/allcustomers/allcustomers.component';
import { EditcustomerComponent } from './pages/managecustomer/editcustomer/editcustomer.component';
import { TransportrateComponent } from './pages/transportrate/transportrate.component';
import { BaserateComponent } from './pages/transportrate/baserate/baserate.component';
import { AdditionalrateComponent } from './pages/transportrate/additionalrate/additionalrate.component';
import { DevicemanagementComponent } from './pages/devicemanagement/devicemanagement.component';
import { AlldevicesComponent } from './pages/devicemanagement/alldevices/alldevices.component';
import { AdddeviceComponent } from './pages/devicemanagement/adddevice/adddevice.component';
import { ManagedevicesComponent } from './pages/devicemanagement/managedevices/managedevices.component';
import { TriptrackingComponent } from './pages/triptracking/triptracking.component';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { ManagegeofenceComponent } from './pages/managegeofence/managegeofence.component';
import { GeofencingComponent } from './pages/managegeofence/geofencing/geofencing.component';
import { OthergeofenceComponent } from './pages/managegeofence/othergeofence/othergeofence.component';
import { CustomergeofenceComponent } from './pages/managegeofence/customergeofence/customergeofence.component';
import { InlineEditableCellComponent } from './pages/inline-editable-cell/inline-editable-cell.component';
import { RateService } from './services/rate/rate.service';
import { PendingbillsComponent } from './pages/billing/pendingbills/pendingbills.component';
import { ApprovedbillsComponent } from './pages/billing/approvedbills/approvedbills.component';
import { BillingComponent } from './pages/billing/billing.component';
import { BilldetailsComponent } from './pages/billing/billdetails/billdetails.component';

import { AgmOverlays } from "agm-overlays";
import { AngularDraggableModule } from 'angular2-draggable';
import { GooglemapComponent } from './pages/googlemap/googlemap.component';
import { NewbillsComponent } from './pages/billing/newbills/newbills.component';
import { NetworkmanagementComponent } from './pages/networkmanagement/networkmanagement.component';
import { AllbranchesComponent } from './pages/networkmanagement/allbranches/allbranches.component';
import { AddbranchComponent } from './pages/networkmanagement/addbranch/addbranch.component';
import { BilltableComponent } from './pages/billing/billtable/billtable.component';
import { EditbranchComponent } from './pages/networkmanagement/editbranch/editbranch.component';
import { AllproductsComponent } from './pages/manageproducts/allproducts/allproducts.component';
import { ManagevehicletypeComponent } from './pages/managevehicletype/managevehicletype.component';
import { AddvehicletypeComponent } from './pages/managevehicletype/addvehicletype/addvehicletype.component';
import { AllvehicletypesComponent } from './pages/managevehicletype/allvehicletypes/allvehicletypes.component';
import { AddzoneComponent } from './pages/networkmanagement/addzone/addzone.component';
import { MapComponent } from './pages/schedulingandplanning/createtriptable/mapcomponent/map.component';
import { CostreportComponent } from './pages/costreport/costreport.component';
import { PodexplorerComponent } from './pages/podexplorer/podexplorer.component';
import { FileManagerComponent } from './pages/podexplorer/filemanager/filemanager.component';
import { MapwindowComponent } from './pages/mapwindow/mapwindow.component';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';
import { BingmapComponent } from './pages/bingmap/bingmap.component';
import { DragAndDropModule } from 'angular-draggable-droppable';
import { HereMapPolygonComponent } from './pages/here-map-polygon/here-map-polygon.component';
import { BulkuploadComponent } from './pages/bulkupload/bulkupload.component';
import { PodcompliancereportComponent } from './pages/podcompliancereport/podcompliancereport.component';
import { EditproductComponent } from './pages/manageproducts/editproduct/editproduct.component';
import { EditvehicletypeComponent } from './pages/managevehicletype/editvehicletype/editvehicletype.component';
import { VendorBillsComponent } from './pages/billing/vendorBills/vendor-bills.component';
import { BillingreportComponent } from './pages/billingreport/billingreport.component';
import { ManageAuditTrailComponent } from './pages/manage-audit-trail/manage-audit-trail.component';
import { AuditTrailService } from './services/audit/audit-trail.service';
import { PlanreportComponent } from './pages/planreport/planreport.component';
import { TripmanagementComponent } from './pages/manageordersandjobs/tripmanagement/tripmanagement.component';
import { PlandeviationsComponent } from './pages/plandeviations/plandeviations.component';
import { OrdertrackingComponent } from './pages/order/ordertracking/ordertracking.component';
import { ManagematerialcategoriesComponent } from './pages/managematerialcategories/managematerialcategories.component';
import { AddmaterialcategoryComponent } from './pages/managematerialcategories/addmaterialcategory/addmaterialcategory.component';
import { AllmaterialcategoriesComponent } from './pages/managematerialcategories/allmaterialcategories/allmaterialcategories.component';
import { EditmaterialcategoryComponent } from './pages/managematerialcategories/editmaterialcategory/editmaterialcategory.component';
import { UploadmaterialcategoryexcelComponent } from './pages/managematerialcategories/uploadmaterialcategoryexcel/uploadmaterialcategoryexcel.component'

//Angular notifier customization options
const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
      distance: 12
    },
    vertical: {
      position: 'bottom',
      distance: 30,
      gap: 10
    }
  },
  theme: 'material',
  behaviour: {
    autoHide: 5000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease'
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50
    },
    shift: {
      speed: 300,
      easing: 'ease'
    },
    overlap: 150
  }
};


@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    NavbarComponent,
    LoginComponent,
    SidebarComponent,
    RegisterComponent,
    ManageFleetComponent,
    PagesComponent,
    ForgotpasswordComponent,
    AddfleetComponent,
    AllfleetComponent,
    EditfleetComponent,
    TransporterComponent,
    AddtransporterComponent,
    AlltransporterComponent,
    EdittransporterComponent,
    UserandroleComponent,
    AlluserroleComponent,
    AdduserComponent,
    AssignroleComponent,
    EdituserComponent,
    UserrolemanageComponent,
    OrderComponent,
    AllorderdetailsComponent,
    SchedulingandplanningComponent,
    OrderDetailsAndEditOrderComponent,
    CreatetriptableComponent,
    EdittriptableComponent,
    ScheduletriptableComponent,
    EdittripmodalComponent,
    ScheduletripmodalComponent,
    TripComponent,
    TripdetailsComponent,
    TripmapviewComponent,
    OrdermapviewComponent,
    OrderlistviewComponent,
    TriplistviewComponent,
    HomeComponent,
    DialogConfirm,
    DeleteOrderConfirm,
    GenericDialogBox,
    GenericDialogBoxWithReference,
    ErrorExcelUpload,
    ChangeStatusDialogConfirm,
    SearchresultComponent,
    ImagePopup,
    AdminComponent,
    TripanalyticsComponent,
    OrderreportComponent,
    ManagecustomerComponent,
    AddcustomerComponent,
    AddproductComponent,
    ManageproductsComponent,
    ManageordersandjobsComponent,
    ManagejobsComponent,
    AllplansComponent,
    SettingsComponent,
    CreatePlanConfirm,
    AddjobComponent,
    AddresourceanduserComponent,
    JobreportComponent,
    UploadproductexcelComponent,
    UploadcustomerexcelComponent,
    CostanalyticsComponent,
    AllcustomersComponent,
    EditcustomerComponent,
    TransportrateComponent,
    BaserateComponent,
    AdditionalrateComponent,
    DevicemanagementComponent,
    AlldevicesComponent,
    AdddeviceComponent,
    ManagedevicesComponent,
    TriptrackingComponent,
    // GeofencingComponent,
    ManagegeofenceComponent,
    GeofencingComponent,
    OthergeofenceComponent,
    CustomergeofenceComponent,
    InlineEditableCellComponent,
    PendingbillsComponent,
    ApprovedbillsComponent,
    BillingComponent,
    BilldetailsComponent,
    AddvehicletypeComponent,
    GooglemapComponent,
    NewbillsComponent,
    NetworkmanagementComponent,
    AllbranchesComponent,
    AddbranchComponent,
    BilltableComponent,
    EditbranchComponent,
    AllproductsComponent,
    ManagevehicletypeComponent,
    AllvehicletypesComponent,
    AddzoneComponent,
    MapComponent,
    CostreportComponent,
    PodexplorerComponent,
    FileManagerComponent,
    MapwindowComponent,
    BingmapComponent,
    HereMapPolygonComponent,
    BulkuploadComponent,
    PodcompliancereportComponent,
    EditproductComponent,
    EditvehicletypeComponent,
    VendorBillsComponent,
    BillingreportComponent,
    ManageAuditTrailComponent,
    PlanreportComponent,
    TripmanagementComponent,
    PlandeviationsComponent,
    OrdertrackingComponent,
    ManagematerialcategoriesComponent,
    AddmaterialcategoryComponent,
    AllmaterialcategoriesComponent,
    EditmaterialcategoryComponent,
    UploadmaterialcategoryexcelComponent
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
    AngularFireStorageModule,
    AngularFireMessagingModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    HttpClientModule,
    NgxDateRangePickerModule,
    AgmCoreModule.forRoot({
      apiKey: environment.mapKey
    }),
    AgmDirectionModule,
    NgbModule.forRoot(),
    NgxPermissionsModule.forRoot(),
    NgxPermissionsModule.forChild({
      permissionsIsolate: true,
      rolesIsolate: true
    }),
    NgxSpinnerModule,
    NotifierModule.withConfig(customNotifierOptions),
    LoggerModule.forRoot({
      level: !environment.production ? NgxLoggerLevel.DEBUG : NgxLoggerLevel.ERROR,
      serverLogLevel: NgxLoggerLevel.OFF
    }),
    SelectDropDownModule,
    AgmOverlays,
    AngularDraggableModule,
    AgmJsMarkerClustererModule,
    AgmSnazzyInfoWindowModule,
    /********************  
    * Developer : Swapnil Sawant (CoReCo Technologies)
    * Date : 15 Oct 2020
    * Below specify the library as an import
    * ********************/
    DragAndDropModule,
  ],
  providers: [
    UsersService,
    FleetService,
    ResourceService,
    TransporterService,
    RoleService,
    TripService,
    SearchService,
    DatePipe,
    DecimalPipe,
    TokenStorage,
    AuthGuard,
    AuthService,
    StoreUserDetails,
    UploadfileService,
    MessagesService, {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true
    },
    MessagingService,
    AsyncPipe,
    NotificationService,
    RateService,
    CostService,
    AuditTrailService,
  //  FileDatabase
  FileService
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    EdituserComponent,
    AllorderdetailsComponent,
    EdittripmodalComponent,
    ScheduletripmodalComponent,
    OrderDetailsAndEditOrderComponent,
    TripdetailsComponent,
    DialogConfirm,
    GenericDialogBox,
    GenericDialogBoxWithReference,
    ChangeStatusDialogConfirm,
    DeleteOrderConfirm,
    ErrorExcelUpload,
    ForgotpasswordComponent,
    EditfleetComponent,
    EdittransporterComponent,
    ImagePopup,
    TripanalyticsComponent,
    SettingsComponent,
    CreatePlanConfirm,
    AddjobComponent,
    AddresourceanduserComponent,
    EditcustomerComponent,
    BilldetailsComponent,
    AddvehicletypeComponent,
    EditbranchComponent,
    MapComponent,
    MapwindowComponent,
    EditproductComponent,
    EditvehicletypeComponent,
    PlandeviationsComponent,
    EditmaterialcategoryComponent
  ]
})
export class AppModule { }