import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { TransporterService } from 'src/app/services/transporter/transporter.service';
import { RateService } from 'src/app/services/rate/rate.service';
import { NotifierService } from 'angular-notifier';
import { FleetService } from 'src/app/services/fleet/fleet.service';
import { BranchService } from 'src/app/services/branch/branch.service';
import { Subscription } from 'rxjs';
import { ExcelgeneratorService } from 'src/app/services/excelgenerator/excelgenerator.service';
import { UploadfileService } from 'src/app/services/uploadfile/uploadfile.service';
import { TokenStorage } from 'src/app/auth/token.storage';

@Component({
  selector: 'app-baserate',
  templateUrl: './baserate.component.html',
  styleUrls: ['./baserate.component.scss']
})
export class BaserateComponent implements OnInit {

  from: string;
  selectedTransporter: number = 0;
  rateData = new MatTableDataSource<any>();
  isFileUploading: boolean;
  showSpinner: boolean = false;
  fileName: any;
  excelProgressSubscription: Subscription;
  progress: string;
  subExcel: Subscription;

  origin = [];

  transporters = [];

  isEditing: boolean = false;

  vehicleTypes = [];

  columnsToDisplay = [
    "to"
  ];

  tableShown: boolean = false;
  loading: boolean = false;

  isOriginSelected: boolean = false;

  style: any;
  branchesSubscription: Subscription;
  excelRecords = [];
  isSuhana: boolean = false;

  constructor(
    private transporterService: TransporterService,
    private rateService: RateService,
    private fleetService: FleetService,
    private notifierService: NotifierService,
    private branchService: BranchService,
    private excelService: ExcelgeneratorService,
    private uploadService: UploadfileService,
    private token: TokenStorage
  ) { }

  ngOnInit() {
    if (this.token.getTenant() == "suhana-dist-prod" || this.token.getTenant() == "suhana-dist-test") {
      this.isSuhana = true;
    }
    this.branchesSubscription = this.branchService.branchesObservable.subscribe(branches => {
      this.origin = branches;
    });
    this.excelProgressSubscription = this.uploadService.progressObservable.subscribe(progress => {
      console.log(progress);
      this.progress = progress.progressStatus;
    });

    this.subExcel = this.uploadService.event.subscribe(val => {
      this.isFileUploading = false;
    });
  }

  submitFile(fileEvent) {
    console.log(fileEvent)
    if (fileEvent) {
      console.log(fileEvent.target.files[0]);
      this.isFileUploading = true;
      if (fileEvent.target.files.length > 0) {
        const file: File = fileEvent.target.files[0];
        this.uploadService.uploadRateExcelFile(file, '/rates/');
      }
    }
  }

  originSelected(event) {
    this.from = event.target.value;
    this.tableShown = false;
    this.transporters = [];
    this.isOriginSelected = true;
    this.selectedTransporter = 0;
    this.transporterService.getTransporterByBranches([this.from]).subscribe(transporters => {
      if (transporters.length == 0) {
        this.notifierService.notify('default', "No transporters for this branch.");
      }
      this.transporters = transporters;
    }, () => {
      this.notifierService.notify("error", "Error fetching transporters.");
    });
  }

  transporterSelected(event) {

    this.rateData.data = [];
    this.vehicleTypes = [];
    this.columnsToDisplay = ["to"];
    this.style = "";
    this.tableShown = false;
    this.loading = true;
    this.selectedTransporter = event.target.value;


    this.fleetService.getVehicleTypes().subscribe(vehicles => {

      vehicles.forEach(vehicle => {
        if (this.vehicleTypes.indexOf(vehicle.vehicleType) === -1) {
          this.vehicleTypes.push(vehicle.vehicleType);
        }

        if (this.columnsToDisplay.indexOf(vehicle.vehicleType) === -1) {
          this.columnsToDisplay.push(vehicle.vehicleType);
        }
      });

      let columnWidth = 100 / this.columnsToDisplay.length;
      this.style = {
        'width': columnWidth + '%'
      }

      this.rateService.getRatesByOriginAndTransporter(this.from, this.selectedTransporter).subscribe((rates: any) => {
        this.rateData.data = rates;

        this.tableShown = true;
        this.loading = false;

        if (this.rateData.data == null || this.rateData.data.length == 0) {
          this.notifierService.notify("default", "No data to show.");
        }

        this.rateData.data.forEach(row => {
          row.vehicleRates.forEach(rate => {
            if (rate) {
              rate.isEditing = false;
            }
          });
        });

        console.log(rates);
      }, () => {
        this.loading = false;
        this.notifierService.notify("error", "Error fetching data, please try again,");
      });

    }, () => {
      this.loading = false;
      this.notifierService.notify("error", "Error fetching data, please try again,");
    });
  }

  clicked(vehicleIndex: number, rowIndex: number, value: number) {
    debugger;
    this.rateData.filteredData[rowIndex].vehicleRates[vehicleIndex].isEditing = !this.rateData.filteredData[rowIndex].vehicleRates[vehicleIndex].isEditing;
    if (value != null) {
      this.rateData.filteredData[rowIndex].vehicleRates[vehicleIndex].rate = value;
      this.rateService.updateBaseRate([this.rateData.filteredData[rowIndex]]).subscribe(() => {
        this.notifierService.notify('success', "Rates saved successfully.");
      }, () => {
        this.notifierService.notify('error', "Error saving rates.");
      });
    }
  }

  addNewRate(vehicleIndex: number, rowIndex: number) {
    let temp = {
      rate: 1,
      isEditing: true,
      vehicleType: this.vehicleTypes[vehicleIndex]
    }
    this.rateData.filteredData[rowIndex].vehicleRates[vehicleIndex] = temp;
  }

  ngOnDestroy() {
    if (this.branchesSubscription)
      this.branchesSubscription.unsubscribe();
    if (this.subExcel)
      this.subExcel.unsubscribe();
    if (this.excelProgressSubscription)
      this.excelProgressSubscription.unsubscribe();
  }

  exportAsXLSX() {
    this.excelRecords = [];
    let transporterName, transporterCode;
    transporterName = this.transporters.find(trans => trans.transporterId == this.selectedTransporter).transporterName;
    transporterCode = this.transporters.find(trans => trans.transporterId == this.selectedTransporter).transporterCode; this.rateData.data.forEach(rate => {
      let row = {}, destinationCode, destination, branchCode;

      if (rate) {
        if (rate.destinationCode)
          destinationCode = rate.destinationCode
        else
          destinationCode = ''

        if (rate.destination)
          destination = rate.destination
        else
          destination = ''

        if (rate.originCode)
          branchCode = rate.originCode
        else
          branchCode = ''

        row['Branch Name'] = this.from;
        row['Branch Code'] = branchCode;
        row['Transporter Name'] = transporterName;
        row['Transporter Code'] = transporterCode;
        row['Route/Destination'] = destination;
        row['Destination Code'] = destinationCode;
      }

      this.vehicleTypes.forEach((type, index) => {
        if (rate.vehicleRates[index] != null && rate.vehicleRates[index].rate == 0)
          row[type] = '';
        else
          row[type] = rate.vehicleRates[index] != null ? rate.vehicleRates[index].rate : '';
      });

      this.excelRecords.push(row);
    });

    this.excelService.exportAsExcelFile(this.excelRecords, 'baserates');
  }

  applyFilter(filterValue: string) {
    this.rateData.filter = filterValue.trim().toLowerCase();
  }
}
