import { Injectable, } from '@angular/core';
import { Headers } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  base_url: string;
  constructor(
    private http: HttpClient,
  ) {
    this.base_url = environment.appUrl;
  }

  makeLogin(userCredenetials): Observable<any> {
    let headers = new Headers();
    JSON.stringify(userCredenetials);
    console.log(userCredenetials);
    debugger;
    headers.append('Content-Type', 'application/json');
    return this.http.post(this.base_url + '/login', JSON.stringify(userCredenetials));
  }

  newRegistration(registerData): Observable<any> {
    return this.http.post(this.base_url + '/registration', JSON.stringify(registerData));
  }

  getAllUsers(): Observable<any> {
    if ((window.sessionStorage.getItem("selectedBranchesCodes") && window.sessionStorage.getItem("totalUserBranches") == JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")).length) || !window.sessionStorage.getItem("selectedBranchesCodes")) {
      return this.http.get(this.base_url + '/tenantusers');
    }
    else {
      return this.http.get(this.base_url + "/tenantusers?branchCodes=" + JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")));
    }
  }

  getUsersPagewise(page: number, size: number): Observable<any> {
    const selectedBranchCodes = window.sessionStorage.getItem('selectedBranchesCodes');
    const branchCodes = selectedBranchCodes ? JSON.parse(selectedBranchCodes) : null;

    let url = `${this.base_url}/tenantusers?page=${page}&size=${size}`;
    // if (first) {
    //   url += `&first=true`;
    // } else if (last) {
    //   url += `&last=true`;
    // }
    if (branchCodes && branchCodes.length > 0) {
      const branchCodesParam = branchCodes.map(code => `branchCodes=${code}`).join('&');
      url += `&${branchCodesParam}`;
    }

    return this.http.get(url);
  }

  
  findUserByName(name: string): Observable<any> {
    const selectedBranchCodes = window.sessionStorage.getItem('selectedBranchesCodes');
    const branchCodes = selectedBranchCodes ? JSON.parse(selectedBranchCodes) : null;

    let url = `${this.base_url}/tenantusers/findUser/${name}`;
    if (branchCodes && branchCodes.length > 0) {
      const branchCodesParam = branchCodes.map(code => `branchCodes=${code}`).join('&');
      url += `?${branchCodesParam}`;
    }

    return this.http.get<any>(url);
  }
  
  getUserById(id): Observable<any> {
    return this.http.get(this.base_url + '/tenantusers/id/' + id.user);
  }

  addUser(userData): Observable<any> {
    return this.http.post(this.base_url + '/tenantusers/registration', userData);
  }

  activateUser(userid, bActivate): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.patch(this.base_url + '/tenantusers/id/' + userid, bActivate, httpOptions);
  }

  getRolesByUsers(): Observable<any> {
    let username = sessionStorage.getItem('userdetails')
    return this.http.get(this.base_url + '/tenantusers' + "/" + username + "/roles");
  }

  checkDuplicateUserName(username): Observable<any> {
    return this.http.get(this.base_url + '/users' + "/" + username);
  }

  getTenantUsers(userName): Observable<any> {
    return this.http.get(this.base_url + '/tenantusers/' + userName);
  }

  getManageableRoles(): Observable<any> {
    return this.http.get(this.base_url + '/tenantusers/manageableRoles');
  }

  logoutUser(token):Observable<any> {
    return this.http.post(this.base_url + '/logout', token);
  }

  editUser(userData){
    return this.http.put(this.base_url + '/users', userData);
  }
}
