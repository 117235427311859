import { Injectable } from '@angular/core';
import { TokenStorage } from './token.storage';
@Injectable()
export class AuthService {

  constructor(public tokenStorage: TokenStorage) {}


  public isAuthenticated(): boolean {

    let token = this.tokenStorage.getToken();

    // Check whether the token is expired and return
    // true or false
    return token != null;
  }

}