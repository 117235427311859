import { Component, OnInit, ViewChild, Inject } from '@angular/core';

import {
  MatPaginator, MatTableDataSource,
  MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatDatepickerInputEvent
} from '@angular/material';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import { DataSource } from '@angular/cdk/collections';
import { OrderService } from '../../../services/order/order.service';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/compiler/src/core';
import { Address } from '../../../interfaces/address';
import { Router } from '@angular/router';
import { getLocaleDateFormat } from '@angular/common';
import { serializePath } from '@angular/router/src/url_tree';


@Component({
  selector: 'app-orderdetailsandeditorder',
  templateUrl: './orderdetailsandeditorder.component.html',
  styleUrls: ['./orderdetailsandeditorder.component.scss']
})
export class OrderDetailsAndEditOrderComponent implements OnInit {
  columnsToDisplay = [
    'productDetails',
    'requiredQty',
    'action'
  ];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  orderId: number;
  receiverName: string;
  orderState: string;
  public availableQtyInStore = [];
  invoiceNumber: string;
  orderCreatedTimestamp: string;
  deliveryDatetime: string;
  totalweightCapacity: number;
  totalvolumeCapacity: number;
  dispatchDate: string;
  houseNumber: string;
  area: string;
  city: string;
  pincode: string;
  zone: string;
  zoom: number = 13;
  lat: number = 18.533989;
  lng: number = 73.825592;
  order: any;
  orderDataArray = new MatTableDataSource;
  orderNumber: string;
  ordersByTripIdArray = [];
  productArrayInSelectedOrder = [];
  productInputDisplayArray = [];
  public receiverAddress: Address;
  public originAddress: Address;
  remarksInput: string;
  minDate: Date;
  public selectedOrdersDataArray = [];
  showMessage: boolean = false;
  totalProd: number = 0;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public orderService: OrderService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public router: Router

  ) {
    console.log(data)
    this.order = this.data.data;

  }

  ngOnInit() {
    console.log(this.order);
    this.orderId = this.order.orderInfo.orderUniqueId;
    this.receiverName = this.order.orderInfo.receiverName;
    if (this.order.orderInfo.destinationAddress)
        this.receiverAddress = this.order.orderInfo.destinationAddress;
    this.originAddress = this.order.orderInfo.originAddress;
    if (this.order.orderInfo.destinationAddress)
        this.zone = this.order.orderInfo.destinationAddress.zone;
    this.orderState = (this.order.orderInfo.oState).slice(12);
    this.invoiceNumber = this.order.orderInfo.orderInvoiceNumber;
    this.originAddress = this.order.orderInfo.originAddress;
    this.orderCreatedTimestamp = this.order.orderInfo.orderCreatedDateTime;
    this.deliveryDatetime = this.order.orderInfo.deliveryETADateTime;
    this.totalvolumeCapacity = this.order.orderInfo.totalVolume;
    this.totalweightCapacity = this.order.orderInfo.totalWeight;
    this.dispatchDate = this.order.orderInfo.deliveryETADateTime;
    // this.orderDataArray.data = [this.order];
    console.log(this.orderDataArray.data);
    this.minDate = new Date();
    this.getAllProductsFromOrdersInTrip();
    // setTimeout(()=>{  
    //   this.showmessage();
    // }, 8000);
  }


  changeState() {
    console.log('order' + this.order.orderInfo.orderUniqueId);

    console.log(this.order);
    this.order.orderInfo.oState = "ORDER_STATE_UNPLANNED";
    this.orderService.orderChangeHold(this.order.orderInfo.orderUniqueId, this.order.orderInfo.oState).subscribe(res => {
      console.log(res);
      this.router.navigate(['/scheduling/createtrips']);
    }, Error => {
      console.log(Error);
    });
  }

  getAllProductsFromOrdersInTrip() {
    this.orderService.getAllProductsInOrder(this.orderId).subscribe(res => {
      for (let i = 0; i < res.length; i++) {
            this.productArrayInSelectedOrder.push({
              productId: res[i].product.productId,
              productdetails: res[i].product.productName,
              productQuantity: res[i].productQuantity, 
            });
            this.orderDataArray.data = this.productArrayInSelectedOrder;
      }
      //this.showmessage();
    }, Error => {
      console.log(Error);
    })
  }

  showOrderReadyToMoveToUnplanned() {
    let findProductWithZeroQuantity = this.productArrayInSelectedOrder.find(fp => fp.availableProductQuantity == 0 || fp.productQuantity == 0 || fp.availableProductQuantity<fp.productQuantity);
    console.log("findprod", findProductWithZeroQuantity)
    if (!findProductWithZeroQuantity) {
      this.showMessage = true;
    }

  }

  prepareToSend(orderchecked, Event) {
    let productId = orderchecked.productId;
    let productList = this.order.prodQList;

    let foundProductInOrder = productList.find(pl => pl.product.productId === productId);
    console.log(orderchecked);

    if (Event.checked) {
      foundProductInOrder.bMarkForDelivery = true;
    } else {
      foundProductInOrder.bMarkForDelivery = false;
    }

    console.log(foundProductInOrder);
  }
  submitAction() {
    console.log(this.order);
    this.orderService.prepareOrderForDelivery(this.order).subscribe(result => {
      console.log(result);
      let message = "Updated Delivery";
      this.openSnackBar(message);
    });
  }


  openSnackBar(message) {
    this.snackBar.open(message, 'ok', {
      duration: 2000
    })
  }
}




