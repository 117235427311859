import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs/Observable';


@Injectable({
  providedIn: 'root'
})
export class GeofenceService {
  base_url: string;
  constructor(
    private http: HttpClient,
  ) {
    this.base_url = environment.appUrl + "/geofence";
  }

  getGeofenceObj(): Observable<any> {
    return this.http.get(this.base_url + "/id/" + 1);
  }

  AddGeofence(geofenceObj): Observable<any> {
    return this.http.post(this.base_url, geofenceObj);
  }

  getAllGeofences(): Observable<any> {
    return this.http.get(this.base_url + "s");
  }
  
}
