import { Component, OnInit } from '@angular/core';
import { UploadfileService } from 'src/app/services/uploadfile/uploadfile.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-bulkupload',
  templateUrl: './bulkupload.component.html',
  styleUrls: ['./bulkupload.component.scss']
})
export class BulkuploadComponent implements OnInit {
  isFileUploading: boolean = false;
  subExcel: Subscription;
  fileName: any;

  constructor(private uploadService: UploadfileService,
    ) { }

  ngOnInit() {
    
    this.subExcel = this.uploadService.event.subscribe(val => {
      this.isFileUploading = false;
    });
  }

  submitFile(fileEvent) {
    if (fileEvent) {
      if (fileEvent.target.files.length > 0) {
        console.log(fileEvent.target.files[0]);
        this.isFileUploading = true;
        const file: File = fileEvent.target.files[0];
        this.uploadService.uploadBulkExcelFile(file);
      }
    }
  }
}
