import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-transporter',
  templateUrl: './transporter.component.html',
  styleUrls: ['./transporter.component.scss']
})
export class TransporterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
