import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BranchService } from '../../../services/branch/branch.service';
import { MatSnackBar, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { TransporterService } from 'src/app/services/transporter/transporter.service';
import { ValidatorService } from 'src/app/services/validator/validator.service';
import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-editbranch',
  templateUrl: './editbranch.component.html',
  styleUrls: ['./editbranch.component.scss']
})
export class EditbranchComponent implements OnInit {

  config = {
    placeholder: 'Select Branchtype'
  }
  branchForm: FormGroup;
  branchType: boolean;
  allTransportSegment: any[];
  allBranchType: any[];
  isEdited: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private branchService: BranchService,
    private router: Router,
    private matDialog: MatDialog,
    private transporterService: TransporterService,
    private notifierService: NotifierService,
    private spinnerService: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: any

  ) { }

  editBranch() {
    this.branchForm.value.branchCode = this.data.branchCode;
    this.branchForm.value.branchId = this.data.branchId;

    if (this.branchForm.valid) {
      this.spinnerService.show();
      this.isEdited = true;
      console.log(this.branchForm.value);
      this.branchService.editBranch(this.branchForm.value).subscribe(res => {
        this.spinnerService.hide();
        console.log(res);
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigate(["/networkmanagement"]));
        this.matDialog.getDialogById('editBranchWindow').close(true);
        this.notifierService.notify('success', "Branch: " + this.branchForm.value.branchName + " updated successfully");
      }, Error => {
        this.spinnerService.hide();
        console.log(Error);
        this.notifierService.notify('error', "Error saving changes, please try again.");
      })
    }
  }

  onSelectBranchType() {
    this.branchType = true;
  }

  ngOnInit() {
    console.log("Branch data", this.data)
    this.intializeForm();
    this.branchForm.controls['branchCode'].disable();
    this.branchService.getAllBranchType().subscribe(allBranchType => {
      this.allBranchType = [];
      this.allBranchType = allBranchType;
    });


    this.transporterService.getAllTransportSegment().subscribe(allTransportSegment => {
      this.allTransportSegment = [];
      this.allTransportSegment = allTransportSegment;
    });
    this.branchForm.patchValue({
      branchId: this.data.branchId,
      branchName: this.data.branchName,
      branchType: this.data.branchType,
      branchCode: this.data.branchCode,
      pinCode: this.data.pinCode,
      city: this.data.city,
      state: this.data.state,
      area: this.data.area,
      address: this.data.address,
      country: this.data.country,
      region: this.data.region,
      contactPersonName: this.data.contactPersonName,
      contactPersonPhoneNumber: this.data.contactPersonPhoneNumber,
      contactPersonEmailId: this.data.contactPersonEmailId,
      transportLeg: this.data.transportLeg,
      location: this.data.location

    })

  }

  intializeForm() {
    this.branchForm = this.formBuilder.group({
      'branchName': [null,
        Validators.compose([
          Validators.required,
          ValidatorService.whitespaceValidator
        ])],
      'branchType': [null,
        [
          Validators.maxLength(255),
          ValidatorService.whitespaceValidator
        ]],
      'branchCode': [null,
        Validators.compose([
          Validators.required,
          Validators.pattern("^[[A-Za-z0-9\\-\\_\\/ ]+$"),
          ValidatorService.whitespaceValidator
        ])],
      'area': [null,
        Validators.compose([
          Validators.pattern("^[a-zA-Z0-9\\/\\- ]+$"),
          ValidatorService.whitespaceValidator
        ])],
      'address': [null,
        Validators.compose([
          Validators.required,
          ValidatorService.whitespaceValidator
        ])],
      'contactPersonPhoneNumber': [null,
        Validators.compose([
          Validators.required,
          Validators.minLength(10),
          Validators.pattern('^[0-9]+$'),
          ValidatorService.whitespaceValidator
        ])],
      'contactPersonName': [null,
        Validators.compose([
          Validators.required,
          Validators.pattern("^[a-zA-Z ]+$"),
          ValidatorService.whitespaceValidator
        ])],
      'pinCode': [null,
        Validators.compose([
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(10),
          Validators.pattern('^[0-9]+$'),
          ValidatorService.whitespaceValidator
        ])],
      'city': [null,
        Validators.compose([
          Validators.pattern('^[a-zA-Z ]*$'),
          ValidatorService.whitespaceValidator
        ])],
      'state': [null,
        [
          Validators.pattern("^[a-zA-Z ]+$"),
          ValidatorService.whitespaceValidator
        ]],
      'region': [null],
      'country': [null, [
        Validators.pattern("^[a-zA-Z ]+$"),
        ValidatorService.whitespaceValidator
      ]],
      'contactPersonEmailId': [null, [
        Validators.email,
        ValidatorService.whitespaceValidator
      ]],
      'transportLeg': [null, Validators.required],
      'location': [null]
    });
  }

}
