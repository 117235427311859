import { Component, OnInit } from '@angular/core';
import { TokenStorage } from '../../auth/token.storage';
import { Router, NavigationStart } from '@angular/router';
import { StoreUserDetails } from '../../auth/storeuserdetails';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { OrderService } from '../../services/order/order.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { MessagingService } from '../messaging.service';
import { DatePipe } from '@angular/common';
import { UsersService } from 'src/app/services/users/users.service';
import { BranchService } from 'src/app/services/branch/branch.service';



@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit {
  showFiller = false;

  notificationList = [];
  notificationCount: number = 0;
  visible = true;
  _subscription;

  username: string;
  searchText: string;
  searchOption: string = "invoicenumber";
  previsearchOption: string;
  options = [];
  filteredOptions: Observable<string[]>;
  allOrders = [];
  fromDate: any;
  toDate: any;
  userRole: any;
  transporterLeg = [];
  selectedBranchNames = [];
  selectedBranchId = [];
  allBranches = [];
  isSa: boolean = false;
  isHeretech: boolean = false;
  prevSearchText: string;
  constructor(
    private token: TokenStorage,
    private router: Router,
    private userDetails: StoreUserDetails,
    private notificationService: NotificationService,
    messagingService: MessagingService,
    private usersService: UsersService,
    private branchService: BranchService) {
    this._subscription = messagingService.currentMessage.subscribe(message => {
      if (message) {
        this.notificationList.push(message.data);
        this.notificationCount = this.notificationList.length;
      }
    })
    this.userRole = window.sessionStorage.getItem('permissions').substr(2).slice(0, -2);
    if (this.token.getTenant() == 'sa-dist-prod')
      this.isSa = true;

    if (this.token.getTenant() == 'heretech-dist-prod' || this.token.getTenant() == 'heretech-dist-test')
      this.isHeretech = true;

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.prevSearchText = this.searchText;
        this.searchText = '';
      }
    })
  }
  myControl: FormControl = new FormControl();


  ngOnInit() {
    if (this.userRole != 'transporter' && this.userRole != 'finance_admin' && this.userRole != 'key_account_manager') {
      this.notificationService.getNotifcationByReadState().subscribe(notifications => {
        this.notificationList = notifications;
        this.notificationCount = notifications.length;
      });
    }
    this.username = this.userDetails.getUser();
    this.usersService.getTenantUsers(this.username).subscribe(userdetails => {
      let tLeg = [];
      console.log("user", userdetails)
      if (userdetails) {
        userdetails.branches.sort(function (a, b) {
          let branchName1 = a.branchName.toLowerCase(), branchName2 = b.branchName.toLowerCase();
          if (branchName1 < branchName2) { //sort string ascending
            return -1;
          } else if (branchName1 > branchName2) {
            return 1;
          } else {
            return 0;
          }
        });

        this.allBranches = userdetails.branches;
        if (window.sessionStorage.getItem("selectedBranchesCodes")) {
          if (window.sessionStorage.getItem("totalUserBranches") == JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")).length) {
            this.selectedBranchNames = this.allBranches;
          }
          else {
            userdetails.branches.forEach(branch => {
              JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")).forEach(branchCode => {
                if (branch.branchCode == branchCode)
                  this.selectedBranchNames.push(branch)
              });
            });
          }
          this.branchService.setUserbranches(this.selectedBranchNames);

        }
        else {
          this.branchService.setUserbranches(userdetails.branches);
          window.sessionStorage.setItem("totalUserBranches", userdetails.branches.length);
          window.sessionStorage.setItem("branchId", userdetails.branches[0].branchId);
          this.selectedBranchNames = userdetails.branches;
          let branchIds = this.selectedBranchNames.map(branch => branch.branchCode)
          window.sessionStorage.setItem("selectedBranchesCodes", JSON.stringify(branchIds));
          this.branchService.setUserbranches(this.selectedBranchNames);
        }


        userdetails.branches.forEach(branch => {
          branch.transportLeg.forEach(tleg => {
            if (tleg === 'TRANSPORT_LEG_SECONDARY')
              tLeg.push('secondary');
            else if (tleg === 'TRANSPORT_LEG_PRIMARY')
              tLeg.push('primary');
            if (tleg === 'TRANSPORT_LEG_INBOUNT')
              tLeg.push('inbound');
            else if (tleg === 'TRANSPORT_LEG_LAST_MILE')
              tLeg.push('last_mile');
          })
        });
        this.transporterLeg = tLeg.filter(function (item, i, ar) {
          return ar.indexOf(item) === i;
        });
      }
    });
  }

  clearText(){
    console.log("called")
    if (this.searchOption != this.previsearchOption) {
      this.searchText = '';
    }    
  }

  keyDownFunction(event) {
    if (this.searchOption != this.previsearchOption) {
      this.searchText = '';
      if (this.searchOption === 'invoicenumber')
        this.options = this.allOrders.map(invoice => invoice.orderInvoiceNumber);

      else if (this.searchOption === 'customer')
        this.options = this.allOrders.map(customer => customer.customerInfo.customerName);
    }

    if (event.keyCode == 13) {
      if (this.searchText != undefined && this.searchText != "") {
        this.router.navigate(['./searchresult', { searchOption: this.searchOption, searchText: this.searchText }]);
        if (this.prevSearchText) {
          this.searchText = this.prevSearchText;
        }
      }
    }
    this.previsearchOption = this.searchOption;
  }


  filter(val: string): string[] {
    return this.options.filter(option => option !== null && option.toLowerCase().includes(val.toLowerCase()));
  }
  doLogout() {
    this.usersService.logoutUser(this.token.getToken()).subscribe(() => {
      this.token.signOut();
      this.userDetails.removeUser();
      this.router.navigate(['login']);
    }, Error => {
      console.log(Error);
    })
  }

  removeNotification(notification: any) {
    var i = this.notificationList.indexOf(notification);
    this.notificationList.splice(i, 1);
    this.notificationCount = this.notificationList.length;
  }

  markAllRead() {
    let temp = [];
    this.notificationList.forEach(notification => {
      temp.push(notification.notificationId);
    });
    if (temp.length != 0) {
      this.notificationService.markAllRead(temp).subscribe(result => {
        console.log(result);
      }, Error => {
        console.log(Error);
      });
    }
  }


  setBranches(event) {
    if (event == 'selectAll') {
      this.selectedBranchNames = this.allBranches;
    }
    else if (event == 'deselectAll') {
      this.selectedBranchNames = []
    }
    else {
      this.selectedBranchNames = event.value;
    }
    window.sessionStorage.removeItem("selectedBranchesCodes")
    let branchIds = this.selectedBranchNames.map(branch => branch.branchCode)
    window.sessionStorage.setItem("selectedBranchesCodes", JSON.stringify(branchIds));
    this.branchService.setUserbranches(this.selectedBranchNames);
  }
}
