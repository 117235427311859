import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { TokenStorage } from 'src/app/auth/token.storage';
import { BranchService } from 'src/app/services/branch/branch.service';
import { FleetService } from 'src/app/services/fleet/fleet.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-editvehicletype',
  templateUrl: './editvehicletype.component.html',
  styleUrls: ['./editvehicletype.component.scss']
})
export class EditvehicletypeComponent implements OnInit {

  vehicleForm: FormGroup;
  volUnit: string;
  isAssociatedUnit: boolean = false;
  allBranches = [];
  associatedBranches = [];
isEdited: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private fleetService: FleetService,
    private notifierService: NotifierService,
    private branchService: BranchService,
    public dialog: MatDialog,
    private spinnerService: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) { }

  intializeForm() {
    this.volUnit = '(CFT)';
    this.vehicleForm = this.formBuilder.group({
      'vehicleMakeModel': [null, [
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9- ]+$")
      ]],
      'vehicleType': [null, [
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9- ]+$")
      ]],

      'volumeCapacity': [null, [
        Validators.required,
        Validators.pattern('^[0-9]{1,15}(,[0-9]{10})*(([\\.,]{1}[0-9]{1,3})|())$')
      ]],
      'weightCapacity': [null, [
        Validators.required,
        Validators.pattern('^[0-9]{1,15}(,[0-9]{10})*(([\\.,]{1}[0-9]{1,3})|())$')
      ]],
      'vehicleTypeCode': [null],
      'vehicleTypeId': [null],
      'branches': [null, [Validators.required]],
    });
  }



  ngOnInit() {
    this.intializeForm();
    console.log(this.data);

    // sort branch
    this.data.branches.forEach(branch => {
      this.associatedBranches.push(branch.branchId);
    });

    this.branchService.branchesObservable.subscribe(allBranches => {
      this.allBranches = allBranches;

      this.allBranches.sort(function (a, b) {
        let branchArea1 = a.branchName.toLowerCase(), branchArea2 = b.branchName.toLowerCase()
        if (branchArea1 < branchArea2)
          return -1
        if (branchArea1 > branchArea2)
          return 1
        return 0
      });
    });

    //Show existing value in form
    this.vehicleForm.patchValue({
      vehicleMakeModel: this.data.vehicleMakeModel,
      vehicleType: this.data.vehicleType,
      volumeCapacity: this.data.volumeCapacity,
      weightCapacity: this.data.weightCapacity,
      vehicleTypeCode: this.data.vehicleTypeCode,
      vehicleTypeId: this.data.vehicleTypeId
    })
  }

  updateVehicleType() {
    this.isEdited = true;
    this.spinnerService.show();
    let branches = [];
    this.allBranches.forEach(branch => {
      this.associatedBranches.forEach(branchId => {
        if (branch.branchId == branchId) {
          branches.push(branch);
        }
      });
    });

    this.vehicleForm.value.branches = branches;
    console.log(this.vehicleForm.value);
    this.vehicleForm.value.vehicleType = this.titleCase(this.vehicleForm.value.vehicleType);
    this.vehicleForm.value.vehicleMakeModel = this.titleCase(this.vehicleForm.value.vehicleMakeModel);
    this.fleetService.updateVehicleType(this.vehicleForm.value).subscribe(vehicleTypeAdded => {
      this.spinnerService.hide();
      console.log("vehicletype update", vehicleTypeAdded);
      this.notifierService.notify("success", 'Vehicle Type Updated SuccessFully');
      this.dialog.getDialogById('editVehicleTypeWindow').close(true);
    }, Error => {
      this.spinnerService.hide();
      console.log(Error)
      if (Error.status == 400)
        this.notifierService.notify("error", Error.error.Result);
      else
        this.notifierService.notify("error", "Error In Updating Vehicle Type. Please Try Again Later.");

    })
  }

  //cpaitalize 1st letter of each word in a string
  titleCase(str) {
    let words;
    words = str.toLowerCase().split(' ');

    for (var i = 0; i < words.length; i++) {
      var letters = words[i].split('');
      letters[0] = letters[0].toUpperCase();
      words[i] = letters.join('');
    }
    return words.join(' ');
  }

}