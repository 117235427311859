import { Injectable, EventEmitter, } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class FleetService {

  base_url: string;
  eventEmitter = new EventEmitter();

  constructor(
    private http: HttpClient,
  ) {
    this.base_url = environment.appUrl + "/fleets";
  }

  getFleetByVehicleLicenseNumber(vehicleLicenseNumber): Observable<any> {
    console.log(vehicleLicenseNumber);
    return this.http.get(this.base_url + '/licenseNumber' + '/' + vehicleLicenseNumber.licenseNumber);
  }

  getallFleets(page): Observable<any> {
      return this.http.get(this.base_url + "?page=" + page);
  }

  getAllFleetsByType(type): Observable<any> {
    return this.http.get(this.base_url + "/type/" + type);
  }

  getAllFleetsByTypeAndStatus(type): Observable<any> {
    return this.http.get(this.base_url + "/available?type=" + type);
  }

  addFleet(newFleet): Observable<any> {
    JSON.stringify(newFleet);
    console.log(newFleet);
    return this.http.post(this.base_url, newFleet);
  }

  updateFleet(updatedFleet): Observable<any> {
    return this.http.put(this.base_url, updatedFleet);
  }

  ActivateFleet(f, bActivate): Observable<any> {
    return this.http.patch(this.base_url + '/licenseNumber' + '/' + f + '/' + bActivate, null);
  }

  getAllFleetsByStatus(fleetStatus: String): Observable<any> {
    return this.http.get(this.base_url + '/status/' + fleetStatus);
  }

  getFleetCountByStatus(): Observable<any> {
    return this.http.get(this.base_url + '/status/count');
  }

  getAllAvailabilityForAllFleets(): Observable<any> {
    return this.http.get(this.base_url + '/availability');
  }

  addFleetAvailability(newFleetAvailability): Observable<any> {
    console.log(newFleetAvailability);
    return this.http.post(this.base_url + '/availability', newFleetAvailability);
  }

  assignPreferredDriverToFleet(licenseNumber, resource): Observable<any> {
    return this.http.put(this.base_url + '/licenseNumber/' + licenseNumber + '/preferreddriver', resource);
  }

  getVehicleTypes(): Observable<any> {
    if ((window.sessionStorage.getItem("selectedBranchesCodes") && window.sessionStorage.getItem("totalUserBranches") == JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")).length) || !window.sessionStorage.getItem("selectedBranchesCodes") || JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")).length == 0) {
      return this.http.get(environment.appUrl + '/vehicleTypes');
    }
    else {
      return this.http.get(environment.appUrl + '/vehicleTypes' + "?branchCodes=" + JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")));
    }
  }

  getVehicleTypesByBranchId(): Observable<any> {
    debugger;
    return this.http.get(environment.appUrl + '/branches/' + JSON.parse(window.sessionStorage.getItem("branchId")) + "/preferences");
  }

  saveSetting(planSeetingObj): Observable<any> {
    debugger;
    return this.http.post(environment.appUrl + '/branches/' + JSON.parse(window.sessionStorage.getItem("branchId")) + "/preferences", planSeetingObj);
  }

  // Added By Digvijay to pass branchId from dropdown instead of current session.
  getVehicleTypesByBranchIdParameter(branchId): Observable<any> {
    return this.http.get(environment.appUrl + '/branches/' + branchId + "/preferences");
  }

  saveSettingParameter(planSeetingObj, branchId): Observable<any> {
    return this.http.post(environment.appUrl + '/branches/' + branchId + "/preferences", planSeetingObj);
  }

  // Digvijay End

  addVehicleType(vehicleTypeObj): Observable<any> {
    return this.http.post(environment.appUrl + '/addVehicleType', vehicleTypeObj);
  }

  getAllFleetOwnership(): Observable<any> {
    return this.http.get(this.base_url + '/ownership');
  }

  notifyFleetListComponent(fleetType: string) {
    this.eventEmitter.emit(fleetType);
  }

  updateVehicleType(vehicleType): Observable<any> {
    return this.http.put(environment.appUrl + '/vehicleTypes', vehicleType);
  }
  searchFleet(licenseNumber) {
    return this.http.get(this.base_url + '/licenseNumber/' + licenseNumber)
  }

  getFleetForTrip(ownership, vehicletype): Observable<any> {
    return this.http.get(this.base_url + '/ownershipandvehicletype?ownership=' + ownership + '&vehicletype=' + vehicletype);
  }
}