import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import {
  MatPaginator, MatTableDataSource, MatSnackBar, MatSort, MatDialogRef, MAT_DIALOG_DATA, MatDialog
} from '@angular/material';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { OrderService } from '../../../services/order/order.service';
import { TripService } from '../../../services/trip/trip.service';
import 'rxjs/add/observable/of';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { MessagesService } from '../../../services/messages/messages.service';
import * as _ from "lodash";
import { UploadfileService } from '../../../services/uploadfile/uploadfile.service';
import { OrderDetailsAndEditOrderComponent } from '../orderdetailsandeditorder/orderdetailsandeditorder.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { ChangeStatusDialogConfirm } from '../../manageordersandjobs/admin/admin.component';
import { PlanService } from 'src/app/services/plan/plan.service';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { SettingsComponent } from '../settingscomponent/settingscomponent.component';
import { TokenStorage } from '../../../auth/token.storage';
import { interval } from 'rxjs';
import { NotifierService } from 'angular-notifier';
import { ErrorExcelUpload } from '../schedulingandplanning.component';
import { FleetService } from 'src/app/services/fleet/fleet.service';
import { NgxDateRangePickerOptions } from 'src/ngx-daterangepicker';
import { MapComponent } from './mapcomponent/map.component';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';


export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MM YYYY',
  },
};
@Component({
  selector: 'app-createtriptable',
  templateUrl: './createtriptable.component.html',
  styleUrls: ['./createtriptable.component.scss'],
  providers: [

    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }]
})
export class CreatetriptableComponent implements OnInit {

  columnsToDisplay = [
    'invoice',
    'origin',
    'orderTypeAndDes',
    'zone',
    'route',
    'deliveryETA',
    'pickupdetails',
    // 'pickupLocation',
    'receiverDetails',
    // 'receiverLocation',
    'customertype',
    'packageDetails',
    'action',
  ];
  OrdersDataArray = new MatTableDataSource;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  selectedOrdersDataArray: Array<any> = [];
  selectedOrderIdArray = [];
  zones = [];
  selectedValue = []; // selected zone for filter date
  orders: any;
  showSpinner: boolean = false;
  planSettings: any;
  vehicleMixSetting: any = [];
  planMode: boolean = false;
  zoneClubbing: boolean = true;

  //if zone is filtered assgin filtered data to new array for date filtering
  isZoneFiltered: boolean = false;
  //if date is filtered assgin filtered data to new array for zone filtering
  isDateFiltered: boolean = false;
  orderDataArrayForCheck = [];

  daterRangevalue: any;
  options: NgxDateRangePickerOptions;

  fromDate: any;
  toDate: any;
  prevFromDate: any;
  prevTodate: any;
  prevZone = [];
  page: number = 0;
  totalRecords: number;
  typeOfOrder: string;
  pageSize: number = 25;
  isFileUploading: boolean = false;
  isAllSelected: boolean = false;
  fileName: any;
  isPageAllSelected = [];
  minDate: Date;
  isGodrej: boolean = false;
  subscription: Subscription;
  subExcel: Subscription;
  createPlanStatus: any;
  excelProgressSubscription: Subscription;
  progress: string;
  isCg: boolean = false;
  isLoading: boolean = false;
  vehicleByBranchId: any;
  volUnit: string;
  isKcc: boolean = false;
  isSuhana: boolean = false;
  colorArray = ['2E8962', '3F292B', '3F2472', '06F58D', '6F4E00',
    '7A3F93', '13D505', '017EFE', '82B726', '134E28',
    '0700F9', '03045E', '3945A1', '4743DE', '73892E',
    '81380D', '191591', '632053', '722424', 'A13963',
    'AA6000', 'AFBC3A', 'BCB71B', 'BE0B7C', 'C100F9',
    'EE2BDA', 'F9A600', 'F85F18', 'F9008D', 'F90000'];
  hereMapShowFlag: boolean = false;
  lat: any;
  lng: any;
  zonesWiseData: any = [];
  excelData = [];

  constructor(
    private datePipe: DatePipe,
    public dialogService: DialogService,
    public dialog: MatDialog,
    public orderService: OrderService,
    public tripService: TripService,
    private router: Router,
    public snackBar: MatSnackBar,
    private messagesService: MessagesService,
    private uploadService: UploadfileService,
    private planService: PlanService,
    private token: TokenStorage,
    private notifier: NotifierService,
    private fleetService: FleetService,
    private spinner: NgxSpinnerService
  ) {
    if (this.token.getTenant() == 'kimberly-dist-prod') {
      this.volUnit = 'CM';
      this.planMode = false;
      this.isKcc = true;
    } else if (this.token.getTenant() == 'godrej-dist-prod') {
      this.volUnit = 'CCM';
    } else {
      if (token.getTenant() == 'metro-dist-prod' || token.getTenant() == 'metro-dist-test') {
        this.planMode = false;
      }
      if (this.token.getTenant() == 'suhana-dist-test' || this.token.getTenant() == 'suhana-dist-prod') {
        this.isSuhana = true;
        this.planMode = false; //Digvijay added to make default as Manual Plan
      }
      this.volUnit = 'CF';
    }
  }

  ngOnInit() {
    this.minDate = new Date();
    this.setDatePickerToCurrentDate();
    this.fromDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.toDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    /* this.orderService.getZoneList().subscribe(allZones => {
      this.zones = allZones;
    }); */
    //this.isDateFiltered = true;
    this.findSelectedDateOrders();
    this.OrdersDataArray.filterPredicate = (data: any, filter: string) => {
      let isCustomerName = false, isZone = false, isOrderInvoice = false;
      if (data) {
        if (data.customerInfo) {
          if (data.customerInfo.customerName) {
            isCustomerName = data.customerInfo.customerName.toString().toLowerCase().indexOf(filter) != -1;
          }
          if (data.customerInfo.zone && data.customerInfo.zone.zoneName) {
            isZone = data.customerInfo.zone.zoneName.toString().toLowerCase().indexOf(filter) != -1;
          }
        }
        if (data.orderInvoiceNumber) {
          isOrderInvoice = data.orderInvoiceNumber.toString().toLowerCase().indexOf(filter) != -1;
        }
      }
      return isCustomerName || isZone || isOrderInvoice;
    };

    this.typeOfOrder = 'ORDER_STATE_UNPLANNED';

    this.subExcel = this.uploadService.event.subscribe(() => {
      this.isFileUploading = false;
    });

    this.progress = "";
    this.excelProgressSubscription = this.uploadService.progressObservable.subscribe(progress => {
      console.log(progress);
      this.progress = progress.progressStatus;
    });
    this.spinner.show();
    this.fleetService.getVehicleTypesByBranchId().subscribe(vehicletypes => {
      this.spinner.hide();
      this.vehicleByBranchId = vehicletypes;
      if (this.vehicleByBranchId && this.vehicleByBranchId.bEnableAutoPlan) {
        this.planMode = this.vehicleByBranchId.bEnableAutoPlan;
      }
      console.log("vehicleTypes", vehicletypes)
      if (vehicletypes && vehicletypes.inputVehicles.length > 0) {
        vehicletypes.inputVehicles.forEach(type => {
          this.vehicleMixSetting.push({
            "vehicleTypeMaster": type.vehicleTypeMaster,
            "numVehicles": type.numVehicles,
            "vehicleCost": 0,
          })
        });
      }
    }, () => {
      this.spinner.hide();
    })
  }

  ngOnDestroy() {
    this.subExcel.unsubscribe();
    this.excelProgressSubscription.unsubscribe();
  }

  /*   sortByZone() {
      console.log(this.selectedValue);
      this.isZoneFiltered = true;
      this.sortByDate();
    } */

  sortByZone() {
    let newres = [];
    for (let i = 0; i < this.orders.length; i++) {
      let custZone
      if (this.orders[i].customerInfo.zone) {
        if (!this.isSuhana) {
          custZone = this.orders[i].customerInfo.zone.zoneName;
        }
        else {
          custZone = this.orders[i].customerInfo.zone.route;
        }
      }
      this.selectedValue.forEach(selectedZone => {
        if (custZone === selectedZone) {
          newres.push(this.orders[i]);
        }
        if (selectedZone == "Other" && this.orders[i].customerInfo.zone && (!this.orders[i].customerInfo.zone.route)) {
          newres.push(this.orders[i]);
        }
        this.OrdersDataArray.data = newres;
        this.orderDataArrayForCheck = newres;
        let selOrder = this.selectedOrdersDataArray;

        /*this filters selected orders that is ticked orders so that when user
         clicks next or prev button the selected order won't loss*/
        this.orderDataArrayForCheck.filter(r => {
          selOrder.forEach(l => {
            if (l.orderUniqueId === r.orderUniqueId) {
              r.isChecked = true;
            }
          });
        });
        this.totalRecords = newres.length;
        if (this.selectedOrdersDataArray.length < newres.length)
          this.isAllSelected = false;
      });
    }
    /*   else {
        if (this.orders[i].customerInfo.zone)
          custZone = this.orders[i].customerInfo.zone.route;
        this.selectedValue.forEach(selectedRoute => {
          if (custZone === selectedRoute) {
            newres.push(this.orders[i]);
          }
          this.OrdersDataArray.data = newres;
          this.orderDataArrayForCheck = newres;
          let selOrder = this.selectedOrdersDataArray;

         
          this.orderDataArrayForCheck.filter(r => {
            selOrder.forEach(l => {
              if (l.orderUniqueId === r.orderUniqueId) {
                r.isChecked = true;
              }
            });
          });
          this.totalRecords = newres.length;
          if (this.selectedOrdersDataArray.length < newres.length)
            this.isAllSelected = false;
        });
      } 
    }*/
    if (this.selectedValue.length <= 0) {
      this.OrdersDataArray.data = this.orders;
      this.orderDataArrayForCheck = this.orders;
      let selOrder = this.selectedOrdersDataArray;

      /*this filters selected orders that is ticked orders so that when user
       clicks next or prev button the selected order won't loss*/
      this.orderDataArrayForCheck.filter(r => {
        selOrder.forEach(l => {
          if (l.orderUniqueId === r.orderUniqueId) {
            r.isChecked = true;
          }
        });
      });
      this.totalRecords = this.orders.length;

    }
    if (this.selectedOrdersDataArray.length < this.orders.length)
      this.isAllSelected = false;

    this.checkAllOrdersAreAlreadySelectedFromFilter();
  }

  //this function checks whether All orders from seleced filter are already selected
  checkAllOrdersAreAlreadySelectedFromFilter() {
    for (let i = 0; i < this.orderDataArrayForCheck.length; i++) {
      let findOrder = this.selectedOrdersDataArray.findIndex(sel => sel.orderUniqueId === this.orderDataArrayForCheck[i].orderUniqueId);
      if (findOrder == -1) {
        this.isAllSelected = false;
        break;
      }
      else {
        this.isAllSelected = true;
      }
    }
  }

  sortByDate() {
    if (this.isDateFiltered && this.page > 0) {
      this.page = 0;
      this.paginator.pageIndex = 0;
    }
    console.log(this.daterRangevalue);
    if (this.selectedValue && !this.daterRangevalue) {
      this.fromDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
      this.toDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
      this.findSelectedDateOrders();
      this.prevFromDate = this.fromDate;
      this.prevTodate = this.toDate;
      this.prevZone = this.selectedValue;
    }

    else if (this.daterRangevalue) {
      this.fromDate = this.daterRangevalue.from.substring(6, 10) + '-' + this.daterRangevalue.from.substring(3, 5) + '-' + this.daterRangevalue.from.substring(0, 2);
      this.toDate = this.daterRangevalue.to.substring(6, 10) + '-' + this.daterRangevalue.to.substring(3, 5) + '-' + this.daterRangevalue.to.substring(0, 2);
      //call the function only if todate or fromdate or zone value changes 
      if (this.toDate != this.prevTodate || this.fromDate != this.prevFromDate) {
        this.ngUnsubscribe.next();
        this.isDateFiltered = true;
        this.findSelectedDateOrders();
        this.prevFromDate = this.fromDate;
        this.prevTodate = this.toDate;
        this.prevZone = this.selectedValue;
      }
    }
  }


  findSelectedDateOrders() {
    this.isLoading = true;
    this.OrdersDataArray.data = [];
    this.getData('ORDER_STATE_UNPLANNED', this.fromDate, this.toDate).pipe(takeUntil(this.ngUnsubscribe)).subscribe(unplannedOrderList => {
      this.isLoading = false;
      if (unplannedOrderList.content.length === 0) {
        this.openSnackBar("Orders not found");
        this.OrdersDataArray.data = [];
        this.orderDataArrayForCheck = [];
        this.selectedValue = [];
        this.totalRecords = 0;
        this.page = 0;
        this.paginator.pageIndex = 0;
        this.zones = [];
      }
      else {
        this.zones = [];
        console.log(unplannedOrderList);
        if (this.isPageAllSelected.length == 0)
          for (let i = 0; i < unplannedOrderList.totalPages; i++) {
            this.isPageAllSelected.push(0);
          }
        /*adding ischecked to each order object for 
        selection of order if page changes selected order should remain selected*/

        unplannedOrderList.content.forEach(function (obj) { obj.isChecked = false; });
        unplannedOrderList.content.forEach(order => {
          if (order.orderType == 'ORDER_TYPE_PICKUP_AND_DELIVERY') {
            if (order && order.pickupCustomerInfo && order.pickupCustomerInfo.zone)
              if (this.isSuhana) {
                if (order.pickupCustomerInfo.zone.route)
                  this.zones.push(order.pickupCustomerInfo.zone.route);
              }
              else {
                this.zones.push(order.pickupCustomerInfo.zone.zoneName);
              }
          }
          if (order && order.customerInfo && order.customerInfo.zone) {
            if (this.isSuhana) {
              if (order.customerInfo.zone.route)
                this.zones.push(order.customerInfo.zone.route);
            }
            else {
              this.zones.push(order.customerInfo.zone.zoneName);
            }
          }
        });
        console.log(this.zones)
        this.zones.sort(function (a, b) {
          if (a.toLowerCase() < b.toLowerCase()) { //sort string ascending
            return -1;
          } else if (a.toLowerCase() > b.toLowerCase()) {
            return 1;
          } else {
            return 0;
          }
        });
        this.zones.push("Other");

        //find unique zone names
        this.zones = this.zones.filter(function (elem, index, self) {
          return index === self.indexOf(elem);
        })

        this.orders = unplannedOrderList.content;
        this.OrdersDataArray.data = unplannedOrderList.content;

        //this array is used to check whether order is selected for plan or not
        this.orderDataArrayForCheck = unplannedOrderList.content;
        this.totalRecords = unplannedOrderList.totalElements;
        let selOrder = this.selectedOrdersDataArray;

        //if orders are more than already selected orders then isAllselected is false
        if (this.selectedOrdersDataArray.length < this.orders.length)
          this.isAllSelected = false;

        /*this filters selected orders that is ticked orders so that when user
       clicks next or prev button the selected order won't loss*/
        this.orderDataArrayForCheck.filter(r => {
          selOrder.forEach(l => {
            if (l.orderUniqueId === r.orderUniqueId) {
              r.isChecked = true;
            }
          });
        });
        // this.findZoneForPage(res.content);
        console.log("All Orders from selected Filter", this.OrdersDataArray.data);

      }

      this.OrdersDataArray.paginator = this.paginator;
      this.OrdersDataArray.sortingDataAccessor = (item: any, property) => {
        switch (property) {
          case 'receiverDetails': return item.customerInfo.customerName + " " + item.customerInfo.address.area;
          case 'zone': return item.customerInfo.zone.zoneName;
          case 'customertype': return item.customerInfo.customerType;
          case 'route': return item.customerInfo.zone.route;
        }
      };
      this.OrdersDataArray.sort = this.sort;
      if (this.selectedValue.length != 0) {
        this.isZoneFiltered = true;
        this.sortByZone();
      }

      this.checkAllOrdersAreAlreadySelectedFromFilter();
    }, () => {
      this.openSnackBar("Error in fetching orders");
      this.orderDataArrayForCheck = [];
      this.selectedOrdersDataArray = [];
      this.selectedOrderIdArray = [];
      this.OrdersDataArray.data = [];
      this.isLoading = false;
    });
  }


  clearAllFilter() {
    console.log('clear filter');
    this.isDateFiltered = false;
    this.isZoneFiltered = false;
    this.selectedValue = [];
    this.zones = [];
    this.orderDataArrayForCheck = [];
    this.selectedOrdersDataArray = [];
    this.selectedOrderIdArray = [];
    this.OrdersDataArray.data = [];
    this.setDatePickerToCurrentDate();
    this.fromDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.toDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.page = 0;
    this.paginator.pageIndex = 0;
    // this.selectedOrdersDataArray = [];
    this.findSelectedDateOrders();
  }

  setDatePickerToCurrentDate() {
    this.options = {
      theme: 'default',
      labels: ['Start', 'End'],
      menu: null,
      dateFormat: 'YYYY-MM-DD',
      outputFormat: 'DD-MM-YYYY',
      startOfWeek: 1,
      outputType: 'object',
      locale: 'en',
      date: {
        from: {
          year: new Date().getFullYear(),
          month: new Date().getMonth() + 1,
          day: new Date().getDate()
        },
        to: {
          year: new Date().getFullYear(),
          month: new Date().getMonth() + 1,
          day: new Date().getDate()
        }
      }
    };
  }

  openSnackBar(message) {
    this.snackBar.open(message, 'X', {
      duration: 1000,
      panelClass: ['extraClasses']
    })
  }


  createTrip() {
    console.log("selected Orders To Create paln", this.selectedOrdersDataArray);
    if (this.selectedOrdersDataArray.length != 0) {
      this.showSpinner = true;
      this.tripService.createTrip(this.selectedOrdersDataArray).subscribe(res => {
        this.showSpinner = false;
        console.log(res.Result);
        this.messagesService.sendMessage(res.Result);
        this.router.navigate(['/scheduling/edittrips']).then(res => {
          console.log(res);
        }, Error => {
          console.log(Error);
        })
      }, Error => {
        this.router.navigate(['/scheduling/edittrips']).then(res => {
          console.log(res);
        }, Error => {
          console.log(Error);
        })
        console.log(Error);
      });
    } else {

      this.snackBar.open("Please select at least one order to create a trip", 'ok', {
        duration: 2000,
      });

    }
  }

  createPlan() {
    //if (this.token.getTenant() === 'nivea-dist-prod' || this.token.getTenant() === 'nivea-dist-test') {
    let createPlandialog = this.dialog.open(CreatePlanConfirm, {
      width: '300px',
      data: {
        data: this.selectedOrdersDataArray,
        planMode: this.planMode,
      }
    });
    createPlandialog.afterClosed().subscribe(result => {
      if (result) {
        this.createPlanOfSelectedOrders();
      }
    });
    /*  }
     else {
       this.createPlanOfSelectedOrders();
     } */
  }

  createPlanOfSelectedOrders() {
    if (this.selectedOrdersDataArray.length != 0) {
      this.showSpinner = true;
      let optConfig;
      if (!this.planSettings) {
        optConfig = this.vehicleByBranchId;
      }
      else {
        optConfig = this.planSettings;
      }

      delete optConfig.optimizationType;
      optConfig.optimizationType = "distance";
      let autoPlannerInput = {
        orderIds: this.selectedOrderIdArray,
        orderList: this.selectedOrdersDataArray,
        optimizationConfigurations: optConfig,
        bEnableAutoPlan: this.planMode,
        vehicles: this.vehicleMixSetting,
        //  zoneClubbing: this.zoneClubbing
      };

      console.log(autoPlannerInput)

      this.planService.createPlan(autoPlannerInput).subscribe(res => {
        console.log(res);
        if (res.status == 202) {
          const timer = interval(5000);
          this.subscription = timer.subscribe(() => this.getPlanStatusApiCall(res.body.hashId));
        }
      }, error => {
        this.selectedOrdersDataArray = [];
        this.selectedOrderIdArray = [];
        let orders = [];
        orders = this.OrdersDataArray.data;
        orders.forEach(function (obj) { obj.isChecked = false; });
        this.OrdersDataArray.data = orders;
        this.showSpinner = false;
        this.notifier.notify("error", "Error creating plan");
        this.subscription.unsubscribe();
        if (error.error.Error) {
          this.dialog.open(ErrorExcelUpload, {
            width: '470px',
            maxWidth: '80vw',
            maxHeight: '80vh',
            data: {
              Result: error.error.Result + "\n" + error.error.Error,
            }
          });
        }
        console.log(error);
      });
    } else {
      this.snackBar.open("Please select at least one order to create a plan", 'OK', {
        duration: 2000,
      });
    }
  }

  getPlanStatusApiCall(hashId) {
    this.planService.getPlansCompletionStatus(hashId).subscribe(res => {
      console.log("response", res);
      this.createPlanStatus = res.body.progressStatus;
      if (res.status == 200) {
        this.showSpinner = false;
        this.notifier.notify("success", "Plan Created Successfully");
        this.subscription.unsubscribe();
        this.router.navigate(['/scheduling/allplans']).then(res => {
          console.log(res);
        }, error => {
          console.log(error);
        });
      }
    }, error => {
      this.selectedOrdersDataArray = [];
      this.selectedOrderIdArray = [];
      let orders = [];
      orders = this.OrdersDataArray.data;
      orders.forEach(function (obj) { obj.isChecked = false; });
      this.OrdersDataArray.data = orders;
      this.showSpinner = false;
      this.notifier.notify("error", "Error creating plan");
      if (error.error.Error) {
        this.dialog.open(ErrorExcelUpload, {
          width: '470px',
          maxWidth: '80vw',
          maxHeight: '80vh',
          data: {
            Result: error.error.Result + "\n" + error.error.Error,
          }
        });
      }
      this.subscription.unsubscribe();
      console.log(error);
    });
  }

  prepareToSend(order, Event) {
    let oid = order.orderUniqueId;
    let foundOrder = this.orderDataArrayForCheck.find(pl => pl.orderUniqueId === oid);
    if (Event.checked) {
      let index = this.orderDataArrayForCheck.indexOf(foundOrder);
      this.orderDataArrayForCheck[index].isChecked = true;
      this.selectedOrdersDataArray.push(order);
      this.selectedOrderIdArray.push(oid);
    } else if (!Event.checked) {
      if (this.isPageAllSelected[this.page] == true) {
        this.isAllSelected = false;
        this.isPageAllSelected[this.page] = false;
      }
      let index = this.orderDataArrayForCheck.indexOf(foundOrder);
      let removeIndex = this.selectedOrdersDataArray.findIndex(i => i.orderUniqueId === order.orderUniqueId);
      this.orderDataArrayForCheck[index].isChecked = false;
      this.selectedOrdersDataArray.splice(removeIndex, 1);
      this.selectedOrderIdArray.splice(removeIndex, 1);
    }
    console.log("Selected Orders To Create Plan", this.selectedOrdersDataArray);
    this.OrdersDataArray.data = this.orderDataArrayForCheck;
    this.checkAllOrdersAreAlreadySelectedFromFilter();

  }

  getData(type, from, to) {
    //passing page=-1 fetches all orders
    return this.orderService.getAllOrdersByTypesDateRange(type, -1, from, to, 'nozone');
  }


  pageChanged(event) {
    this.isAllSelected = true;
    this.page = event.pageIndex;
    if (this.isPageAllSelected[this.page] == true) {
      this.isAllSelected = true;
    }
    else
      this.isAllSelected = false;
    this.fromDate = this.daterRangevalue.from.substring(6, 10) + '-' + this.daterRangevalue.from.substring(3, 5) + '-' + this.daterRangevalue.from.substring(0, 2);
    this.toDate = this.daterRangevalue.to.substring(6, 10) + '-' + this.daterRangevalue.to.substring(3, 5) + '-' + this.daterRangevalue.to.substring(0, 2);
    this.findSelectedDateOrders();
  }

  submitFile(evt: any) {
    debugger;
    if (this.token.getTenant() == 'kimberly-dist-prod' || this.token.getTenant() == 'kimberly-ent-test'
      || this.token.getTenant() == 'suhana-dist-prod' || this.token.getTenant() == 'suhana-dist-test'
      // || this.token.getTenant() == 'cipla-dist-prod' || this.token.getTenant() == 'cipla-dist-test') {
        || this.token.getTenant() == 'cipla-dist-prod' || this.token.getTenant() == 'cipla-dist-test' ||this.token.getTenant() == 'cipla-dist-test-siliguri') {// Digvijay
      console.log("called", evt)
      /* wire up file reader */
      const target: DataTransfer = <DataTransfer>(evt.target);
      if (evt) {
        const reader: FileReader = new FileReader();
        reader.onload = (e: any) => {
          this.spinner.show();
          /* read workbook */
          const bstr: string = e.target.result;
          const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
          /* grab first sheet */
          const wsname: string = wb.SheetNames[0];
          const ws: XLSX.WorkSheet = wb.Sheets[wsname];
          /* save data */
          this.excelData = <any>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
          console.log(this.excelData)
          this.validateExcel(evt);
        };
        reader.readAsBinaryString(target.files[0]);
      }
    }
    else {
      console.log("in validate file else")
      this.uploadToAws(evt)
    }
  }

  validateExcel(fileEvent) {
    let keys = this.excelData.shift();
    console.log("keys", keys)
    let rowIndex = 2, errorSummary: string = '', rowArray, errorCount = 0, initialErrors: string = ''
    if (this.token.getTenant() == 'kimberly-dist-prod' || this.token.getTenant() == 'kimberly-ent-test') {
      rowArray = this.excelData.map((e) => {
        let obj = {};
        keys.forEach((key, i) => {
          console.log(key)
          let objKey = key;
          key = key.replace(/[^a-zA-Z ]/g, "");
          key = key.split(' ').join('');
          if (key.toUpperCase() == 'PLANT' || key.toUpperCase() == 'TRANSPORTERCODE' || key.toUpperCase() == 'TRUCKNUMBER' || key.toUpperCase() == 'TRUCKTYPE' || key.toUpperCase() == 'ORDERDATE'
            || key.toUpperCase() == 'BILLINGDATE' || key.toUpperCase() == 'CUSTOMERNO' || key.toUpperCase() == 'SHIPMENTNUMBER' || key.toUpperCase() == 'ORDERNUMBER' || key.toUpperCase() == 'BILLINGDOCUMENT' || key.toUpperCase() == 'MATERIALNO' ||
            key.toUpperCase() == 'QTY' || key.toUpperCase() == 'NETVALUEEXCLGST' || key.toUpperCase() == 'REFDOCUMENTNUMBER' || key.toUpperCase() == 'CUSTOMERGROUP')
            obj[objKey] = e[i];
        });
        return obj;
      });
    }

    if (this.token.getTenant() == 'suhana-dist-prod' || this.token.getTenant() == 'suhana-dist-test') {
      rowArray = this.excelData.map((e) => {
        let obj = {};
        keys.forEach((key, i) => {
          console.log(key)
          let objKey = key;
          key = key.replace(/[^a-zA-Z ]/g, "");
          key = key.split(' ').join('');
          if (key.toUpperCase() == 'PICKINGDATE' || key.toUpperCase() == 'DELIVERY' || key.toUpperCase() == 'REFERENCEDOCUMENT' || key.toUpperCase() == 'SHIPTOPARTY' || key.toUpperCase() == 'MATERIAL'
            || key.toUpperCase() == 'DISTRIBUTIONCHANNEL' || key.toUpperCase() == 'BATCH' || key.toUpperCase() == 'DELIVERYQUANTITY')
            obj[objKey] = e[i];
        });
        return obj;
      });
    }

    // if (this.token.getTenant() == 'cipla-dist-prod' || this.token.getTenant() == 'cipla-dist-test') {
      if (this.token.getTenant() == 'cipla-dist-prod' || this.token.getTenant() == 'cipla-dist-test'||this.token.getTenant() == 'cipla-dist-test-siliguri') {  //Digvijay
      rowArray = this.excelData.map((e) => {
        debugger;
        let obj = {};
        console.log(this.token.getTenant()); //Digvijay Testing
        keys.forEach((key, i) => {
          let objKey = key;
          key = key.replace(/[^a-zA-Z ]/g, "");
          key = key.split(' ').join('');
          if (key.toUpperCase() == 'DISPATCHDATE' || key.toUpperCase() == 'ORIGINCODE' || key.toUpperCase() == 'INVOICENUMBER' || key.toUpperCase() == 'DESTINATIONCODE' || key.toUpperCase() == 'PRODUCTCODE'
            || key.toUpperCase() == 'WEIGHT' || key.toUpperCase() == 'TOTALWEIGHT' || key.toUpperCase() == 'TOTALVOLUMEINCUBICINCH' || key.toUpperCase() == 'QUANTITY' || key.toUpperCase() == 'PRODUCTNAME' || key.toUpperCase() == 'MATERIALTYPE')
            obj[objKey] = e[i];
        });
        return obj;
      });
    }

    console.log("res", rowArray);
    if (rowArray.length == 0) {
      initialErrors = "Attempt to upload blank or excel with only headers."
    }
    else {
      rowArray.forEach(excelRowObj => {
        excelRowObj['rowIndex'] = rowIndex;
        let undefinedIndex = [], blankIndex = [], indexes
        undefinedIndex = this.getAllIndexes(Object.values(excelRowObj), undefined);
        blankIndex = this.getAllIndexes(Object.values(excelRowObj), '')
        if (undefinedIndex && blankIndex) {
          indexes = undefinedIndex.concat(blankIndex)
        }
        else if (undefinedIndex) {
          indexes = undefinedIndex
        }
        else if (blankIndex) {
          indexes = blankIndex
        }
        if (indexes && indexes.length > 0) {
          indexes.forEach(index => {
            if (errorCount < 5) {
              initialErrors = initialErrors.concat('Row ' + excelRowObj['rowIndex'] + ' - missing ' + Object.keys(excelRowObj)[index] + '\n')
            }
            else {
              errorSummary = errorSummary.concat('Row ' + excelRowObj['rowIndex'] + ' - missing ' + Object.keys(excelRowObj)[index] + '\n')
            }
            errorCount = errorCount + 1;
          });
        }
        rowIndex = rowIndex + 1
      });
    }

    this.spinner.hide();

    if (errorCount > 0) {
      this.dialog.open(ErrorExcelUpload, {
        width: 'fit-content',
        maxWidth: 'fit-content',
        maxHeight: '76vh',
        position: { top: '8%' },
        data: {
          Result: initialErrors,
          ErrorSummary: errorSummary
        }
      });
      fileEvent.target.value = '';
    }
    else {
      console.log("in validate file else")
      this.uploadToAws(fileEvent)
    }

    console.log(errorSummary)
  }

  getAllIndexes(arr, val) {
    for (i = 0; i < arr.length; i++) {
      if (arr[i]) {
        arr[i] = arr[i].toString().trim()
      }
    }
    var indexes = [], i = -1;
    while ((i = arr.indexOf(val, i + 1)) != -1) {
      indexes.push(i);
    }
    if (indexes.length != arr.length - 1) {
      return indexes;
    }
  }

  uploadToAws(fileEvent) {
    this.progress = "";
    if (fileEvent) {
      if (fileEvent.target.files.length > 0) {
        console.log(fileEvent.target.files[0]);
        this.isFileUploading = true;
        const file: File = fileEvent.target.files[0];
        // this.uploadService.uploadExcelFile(file);
        if (this.token.getTenant() == 'kimberly-dist-prod' || this.token.getTenant() == 'kimberly-ent-test'
          || this.token.getTenant() == 'cipla-dist-prod' || this.token.getTenant() == 'cipla-dist-test' ||this.token.getTenant() == 'cipla-dist-test-siliguri'
          || this.token.getTenant() == 'suhana-dist-prod' || this.token.getTenant() == 'suhana-dist-test') {// Digvijay
            this.uploadService.uploadOrderExcelFile_WithoutS3(file);
        }
        else{
          this.uploadService.uploadExcelFile(file);
        }
      }
    }
  }

  deliveryDateChange(event: MatDatepickerInputEvent<Date>, order) {
    console.log(event.value);
    const dialogRef = this.dialog.open(ChangeStatusDialogConfirm, {
      width: '300px',
      data: 'dateChange'
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let date = this.datePipe.transform(new Date(event.value), 'yyyy-MM-dd')
        let getDate = date + ' 05:30:00';
        delete order.isChecked;
        order.deliveryETADateTime = getDate;
        console.log(order);
        this.orderService.updateDispatchDate(order.orderUniqueId, getDate).subscribe(res => {
          console.log(res);
        }, Error => {
          console.log(Error);
        });
      }
    });
  }

  EditOrderDetails(data) {
    let editDialog = this.dialog.open(OrderDetailsAndEditOrderComponent, {
      width: '800px',
      data: { data: data }
    });
    editDialog.updatePosition({
      top: '50px', right: '0px',
    });
    editDialog.afterClosed().subscribe(result => {
      console.log(result);
    });
  }

  selectAll(event) {
    if (event.checked) {
      this.isPageAllSelected[this.page] = true;
      this.isAllSelected = true;
      this.orderDataArrayForCheck.forEach(function (obj) {
        if (obj.customerInfo && obj.customerInfo.address && obj.customerInfo.address.location) {
          obj.isChecked = true;
        }
      });
      this.OrdersDataArray.data = this.orderDataArrayForCheck;
      this.orderDataArrayForCheck.forEach(order => {
        //skip order if already exist
        let isOrderExistIndex = this.selectedOrdersDataArray.findIndex(i => i.orderUniqueId === order.orderUniqueId);
        if (isOrderExistIndex == -1) {
          if (order.customerInfo && order.customerInfo.address && order.customerInfo.address.location) {
            this.selectedOrdersDataArray.push(order);
            this.selectedOrderIdArray.push(order.orderUniqueId);
          }
        }
      });
    }
    else {
      this.isPageAllSelected[this.page] = false;
      this.isAllSelected = false;
      this.orderDataArrayForCheck.forEach(function (obj) { obj.isChecked = false; });

      //remove unselected orders from already selected orders
      this.orderDataArrayForCheck.forEach(order => {
        let removeIndex = this.selectedOrdersDataArray.findIndex(i => i.orderUniqueId === order.orderUniqueId);
        this.selectedOrdersDataArray.splice(removeIndex, 1);
        this.selectedOrderIdArray.splice(removeIndex, 1);
      });
      this.OrdersDataArray.data = this.orderDataArrayForCheck;
    }
    console.log("Selected Orders To Create Plan", this.selectedOrdersDataArray);
  }


  openSettings() {
    let fleetConstraints, serviceConstraints, optimizationType;
    if (this.isSuhana) {
    }
    if (this.vehicleByBranchId && this.vehicleByBranchId.fleetConstraints)
      fleetConstraints = this.vehicleByBranchId.fleetConstraints;

    if (this.vehicleByBranchId && this.vehicleByBranchId.serviceConstraints)
      serviceConstraints = this.vehicleByBranchId.serviceConstraints;

    if (this.vehicleByBranchId && this.vehicleByBranchId.bEnableAutoPlan) {
      this.planMode = this.vehicleByBranchId.bEnableAutoPlan;
    }

    if (this.planSettings != null) {
      optimizationType = this.planSettings.optimizationType;
    }
    else if (this.vehicleByBranchId && this.vehicleByBranchId.optimizationType) {
      optimizationType = this.vehicleByBranchId.optimizationType;
    }

    /*   if (this.vehicleByBranchId && this.vehicleByBranchId.serviceConstraints && this.vehicleByBranchId.serviceConstraints.maxNodesToVisit)
        maxVisitsPerVehicle = this.vehicleByBranchId.serviceConstraints.maxNodesToVisit; */
    if (this.vehicleMixSetting && this.vehicleMixSetting.length > 0)
      this.vehicleMixSetting = this.vehicleMixSetting;
    else
      this.vehicleMixSetting = [];
    let settingsDialog;
    if (this.token.getTenant() == 'kimberly-dist-prod' || this.token.getTenant() == 'metro-dist-prod') {
      settingsDialog = this.dialog.open(SettingsComponent, {
        width: '65vw',
        //  height: 'auto',
        maxHeight: '85vh',
        data: { data: this.planSettings, isAutoMode: this.planMode, optimizationType: optimizationType, zoneClubbing: this.zoneClubbing, vehicleMixSetting: this.vehicleMixSetting, serviceConstraints: serviceConstraints, fleetConstraints: fleetConstraints }
      });
    }
    else {
      settingsDialog = this.dialog.open(SettingsComponent, {
        width: '65vw',
        //height: 'auto',
        maxHeight: '85vh',
        data: { data: this.planSettings, isAutoMode: this.planMode, optimizationType: optimizationType, zoneClubbing: this.zoneClubbing, vehicleMixSetting: this.vehicleMixSetting, serviceConstraints: serviceConstraints, fleetConstraints: fleetConstraints }
      });
    }
    const subscription = settingsDialog.componentInstance.saveSettings.subscribe((settings) => {
      console.log("Setting Object", settings)
      if (settings != null) {
        this.planSettings = settings.optimizationConfigurations;
        this.vehicleMixSetting = settings.vehicles
      }
    });

    const sub = settingsDialog.componentInstance.planMode.subscribe((mode: boolean) => {
      this.planMode = mode;
      console.log("planMode", this.planMode);
    })

    const subZoneClubbing = settingsDialog.componentInstance.zoneClubbing.subscribe((zoneToggle: boolean) => {
      this.zoneClubbing = zoneToggle;
      console.log("zoneClubbing", this.zoneClubbing);
    })

    settingsDialog.updatePosition({
      top: '45px',
    });

    settingsDialog.afterClosed().subscribe(() => {
      subscription.unsubscribe();
      sub.unsubscribe();
      subZoneClubbing.unsubscribe();
    });
  }

  orderDetails(data) {
    this.dialogService.orderDetails(data.orderUniqueId, 'orderlistview');
  }

  applyFilter(filterValue: string) {
    this.OrdersDataArray.filter = filterValue.trim().toLowerCase();
  }

  openMap(customerInfo) {
    if (customerInfo.address.location) {
      let mapDialog = this.dialog.open(MapComponent, {
        width: '800px',
        data: customerInfo
      });

      mapDialog.updatePosition({
        top: '50px', right: '0px',
      });

      mapDialog.afterClosed().subscribe(result => {
        console.log(result);
      });
    } else {
      this.notifier.notify('error', "No location available");
    }
  }
  getIconForMarker(k) {
    return '../../../../assets/images/IoT/circle/' + this.colorArray[k] + '.png';
  }

  showModal() {
    // this.hereMapShowFlag = true
    this.zonesWiseData = [];
    for (let i = 0; i < this.selectedOrdersDataArray.length; i++) {
      let item = this.selectedOrdersDataArray[i].customerInfo;

      if (item.address.location) {

        if (this.zonesWiseData.length == 0) {
          console.log("-------------------")
          console.log(this.selectedOrdersDataArray[i])
          this.lat = parseFloat(item.address.location.coordinates[0]);
          this.lng = parseFloat(item.address.location.coordinates[1]);
          this.zonesWiseData.push({
            // zoneID:item.zone.zoneId,
            // zoneName:item.zone.zoneName,
            // zoneCode:item.zone.zoneCode,
            zoneID: item.zone ? item.zone.zoneId : 0,
            zoneName: item.zone ? item.zone.zoneName : null,
            zoneCode: item.zone ? item.zone.zoneCode : null,
            zoneCity: item.address.city,
            zoneFlag: false,
            originBranchAddress: this.selectedOrdersDataArray[i].customerInfo.address,
            zoneOrigin:
            {
              lat: this.selectedOrdersDataArray[i].customerInfo.address.location.coordinates[0],
              lng: this.selectedOrdersDataArray[i].customerInfo.address.location.coordinates[1]
            },
            zoneLocation: [
              {
                lat: item.address.location.coordinates[0],
                lng: item.address.location.coordinates[1],
                customerName: item.customerName,
                customerCode: item.customerCode,
                totalWeight: this.selectedOrdersDataArray[i].totalWeight,
                totalVolume: this.selectedOrdersDataArray[i].totalVolume,
                orderType: this.selectedOrdersDataArray[i].orderType,
                houseNumber: item.address.houseNumber,
                pincode: item.address.pincode,
                city: item.address.city,

              }
            ]
          })
        }
        else {
          console.log("-------------------" + i + "---")
          console.log(this.selectedOrdersDataArray[i])
          let tempZoneID = item.zone ? item.zone.zoneId : 0;
          console.log(tempZoneID);
          console.log(this.zonesWiseData.some(function (o) { return o["zoneID"] === tempZoneID }));
          if (this.zonesWiseData.some(function (o) { return o["zoneID"] === tempZoneID; }) == true) {
            let lat, lng;
            if (item.address && item.address.location) {
              lat = item.address.location.coordinates[0],
                lng = item.address.location.coordinates[1]
            }
            //It means zone is already there
            var index = this.zonesWiseData.findIndex(p => p.zoneID == tempZoneID);

            this.zonesWiseData[index].zoneLocation.push(
              {
                lat: lat,
                lng: lng,
                customerName: item.customerName,
                customerCode: item.customerCode,
                totalWeight: this.selectedOrdersDataArray[i].totalWeight,
                totalVolume: this.selectedOrdersDataArray[i].totalVolume,
                orderType: this.selectedOrdersDataArray[i].orderType,
                houseNumber: item.address.houseNumber,
                pincode: item.address.pincode,
                city: item.address.city,

              }
            )

          }
          else {
            //It means zone is not present there
            this.zonesWiseData.push({
              zoneID: item.zone ? item.zone.zoneId : 0,
              zoneName: item.zone ? item.zone.zoneName : null,
              zoneCode: item.zone ? item.zone.zoneCode : null,
              zoneCity: item.address.city,
              zoneFlag: false,
              originBranchAddress: this.selectedOrdersDataArray[i].customerInfo.address,
              zoneOrigin:
              {
                lat: this.selectedOrdersDataArray[i].customerInfo.address.location.coordinates[0],
                lng: this.selectedOrdersDataArray[i].customerInfo.address.location.coordinates[1]
              },
              zoneLocation: [
                {
                  lat: item.address.location.coordinates[0],
                  lng: item.address.location.coordinates[1],
                  customerName: item.customerName,
                  customerCode: item.customerCode,
                  totalWeight: this.selectedOrdersDataArray[i].totalWeight,
                  totalVolume: this.selectedOrdersDataArray[i].totalVolume,
                  orderType: this.selectedOrdersDataArray[i].orderType,
                  houseNumber: item.address.houseNumber,
                  pincode: item.address.pincode,
                  city: item.address.city
                }
              ]
            });
          }
        }
      }
    }
    console.log(this.zonesWiseData);
  }

  mapChanged(mapvalue) {
    if (mapvalue == "true")
      this.hereMapShowFlag = true;
    else
      this.hereMapShowFlag = false;
  }

  updateZoneData(item, $event) {
    item.zoneFlag = $event.checked;
  }

  getColor(i) {
    return "#" + this.colorArray[i];
  }

  generateId(index, flag) {
    if (flag)
      return 'zone' + index;
    else
      return '#zone' + index;
  }

  clubSelectedZones() {
    let clubData = [];
    let zoneData = JSON.parse(JSON.stringify(this.zonesWiseData));
    for (let i = 0; i < zoneData.length; i++) {
      let item;
      item = zoneData[i];
      if (item.zoneFlag == true) {
        if (clubData.length == 0) {
          clubData.push(item);
        }
        else {
          clubData[0].zoneName += ", " + item.zoneName;
          clubData[0].zoneCity += ", " + item.zoneCity;
          clubData[0].zoneCode += ", " + item.zoneCode;
          clubData[0].zoneID += ", " + item.zoneID;
          clubData[0].zoneFlag = false;

          for (let j = 0; j < item.zoneLocation.length; j++) {
            clubData[0].zoneLocation.push(item.zoneLocation[j]);
          }
        }
      }
    }
    for (let i = 0; i < zoneData.length; i++) {
      let item;
      item = this.zonesWiseData[i];
      if (item.zoneFlag == false) {
        clubData.push(item);
      }
    }
    this.zonesWiseData = JSON.parse(JSON.stringify(clubData));
  }
}


export interface NgxDateRangePickerDates {
  from: {
    year: number,
    month: number,
    day: number
  }
  to: {
    year: number,
    month: number,
    day: number
  }
}

@Component({
  selector: 'dialog-order-confirm',
  template: `
  <div style="padding:20px; text-align: center;">
    <p class="mt-0 mb-0"> Total Orders : {{totalOrders}}</p>
    <p class="mt-0 mb-0"> Total Customers : {{totalCustomers}}</p>
    <p class="mt-0 mb-0" *ngIf="isGtNongtshown"> Total GT/IDKA Customers : {{totalGtCustomers}}</p>
    <p class="mt-0" *ngIf="isGtNongtshown"> Total Non GT/IDKA Customers : {{totalNonGtCustomer}}</p>
    <p class="mt-0">Plan Mode: {{planMode}}</p>
    <div class="row mt-4 ml-1">
    <div class="col">
       <button mat-raised-button color="primary" [mat-dialog-close]="true">Ok</button>
     </div>
      <div class="col">
        <button mat-raised-button color="warn" (click)="onNoClick()">Cancel</button>
      </div>
    </div>
  </div>
  `,
})


export class CreatePlanConfirm {
  totalOrders: number = 0;
  totalCustomers: number = 0;
  totalGtCustomers: number = 0;
  totalNonGtCustomer: number = 0;
  isGtNongtshown: boolean = false;
  planMode: string;
  constructor(
    public cancelCreatingPlan: MatDialogRef<CreatePlanConfirm>,
    private token: TokenStorage,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    if (this.token.getTenant() === 'nivea-dist-prod' || this.token.getTenant() === 'nivea-dist-test') {
      this.isGtNongtshown = true;
    }
    console.log("selected array data", this.data);
    this.totalOrders = this.data.data.length;
    let totalcustomers = [], totalGt = [], totalnonGt = [];
    totalcustomers = this.data.data.reduce(function (uniqcustomerArray, customer) {
      if (uniqcustomerArray.indexOf(customer.customerInfo.customerName) == -1) {
        uniqcustomerArray.push(customer.customerInfo.customerName)
        if (customer.customerInfo.customerType === "GT" || customer.customerInfo.customerType === "IDKA")
          totalGt.push(customer.customerInfo);
        else
          totalnonGt.push(customer.customerInfo);
      }
      return uniqcustomerArray;
    }, []);
    this.totalCustomers = totalcustomers.length;
    this.totalGtCustomers = totalGt.length;
    this.totalNonGtCustomer = totalnonGt.length;
    if (this.data.planMode)
      this.planMode = "Auto";
    else
      this.planMode = "Manual";
  }

  onNoClick(): void {
    this.cancelCreatingPlan.close();
  }
}