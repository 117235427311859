import { NgModule  } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
    MatButtonModule,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    MatMenuModule,
    MatListModule,
    MatCardModule,
    MatDialogModule,
    MatTableModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatFormFieldControl,
    MatInputModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatExpansionModule,
    MatOptionModule,
    MatSelectModule,
    MatTabsModule,
    MatButtonToggleModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatSortModule,
    MatBadgeModule,
    MatTooltipModule,
    MatRadioModule,
    MatGridListModule
} from '@angular/material'
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

@NgModule({
    imports:[
        MatButtonModule,
        MatToolbarModule,
        MatIconModule,
        MatSidenavModule,
        MatMenuModule,
        MatListModule,
        MatCardModule,
        MatDialogModule,
        MatTableModule,
        MatPaginatorModule,
        MatCheckboxModule,
        MatSlideToggleModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatNativeDateModule,
        MatInputModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatExpansionModule,
        MatOptionModule,
        MatSelectModule,
        MatTabsModule,
        MatButtonToggleModule,
        MatAutocompleteModule,
        MatChipsModule,
        MatSortModule,
        MatBadgeModule,
        MatTooltipModule,
        MatRadioModule,
        MatGridListModule
    ],
    exports:[
        MatButtonModule,
        MatToolbarModule,
        MatIconModule,
        MatSidenavModule,
        MatMenuModule,
        MatListModule,
        MatCardModule,
        MatDialogModule,
        MatTableModule,
        MatPaginatorModule,
        MatCheckboxModule,
        MatSlideToggleModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatNativeDateModule,
        MatInputModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatExpansionModule,
        MatOptionModule,
        MatSelectModule,
        MatTabsModule,
        MatButtonToggleModule,
        MatAutocompleteModule,
        MatChipsModule,
        MatSortModule,
        MatBadgeModule,
        MatTooltipModule,
        MatRadioModule,
        MatGridListModule
    ]
})


export class MaterialModule {}