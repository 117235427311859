import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-networkmanagement',
  templateUrl: './networkmanagement.component.html',
  styleUrls: ['./networkmanagement.component.scss']
})
export class NetworkmanagementComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
