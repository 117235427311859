import { Injectable } from '@angular/core';
import * as S3 from 'aws-sdk/clients/s3';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { TokenStorage } from '../../auth/token.storage';
import { Router } from '@angular/router';
import { ErrorExcelUpload } from '../../pages/schedulingandplanning/schedulingandplanning.component';
import { MatDialog } from '@angular/material';
import { BehaviorSubject, interval, Subscription } from 'rxjs';
import { NotifierService } from 'angular-notifier';

@Injectable({
  providedIn: 'root'
})

export class UploadfileService {
  subscription: Subscription;
  private stopSpinner = new BehaviorSubject<any>(null);
  event = this.stopSpinner.asObservable();

  private notifyProgress = new BehaviorSubject<any>({ progressStatus: "" });
  progressObservable = this.notifyProgress.asObservable();

  month = new Date().getMonth() + 1;
  folderName = "excel" + "/" + new Date().getDate() + "-" +
    this.month + "-" + new Date().getFullYear() +
    "T" + new Date().getHours() + ":" + new Date().getMinutes() +
    ":" + new Date().getSeconds() + "_";

  constructor(private httpClient: HttpClient,
    private token: TokenStorage,
    private router: Router,
    public dialog: MatDialog,
    private notifier: NotifierService
  ) {
  }

  uploadExcelFile(excelFile) {
    debugger;
    let paramsToAws;
    console.log("In upload service", excelFile, this.token.getTenant());
    const bucket = new S3(environment.S3Key);

    paramsToAws = {
      Bucket: this.token.getTenant(),
      Key: this.folderName + excelFile.name,
      Body: excelFile
    };

    // bucket.upload(paramsToAws).promise().then(data => { // Commented by Digvijay and added below code
    let bucketUploadPromise = bucket.upload(paramsToAws).promise();
    bucketUploadPromise.then(data => {
      console.log('Success in uploading file to aws', data);
      this.httpClient.get(environment.appUrl + '/orders/excel-report?filePath='.concat(this.folderName).concat(excelFile.name), { observe: 'response' }).subscribe(response => {
        let obj = JSON.stringify(response.body);
        if (response.status == 202) {
          const timer = interval(10000); // Digvijay changed from 7000 to 10000
          this.subscription = timer.subscribe(val => this.getUploadOrderStatusApiCall(JSON.parse(obj).hashId));
        }
      }, error => {
        debugger
        console.log("Error", error);
        this.stopSpinner.next(null);
        debugger
        this.notifier.notify('error', "Error uploading excel, Please try again.");
        debugger
      });
    }).catch(err => {
      debugger
      this.stopSpinner.next(null);
      this.notifier.notify('error', "Error uploading excel, Please try again.");
      debugger
      console.log(err);
    });
  }

  debugger
  uploadOrderExcelFile_WithoutS3(excelFile) {
    
    debugger;
    let paramsToAws;
    console.log("In upload service", excelFile, this.token.getTenant());
    this.debugger
    const bucket = new S3(environment.S3Key);
    let excelData = new FormData();
    excelData.append('file', excelFile);

    paramsToAws = {
      Bucket: this.token.getTenant(),
      Key: this.folderName + excelFile.name,
      Body: excelFile
    };

    // bucket.upload(paramsToAws).promise().then(data => { // Commented by Digvijay and added below code
    let bucketUploadPromise = bucket.upload(paramsToAws).promise();
    bucketUploadPromise.then(data => {
      console.log('Success in uploading file to aws', data);
      this.httpClient.post(environment.appUrl + '/orders/excel-report-without-s3?filePath='.concat(this.folderName).concat(excelFile.name), excelData, { observe: 'response' }).subscribe(
      response => {  
        let obj = JSON.stringify(response.body);
        if (response.status == 202) {
          const timer = interval(10000); // Digvijay changed from 7000 to 10000
          this.subscription = timer.subscribe(val => this.getUploadOrderStatusApiCall(JSON.parse(obj).hashId));
        }
      }, error => {
        debugger
        console.log("Error", error);
        this.stopSpinner.next(null);
        this.notifier.notify('error', "Error uploading excel, Please try again.");
      });
    }).catch(err => {
      debugger
      this.stopSpinner.next(null);
      this.notifier.notify('error', "Error uploading excel, Please try again.");
      console.log(err);
    });
    
    /*
    debugger;
    let paramsToAws;
    console.log("In upload service", excelFile, this.token.getTenant());
    const bucket = new S3(environment.S3Key);


    let excelData = new FormData();
    let fileReader = new FileReader();
    fileReader.readAsBinaryString(excelFile);
    let jsonData: any;
    fileReader.onload = (event) => {
      console.log("event", event);
      // let binaryData = event.target.result;
      let binaryData = fileReader.result;
      var XLSX = require("xlsx");
      let workbook = XLSX.read(binaryData, {type: 'binary'});
      jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);
      console.log("workbook", workbook);
      console.log("JsonData", jsonData);
      
      excelData.append('file', excelFile);
      // excelData.append('data', JSON.stringify(jsonData, undefined, 4));
      console.log("JsonDataStringify",JSON.stringify(jsonData, undefined, 4));

      paramsToAws = {
        Bucket: this.token.getTenant(),
        Key: this.folderName + excelFile.name,
        Body: excelFile
      };

      let bucketUploadPromise = bucket.upload(paramsToAws).promise();
      bucketUploadPromise.then(data => {
    
        excelData.append('data', JSON.stringify(jsonData, undefined, 4));
        this.httpClient.post(environment.appUrl + '/orders/excel-report-without-s3?filePath='.concat(this.folderName).concat(excelFile.name), excelData, { observe: 'response' }).subscribe(
        response => {
          console.log("excelData", response);
          let obj = JSON.stringify(response.body);
          if (response.status == 202) {
            const timer = interval(10000); // Digvijay changed from 7000 to 10000
            this.subscription = timer.subscribe(val => this.getUploadOrderStatusApiCall(JSON.parse(obj).hashId));
          }
          // let obj = JSON.stringify(response);
          // const timer = interval(10000); // Digvijay changed from 7000 to 10000
          // this.subscription = timer.subscribe(val => this.getUploadOrderStatusApiCall(JSON.parse(obj).hashId));
        },
        error => {
          console.log("Error", error);
          this.stopSpinner.next(null);
          this.notifier.notify('error', "Error uploading excel, Please try again.");
        })
      });
    };
    */
    
/*
    // this.httpClient.post(environment.appUrl + '/orders/excel-report-without-s3?filePath='.concat(this.folderName).concat(excelFile.name), excelData, { observe: 'response' }).subscribe(
    //   response => {
    //     console.log("excelData", response);
    //     let obj = JSON.stringify(response.body);
    //     if (response.status == 202) {
    //       const timer = interval(10000); // Digvijay changed from 7000 to 10000
    //       this.subscription = timer.subscribe(val => this.getUploadOrderStatusApiCall(JSON.parse(obj).hashId));
    //     }
    //     // let obj = JSON.stringify(response);
    //     // const timer = interval(10000); // Digvijay changed from 7000 to 10000
    //     // this.subscription = timer.subscribe(val => this.getUploadOrderStatusApiCall(JSON.parse(obj).hashId));
    //   },
    //   error => {
    //     console.log("Error", error);
    //     this.stopSpinner.next(null);
    //     this.notifier.notify('error', "Error uploading excel, Please try again.");
    //   });
    */
  }


  uploadCustomerExcelFile(excelFile) {
    debugger;
    let paramsToAws;
    console.log("In upload service", excelFile, this.token.getTenant());
    const bucket = new S3(environment.S3Key);

    paramsToAws = {
      Bucket: this.token.getTenant(),
      Key: this.folderName + excelFile.name,
      Body: excelFile
    };

    // bucket.upload(paramsToAws).promise().then(data => {   // Commented by Digvijay and added below code
    let bucketUploadPromise = bucket.upload(paramsToAws).promise();
    bucketUploadPromise.then(data => {
      console.log('Success in uploading file to aws', data);
      this.httpClient.get(environment.appUrl + '/customer/excel-report?filePath='.concat(this.folderName).concat(excelFile.name), { observe: 'response' }).subscribe(response => {
        let obj = JSON.stringify(response.body);
        console.log('Response', response);
        if (response.status == 202) {
          const timer = interval(7000);
          this.subscription = timer.subscribe(val => this.getUploadCustomerStatusApiCall(JSON.parse(obj).hashId));
        }
      }, error => {
        debugger
        console.log("Error", error);
        this.stopSpinner.next(null);
        this.notifier.notify('error', "Error uploading excel, Please try again.");
      });
    }).catch(err => {
      debugger
      this.stopSpinner.next(null);
      this.notifier.notify('error', "Error uploading excel, Please try again.");
      console.log(err);
    });
  }

  uploadProductExcelFile(excelFile) {
    let paramsToAws;
    console.log("In upload service", excelFile, this.token.getTenant());
    const bucket = new S3(environment.S3Key);

    paramsToAws = {
      Bucket: this.token.getTenant(),
      Key: this.folderName + excelFile.name,
      Body: excelFile
    };

    // bucket.upload(paramsToAws).promise().then(data => {   // Commented by Digvijay and added below code
    let bucketUploadPromise = bucket.upload(paramsToAws).promise();
    bucketUploadPromise.then(data => {
      console.log('Success in uploading file to aws', data);
      this.httpClient.get(environment.appUrl + '/product/excel-report?filePath='.concat(this.folderName).concat(excelFile.name), { observe: 'response' }).subscribe(response => {
        let obj = JSON.stringify(response.body);
        console.log('Response', response);
        if (response.status == 202) {
          const timer = interval(7000);
          this.subscription = timer.subscribe(val => this.getUploadProductStatusApiCall(JSON.parse(obj).hashId));
        }
      }, error => {
        debugger
        console.log("Error", error);
        this.stopSpinner.next(null);
        this.notifier.notify('error', "Error uploading excel, Please try again.");
        debugger
      });
    }).catch(err => {
      debugger
      this.stopSpinner.next(null);
      this.notifier.notify('error', "Error uploading excel, Please try again.");
      debugger
      console.log(err);
    });
  }

  getUploadCustomerStatusApiCall(hashId) {
    this.httpClient.get(environment.appUrl + '/customer/excel/progress/' + hashId, { observe: 'response' }).subscribe((res: any) => {
      console.log("response", res);
      if (res.status == 200) {
        this.stopSpinner.next(null);
        this.subscription.unsubscribe();
        console.log(res.body);
        if (res.body.Result) {
          this.notifier.notify('success', "Excel Uploaded Successfully");
          const showResult = this.dialog.open(ErrorExcelUpload, {
            width: 'fit-content',
            maxWidth: 'fit-content',
            maxHeight: '76vh',
            position: { top: '8%' },
            data: res.body
          });

          showResult.afterClosed().subscribe(result => {
            if (result) {
              this.router.navigate(["/managecustomers"]);
            }
          });
        }
      }
    }, error => {
      this.stopSpinner.next(null);
      this.subscription.unsubscribe();
      console.log(error)
      if (error.status == 400) {
        if (error.error.Result == 'blank' || error.error.Result == 'only header')
          this.notifier.notify('error', error.error.ErrorSummary)
        else if (error.error.Result == null && error.error.ErrorSummary != null)
          this.notifier.notify('error', "Customer excel uploading process terminated. Please contact to Administrator.")
        else {
          this.notifier.notify('error', "Customer excel has some error, please correct the excel and upload it again.");
          this.dialog.open(ErrorExcelUpload, {
            width: 'fit-content',
            maxWidth: 'fit-content',
            maxHeight: '76vh',
            position: { top: '8%' },
            data: error.error
          });
        }
      }
      else {
        this.notifier.notify('error', "Error in customer uploading excel, Please try again.");
      }
    });
  }

  getUploadProductStatusApiCall(hashId) {
    this.httpClient.get(environment.appUrl + '/product/excel/progress/' + hashId, { observe: 'response' }).subscribe((res: any) => {
      console.log("response", res);
      if (res.status == 200) {
        this.stopSpinner.next(null);
        this.subscription.unsubscribe();
        console.log(res.body);
        if (res.body.Result) {
          this.notifier.notify('success', "Excel Uploaded Successfully");
          const showResult = this.dialog.open(ErrorExcelUpload, {
            width: 'fit-content',
            maxWidth: 'fit-content',
            maxHeight: '76vh',
            position: { top: '8%' },
            data: res.body
          });

          showResult.afterClosed().subscribe(result => {
            if (result) {
              this.router.navigate(["/manageproducts"]);
            }
          });
        }
      }
    }, error => {
      this.stopSpinner.next(null);
      this.subscription.unsubscribe();
      console.log(error)
      if (error.status == 400) {
        if (error.error.Result == 'blank' || error.error.Result == 'only header')
          this.notifier.notify('error', error.error.ErrorSummary)
        else if (error.error.Result == null && error.error.ErrorSummary != null)
          this.notifier.notify('error', "Product excel uploading process terminated. Please contact to Administrator.")
        else {
          this.notifier.notify('error', "Product excel has some error, please correct the excel and upload it again.");
          this.dialog.open(ErrorExcelUpload, {
            width: 'fit-content',
            maxWidth: 'fit-content',
            maxHeight: '76vh',
            position: { top: '8%' },
            data: error.error
          });
        }
      }
      else {
        this.notifier.notify('error', "Error in product uploading excel, Please try again.");
      }
    });
  }

  getUploadOrderStatusApiCall(hashId) {
    this.httpClient.get(environment.appUrl + '/orders/excel-report/progress/' + hashId, { observe: 'response' }).subscribe((res: any) => {
      console.log("response", res);
      if (res.status == 200) {
        this.stopSpinner.next(null);
        this.subscription.unsubscribe();
        console.log(res.body);
        if (res.body.Result) {
          this.notifier.notify('success', "Excel Uploaded Successfully");
          const showResult = this.dialog.open(ErrorExcelUpload, {
            width: 'fit-content',
            maxWidth: 'fit-content',
            maxHeight: '76vh',
            position: { top: '8%' },
            data: res.body,
            disableClose: true
          });

          if (this.token.getTenant() == "kimberly-dist-prod") {
            showResult.afterClosed().subscribe(result => {
              if (result) {
                this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                  this.router.navigate(["/scheduling/edittrips"]));
              }
            });
          }
          else {
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
              this.router.navigate(["/scheduling"]));
          }
        }
      }
    }, error => {
      this.stopSpinner.next(null);
      this.subscription.unsubscribe();
      console.log(error)
      if (error.status == 400) {
        if (error.error.Result == 'blank' || error.error.Result == 'only header')
          this.notifier.notify('error', error.error.ErrorSummary)
        else if (error.error.Result == null && error.error.ErrorSummary != null)
          this.notifier.notify('error', "Order excel uploading process terminated. Please contact to Administrator.")
        else {
          this.notifier.notify('error', "Order excel has some error, please correct the excel and upload it again.");
          this.dialog.open(ErrorExcelUpload, {
            width: 'fit-content',
            maxWidth: 'fit-content',
            maxHeight: '76vh',
            position: { top: '8%' },
            data: error.error
          });
        }
      }
      else {
        this.notifier.notify('error', "Error in order uploading excel, Please try again.");
      }
    });
  }

  async uploadImage(image: File, jobId: number) {
    let uploadObject: any;
    const bucket = new S3(environment.S3Key);
    let imageName: string = new Date().getDate() + "-" +
      this.month + "-" + new Date().getFullYear() +
      "T" + new Date().getHours() + ":" + new Date().getMinutes() +
      ":" + new Date().getSeconds() + "_" + image.name;

    uploadObject = {
      Bucket: this.token.getTenant(),
      Key: 'images/' + jobId + '/transporter/web/' + imageName,
      Body: image,
      ContentType: image.type
    };

    //Added By Digvijay to allow only image files to be uploaded as POD image
    // console.log(uploadObject.ContentType);
    // console.log(image.type.includes("image"));
    // debugger;
    // if(image.type.includes("image")){

    await bucket.upload(uploadObject).promise().then(async () => {
      await this.httpClient.put(environment.appUrl + '/pod/job/?jobId=' + jobId + '&web=' + true, null).toPromise().then(async (status) => {
        this.stopSpinner.next(null);
        this.notifier.notify('success', "Upload successful.");
        console.log(status);
        if (Object.values(status)[0] != "JOB_STATE_DELIVERED") {
          await this.httpClient.put(environment.appUrl + '/jobs/' + jobId + '/updatedeliverystatus?web=' + true, null).toPromise().then(() => {
            this.notifier.notify('success', "Job Delivered Successfully.");
          }, Error => {
            this.stopSpinner.next(null);
            this.notifier.notify('error', "Failed to update job delivery status.");
          })
        }
      }, Error => {
        this.stopSpinner.next(null);
      })

    }).catch(() => {
      this.stopSpinner.next(null);
      this.notifier.notify('error', "Error uploading POD, Please try again.");
    });
  // }
  // else{
  //   this.notifier.notify('error', "Invalid file type.");
  // }
  }

  uploadDocument(document: File, jobId: number) {
    let uploadObject: any;
    const bucket = new S3(environment.S3Key);
    let documentName: string = new Date().getDate() + "-" +
      this.month + "-" + new Date().getFullYear() +
      "T" + new Date().getHours() + ":" + new Date().getMinutes() +
      ":" + new Date().getSeconds() + "_" + document.name;

    uploadObject = {
      Bucket: this.token.getTenant(),
      Key: 'documents/' + jobId + '/' + documentName,
      Body: document
    };

    // bucket.upload(uploadObject).promise().then(() => { // Commented by Digvijay
    let bucketUploadPromise = bucket.upload(uploadObject).promise();
    bucketUploadPromise.then(() => {  
      this.notifier.notify('success', "Upload successful.");
      this.stopSpinner.next(null);
    }).catch(() => {
      this.stopSpinner.next(null);
      this.notifier.notify('error', "Error uploading file, Please try again.");
    });
  }


  uploadRateExcelFile(excelFile, apiStart) {
    let paramsToAws;
    console.log("In upload service", excelFile, this.token.getTenant());
    const bucket = new S3(environment.S3Key);

    paramsToAws = {
      Bucket: this.token.getTenant(),
      Key: this.folderName + excelFile.name,
      Body: excelFile
    };

    // bucket.upload(paramsToAws).promise().then(data => { // Commented by Digvijay

    let bucketUploadPromise = bucket.upload(paramsToAws).promise();
    bucketUploadPromise.then(data => {  
      console.log('Success in uploading file to aws', data);
      this.httpClient.get(environment.appUrl + apiStart + 'excel-report?filePath='.concat(this.folderName).concat(excelFile.name), { observe: 'response' }).subscribe(response => {
        let obj = JSON.stringify(response.body);
        console.log('Response', response);
        if (response.status == 202) {
          const timer = interval(7000);
          this.subscription = timer.subscribe(val => this.getUploadRateStatusApiCall(apiStart, JSON.parse(obj).hashId));
        }
      }, error => {
        debugger
        console.log("Error", error);
        this.stopSpinner.next(null);
        this.notifier.notify('error', "Error uploading excel, Please try again.");
        debugger
      });
    }).catch(err => {
      debugger
      this.stopSpinner.next(null);
      this.notifier.notify('error', "Error uploading excel, Please try again.");
      debugger
      console.log(err);
    });
  }

  getUploadRateStatusApiCall(apiStart, hashId) {
    this.httpClient.get(environment.appUrl + apiStart + 'excel-report/progress/' + hashId, { observe: 'response' }).subscribe((res: any) => {
      console.log("response", res);
      if (res.status == 200) {
        this.stopSpinner.next(null);
        this.subscription.unsubscribe();
        console.log(res.body);
        if (res.body.Result) {
          this.notifier.notify('success', "Excel Uploaded Successfully");
          const showResult = this.dialog.open(ErrorExcelUpload, {
            width: 'fit-content',
            maxWidth: 'fit-content',
            maxHeight: '76vh',
            position: { top: '8%' },
            data: res.body
          });
          showResult.afterClosed().subscribe(result => {
            if (result) {
              if (apiStart == "/rates/")
                this.router.navigate(["/transportrate"]);
              else {
                this.router.navigate(["/transportrate/additionalrate"]);
              }
            }
          });
        }
      }
    }, error => {
      this.stopSpinner.next(null);
      this.subscription.unsubscribe();
      console.log(error)
      if (error.status == 400) {
        if (error.error.Result == 'blank' || error.error.Result == 'only header')
          this.notifier.notify('error', error.error.ErrorSummary)
        else if (error.error.Result == null && error.error.ErrorSummary != null)
          this.notifier.notify('error', "Rate excel uploading process terminated. Please contact to Administrator.")
        else {
          this.notifier.notify('error', "Rate excel has some error, please correct the excel and upload it again.");
          this.dialog.open(ErrorExcelUpload, {
            width: 'fit-content',
            maxWidth: 'fit-content',
            maxHeight: '76vh',
            position: { top: '8%' },
            data: error.error
          });
        }
      }
      else {
        this.notifier.notify('error', "Error in rate uploading excel, Please try again.");
      }
    });
  }

  uploadBulkExcelFile(excelFile) {
    let paramsToAws;
    console.log("In upload service", excelFile, this.token.getTenant());
    const bucket = new S3(environment.S3Key);

    paramsToAws = {
      Bucket: this.token.getTenant(),
      Key: this.folderName + excelFile.name,
      Body: excelFile
    };

    // bucket.upload(paramsToAws).promise().then(data => { // Commented By Digvijay
    let bucketUploadPromise = bucket.upload(paramsToAws).promise();
    bucketUploadPromise.then(data => {  
      console.log('Success in uploading file to aws', data);
      this.httpClient.get(environment.appUrl + '/orders/excel-report?filePath='.concat(this.folderName).concat(excelFile.name) + '&excelVersion=suhana', { observe: 'response' }).subscribe(response => {
        let obj = JSON.stringify(response.body);
        if (response.status == 202) {
          const timer = interval(7000);
          this.subscription = timer.subscribe(val => this.getUploadOrderStatusApiCall(JSON.parse(obj).hashId));
        }
      }, error => {
        debugger
        console.log("Error", error);
        this.stopSpinner.next(null);
        this.notifier.notify('error', "Error uploading excel, Please try again.");
        debugger
      });
    }).catch(err => {
      debugger
      this.stopSpinner.next(null);
      this.notifier.notify('error', "Error uploading excel, Please try again.");
      debugger
      console.log(err);
    });
  }
}