/// <reference path="../../../../node_modules/bingmaps/types/MicrosoftMaps/Microsoft.Maps.All.d.ts"/>
import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { BingmapService } from 'src/app/services/bingmap/bingmap.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-bingmap',
  templateUrl: './bingmap.component.html',
  styleUrls: ['./bingmap.component.scss']
})
export class BingmapComponent implements OnInit, OnDestroy {

  @Input() private tripData: any;
  @Input() private locationData: any;
  @ViewChild('bingMap') private bingMap: any;

  private bingMapSubscription: Subscription;
  private bing: any;
  private directionWaypointLayer: any;
  private infobox: any;

  constructor(
    private bingMapService: BingmapService,
  ) { }

  ngOnInit() {
    this.bingMapSubscription = this.bingMapService.injectionSubject().subscribe((loaded) => {
      if (loaded) {
        window.setTimeout(() => {
          this.loadMap();
        }, 1000);
      }
    });

    this.bingMapService.injectBingMapMainScript();
  }

  loadMap() {
    this.bing = new Microsoft.Maps.Map(this.bingMap.nativeElement, {
      /* No need to set credentials if already passed in URL */
      navigationBarMode: 3,
      credentials: "",
      center: new Microsoft.Maps.Location(18.700085, 74.022575),
      mapTypeId: Microsoft.Maps.MapTypeId.road
    });

    //Create a layer for managing custom waypoints.
    this.directionWaypointLayer = new Microsoft.Maps.Layer();
    this.bing.layers.insert(this.directionWaypointLayer);
    
    //Create a reusable infobox.
    this.infobox = new Microsoft.Maps.Infobox(this.bing.getCenter(), {
      showCloseButton: false,
      visible: false
    });
    this.infobox.setMap(this.bing);  
    this.populateRoute();  
    this.addInfoBoxOptions();     
  }  

  addInfoBoxOptions() {
    var infobox = this.infobox;
    //Add mouse events for showing instruction when hovering pins in directions waypoint layer.
    Microsoft.Maps.Events.addHandler(this.directionWaypointLayer, 'mouseover', showInstruction);
    Microsoft.Maps.Events.addHandler(this.directionWaypointLayer, 'mouseout', hideInstruction);

    function showInstruction(e) {
      infobox.setOptions({
          location: e.target.getLocation(),
          description: e.target.metadata.description,
          offset: e.target.metadata.infoboxOffset,
          visible: true
      });
    }
    
    function hideInstruction() {
        infobox.setOptions({ visible: false });
    }
    
  }

  populateRoute() {

    console.log("Locations Array",this.locationData);
    if (this.locationData.length > 0) {
      var paths = [];
      let tmp: any = {}; 
      tmp.color = '#337aff';
      tmp.details = 'path1 Details';
      tmp.name = 'path1';
      tmp.points = this.locationData;
      paths.push(tmp);
      var map = this.bing;
      var directionWaypointLayer = this.directionWaypointLayer;
      Microsoft.Maps.loadModule("Microsoft.Maps.SpatialMath", function () {  
        

        for (let i = 0; i < paths.length; i++) {
          createRoute(paths[i].points, paths[i].color, i);
        }

        function createRoute(points, routeColor, index) {
          var wp = [], trip = [];                    
          var count = 0;
          for (var key in points) {
              if(points.hasOwnProperty(key)) {
                var location = new Microsoft.Maps.Location(points[key].latitude, points[key].longitude);
                var pin;
                trip.push(location);
                if(count === 0 || count === (Object.keys(points).length -1)) {
                  pin = new Microsoft.Maps.Pushpin(location, {
                    icon: 'assets/icons/DC143C.png',
                  });
      
                } /* else {
                  var heading = Microsoft.Maps.SpatialMath.getHeading(trip[trip.length-2], trip[trip.length - 1]);
                  pin = new Microsoft.Maps.Pushpin(location, {
                      //icon: '<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"viewBox="0 0 512.008 512.008" style="enable-background:new 0 0 512.008 512.008;" xml:space="preserve" height="32" width="32"><g><g><path d="M500.208,236.915L30.875,2.248C22.32-2.019,11.952-0.099,5.51,7.048c-6.443,7.125-7.317,17.643-2.176,25.749l142.037,223.211L3.334,479.219c-5.141,8.107-4.267,18.624,2.176,25.749c4.139,4.608,9.941,7.04,15.829,7.04c3.243,0,6.507-0.725,9.536-2.24l469.333-234.667c7.232-3.627,11.797-11.008,11.797-19.093S507.44,240.541,500.208,236.915z" style="stroke:red;stroke-width:2px;fill:none;" transform="rotate(' + heading + ', 16, 16)"/></g></g></svg>',
                      icon: '<svg xmlns="http://www.w3.org/2000/svg" height="32" width="32"><path d="M10.5 31 L16 16 21.5 31" style="stroke:red;stroke-width:2px;fill:none;" transform="rotate(' + heading + ', 16, 16)"/></svg>',
                      anchor: new Microsoft.Maps.Point(16, 16),
                  });
                  
                } */
                //Store the instruction information in the metadata.
                pin.metadata = {
                    description: points[key].timestamp + '\n' +((undefined !== points[key].status) ? points[key].status : ''),
                    infoboxOffset: new Microsoft.Maps.Point(-30, 25)
                };
      
                wp.push(pin);
                count++;  
              }
              
            }
      
          //Reverse the order of the pins so that when rendered the last waypoints in the route are on top.
          wp.reverse();
          //Add the pins to the map. 
          directionWaypointLayer.add(wp);
      
          // Add route line
          var line = new Microsoft.Maps.Polyline(trip, {
              strokeColor: routeColor,
              strokeThickness: 5
          });
          //Add the polyline to map
          map.entities.push(line);
          if (index === 0) {
            var bounds = Microsoft.Maps.LocationRect.fromLocations(trip);
            map.setView({bounds:bounds, padding: 100});
          }
            
          dragElement(document.getElementById('mydiv'+index));
      }

      function dragElement(elmnt) {
        console.log(elmnt);
          var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
          if (document.getElementById(elmnt.id + 'header')) {
            /* if present, the header is where you move the DIV from:*/
            document.getElementById(elmnt.id + 'header').onmousedown = dragMouseDown;
          } else {
            /* otherwise, move the DIV from anywhere inside the DIV:*/
            elmnt.onmousedown = dragMouseDown;
          }
    
          function dragMouseDown(e) {
            e = e || window.event;
            e.preventDefault();
            // get the mouse cursor position at startup:
            pos3 = e.clientX;
            pos4 = e.clientY;
            document.onmouseup = closeDragElement;
            // call a function whenever the cursor moves:
            document.onmousemove = elementDrag;
          }
    
          function elementDrag(e) {
            e = e || window.event;
            e.preventDefault();
            // calculate the new cursor position:
            pos1 = pos3 - e.clientX;
            pos2 = pos4 - e.clientY;
            pos3 = e.clientX;
            pos4 = e.clientY;
            // set the element's new position:
            elmnt.style.top = (elmnt.offsetTop - pos2) + 'px';
            elmnt.style.left = (elmnt.offsetLeft - pos1) + 'px';
          }
    
          function closeDragElement() {
            /* stop moving when mouse button is released:*/
            document.onmouseup = null;
            document.onmousemove = null;
          }
        }
        
      });
    }  
  }

  ngOnDestroy(){
    if(this.bingMapSubscription){
      this.bingMapSubscription.unsubscribe();
    }
  }
}
