import { Component, OnInit, ViewChild } from '@angular/core';
import { PlanService } from 'src/app/services/plan/plan.service';
import { MatTableDataSource, MatPaginator, MatDialog } from '@angular/material';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { TokenStorage } from 'src/app/auth/token.storage';
import { GenericDialogBox } from '../edittripmodal/edittripmodal.component';
import { NotifierService } from 'angular-notifier';
import { PlandeviationsComponent } from '../../plandeviations/plandeviations.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-allplans',
  templateUrl: './allplans.component.html',
  styleUrls: ['./allplans.component.scss']
})
export class AllplansComponent implements OnInit {

  columnsToDisplay = [
    'planName',
    'createdDate',
    'loadDetails',
    'trips',
    'customers',
    'action'
  ];

  readMore = false;
  readTripsMore = false;
  plansData = new MatTableDataSource<PlanRow>();
  totalRecords: number;
  pageSize: number;
  planState = ['PLAN_STATE_IN_PLANNING', 'PLAN_STATE_APPROVED'];
  isGodrej: boolean = false;
  isLoading: boolean = false;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  pageNumber: number;
  fromDate: any;
  toDate: any;
  isHeretech: boolean = false;

  constructor(
    private planService: PlanService,
    private datePipe: DatePipe,
    private router: Router,
    private token: TokenStorage,
    private dialog: MatDialog,
    private notifierService: NotifierService,
    private spinner: NgxSpinnerService
  ) {
    if (this.token.getTenant() == 'godrej-dist-prod') {
      this.isGodrej = true;
    }
    if (this.token.getTenant() == 'heretech-dist-test' || this.token.getTenant() == 'heretech-dist-prod') {
      this.isHeretech = true;
    }
  }

  ngOnInit() {
    this.pageNumber = 0;
    this.getPlansData();
  }

  getPlansData() {
    this.isLoading = true;
    let dateBefore90Days = new Date();
    dateBefore90Days.setDate(dateBefore90Days.getDate() - 30); // Digvijay set 30 instead of 90(original was 90)
    this.toDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.fromDate = this.datePipe.transform(dateBefore90Days, 'yyyy-MM-dd');
    this.plansData.data = [];
    this.planService.getPlansByDateAndState(this.planState, this.pageNumber, this.fromDate, this.toDate).subscribe(res => {
      this.isLoading = false;
      console.log("plans", res);
      let plans: any = res;
      if (res != null) {
        this.plansData.data = plans.content;
        this.pageSize = plans.pageable.pageSize;
        this.totalRecords = plans.totalElements;
        for (let i = 0; i < this.plansData.data.length; i++) {
          let jobCount = 0;
          if (this.plansData.data[i].tripList && this.plansData.data[i].tripList.length > 0) {
            this.plansData.data[i].tripList.forEach(trip => {
              if (trip.jobsList && trip.jobsList.length > 0) {
                trip.jobsList.forEach(job => {
                  jobCount = jobCount + 1;
                  job.jobCount = jobCount;
                });
              }
            });
          }

          this.plansData.data[i].readTripsMore = false;
          this.plansData.data[i].readCustomersMore = false;

          if (this.plansData.data[i].pState == 'PLAN_STATE_APPROVED') {
            this.plansData.data[i].pState = 'Plan Approved';
          } else {
            this.plansData.data[i].pState = 'Plan Not Approved';
          }
        }
      }
    }, Error => {
      this.isLoading = false;
    });
  }

  pageChanged(event: any) {
    this.pageNumber = event.pageIndex;
    this.getPlansData();
  }

  logViewPlan(plan) {
    let editDialog = this.dialog.open(PlandeviationsComponent, {
      width: '85%',
      maxWidth: '85vw',
      data: {
        data: plan
      },
      id: 'planDeviationDetails'
    });
    editDialog.updatePosition({
      top: '20px',
    });
    editDialog.afterClosed().subscribe(result => {
      console.log(result);
    });
  }

  editPlan(plan: number) {
    this.router.navigate(['/scheduling/edittrips', { planId: plan }]).then(res => {
      //console.log(res);
    }, error => {
      console.log(error);
    })
  }

  deletePlan(planName, planId, approvedTrips) {
    console.log(planId)
    if (approvedTrips <= 0) {
      const dialogR = this.dialog.open(GenericDialogBox, {
        width: '300px',
        data: 'Are you sure you want to delete this plan ' + planName + '?'
      });

      dialogR.afterClosed().subscribe(result => {
        if (result) {
          this.plansData.data = [];
          this.spinner.show();
          this.dialog.closeAll();
          this.planService.deletePlan(planId).subscribe(res => {
            this.spinner.hide();
            this.notifierService.notify("success", "Plan deleted successfully.");
            this.getPlansData();
          }, error => {
            this.spinner.hide();
            this.notifierService.notify("error", "Error deleting plan try again.");
            console.log(error);
          });
        }
      });
    }
  }
}

export interface PlanRow {
  readCustomersMore: boolean;
  readMore: boolean;
  readTripsMore: boolean;
  planName: string,
  planCreatedDateTime: string;
  totalNumberOfContainers: number;
  updatedByusername: string;
  pState: string;
  tripList: any;
}

