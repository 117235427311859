import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-managegeofence',
  templateUrl: './managegeofence.component.html',
  styleUrls: ['./managegeofence.component.scss']
})
export class ManagegeofenceComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
