import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { FleetService } from '../../../services/fleet/fleet.service';
import { MatSnackBar, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { TransporterService } from '../../../services/transporter/transporter.service';
import { Router } from '@angular/router';
import { StoreUserDetails } from 'src/app/auth/storeuserdetails';
import { DatePipe } from '@angular/common';
import { ValidatorService } from 'src/app/services/validator/validator.service';


@Component({
  selector: 'app-editfleet',
  templateUrl: './editfleet.component.html',
  styleUrls: ['./editfleet.component.scss']
})
export class EditfleetComponent implements OnInit {
  fleetForm: FormGroup;
  alreadySelectedtransporterName: string;
  alltransporters = [];
  selectedvehicletype: any;
  maxDate: Date;
  ownershipList: any[];
  configOwnership = {
    height: '40vh',
    search: true
  }
  isEdited: boolean = false;

  constructor(
    private matDialog: MatDialog,
    public fleetService: FleetService,
    formBuilder: FormBuilder,
    public snackBar: MatSnackBar,
    public transporterservice: TransporterService,
    private router: Router,
    private userDetails: StoreUserDetails,
    private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: any

  ) {
    this.fleetForm = formBuilder.group({
      'vehicleType': [null, [
        Validators.required,
      ]],
      'vehicleLicenseNumber': [null],
      'vehicleIdNumber': [null, [
        Validators.pattern("^[[A-Za-z0-9 ]+$"),
       ValidatorService.whitespaceValidator
      ]],
      'vehicleRegistrationDate': [null],
      'carryingType': [null],
      'transporter': [null,
        Validators.required
      ],
      'ownership': [null],
      'bIsActive': [null],
      'creationDateTime': [null],
      'fState': [null],
      'fleetId': [null],
      'preferedDriver': [null],
      'updatedByusername': [null],
      'updatedDateTime': [null],
      'trackingType':[null]
    })
  }
  openSnackBar(message) {
    this.snackBar.open(message, "X", {
      duration: 100000,
    });
  }

  ngOnInit() {
    this.getOwnershipList();
    this.maxDate = new Date();
    this.fleetForm.controls['vehicleLicenseNumber'].disable();
    this.fleetForm.controls['vehicleRegistrationDate'].disable();
    this.fleetForm.controls['transporter'].disable();
    this.fleetForm.controls['vehicleType'].disable();

    console.log(this.data);
    this.data = this.data.licenseNumber;
    if(this.data.vehicleType.vehicleType && this.data.vehicleType.vehicleTypeCode)
    this.selectedvehicletype = this.data.vehicleType.vehicleType + "-" + this.data.vehicleType.vehicleTypeCode;
    else
    this.selectedvehicletype = this.data.vehicleType.vehicleType;

    if (this.data.transporterName)
      this.alreadySelectedtransporterName = this.data.transporterName;
    this.fleetForm.patchValue({
      carryingType: this.data.carryingType,
      vehicleIdNumber: this.data.vehicleIdNumber,
      vehicleLicenseNumber: this.data.vehicleLicenseNumber,
      vehicleRegistrationDate: this.data.vehicleRegistrationDate,
      vehicleType: this.data.vehicleType,
      transporter: this.data.transporter,
      ownership: this.data.ownership,
      bIsActive: this.data.bIsActive,
      creationDateTime: this.data.creationDateTime,
      fState: this.data.fState,
      fleetId: this.data.fleetId,
      preferedDriver: this.data.preferedDriver,
      updatedByusername: this.userDetails.getUser(),
      updatedDateTime: this.data.updatedDateTime,
      trackingType: this.data.trackingType
    })
  }

  titleCase(str) {
    let words;
    console.log(str.toString());
    words = str.toLowerCase().split(' ');

    for (var i = 0; i < words.length; i++) {
      var letters = words[i].split('');
      letters[0] = letters[0].toUpperCase();
      words[i] = letters.join('');
    }
    return words.join(' ');
  }

  getOwnershipList() {
    this.fleetService.getAllFleetOwnership().subscribe(ownershipList => {
      this.ownershipList = ownershipList;
    })
  }
  editFleet() {
    this.fleetForm.value.transporter = this.data.transporter;
    this.fleetForm.value.vehicleType = this.data.vehicleType;
    this.fleetForm.value.vehicleRegistrationDate = this.datePipe.transform(this.fleetForm.controls['vehicleRegistrationDate'].value, 'yyyy-MM-dd');
    this.fleetForm.value.vehicleLicenseNumber = this.data.vehicleLicenseNumber;
    console.log("fleetform value", this.fleetForm.value)
    this.isEdited = true;
    this.fleetService.updateFleet(this.fleetForm.value).subscribe(res => {
      console.log(res);
      this.matDialog.getDialogById('editFleetWindow').close(true)
      this.openSnackBar("Fleet: " + this.fleetForm.value.vehicleLicenseNumber + " updated successfully");
    }, Error => {
      console.log(Error);
    })
  }

  noWhitespaceValidator(control: FormControl) {
    let isWhitespace;
    if (control && control.value && (control.value.length > 0)) {
      if (control.value.toString().trim().length === 0)
        isWhitespace = true;
      else
        isWhitespace = false;
    }
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
}
