import { Component, ViewChild, TemplateRef } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { TripService } from 'src/app/services/trip/trip.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { PlanService } from 'src/app/services/plan/plan.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotifierService } from 'angular-notifier';
import { GenericDialogBox, GenericDialogBoxWithReference } from '../edittripmodal/edittripmodal.component';
import { MatDialog } from '@angular/material';
import { FleetService } from 'src/app/services/fleet/fleet.service';
import { AllorderdetailsComponent } from '../../order/allorderdetails/allorderdetails.component';
import { AddjobComponent } from '../addjob/addjob.component';
import { DatePipe } from '@angular/common';
import { TokenStorage } from 'src/app/auth/token.storage';
import { DeleteOrderConfirm } from '../../manageordersandjobs/admin/admin.component';
import { ExcelgeneratorService } from 'src/app/services/excelgenerator/excelgenerator.service';
import { OrderService } from 'src/app/services/order/order.service';


@Component({
  selector: 'app-edittriptable',
  templateUrl: './edittriptable.component.html',
  styleUrls: ['./edittriptable.component.scss'],
  animations: [
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0) scale(1.5)' })),
      state('rotated', style({ transform: 'rotate(-180deg) scale(1.5)' })),
      transition('rotated => default', animate('400ms ease-out')),
      transition('default => rotated', animate('400ms ease-in'))
    ])
  ],
})
export class EdittriptableComponent {
  @ViewChild("mapSection") mapSection: TemplateRef<any>;
  @ViewChild("editTripSection") editTripSection: TemplateRef<any>;
  @ViewChild("costSection") costSection: TemplateRef<any>;

  planId: number;
  planData: any;
  editTripIndex: number = 0;
  tripIndexToOpen: number = 0;
  inputVehicles: any;
  error: boolean = false;
  clickedCardIndex: number;
  warehouseIcon = "../../../../assets/icons/warehouse1.png"
  hasApprovedTrips: boolean;
  isGodrej: boolean = false;
  currencySymbol = "₹";
  volUnit: string;
  tripOpened = [];
  isHeretech: boolean = false;
  isCipla: boolean = false;

  currentSection: TemplateRef<any>;
  selectedTrack: any = [];
  singleTrackSelectedFlag: boolean = false;
  directionDetails: any = [];
  markerDetails: any = [];
  directionTrackDetails: any = [];

  //color object for route
  colorArray = ["0700F9", 'C100F9', 'F90000', '13D505', 'BCB71B',
    'F9008D', '191591', '81380D', 'F9A600', 'BE0B7C',
    'F85F18', '73892E', '3F2472', '7A3F93', '3945A1',
    '3F292B', '134E28', 'AA6000', '03045E', '6F4E00', '4743DE'];

  showContent: boolean = false;
  openedWindow;
  latitude: number;
  longitude: number;
  zoom: number = 10;
  moveJobFlag: boolean = false;
  fromDate: any;
  toDate: any;

  planState = ['PLAN_STATE_IN_PLANNING', 'PLAN_STATE_APPROVED'];
  deleteOrdersId = []
  ngOnInit() {
    // this.currentSection = this.mapSection;
    this.inputVehicles = [];
    this.spinner.show();

    this.fleetService.getVehicleTypes().subscribe(vehicletypes => {
      this.inputVehicles = vehicletypes;
      if (this.inputVehicles && this.inputVehicles.length > 0) {
        this.inputVehicles.sort(function (a, b) {
          let vehicletype1 = a.vehicleType.toLowerCase(), vehicletype2 = b.vehicleType.toLowerCase()
          if (vehicletype1 < vehicletype2) //sort string ascending
            return -1
          if (vehicletype1 > vehicletype2)
            return 1
          return 0
        });
      }
      this.fetchData();
    }, error => {
      this.fetchData();
      console.log(error);
    });
  }

  constructor(
    private tripService: TripService,
    private activatedRoute: ActivatedRoute,
    private planService: PlanService,
    private fleetService: FleetService,
    private spinner: NgxSpinnerService,
    private notifierService: NotifierService,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    private router: Router,
    private token: TokenStorage,
    public excelService: ExcelgeneratorService,
    private orderService: OrderService

  ) {

    if (this.token.getTenant() == 'kimberly-dist-prod') {
      this.volUnit = 'CM';
    }
    else if (this.token.getTenant() == 'godrej-dist-prod' || this.token.getTenant() == 'godrej-dist-test') {
      this.volUnit = 'CCM';
      this.isGodrej = true;
    }
    // else if (this.token.getTenant() == 'cipla-dist-prod' || this.token.getTenant() == 'cipla-dist-test') {
    else if (this.token.getTenant() == 'cipla-dist-prod' || this.token.getTenant() == 'cipla-dist-test'||this.token.getTenant() == 'cipla-dist-test-siliguri') {//Digvijay
      this.volUnit = 'CF';
      this.isCipla = true;
    }
    else if (this.token.getTenant() == 'sa-dist-prod') {
      this.currencySymbol = "R";
    }

    else {
      if (this.token.getTenant() == 'heretech-dist-test' || this.token.getTenant() == 'heretech-dist-prod') {
        this.currencySymbol = "€";
        this.isHeretech = true;
      }
      this.volUnit = 'CF';
    }
  }

  processData(plan: any) {
    console.log("data", plan)
    let trips = [];

    for (let i = 0; i < plan.tripList.length; i++) {
      let jobs = [];
      let totalCost = 0;
      for (let j = 0; j < plan.tripList[i].jobs.length; j++) {
        let customer: string;
        let temp = plan.tripList[i].jobs[j].customerInfo.customerName.split(' ');
        if (temp.length >= 2) {
          customer = temp[0] + ' ' + temp[1];
          // if (plan.tripList[i].jobs[j].customerInfo.address.area) {
          //   customer += ' ' + plan.tripList[i].jobs[j].customerInfo.address.area;
          // } else if (plan.tripList[i].jobs[j].customerInfo.address.zone) {
          //   customer += ' ' + plan.tripList[i].jobs[j].customerInfo.address.zone;
          // }
        } else {
          customer = plan.tripList[i].jobs[j].customerInfo.customerName;
          // if (plan.tripList[i].jobs[j].customerInfo.address.area) {
          //   customer += ' ' + plan.tripList[i].jobs[j].customerInfo.address.area;
          // } else if (plan.tripList[i].jobs[j].customerInfo.address.zone) {
          //   customer += ' ' + plan.tripList[i].jobs[j].customerInfo.address.zone;
          // }
        }
        plan.tripList[i].jobs[j].orders.forEach(function (obj) { obj.isChecked = false; });

        let job = {
          jobId: plan.tripList[i].jobs[j].jobId,
          customerName: customer,
          customerCode: plan.tripList[i].jobs[j].customerInfo.customerCode,
          totalNumberOfOrders: plan.tripList[i].jobs[j].totalNumberOfOrders,
          totalNumberOfBoxes: plan.tripList[i].jobs[j].totalNumberOfContainers,
          orderList: plan.tripList[i].jobs[j].orders,
          customerId: plan.tripList[i].jobs[j].customerInfo.id,
          jobType: plan.tripList[i].jobs[j].jobType,
          vehicleOccupiedVolumePercent: plan.tripList[i].jobs[j].vehicleOccupiedVolumePercent,
          vehicleOccupiedWeightPercent: plan.tripList[i].jobs[j].vehicleOccupiedWeightPercent,
          totalWeight: plan.tripList[i].jobs[j].totalWeight,
          totalVolume: plan.tripList[i].jobs[j].totalVolume,
          customerInfo: plan.tripList[i].jobs[j].customerInfo,
        };

        job.orderList.forEach(order => {
          totalCost += order.totalOrderCost;
        });

        jobs.push(job);
      }

      let vehicleCapacity: any, vehicleVolume: any;
      if (this.inputVehicles) {
        for (let l = 0; l < this.inputVehicles.length; l++) {
          if (plan.tripList[i].vehicleType === this.inputVehicles[l].vehicleType) {
            vehicleCapacity = this.inputVehicles[l].weightCapacity;
            vehicleVolume = this.inputVehicles[l].volumeCapacity;
          }
        }
      }

      let percentToSalesValue;
      if (plan.tripList[i].cost && totalCost)
        percentToSalesValue = (plan.tripList[i].cost / totalCost) * 100
      else
        percentToSalesValue = 0;
      let trip = {
        approved: plan.tripList[i].tState === 'TRIP_STATE_APPROVED' || plan.tripList[i].tState === 'TRIP_STATE_IN_TRANSIT'
          || plan.tripList[i].tState === 'TRIP_STATE_SCHEDULED' || plan.tripList[i].tState === 'TRIP_STATE_COMPLETED' ? true : false,
        tripState: plan.tripList[i].tState,
        tripId: plan.tripList[i].tripId,
        isChecked: false,
        state: 'default',
        totalNumberOfJobs: plan.tripList[i].totalNumberOfJobs,
        tripName: plan.tripList[i].tripName,
        tripTransaction: plan.tripList[i].tripTransaction,
        vehicleType: plan.tripList[i].vehicleType,
        vehicleWeightCapacity: vehicleCapacity,
        vehicleVolumeCapacity: vehicleVolume,
        numberOfCustomers: plan.tripList[i].totalNumberOfJobs,

        originAddress: plan.tripList[i].originAddress,
        destinationAddress: plan.tripList[i].destinationAddress,
        totalNumberOfOrders: plan.tripList[i].totalNumberOfOrders,
        tripZone: plan.tripList[i].tripZone,
        cost: plan.tripList[i].cost != null ? Math.ceil(plan.tripList[i].cost) : '--',
        weightUtilization: plan.tripList[i].totalWeightUtilisation != null ? Math.ceil(plan.tripList[i].totalWeightUtilisation) : '---',
        volumeUtilization: plan.tripList[i].totalVolumeUtilisation != null ? Math.ceil(plan.tripList[i].totalVolumeUtilisation) : '---',
        tripDistance: plan.tripList[i].tripTransaction.plannedDistance != null ? plan.tripList[i].tripTransaction.plannedDistance : '--',
        jobList: jobs,
        weight: plan.tripList[i].totalWeight != null ? Math.ceil(plan.tripList[i].totalWeight) : '--',
        volume: plan.tripList[i].totalVolume != null ? Math.ceil(plan.tripList[i].totalVolume) : '--',
        percentToSalesValue: percentToSalesValue,
        tripStartWeightPercent: plan.tripList[i].tripStartWeightPercent,
        tripStartVolumePercent: plan.tripList[i].tripStartVolumePercent,
        branchName: plan.tripList[i].branchName,
        tripCreatedDateTime: plan.tripList[i].tripCreatedDateTime,
        totalWeight: plan.tripList[i].totalWeight,
        totalVolume: plan.tripList[i].totalVolume
      }

      if (trip.approved) {
        this.hasApprovedTrips = true;
      }

      trips.push(trip);
    }
    trips.sort(function (a, b) {
      var keyA = a.jobList[a.jobList.length - 1].orderList[0].customerInfo.address.city,
        keyB = b.jobList[b.jobList.length - 1].orderList[0].customerInfo.address.city;
      // Compare the 2 cities
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });
    plan.tripList.sort(function (a, b) {
      var keyA = a.jobs[a.jobs.length - 1].orders[0].customerInfo.address.city,
        keyB = b.jobs[b.jobs.length - 1].orders[0].customerInfo.address.city;
      // Compare the 2 cities
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });
    for (let i = 0; i < plan.tripList.length; i++) {
      this.setDirections(plan.tripList[i], i)
    }

    //on load show first track only
    // this.selected_track(0)
    let totalVehicles;
    if (this.inputVehicles) {
      totalVehicles = this.inputVehicles.length;
    }
    this.planData = {
      approved: plan.pState === 'PLAN_STATE_APPROVED' ? true : false,
      planId: plan.planRef,
      planName: plan.planName,
      nextPlanId: plan.nextPlanId,
      prevPlanId: plan.prevPlanId,
      totalNumberOfJobs: plan.totalNumberOfJobs,
      numberOfVehiclesUsed: trips.length,
      totalVehicles: totalVehicles,
      volumeUtilization: plan.totalVolumeUtilisation != null ? Math.ceil(plan.totalVolumeUtilisation) : '--',
      weightUtilization: plan.totalWeightUtilisation != null ? Math.ceil(plan.totalWeightUtilisation) : '--',
      volume: plan.totalVolume != null ? Math.ceil(plan.totalVolume) : 0,
      weight: plan.totalWeight != null ? Math.ceil(plan.totalWeight) : 0,
      totalDeliveries: plan.totalNumberOfDeliveries || 0,
      totalPickups: plan.totalNumberOfPickups || 0,
      totalDistance: plan.totalDistance || 0,
      cost: plan.cost != null ? Math.ceil(plan.cost) : '--',
      tripList: trips,
      inputVehicles: this.inputVehicles,
      planCreatedDateTime: plan.planCreatedDateTime != null ? this.datePipe.transform(plan.planCreatedDateTime, 'dd-MM-yy') : '--',
      branchName: plan.branchName
    }
    if (this.planData.tripList.length - 1 < this.tripIndexToOpen) {
      this.tripIndexToOpen = 0;
      this.editTripIndex = 0;
    }
    this.editTrip(this.tripIndexToOpen)
  }

  openCard(index: number) {
    this.planData.tripList[index].state = this.planData.tripList[index].state === 'default' ? 'rotated' : 'default';
    this.selected_track(index);
    if (this.currentSection === this.editTripSection || this.currentSection === this.costSection) {
      this.currentSection = this.mapSection;
      this.clickedCardIndex = index;
    } else {
      if (this.planData.tripList[index].isChecked) {
        //  this.selected_track(index);
        for (let i = 0; i < this.planData.tripList.length; i++) {
          if (i != index) {
            this.planData.tripList[i].state = 'default';
            this.planData.tripList[i].isChecked = false;
          }
        }
      } else {
        // this.selected_track(index);
        this.singleTrackSelectedFlag = false;
        this.editTrip(index)
        //this.selectedTrack = this.directionDetails;
      }
    }
  }

  customerList(customerNames: any) {
    let customerNameList = [];
    // console.log(cosigneeNames)
    for (let k = 0; k < customerNames.length; k++) {
      if (customerNames[k].orderList[0] && !customerNameList.includes(customerNames[k].orderList[0].customerInfo.address.city)) {
        customerNameList.push(customerNames[k].orderList[0].customerInfo.address.city)
      }
    }
    return customerNameList;
  }


  editTrip(index: number) {
    console.log(this.planData.tripList[index])
    if (this.currentSection === this.mapSection || this.currentSection === this.costSection || !this.currentSection) {
      this.editTripIndex = index;
      this.currentSection = this.editTripSection;
      for (let i = 0; i < this.planData.tripList.length; i++) {
        this.planData.tripList[i].state = 'default';
        this.planData.tripList[i].isChecked = false;
      }
    } else {
      if (this.editTripIndex != null && this.planData.tripList[this.editTripIndex].tripId === this.planData.tripList[index].tripId) {
        // this.currentSection = this.mapSection;
        this.clickedCardIndex = index;
      } else {
        this.editTripIndex = index;
      }
    }
  }

  exportAsXLSX(planData) {
    console.log(planData);
    let tripData = planData.tripList;
    let excelRecords = [];
    tripData.forEach(trip => {
      let jobCustomerNameList = '';
      let i = 0;
      console.log("jobCustomerNameList > " + jobCustomerNameList);
      trip.jobList.forEach(job => {
        if (i == 0) {
          excelRecords.push({
            'From': trip.branchName || '',
            'Trip Name': trip.tripName || '',
            'Trip Created Date': this.datePipe.transform(trip.tripCreatedDateTime, "dd-MM-yyyy hh:mm a") || '',
            'Vehicle Type': trip.vehicleType || '',
            'Vehicle Weight (KG)': trip.vehicleWeightCapacity || '',
            'Vehicle Volume (CFT)': trip.vehicleVolumeCapacity || '',
            'Trip Weight (KG)': trip.weight || '',
            'Trip Volume (CFT)': trip.volume || '',
            'Zone': job.customerInfo.zone.zoneName || '',
            'Customer': job.customerInfo.customerName || '',
            'City': job.customerInfo.address.city || '',
            'Order #': job.totalNumberOfOrders || '',
            'Weight (KG)': job.totalWeight || '',
            'Volume (CFT)': job.totalVolume || '',
            'Qty': job.totalNumberOfBoxes || '',
            'Rate': trip.cost || 0

          });
        }
        else {
          excelRecords.push({
            'From': '',
            'Trip Name': '',
            'Trip Created Date': '',
            'Vehicle Type': '',
            'Vehicle Weight (KG)': '',
            'Vehicle Volume (CFT)':'',
            'Trip Weight (KG)': '',
            'Trip Volume (CFT)': '',
            'Zone': job.customerInfo.zone.zoneName || '',
            'Customer': job.customerInfo.customerName || '',
            'City': job.customerInfo.address.city || '',
            'Order #': job.totalNumberOfOrders || '',
            'Weight (KG)': job.totalWeight || '',
            'Volume (CFT)': job.totalVolume || '',
            'Qty': job.totalNumberOfBoxes || '',
            'Rate': ''
          });
        }
        console.log("sjj", i == trip.jobList.length - 1)
        if (i == trip.jobList.length - 1) {
          excelRecords.push({
            'From': '',
            'Trip Name': '',
            'Trip Created Date': '',
            'Vehicle Type': '',
            'Zone': '',
            'Customer': '',
            'City': '',
            'Order #': '',
            'Weight (KG)': trip.totalWeight || '',
            'Volume (CFT)': trip.totalVolume || '',
            'Qty': '',
            'Rate': ''
          });
        }
        i++;
      });
    });
    this.excelService.exportAsExcelFile(excelRecords, planData.planName + '-Trip-Sheets');
  }

  exportAsXLSX_DJ(planData) {
    console.log(planData);
    let tripData = planData.tripList;
    let excelRecords = [];
    tripData.forEach(trip => {
      let jobCustomerNameList = '';
      let i = 0;
      console.log("jobCustomerNameList > " + jobCustomerNameList);
      trip.jobList.forEach(job => {
        let j = 0;
        job.orderList.forEach(order => {
          if (i == 0) {
            excelRecords.push({
              'From': trip.branchName || '',
              'Trip Name': trip.tripName || '',
              'Trip Created Date': this.datePipe.transform(trip.tripCreatedDateTime, "dd-MM-yyyy hh:mm a") || '',
              'Vehicle Type': trip.vehicleType || '',
              'Vehicle Weight (KG)': trip.vehicleWeightCapacity || '',
              'Vehicle Volume (CFT)': trip.vehicleVolumeCapacity || '',
              'Trip Weight (KG)': trip.weight || '',
              'Trip Volume (CFT)': trip.volume || '',
              'Zone': job.customerInfo.zone.zoneName || '',
              'Customer': job.customerInfo.customerName || '',
              'City': job.customerInfo.address.city || '',
              'Invoice Number': order.orderInvoiceNumber || '',
              'Weight (KG)': order.totalWeight || '',
              'Volume (CFT)': order.totalVolume || '',
              'Qty': order.num_of_containers || '',
              'Rate': trip.cost || 0
            });
          }
          else {
            excelRecords.push({
              'From': '',
              'Trip Name': '',
              'Trip Created Date': '',
              'Vehicle Type': '',
              'Vehicle Weight (KG)': '',
              'Vehicle Volume (CFT)':'',
              'Trip Weight (KG)': '',
              'Trip Volume (CFT)': '',
              'Zone': job.customerInfo.zone.zoneName || '',
              'Customer': job.customerInfo.customerName || '',
              'City': job.customerInfo.address.city || '',
              'Invoice Number': order.orderInvoiceNumber || '',
              'Weight (KG)': order.totalWeight || '',
              'Volume (CFT)': order.totalVolume || '',
              'Qty': order.num_of_containers || '',
              'Rate': ''
            });
          }
          j++;
        });

        console.log("sjj", i == trip.jobList.length - 1)
        if (i == trip.jobList.length - 1) {
          excelRecords.push({
            'From': '',
            'Trip Name': '',
            'Trip Created Date': '',
            'Vehicle Type': '',
            'Zone': '',
            'Customer': '',
            'City': '',
            'Invoice Number': '',
            'Weight (KG)': trip.totalWeight || '',
            'Volume (CFT)': trip.totalVolume || '',
            'Qty': '',
            'Rate': ''
          });
        }
        i++;
      });
    });
    this.excelService.exportAsExcelFile(excelRecords, planData.planName + '-Trip-Sheets');
  }



  saveTrip() {
    this.notifierService.notify("success", "Changes saved");
  }

  approveTrip(index: number) {
    const dialogR = this.dialog.open(GenericDialogBox, {
      width: '300px',
      data: 'Are you sure you want to approve this trip ' + this.planData.tripList[index].tripName + '?'
    });
    dialogR.afterClosed().subscribe(result => {
      if (result) {
        this.dialog.closeAll();
        this.spinner.show();
        this.tripService.approveTrip(this.planData.tripList[index].tripId).subscribe(() => {
          this.spinner.hide();
          this.notifierService.notify("success", "Trip has been successfully approved.");
          this.planData.tripList[index].approved = true;
        }, error => {
          this.spinner.hide();
          this.notifierService.notify("error", "Error approving trip. Please try again.");
          console.log(error);
        });
      }
    });
  }

  vehicleTypeChanged(value) {
    console.log(value);
    let oldVal = this.planData.tripList[this.editTripIndex].vehicleType;
    const dialogR = this.dialog.open(GenericDialogBoxWithReference, {
      width: '400px',
      data: 'Are you sure change vehicle ' + oldVal + ' to ' + value + '?'
    });
    dialogR.afterClosed().subscribe(result => {
      if (result) {
        let vehicleTypeObj = {}
        if (this.inputVehicles) {
          this.inputVehicles.forEach(vehicle => {
            if (vehicle.vehicleType === value) {
              vehicleTypeObj = vehicle;
            }
          });
        }
        console.log(vehicleTypeObj);

        this.planData.tripList[this.editTripIndex].vehicleType = value;
        this.tripService.assignVehicleTypeToTrip(this.planData.tripList[this.editTripIndex].tripId, vehicleTypeObj, result).subscribe(() => {
          this.notifierService.notify("success", "Vehicle type changed successfully.");
          this.fetchData();
        }, error => {
          if (error.error.Result) {
            this.notifierService.notify("error", error.error.Result);
          }
          else {
            this.notifierService.notify("error", "Error changing vehicle type. Please try again.");
          }

          this.planData.tripList[this.editTripIndex].vehicleType = oldVal;
          console.log(error);
        });
      }
      else {
        this.fetchData();
      }
    });
  }

  deleteTrip() {
    const dialogR = this.dialog.open(GenericDialogBoxWithReference, {
      width: 'fit-content',
      data: 'Are you sure want to delete trip ' + this.planData.tripList[this.editTripIndex].tripName + ' ?'
    });

    dialogR.afterClosed().subscribe(result => {
      if (result) {
        console.log(result)
        this.dialog.closeAll();
        this.spinner.show();
        this.tripService.deleteTrip(this.planData.tripList[this.editTripIndex].tripId, result).subscribe(() => {
          this.spinner.hide();
          this.notifierService.notify("success", "Trip has been successfully deleted.");
          this.fetchData();
          this.currentSection = this.mapSection;
        }, error => {
          this.spinner.hide();
          this.notifierService.notify("error", "Error deleting trip. Please try again.");
          console.log(error);
        });
      }
    });
  }



  deleteJob(jobId: number) {

    const dialogR = this.dialog.open(GenericDialogBox, {
      width: '300px',
      data: 'Are you sure you want to delete this job' + jobId + '?'
    });

    dialogR.afterClosed().subscribe(result => {
      if (result) {
        this.dialog.closeAll();
        this.spinner.show();
        let tripId = this.planData.tripList[this.editTripIndex].tripId;
        this.tripService.deleteJobFromTrip(tripId, jobId).subscribe(() => {
          this.spinner.hide();
          this.notifierService.notify("success", "Job deleted successfully.");
          this.fetchData();
        }, error => {
          this.spinner.hide();
          this.notifierService.notify("error", "Could not delete job, please try again");
          console.log(error);
        });
      }
    });
  }

  approvePlan() {
    this.spinner.show();
    this.planService.approvePlan(this.planData.planId).subscribe(() => {
      this.spinner.hide();
      this.notifierService.notify("success", "All trips are approved and assigned to warehouse.");
      this.fetchData();
    }, error => {
      this.spinner.hide();
      this.notifierService.notify("error", "Error approving plan. Please try again.");
      console.log(error);
    });
  }

  fetchData() {
    this.spinner.show();
    this.planData = null;
    this.tripIndexToOpen = this.editTripIndex;
    this.activatedRoute.params.subscribe((param: Params) => {
      this.planId = param['planId'];
      if (this.planId != null) {
        this.planService.getPlanById(this.planId).subscribe(plan => {
          this.moveJobFlag = false;
          this.spinner.hide();
          try {
            this.processData(plan);
            this.error = false;
          } catch (err) {
            console.log("catch1")
            this.error = true;
          }
        }, error => {
          console.log(error);
          this.error = true;
          console.log("catch2")
          this.moveJobFlag = false;

          this.spinner.hide();
        });
      } else {
        this.planService.getCurrentPlan().subscribe(res => {
          console.log(res);
          this.moveJobFlag = false;

          this.spinner.hide();
          try {
            this.processData(res);
            this.error = false;
          } catch (err) {
            this.error = true;
            console.log("catch3")
          }
        }, error => {
          this.error = true;
          this.moveJobFlag = false;

          this.spinner.hide();
          console.log("catch4")
          console.log(error);
        });
      }
    });
  }



  nextPlanShow() {
    let planIdNumber = this.planData.nextPlanId;//this.allplanList[this.previousIndex].planId;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/scheduling/edittrips', { planId: planIdNumber }]).then(() => {
        //console.log(res);
      }, error => {
        console.log(error);
      })
    });
  }

  lastPlanShow() {
    let planIdNumber = this.planData.prevPlanId;//this.allplanList[this.nextIndex].planId;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/scheduling/edittrips', { planId: planIdNumber }]).then(() => {
        //console.log(res);
      }, error => {
        console.log(error);
      })
    });
  }

  openMap() {
    console.log(this.selectedTrack)
    if (this.currentSection === this.mapSection) {
      this.showContent = true;
    }
    else {
      this.selectedTrack = this.directionDetails;
      this.currentSection = this.mapSection;
    }
  }

  openOrderDetails(jobIndex: number, orderIndex: number) {
    console.log(this.planData.tripList[this.editTripIndex])
    let data = this.planData.tripList[this.editTripIndex].jobList[jobIndex].orderList[orderIndex];
    data.tripRefId = this.planData.tripList[this.editTripIndex].tripId;
    data.tripRefName = this.planData.tripList[this.editTripIndex].tripName;
    if (!window.sessionStorage.getItem('orderDetailCount'))
      window.sessionStorage.setItem('orderDetailCount', '0')
    else {
      let count = Number(window.sessionStorage.getItem('orderDetailCount')) + 1;
      window.sessionStorage.setItem('orderDetailCount', count.toString())
    }
    let editDialog = this.dialog.open(AllorderdetailsComponent, {
      width: '90%',
      maxWidth: '90vw',
      data: {
        data: data,
        component: 'EditPlan'
      },
      id: 'orderDetail' + window.sessionStorage.getItem('orderDetailCount')
    });
    editDialog.updatePosition({
      top: '50px',
    });
    editDialog.afterClosed().subscribe(result => {
      console.log(result);
    });
  }

  openCostSection() {
    this.currentSection = this.costSection;
    console.log(this.planData)
    for (let i = 0; i < this.planData.tripList.length; i++) {
      this.planData.tripList[i].state = 'default';
      this.planData.tripList[i].isChecked = false;
    }
  }

  deletePlan() {
    const dialogR = this.dialog.open(GenericDialogBox, {
      width: '300px',
      data: 'Are you sure you want to delete this plan' + this.planData.planName + '?'
    });

    dialogR.afterClosed().subscribe(result => {
      if (result) {
        this.dialog.closeAll();
        this.spinner.show();
        this.planService.deletePlan(this.planData.planId).subscribe(() => {
          this.spinner.hide();
          this.router.navigate(['/scheduling/allplans']).then(res => {
            console.log(res);
          }, error => {
            this.spinner.hide();
            console.log(error);
          });
          this.notifierService.notify("success", "Plan deleted successfully.");
        }, error => {
          this.spinner.hide();
          this.notifierService.notify("error", "Error deleting plan try again.");
          console.log(error);
        });
      }
    });
  }




  /********************  
   * Developer : Swapnil Sawant (CoReCo Technologies)
   * Date : 23 Oct 2020
   * Code start from here for drag and drop functionality 
   * ********************/

  // use this function when drag end of div 
  dragEnd() {

  }

  //called this function when Job or Order drag one from another trip
  droppedData(draggedTrip, draggedJobData) {

    console.log(draggedTrip)
    console.log("----------------")
    console.log(draggedJobData)

    if (draggedJobData.tripApproved) {
      this.notifierService.notify("error", "Trip already approved! You can't drag this job / order.");
      return false;
    }

    let draggedJob = JSON.parse(JSON.stringify(draggedJobData));
    console.log("draggedJob:");
    console.log(draggedJob);
    console.log("draggedTrip:");
    console.log(draggedTrip);
    
    //If dropped order in other trip
    if (draggedTrip.tripId != draggedJobData.draggableTripId) {
      if (draggedJob.flag == 'order') {

        const dialogR = this.dialog.open(GenericDialogBoxWithReference, {
          width: 'fit-content',
          data: 'Are you sure to move Order - ' + draggedJob.order.orderInvoiceNumber + ' of "' + draggedJob.job.customerName + '" from Trip-' + draggedJob.draggableTripId + ' to Trip-' + draggedTrip.tripId + '?'
        });



        dialogR.afterClosed().subscribe(result => {
          if (result) {
            this.spinner.show();
            // check job is present or not in trip
            let index = draggedTrip.jobList.findIndex(p => p.customerId == draggedJob.order.customerInfo.id);

            //If job is present there
            if (index != -1) {
              draggedTrip.jobList[index].orderList.push(draggedJob.order)
            }
            //If job is not present in trip
            else {
              draggedJob.job.orderList = [];
              draggedJob.job.orderList.push(draggedJob.order);
              draggedTrip.jobList.push(draggedJob.job);
            }
            //After order pushed removed old one
            this.planData.tripList[this.editTripIndex].jobList[draggedJob.selectedJobIndex].orderList.splice(draggedJob.selectedOrderIndex, 1);

            //moves order in trip
            this.tripService.moveOrdersInTrip(draggedTrip.tripId, draggedJob.order.orderUniqueId, result).subscribe(res => {
              this.spinner.hide();
              // API Success here
              console.log(res);
              //notify success msg here
              this.notifierService.notify("success", "Order moved successfully.");


              //Refresh page here
              this.ngOnInit();

            }, Error => {
              this.spinner.hide();
              if (Error.status == 400) {
                this.notifierService.notify("error", Error.error.Error)
              }
              else {
                this.notifierService.notify("error", 'Error in moving order.')
              }
              this.ngOnInit()
              //Api error shows in console
              console.log(Error);
            })

            //If all orders removed then remove job from there the trip
            if (this.planData.tripList[this.editTripIndex].jobList[draggedJob.selectedJobIndex].orderList.length == 0) {
              this.planData.tripList[this.editTripIndex].jobList.splice(draggedJob.selectedJobIndex, 1);
            }
          }
        });
      }
      //If droped job in other other trip
      else if (draggedJob.flag == 'job') {
        const dialogR = this.dialog.open(GenericDialogBoxWithReference, {
          width: 'fit-content',
          data: 'Are you sure to move job of "' + draggedJob.job.customerName + '" from Trip-' + draggedJob.draggableTripId + ' to Trip-' + draggedTrip.tripId + '?'
        });



        dialogR.afterClosed().subscribe(result => {
          if (result) {

            console.log(result)




            // if(totalJobWeight > totalCapacityOfWeight || totalJobVolume > totalCapacityOfVolume)
            // {
            //   this.notifierService.notify("error", "Vehicle weight/volume capacity exceeded.");
            //   return false;
            // }

            // if(totalJobVolume > totalCapacityOfVolume)
            // {
            //   this.notifierService.notify("error", "Vehicle volume capacity crossed.");
            //   return false;
            // }
            // End code for validation of volume

            //check job is present or not in trip
            let index = draggedTrip.jobList.findIndex(p => p.customerId == draggedJob.job.customerId);
            //If job is present in trip
            if (index != -1) {
              // console.log("job is present");
              for (let i = 0; i < draggedJob.job.orderList.length; i++) {
                draggedTrip.jobList[index].orderList.push(draggedJob.job.orderList[i]);
              }
            }
            //If job is not present in trip
            else {
              draggedTrip.jobList.push(draggedJob.job)
            }

            this.planData = null;
            this.spinner.show();
            this.moveJobFlag = true;
            // Rest api call for moved job in other trip
            this.tripService.moveJobsInTrip(draggedTrip.tripId, draggedJobData.job.jobId, result).subscribe(res => {
              // API Success here
              console.log(res);
              //notify success msg here
              this.notifierService.notify("success", "Job moved successfully.");


              //Refresh page here
              this.ngOnInit();

            }, Error => {
              this.spinner.hide();
              if (Error.status == 400) {
                this.notifierService.notify("error", Error.error.Error)
              }
              else {
                this.notifierService.notify("error", 'Error in moving job.')
              }
              this.ngOnInit()
              //Api error shows in console
              console.log(Error);
            })
            //Removed old one job which moved in another trip
            if (this.planData) {
              this.planData.tripList[this.editTripIndex].jobList.splice(draggedJob.selectedJobIndex, 1);
            }
          }
        });
      }
    }
    else {
      this.notifierService.notify("error", "You can't drop job/order in same trip.");
    }
  }

  /********************  
 * Developer : Swapnil Sawant (CoReCo Technologies)
 * Date : 23 Oct 2020
 * Code end here for drag and drop functionality 
 * ********************/


  addJob() {
    let addJobDialog = this.dialog.open(AddjobComponent, {
      width: '50%',
      height: '95%',
    });

    const subscription = addJobDialog.componentInstance.addJob.subscribe(res => {
      let tripId = this.planData.tripList[this.editTripIndex].tripId;
      this.planData = null;
      this.spinner.show();
      this.tripService.addJobsInTrip(tripId, res.orders).subscribe(() => {
        this.notifierService.notify("success", "Job added successfully");
        this.fetchData();
      }, error => {
        console.log(error);
        this.fetchData();
        this.notifierService.notify("error", error.error.Error);
      });
    });

    addJobDialog.afterClosed().subscribe(() => {
      subscription.unsubscribe();
    });
  }

  deleteOrderFromTrip(orderId, jobId) {
    const delOrder = this.dialog.open(DeleteOrderConfirm, {
      width: '300px',
      data: 'Are you sure you want to delete order?'
    });

    delOrder.afterClosed().subscribe(result => {
      localStorage.setItem('index', this.editTripIndex.toString())
      console.log(result)
      if (result) {
        {
          this.spinner.show();
          console.log(this.planData.tripList[this.editTripIndex].tripId, orderId)
          this.tripService.deleteSingleOrderFromTrip(orderId, jobId).subscribe(res => {
            this.spinner.hide();
            console.log(res);
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
              this.router.navigate(['/scheduling/edittrips', { planId: this.planData.planId }]));
          }, Error => {
            this.spinner.hide();
            console.log(Error);
          })
        }
      }
    });
  }



  //map integration code
  setDirections(item, i) {
    //condition for show short trips on map
    /*  if(i==0)
       { */
    let routeData = item.route.jobs;
    let routeDetails = [];
    let routeMarker = [];
    let routes = []
    let waypointsData = [];

    if (i != 0) {
      let originAdd;
      if (item.originAddress) {
        originAdd = (item.originAddress).toString()
      }
      else {
        originAdd = " "
      }
      if (item.origin) {
        this.markerDetails.push(
          {
            'lat': item.origin.coordinates[0],
            'lng': item.origin.coordinates[1],
            'iconurl': `../../../../assets/images/IoT/icons/${this.colorArray[i]}-start.png`,
            "title": originAdd,
            "name": " "
          }
        );
      }
    }
    for (let j = 0; j < routeData.length; j++) {
      let data = routeData[j];
      //onload markers
      this.markerDetails.push(
        {
          'lat': data.latitude,
          'lng': data.longitude,
          'iconurl': `../../../../assets/images/IoT/icons/${this.colorArray[i]}-route.png`,
          "name": (j + 1).toString(),
          "title": item.jobs[j].customerInfo.customerName,
        }
      );

      waypointsData.push(
        {
          'lat': data.latitude,
          'lng': data.longitude,
          'iconurl': `../../../../assets/images/IoT/icons/${this.colorArray[i]}-route.png`,
          "name": (j + 1).toString(),
          "title": item.jobs[j].customerInfo.customerName
        }
      );
      //route location added here
      routeDetails.push(
        {
          location: { lat: data.latitude, lng: data.longitude }, stopover: false,
        }
      );
      //route marker generate here
      routeMarker.push(
        {
          infoWindow: '<h5 class="infwin' + j + '">' + item.jobs[j].customerInfo.customerName + '<h5>',
          icon: `../../../../assets/images/IoT/bus.png`,
          label: {
            color: "#fff",
            fontSize: "11px",
            fontWeight: "700",
            text: (j + 1).toString()
          }
        }
      );
      //route information added here
      routes.push(
        {
          lat: data.latitude,
          lng: data.longitude,
          name: item.jobs[j].customerInfo.customerName,
          code: item.jobs[j].customerInfo.customerCode,
          jobId: item.jobs[j].jobId,
          numberOfPickups: item.jobs[j].numberOfPickups,
          numberOfDeliveries: item.jobs[j].numberOfDeliveries
        }
      );
    };
    if (i != 0) {
      if (item.destinationAddress) {
      }
      else {
      }

    }
    let originLat, originLng, destLat, destLng;
    if (item.origin) {
      originLat = item.origin.coordinates[0];
      originLng = item.origin.coordinates[1];
    }

    if (item.destination) {
      destLat = waypointsData[waypointsData.length - 1].lat;//item.destination.coordinates[0];
      destLng = waypointsData[waypointsData.length - 1].lng;//item.destination.coordinates[1];
    }
    //Generate final tracking details array here for only onload data
    this.directionDetails.push(
      {
        'tripId': item.tripId,
        'tripName': item.tripName,
        'vehicleType': item.vehicleType,
        'plannedTravelTime': item.tripTransaction.plannedTravelTime,
        'plannedDistance': item.tripTransaction.plannedDistance,
        'cost': item.cost,
        "routeColor": "#" + this.colorArray[i],
        'originAddress': item.originAddress || " ",
        'origin':
        {
          'lat': originLat,
          'lng': originLng
        },
        'destinationAddress': item.destinationAddress,
        'destination':
        {
          'lat': destLat,
          'lng': destLng
        },
        "renderOptions": { polylineOptions: { strokeColor: `#${this.colorArray[i]}` }, suppressMarkers: true },
        "waypoints": routeDetails,
        "waypointsData": waypointsData,
        "routes": routes,
        "markerOptions":
        {
          origin:
          {
            // icon: `../../../../assets/images/IoT/icons/${this.colorArray[i]}-start.png`,
            icon: `../../../../assets/images/IoT/icons/warehouse.png`,
            infoWindow: '<h5>' + item.originAddress + '<h5>'
          },
          waypoints: routeMarker,
          // destination:
          // {
          //   icon: `../../../../assets/images/IoT/icons/${this.colorArray[i]}-end.png`,
          //   opacity: 0.8,
          //   infoWindow: '<h5>' + item.destinationAddress + '<h5>',
          // }

        }
      }
    );

    //Generate final tracking details array here for only track selection
    this.directionTrackDetails.push(
      {
        'tripId': item.tripId,
        'tripName': item.tripName,
        'vehicleType': item.vehicleType,
        'plannedTravelTime': item.tripTransaction.plannedTravelTime,
        'plannedDistance': item.tripTransaction.plannedDistance,
        'cost': item.cost,
        "routeColor": "#" + this.colorArray[i],
        'originAddress': item.originAddress || " ",
        'origin':
        {
          'lat': originLat,
          'lng': originLng
        },
        'destinationAddress': item.destinationAddress,
        'destination':
        {
          'lat': originLat,
          'lng': originLng
        },
        "renderOptions": { polylineOptions: { strokeColor: `#${this.colorArray[i]}` }, suppressMarkers: true },
        "waypoints": routeDetails,
        "waypointsData": waypointsData,
        "routes": routes,
        "markerOptions":
        {
          origin:
          {
            icon: `../../../../assets/images/IoT/icons/warehouse.png`,
            infoWindow: '<h5>' + item.originAddress + '<h5>'
          },
          waypoints: routeMarker,
          // destination:
          // {
          //   icon: `../../../../assets/images/IoT/icons/${this.colorArray[i]}-end.png`,
          //   opacity: 0.8,
          //   infoWindow: '<h5>' + item.destinationAddress + '<h5>',
          // }

        }
      }
    );
    // }
    this.selectedTrack = JSON.parse(JSON.stringify(this.directionDetails));
    /*   console.log(this.directionDetails)
      console.log(this.markerDetails) */
  }

  //function for single trip selection
  selected_track(i) {
    this.singleTrackSelectedFlag = true;
    this.selectedTrack = [];
    this.showContent = false;
    this.selectedTrack.push(this.directionTrackDetails[i]);
  }

  closeWindow() {
    this.openedWindow = null;
  }

  isInfoWindowOpen(id) {

    return this.openedWindow == id;
  }

  openWindow(data, i) {
    if (this.openedWindow != i) {
      this.zoom = 10;
      this.latitude = data[0].lat;
      this.longitude = data[0].lng;
      console.log("data", data)
      setTimeout(() => {
        this.openedWindow = i;
      }, 100);
    }
    else {
      this.latitude = null;
      this.longitude = null;
      this.openedWindow = null;
      this.zoom = 10;
    }
  }

  deleteMultipleOrders(event, orderIndex, jobindex) {
    if (event.checked) {
      this.planData.tripList[this.editTripIndex].jobList[jobindex].orderList[orderIndex].isChecked = true;
      this.deleteOrdersId.push(this.planData.tripList[this.editTripIndex].jobList[jobindex].orderList[orderIndex].orderUniqueId)
    }
    else {
      this.planData.tripList[this.editTripIndex].jobList[jobindex].orderList[orderIndex].isChecked = false;
      let index = this.deleteOrdersId.indexOf(this.planData.tripList[this.editTripIndex].jobList[jobindex].orderList[orderIndex].orderUniqueId)
      if (index > -1) {
        this.deleteOrdersId.splice(index)
      }
    }
    console.log(this.deleteOrdersId)
  }


  deleteOrders() {
    const delOrder = this.dialog.open(DeleteOrderConfirm, {
      width: '300px',
      data: 'Are you sure you want to delete orders?'
    });

    delOrder.afterClosed().subscribe(result => {
      console.log(result)
      if (result) {
        {
          this.spinner.show();
          this.orderService.deleteOrders(this.deleteOrdersId).subscribe(res => {
            this.notifierService.notify("success", "Orders has been successfully deleted.");
            this.fetchData();
          }, Error => {
            console.log(Error);
            this.spinner.hide();
            this.notifierService.notify("Error", "Error in deleting orders. Please try again later.")
          });
        }
      }
    });
  }

  openJob() {
    this.planData.tripList.forEach(trip => {
      trip.jobList.forEach(job => {
        job.orderList.forEach(order => {
          order.isChecked = false;
        });
      });
    });
    this.deleteOrdersId = [];
  }
}