import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { OrderService } from '../../services/order/order.service';
import { PdfgeneratorService } from '../../services/pdfgenerator/pdfgenerator.service';
import { ExcelgeneratorService } from '../../services/excelgenerator/excelgenerator.service';
import { DatePipe } from '@angular/common';
import { NgxDateRangePickerOptions } from '../../../ngx-daterangepicker';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BranchService } from 'src/app/services/branch/branch.service';

@Component({
  selector: 'app-orderreport',
  templateUrl: './orderreport.component.html',
  styleUrls: ['./orderreport.component.scss']
})
export class OrderreportComponent implements OnInit {

  columnsToDisplay = [
    'region',
    'branch',
    'invoice',
    'status',
    'deliveredon',
    'boxes',
    'receiver',
    'customerType',
    'lorryReceipt',
    'podDetails',
    'deliveredby',
  ];
  options: NgxDateRangePickerOptions;
  orderDataArray = new MatTableDataSource();
  page: number = 0;
  excelRecords = [];
  totalRecords: number = 0;
  range = [1, 2, 3, 4, 5];
  daterRangevalue: any = { to: "", from: "" };;
  fromDate: any;
  toDate: any;
  isDateFiltered: boolean = false;
  orders = [];
  prevFromDate: any;
  prevTodate: any;
  quarterSelectArray = [];
  isQuarterfiltered: boolean = false;
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  branchesSubscription: Subscription;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(public orderService: OrderService,
    public generatePdf: PdfgeneratorService,
    public excelService: ExcelgeneratorService,
    public datePipe: DatePipe,
    public dialogService: DialogService,
    private spinner: NgxSpinnerService,
    private branchService: BranchService
  ) { }

  ngOnInit() {
    this.prepareQuarterArray();
    this.createInitialFilter();
    this.branchesSubscription = this.branchService.branchesObservable.subscribe(() => {
      this.ngUnsubscribe.next();
      if (window.sessionStorage.getItem("selectedBranchesCodes") && JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")).length == 0) {
        this.orderDataArray.data = [];
        this.spinner.hide();
      }
      else {
        this.orderDataArray.data = [];
        this.orderFilter();
      }
    });
  }

  prepareQuarterArray() {
    let today, year, month;
    today = new Date();
    year = today.getFullYear();
    month = today.getMonth();
    this.quarterSelectArray = [year - 1 + "-Oct to Dec", year - 1 + "-Jul to Sep", year - 1 + "-Apr to Jun", year - 1 + "-Jan to Mar", year - 2 + "-Oct to Dec", year - 2 + "-Jul to Sep", year - 2 + "-Apr to Jun", year - 2 + "-Jan to Mar"]

    if (month >= 1 && month <= 3) {
      this.quarterSelectArray.unshift(year + "-Jan to Mar")
    }
    else if (month >= 4 && month <= 6) {
      this.quarterSelectArray.unshift(year + "-Jan to Mar")
      this.quarterSelectArray.unshift(year + "-Apr to Jun")
    }
    else if (month >= 6 && month <= 9) {
      this.quarterSelectArray.unshift(year + "-Jan to Mar")
      this.quarterSelectArray.unshift(year + "-Apr to Jun")
      this.quarterSelectArray.unshift(year + "-Jul to Sep")
    }
    else if (month >= 9 && month <= 12) {
      this.quarterSelectArray.unshift(year + "-Jan to Mar")
      this.quarterSelectArray.unshift(year + "-Apr to Jun")
      this.quarterSelectArray.unshift(year + "-Jul to Sep")
      this.quarterSelectArray.unshift(year + "-Oct to Dec")
    }
  }

  ngAfterViewInit() {
    this.orderDataArray.paginator = this.paginator;
  }

  exportAsXLSX() {
    this.excelRecords = [];
    let ordersData = [];
    ordersData = this.orderDataArray.data;
    ordersData.forEach(order => {
      let delivereddate, invoicenum, orderstatus, receivername, driver, vehicle, warehouse, type, boxes,
        lorryreceipt, numofpods, poduploadedby, poduploadedbyrole, custometype, createdDate, scheduledDate, plannedDate, billedDate;

      if (order.delivered_date_time)
        delivereddate = this.datePipe.transform(order.delivered_date_time, "dd-MM-yyyy hh:mm a");
      else
        delivereddate = ''
      if (order.created_date_time)
        createdDate = this.datePipe.transform(order.created_date_time, "dd-MM-yyyy hh:mm a");
      else
        createdDate = ''
      if (order.scheduled_date_time)
        scheduledDate = this.datePipe.transform(order.scheduled_date_time, "dd-MM-yyyy hh:mm a");
      else
        scheduledDate = ''

      if (order.dispatch_date_time)
        billedDate = this.datePipe.transform(order.dispatch_date_time, "dd-MM-yyyy");
      else
        billedDate = ''

      if (order.planned_date_time)
        plannedDate = this.datePipe.transform(order.planned_date_time, "dd-MM-yyyy hh:mm a");
      else
        plannedDate = ''

      invoicenum = order.invoice_number;
      orderstatus = order.orderstatus.slice(12);
      receivername = order.receiver_name;
      warehouse = order.wareHouse;
      lorryreceipt = order.lorry_receipt_number;

      if (order.driver)
        driver = order.driver;
      else
        driver = "";
      if (order.vehicle)
        vehicle = order.vehicle;
      else
        vehicle = "";
      if (order.vehicle_type)
        type = order.vehicle_type;
      else
        type = "";
      if (order.no_of_boxes)
        boxes = order.no_of_boxes;
      else
        boxes = "";

      if (lorryreceipt)
        lorryreceipt = lorryreceipt;
      else
        lorryreceipt = "";
      if (order.customer_type)
        custometype = order.customer_type
      else
        custometype = ''

      if (order) {
        this.excelRecords.push({
          'Region': order.branchRegion || '',
          'Branch': warehouse,
          'Branch Code': order.branchCode || '',
          'Invoice No.': invoicenum,
          'Status': orderstatus,
          'Billed': billedDate,
          'Planned': plannedDate,
          'Scheduled': scheduledDate,
          'Delivered': delivereddate,
          'Boxes #': boxes,
          'Receiver Name': receivername,
          'Receiver Code': order.receiver_code || '',
          'Customer Type': custometype,
          'Transporter': order.transporter || '',
          'LR No.': lorryreceipt,
          'Delivered By': driver,
          'Driver Phone No.': order.driver_phone_number || '',
          'Vehicle No.': vehicle,
          'Vehicle Type': type,
          'Total POD': order.total_pod || '',
          'POD Uploaded By [Primary]': order.pod_uploadedby_primary || '',
          'POD Uploaded Role [Primary]': order.primary_role || '',
          '# POD [Primary]': order.primary_podcount || '',
          'POD Uploaded By [Secondary]': order.pod_uploadedby_secondary || '',
          'POD Uploaded Role [Secondary]': order.secondary_role || '',
          '# POD [Secondary]': order.secondary_podcount || '',
          'POD Clean Status': order.is_pod_valid || '',
          'POD Remarks': order.pod_remarks || ''
        })
      }
    });
    this.excelService.exportAsExcelFile(this.excelRecords, 'orderReport');
  }

  /*   public captureScreen() {
      var data = document.getElementById('contentToConvert');
      html2canvas(data).then(canvas => {
        this.generatePdf.pdfgenerator(canvas, this.orderDataArray.data.length);
      });
    } */


  public isMarked = (index, score) => {
    if (score >= index + 1) {
      return 'fa-star';
    }
    else {
      return 'fa-star-o';
    }
  }

  createInitialFilter() {
    this.options = {
      theme: 'default',
      labels: ['Start', 'End'],
      menu: null,
      dateFormat: 'YYYY-MM-DD',
      outputFormat: 'DD-MM-YYYY',
      startOfWeek: 1,
      outputType: 'object',
      locale: 'en',
      date: {
        from: {
          year: new Date().getFullYear(),
          month: new Date().getMonth() + 1,
          day: new Date().getDate()
        },
        to: {
          year: new Date().getFullYear(),
          month: new Date().getMonth() + 1,
          day: new Date().getDate()
        }
      }
    };
    this.fromDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.toDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.daterRangevalue.from = this.datePipe.transform(new Date(), 'dd-MM-yyyy');
    this.daterRangevalue.to = this.datePipe.transform(new Date(), 'dd-MM-yyyy');
    this.prevFromDate = this.fromDate;
    this.prevTodate = this.toDate;
    this.isDateFiltered = true;
  }

  orderFilter() {
    if (this.isDateFiltered || this.isQuarterfiltered) {
      if (this.fromDate && this.toDate) {
        this.orderDataArray.data = [];
        this.spinner.show();
        this.getData(this.fromDate, this.toDate).pipe(takeUntil(this.ngUnsubscribe)).subscribe(orders => {
          this.spinner.hide();
          console.log("orders in report", orders);
          this.orderDataArray.data = orders;
          this.orders = orders;
          this.totalRecords = orders.length;
          this.orderDataArray.sortingDataAccessor = (order: any, property) => {
            switch (property) {
              case 'status': return order.orderstatus;
              case 'receiver': return order.receiver_name;
            }
          };
        }, Error => {
          this.spinner.hide();
        })
      }
    }
  }

  getData(from, to) {
    return this.orderService.getOrderReport(from, to);
  }

  sortByDate() {
    console.log(this.daterRangevalue)
    if (this.daterRangevalue != undefined) {
      this.fromDate = this.daterRangevalue.from.substring(6, 10) + '-' + this.daterRangevalue.from.substring(3, 5) + '-' + this.daterRangevalue.from.substring(0, 2);
      this.toDate = this.daterRangevalue.to.substring(6, 10) + '-' + this.daterRangevalue.to.substring(3, 5) + '-' + this.daterRangevalue.to.substring(0, 2);
      if (this.toDate != this.prevTodate || this.fromDate != this.prevFromDate) {
        this.ngUnsubscribe.next();
        this.isDateFiltered = true;
        this.orderFilter();
        this.prevFromDate = this.fromDate;
        this.prevTodate = this.toDate;
      }
    }

  }

  clearAllFilter() {
    this.paginator.pageIndex = 0;
    this.createInitialFilter();
    // this.orderFilter();
    this.prevFromDate = this.fromDate;
    this.prevTodate = this.toDate;
    this.isDateFiltered = false;
  }

  orderDetails(data) {
    this.dialogService.orderDetails(data, 'orderReport');
  }

  applyFilter(filterValue: string) {
    this.orderDataArray.filter = filterValue.trim().toLowerCase();
  }

  reportForQuarter(quarter) {
    this.isQuarterfiltered = true;
    if (quarter.toString().slice(5, 15) == "Jan to Mar") {
      this.fromDate = quarter.toString().slice(0, 4) + "-01-01";
      this.toDate = quarter.toString().slice(0, 4) + "-03-31";
    }
    if (quarter.toString().slice(5, 15) == "Apr to Jun") {
      this.fromDate = quarter.toString().slice(0, 4) + "-04-01"
      this.toDate = quarter.toString().slice(0, 4) + "-06-30"
    }
    if (quarter.toString().slice(5, 15) == "Jul to Sep") {
      this.fromDate = quarter.toString().slice(0, 4) + "-07-01"
      this.toDate = quarter.toString().slice(0, 4) + "-09-30"
    }
    if (quarter.toString().slice(5, 15) == "Oct to Dec") {
      this.fromDate = quarter.toString().slice(0, 4) + "-10-01"
      this.toDate = quarter.toString().slice(0, 4) + "-12-31"
    }
    this.prevFromDate = this.fromDate;
    this.prevTodate = this.toDate;
    this.orderFilter();
  }

  ngOnDestroy() {
    this.branchesSubscription.unsubscribe();
  }
}