import { Component, OnInit, ViewChild } from '@angular/core';
import { JobsService } from 'src/app/services/jobs/jobs.service';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { NgxDateRangePickerOptions } from 'src/ngx-daterangepicker';
import { ExcelgeneratorService } from 'src/app/services/excelgenerator/excelgenerator.service';
import { DatePipe } from '@angular/common';
import { OrderService } from '../../services/order/order.service';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { BranchService } from 'src/app/services/branch/branch.service';

@Component({
  selector: 'app-jobreport',
  templateUrl: './jobreport.component.html',
  styleUrls: ['./jobreport.component.scss']
})
export class JobreportComponent implements OnInit {
  columnsToDisplay = [
    'customerName',
    'customerType',
    'tripName',
    'status',
    'completedAndScheduled',
    'noOfBoxes',
    'podDetails',
    'travelTime',
    'reportingTime',
    'unloadingTime',
    'rating',
  ];
  jobDataArray = new MatTableDataSource()
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  options: NgxDateRangePickerOptions;
  page: number = 0;
  totalRecords: Number;
  excelRecords = [];
  selected = '7';
  daterRangevalue: any;
  fromDate: any;
  toDate: any;
  isDateFiltered: boolean = false;
  jobs = [];
  range = [1, 2, 3, 4, 5]
  prevFromDate: any;
  prevTodate: any;
  isLoading: boolean = false;
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  branchesSubscription: Subscription;

  constructor(
    public orderService: OrderService,
    private jobService: JobsService,
    private dialogService: DialogService,
    private excelService: ExcelgeneratorService,
    private datePipe: DatePipe,
    private branchService: BranchService
    ) { }

  ngOnInit() {
    this.createInitialFilter();
    this.branchesSubscription = this.branchService.branchesObservable.subscribe(() => {
      this.ngUnsubscribe.next();
      if (window.sessionStorage.getItem("selectedBranchesCodes") && JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")).length == 0) {
        this.jobDataArray.data = [];
        this.isLoading = false;
      }
      else {
        this.jobDataArray.data = [];
        this.jobFilter();
      }
    });
  }

  exportAsXLSX() {
    this.excelRecords = [];
    let jobDetails = [];
    jobDetails = this.jobDataArray.data;
    jobDetails.forEach(job => {
      let createdTime, reportingTime, unLoadingTime, rating, travelTime, completedOn, numofpods, poduploadedby, poduploadedbyrole, custometype;

      if (job.job_created_time)
        createdTime = this.datePipe.transform(job.job_created_time, "dd-MM-yyyy hh:mm a");
      else
        createdTime = '';

      if (job.reporting_time)
        reportingTime = this.datePipe.transform(job.reporting_time, "dd-MM-yyyy hh:mm a");
      else
        reportingTime = '';

      if (job.job_completed_on)
        completedOn = this.datePipe.transform(job.job_completed_on, "dd-MM-yyyy hh:mm a");
      else
        completedOn = '';

      if (job.unloading_time) {
        if (job.unloading_time.slice('0', '-6') != '00' || job.unloading_time.slice('-5', '-3') != '00')
          unLoadingTime = job.unloading_time.slice('0', '-6') + " hrs " + job.unloading_time.slice('-5', '-3') + " min";
        else
          unLoadingTime = '';
      }
      else
        unLoadingTime = '';

      if (job.job_travel_time) {
        if (job.job_travel_time.slice('0', '-6') != '00' || job.job_travel_time.slice('-5', '-3') != '00')
          travelTime = job.job_travel_time.slice('0', '-6') + " hrs " + job.job_travel_time.slice('-5', '-3') + " min";
        else
          travelTime = '';
      }
      else
        travelTime = '';

      if (job.feedback_rating) {
        rating = job.feedback_rating.substr(job.feedback_rating.length - 1);
      }
      else
        rating = '';

      if (job.number_of_pods)
        numofpods = job.number_of_pods
      else
        numofpods = ''
      if (job.pod_uploaded_by)
        poduploadedby = job.pod_uploaded_by
      else
        poduploadedby = ''

      if (job.role)
        poduploadedbyrole = job.role
      else
        poduploadedbyrole = ''

      if (job.customer_type)
        custometype = job.customer_type
      else
        custometype = ''

      if (job) {
        this.excelRecords.push({
          'Receiver': job.receiver,
          'Customer Type': custometype,
          'Trip Name': job.trip_name,
          'Status': job.status.slice(10),
          'Scheduled': createdTime,
          'Completed On': completedOn,
          'Boxes #': job.no_of_boxes,
          'Orders #': job.no_of_orders,
          'Pod #': numofpods,
          'Pod Uploaded By': poduploadedby,
          'Pod Uploader Role': poduploadedbyrole,
          'Travel Time': travelTime,
          'Vehicle Reporting': reportingTime,
          'Un-Loading Time': unLoadingTime,
          'Customer Rating': rating
        });
      }
    });
    this.excelService.exportAsExcelFile(this.excelRecords, 'customerReport');
  }

  createInitialFilter() {
    this.options = {
      theme: 'default',
      labels: ['Start', 'End'],
      menu: null,
      dateFormat: 'YYYY-MM-DD',
      outputFormat: 'DD-MM-YYYY',
      startOfWeek: 1,
      outputType: 'object',
      locale: 'en',
      date: {
        from: {
          year: new Date().getFullYear(),
          month: new Date().getMonth() + 1,
          day: new Date().getDate()
        },
        to: {
          year: new Date().getFullYear(),
          month: new Date().getMonth() + 1,
          day: new Date().getDate()
        }
      }
    };

    this.fromDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.toDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.isDateFiltered = true;
  }

  jobFilter() {
    if (this.isDateFiltered) {
      if (this.fromDate && this.toDate) {
        this.jobDataArray.data = [];
        this.isLoading = true;
        this.getData(this.fromDate, this.toDate).pipe(takeUntil(this.ngUnsubscribe)).subscribe(jobs => {
          this.isLoading = false;
          console.log("Jobs in report", jobs);
          this.jobDataArray.data = jobs;
          this.totalRecords = jobs.length;
          this.jobDataArray.sortingDataAccessor = (job: any, property) => {
            switch (property) {
              case 'status': return job.status;
              case 'customerName': return job.receiver;
            }
          };
          this.jobDataArray.sort = this.sort;
        }, Error => {
          this.isLoading = false;
        })
      }
    }
  }

  getData(from, to) {
    return this.jobService.getJobReports(from, to);
  }

  sortByDate() {
    if (this.daterRangevalue != undefined) {
      this.fromDate = this.daterRangevalue.from.substring(6, 10) + '-' + this.daterRangevalue.from.substring(3, 5) + '-' + this.daterRangevalue.from.substring(0, 2);
      this.toDate = this.daterRangevalue.to.substring(6, 10) + '-' + this.daterRangevalue.to.substring(3, 5) + '-' + this.daterRangevalue.to.substring(0, 2);
      if (this.toDate != this.prevTodate || this.fromDate != this.prevFromDate) {
        this.ngUnsubscribe.next();
        this.isDateFiltered = true;
        this.jobFilter();
        this.prevFromDate = this.fromDate;
        this.prevTodate = this.toDate;
      }
    }
  }

  clearAllFilter() {
    this.paginator.pageIndex = 0;
    this.createInitialFilter();
    this.isDateFiltered = false;
    this.prevFromDate = this.fromDate;
    this.prevTodate = this.toDate;
  }




  tripDetails(tripId){
    this.dialogService.tripDetails(tripId,'jobreport')
  } 

  public isMarked = (index, score) => {
    if (score >= index + 1) {
      return 'fa-star';
    }
    else {
      return 'fa-star-o';
    }
  }

  applyFilter(filterValue: string) {
    this.jobDataArray.filter = filterValue.trim().toLowerCase();
  }

  ngOnDestroy() {
    this.branchesSubscription.unsubscribe();
  }
}
